import { useState } from "react";

function save<T>(key: string, value: T) {
  console.debug(`Saving ${key} as ${value})`);
  if (!!value) {
    window.localStorage.setItem(key, typeof value === "object" ? JSON.stringify(value) : value.toString());
  } else {
    window.localStorage.removeItem(key);
  }
}

/**
 * Back a react state value in localstorage
 * @param key required id to key this storage item by
 * @param defaultValue default value for this item if not already in storage. If a function this function is called to fetch the value, with key passed as arg
 * @returns [value,setter] as per useState()
 */
export default function useLocalStorage<T>(key: string, defaultValue?: T | ((key: string) => T)) {
  const [value, setValue] = useState(() => {
    const current = window.localStorage.getItem(key);
    if (current) {
      if (/^\s*[{[].*[\]}]\s*$/.test(current)) {
        // rough match json object
        try {
          return JSON.parse(current);
        } catch (e) {
          return current;
        }
      } else return current.replace(/^"|"$/g, ""); // de-quote
    } else {
      return typeof defaultValue === "function" ? (defaultValue as (key: string) => T)(key) : defaultValue;
    }
  });
  function persistValue(value: T) {
    save(key, value);
    setValue(value);
  }

  return [value, persistValue];
}
