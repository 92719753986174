import {AppUsers} from "../../models";
export function shortDisplayName({GivenName, FamilyName}: AppUsers) {
  return [GivenName, FamilyName].filter(Boolean).join(" ");
}
const AppUserField: React.FC<{
  row: AppUsers;
}> = ({row}) => {
  return <span>{shortDisplayName(row)}</span>;
};

export default AppUserField;
