import { PersistentModel } from "aws-amplify/dist/esm/datastore";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toObjectGraph } from "../amplify/schemaHelpers";
import { models } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { View } from "@aws-amplify/ui-react";

export type ObjectGraphProps = Record<string, never>;
const ObjectGraph: React.FC<ObjectGraphProps> = () => {
  const { id, model } = useParams();
  const modelCtor = model && models()?.[model];
  const [graph, setGraph] = useState<PersistentModel | undefined>();
  const datastore = useCustomerDataStore();
  useEffect(() => {
    if (id && modelCtor) {
      (async () => {
        const item = await datastore.query(modelCtor, id);
        const { graph } = await toObjectGraph(item);
        setGraph(graph);
      })();
    }
  }, [id, modelCtor, datastore]);
  return graph ? (
    <View>
      <pre>{JSON.stringify(graph, null, 2)}</pre>
    </View>
  ) : (
    <Spinner />
  );
};

export default ObjectGraph;
