import React, { PropsWithChildren, ReactNode, useContext, useState } from "react";

import { Toast as BootstrapToast, ToastContainer, ToastProps } from "react-bootstrap";
import { ToastPosition } from "react-bootstrap/esm/ToastContainer";
export type GrlToastProps = ToastProps & {
  animation?: boolean;
  delay?: number;
  autohide?: boolean;
  bg?: string;
  className?: string;
  position?: ToastPosition;
  header?: ReactNode;
};
const ToastContext = React.createContext<((props: GrlToastProps) => void) | undefined>(undefined);

export const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [{ content = undefined, onClose, ...rest }, setToastProps] = useState<
    Partial<{
      content: ReactNode;
      onClose: ToastProps["onClose"];
    }>
  >({});
  function showToast(props?: GrlToastProps) {
    setToastProps(props || {});
  }

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <Toast
        show={!!content}
        onClose={() => {
          setToastProps({});
          onClose && onClose();
        }}
        {...rest}
      >
        {content}
      </Toast>
    </ToastContext.Provider>
  );
};

const Toast: React.FC<PropsWithChildren<GrlToastProps>> = ({
  children,
  animation = true,
  delay = 5000,
  autohide = true,
  bg = "success",
  className = "text-white",
  position = "bottom-center",
  header,
  ...rest
}) => {
  return (
    <ToastContainer className="p-3" position={position} style={{ zIndex: 9999 }}>
      <BootstrapToast delay={delay} animation={animation} autohide={autohide} bg={bg} className={className} {...rest}>
        {header && <BootstrapToast.Header closeButton>{header}</BootstrapToast.Header>}
        <BootstrapToast.Body>{children}</BootstrapToast.Body>
      </BootstrapToast>
    </ToastContainer>
  );
};
export default Toast;

export function useToast() {
  const showToast = useContext(ToastContext);
  if (!showToast) throw new Error("ToastProvider required");
  return showToast;
}
