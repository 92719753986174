/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { GhgEmissionUncertainty } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function GhgEmissionUncertaintyCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    UncertaintyName: "",
    AreaofUncertainty: "",
    UncertaintyDescription: "",
    UncertaintySource: "",
    UncertaintyEffects: "",
  };
  const [UncertaintyName, setUncertaintyName] = React.useState(
    initialValues.UncertaintyName
  );
  const [AreaofUncertainty, setAreaofUncertainty] = React.useState(
    initialValues.AreaofUncertainty
  );
  const [UncertaintyDescription, setUncertaintyDescription] = React.useState(
    initialValues.UncertaintyDescription
  );
  const [UncertaintySource, setUncertaintySource] = React.useState(
    initialValues.UncertaintySource
  );
  const [UncertaintyEffects, setUncertaintyEffects] = React.useState(
    initialValues.UncertaintyEffects
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setUncertaintyName(initialValues.UncertaintyName);
    setAreaofUncertainty(initialValues.AreaofUncertainty);
    setUncertaintyDescription(initialValues.UncertaintyDescription);
    setUncertaintySource(initialValues.UncertaintySource);
    setUncertaintyEffects(initialValues.UncertaintyEffects);
    setErrors({});
  };
  const validations = {
    UncertaintyName: [],
    AreaofUncertainty: [],
    UncertaintyDescription: [],
    UncertaintySource: [],
    UncertaintyEffects: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          UncertaintyName,
          AreaofUncertainty,
          UncertaintyDescription,
          UncertaintySource,
          UncertaintyEffects,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new GhgEmissionUncertainty(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "GhgEmissionUncertaintyCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Greenhouse Gas Emission Uncertainty"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS3 paragraph 53."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record that describes the uncertainties relevant to your quantification of your GHG emissions, including the effects of these uncertainties on the GHG emissions disclosures. You should address where uncertainty in your data and estimations impacts your disclosure, and the source and nature of the uncertainty."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Uncertainty record name (e.g. 2025 ghg emission uncertainty record)"
        isRequired={false}
        isReadOnly={false}
        value={UncertaintyName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UncertaintyName: value,
              AreaofUncertainty,
              UncertaintyDescription,
              UncertaintySource,
              UncertaintyEffects,
            };
            const result = onChange(modelFields);
            value = result?.UncertaintyName ?? value;
          }
          if (errors.UncertaintyName?.hasError) {
            runValidationTasks("UncertaintyName", value);
          }
          setUncertaintyName(value);
        }}
        onBlur={() => runValidationTasks("UncertaintyName", UncertaintyName)}
        errorMessage={errors.UncertaintyName?.errorMessage}
        hasError={errors.UncertaintyName?.hasError}
        {...getOverrideProps(overrides, "UncertaintyName")}
      ></TextField>
      <TextAreaField
        label="Where uncertainty in greenhouse gas emission quantification impacts disclosure"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UncertaintyName,
              AreaofUncertainty: value,
              UncertaintyDescription,
              UncertaintySource,
              UncertaintyEffects,
            };
            const result = onChange(modelFields);
            value = result?.AreaofUncertainty ?? value;
          }
          if (errors.AreaofUncertainty?.hasError) {
            runValidationTasks("AreaofUncertainty", value);
          }
          setAreaofUncertainty(value);
        }}
        onBlur={() =>
          runValidationTasks("AreaofUncertainty", AreaofUncertainty)
        }
        errorMessage={errors.AreaofUncertainty?.errorMessage}
        hasError={errors.AreaofUncertainty?.hasError}
        {...getOverrideProps(overrides, "AreaofUncertainty")}
      ></TextAreaField>
      <TextAreaField
        label="Description of uncertainty"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UncertaintyName,
              AreaofUncertainty,
              UncertaintyDescription: value,
              UncertaintySource,
              UncertaintyEffects,
            };
            const result = onChange(modelFields);
            value = result?.UncertaintyDescription ?? value;
          }
          if (errors.UncertaintyDescription?.hasError) {
            runValidationTasks("UncertaintyDescription", value);
          }
          setUncertaintyDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("UncertaintyDescription", UncertaintyDescription)
        }
        errorMessage={errors.UncertaintyDescription?.errorMessage}
        hasError={errors.UncertaintyDescription?.hasError}
        {...getOverrideProps(overrides, "UncertaintyDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Source of uncertainty"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UncertaintyName,
              AreaofUncertainty,
              UncertaintyDescription,
              UncertaintySource: value,
              UncertaintyEffects,
            };
            const result = onChange(modelFields);
            value = result?.UncertaintySource ?? value;
          }
          if (errors.UncertaintySource?.hasError) {
            runValidationTasks("UncertaintySource", value);
          }
          setUncertaintySource(value);
        }}
        onBlur={() =>
          runValidationTasks("UncertaintySource", UncertaintySource)
        }
        errorMessage={errors.UncertaintySource?.errorMessage}
        hasError={errors.UncertaintySource?.hasError}
        {...getOverrideProps(overrides, "UncertaintySource")}
      ></TextAreaField>
      <TextAreaField
        label="Effects of these uncertainties on the GHG emissions disclosure"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              UncertaintyName,
              AreaofUncertainty,
              UncertaintyDescription,
              UncertaintySource,
              UncertaintyEffects: value,
            };
            const result = onChange(modelFields);
            value = result?.UncertaintyEffects ?? value;
          }
          if (errors.UncertaintyEffects?.hasError) {
            runValidationTasks("UncertaintyEffects", value);
          }
          setUncertaintyEffects(value);
        }}
        onBlur={() =>
          runValidationTasks("UncertaintyEffects", UncertaintyEffects)
        }
        errorMessage={errors.UncertaintyEffects?.errorMessage}
        hasError={errors.UncertaintyEffects?.hasError}
        {...getOverrideProps(overrides, "UncertaintyEffects")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
