import { Card, Col, Container, Row, Stack } from "react-bootstrap";

import { Accordion, Button, TextField } from "@aws-amplify/ui-react";
import { PersistentModel, PersistentModelConstructor, isEnumFieldType } from "aws-amplify/datastore";
import { isEmpty, memoize } from "lodash";
import { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { BsSearch, BsX } from "react-icons/bs";
import DeleteItemButton from "../action/DeleteItemButton";
import { combinedSchemaFor, defaultFilter, findUniqueFieldName, isScalar, validateDataItem } from "../amplify/schemaHelpers";
import { models, uischema } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { renderField } from "../field/renderField";
import { useModal } from "../modal/useModal";
import ContentReplace from "../util/ContentReplace";
import { FormStackProvider } from "../util/FormStack";
import styles from "./Search.module.css";

function ItemCard({ item, replaceRe }: { item: PersistentModel; replaceRe?: RegExp }) {
  const model = item.constructor as PersistentModelConstructor<any>;
  const modelSchema = uischema().models[model.name];
  const { discriminatorField } = modelSchema;
  const subtype = discriminatorField && item[discriminatorField];
  const { displayName, fields } = combinedSchemaFor({ model, subtype });
  const ItemModal = useModal({ model, subtype });
  const nameField = findUniqueFieldName(model);
  function renderMatched(text: string) {
    return <span className={styles.match}>{text}</span>;
  }

  return (
    <Card className={styles.result}>
      <Card.Header>
        {displayName}
        {nameField && (
          <>
            {": "}
            <ContentReplace text={item[nameField]} regEx={replaceRe} render={renderMatched} />
          </>
        )}
      </Card.Header>
      <Card.Body>
        <dl>
          {fields &&
            Object.values(fields)
              .filter(isScalar)
              .filter((field) => !field?.hideFromSearch && defaultFilter(modelSchema, field))
              .filter(({ name }) => ![nameField].includes(name))
              .sort(({ order: order1 }, { order: order2 }) => (order1 || 0) - (order2 || 0))
              .map((field, i) => {
                const renderer = memoize(renderField)(field);
                const { name, displayName } = field;
                const value = item[name];
                const rendered = value && renderer({ value, row: item });
                return (
                  value && (
                    <Fragment key={i}>
                      <dt>{displayName}</dt>
                      <dd>
                        {typeof rendered === "string" ? (
                          <ContentReplace text={renderer({ value })} regEx={replaceRe} render={renderMatched} />
                        ) : (
                          rendered
                        )}
                      </dd>
                    </Fragment>
                  )
                );
              })}
        </dl>
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end gap-2">
        <DeleteItemButton model={model} id={item.id} />
        <ItemModal.Edit size="small" id={item.id} />
      </Card.Footer>
    </Card>
  );
}

function SearchModel({
  model,
  text,
  textRe
}: {
  model: PersistentModelConstructor<any>;
  text: string;
  textRe?: RegExp;
}): ReactNode {
  const customerDataStore = useCustomerDataStore();
  const modelName = model.name;
  const modelSchema = uischema().models?.[modelName];
  const searchableFields = useMemo(
    () =>
      text &&
      modelSchema &&
      modelSchema.fields &&
      Object.values(modelSchema.fields)
        .filter(
          ({ type, isArray, hideFromSearch }) => !hideFromSearch && ((!isArray && isEnumFieldType(type)) || type === "String")
        )
        .filter((field) => defaultFilter(modelSchema, field)),
    [text, modelSchema]
  );

  const lastSearch = useRef<string>();
  const [results, setResults] = useState<PersistentModel[] | undefined>();
  useEffect(() => {
    lastSearch.current = text;
  }, [text]);
  useEffect(() => {
    if (searchableFields && !isEmpty(searchableFields)) {
      (async () => {
        const predicate = (c: any) =>
          c.or((c1: any) =>
            searchableFields.map(({ name: fieldName }) => {
              return c1[fieldName].contains(text);
            })
          );
        const modelResults = await customerDataStore.query(model, predicate);
        const validResults = modelResults.filter(validateDataItem);
        if (lastSearch.current === text) {
          setResults(validResults);
        }
      })();
    }
  }, [model, text, customerDataStore, searchableFields]);
  return (
    results &&
    !isEmpty(results) && (
      <Accordion.Item value={modelName} key={modelName}>
        <Accordion.Trigger>
          <span>
            <b>{modelSchema?.displayName}</b> items <small>({results.length} matched)</small>
          </span>
          <Accordion.Icon />
        </Accordion.Trigger>
        <Accordion.Content>
          <div className={styles.results}>
            {results.map((item, i) => (
              <ItemCard key={i} item={item} replaceRe={textRe} />
            ))}
          </div>
        </Accordion.Content>
      </Accordion.Item>
    )
  );
}

export default function Search(): ReactNode {
  const allModels = models();
  const allSchemaModels = uischema().models;
  const searchModels = useMemo(
    () =>
      Object.values(allSchemaModels)
        .filter(({ hideFromSearch }) => !hideFromSearch)
        .sort(({ displayName: a }, { displayName: b }) => (a || "").localeCompare(b || ""))
        .map(({ name }) => allModels[name]),
    [allModels, allSchemaModels]
  );
  const [{ text, cleanText, textRe }, setText] = useState<Partial<{ text: string; cleanText: string; textRe: RegExp }>>({});
  function handleSearch(text?: string) {
    const cleanText = text && text.trim();
    setText({
      text: text,
      cleanText: cleanText,
      textRe: cleanText ? new RegExp(cleanText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g") : undefined
    });
  }
  return (
    <Stack className="page p-2">
      <FormStackProvider
        onChange={(currentIndex: number) => {
          if (currentIndex === 0) handleSearch(text);
        }}
        baseBreadcrumbName="Search"
        base={
          <>
            <Container fluid>
              <Row className="align-items-center">
                <Col xs={3}>
                  <TextField
                    value={text || ""}
                    label="Search Text"
                    onChange={({ target: { value } }) => {
                      handleSearch(value);
                    }}
                    innerEndComponent={
                      text && (
                        <Button variation="link" onClick={() => handleSearch()}>
                          <BsX />
                        </Button>
                      )
                    }
                    outerEndComponent={
                      <>
                        <Button onClick={() => handleSearch(text)}>
                          <BsSearch />
                        </Button>
                      </>
                    }
                  />
                </Col>
              </Row>
            </Container>
            <hr></hr>
            {!!cleanText && (
              <Accordion.Container>
                {searchModels.map((model, i) => (
                  <SearchModel model={model} text={cleanText} textRe={textRe} key={i.toString()} />
                ))}
              </Accordion.Container>
            )}

            {!text && "Enter search text to find data items with a case-sensitive match"}
          </>
        }
      />
    </Stack>
  );
}
