/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { RiskManagementProcesses } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RiskManagementProcessesCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    RiskMgmtProcessName: "",
    ProcessDescription: "",
    ProcessType: "",
    IntegrationRiskMgmt: "",
    TimeHorizon: "",
    ValueChainCoverage: "",
    ValueChainCoverageExclusions: "",
    FrequencyAssessment: "",
  };
  const [RiskMgmtProcessName, setRiskMgmtProcessName] = React.useState(
    initialValues.RiskMgmtProcessName
  );
  const [ProcessDescription, setProcessDescription] = React.useState(
    initialValues.ProcessDescription
  );
  const [ProcessType, setProcessType] = React.useState(
    initialValues.ProcessType
  );
  const [IntegrationRiskMgmt, setIntegrationRiskMgmt] = React.useState(
    initialValues.IntegrationRiskMgmt
  );
  const [TimeHorizon, setTimeHorizon] = React.useState(
    initialValues.TimeHorizon
  );
  const [ValueChainCoverage, setValueChainCoverage] = React.useState(
    initialValues.ValueChainCoverage
  );
  const [ValueChainCoverageExclusions, setValueChainCoverageExclusions] =
    React.useState(initialValues.ValueChainCoverageExclusions);
  const [FrequencyAssessment, setFrequencyAssessment] = React.useState(
    initialValues.FrequencyAssessment
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setRiskMgmtProcessName(initialValues.RiskMgmtProcessName);
    setProcessDescription(initialValues.ProcessDescription);
    setProcessType(initialValues.ProcessType);
    setIntegrationRiskMgmt(initialValues.IntegrationRiskMgmt);
    setTimeHorizon(initialValues.TimeHorizon);
    setValueChainCoverage(initialValues.ValueChainCoverage);
    setValueChainCoverageExclusions(initialValues.ValueChainCoverageExclusions);
    setFrequencyAssessment(initialValues.FrequencyAssessment);
    setErrors({});
  };
  const validations = {
    RiskMgmtProcessName: [],
    ProcessDescription: [],
    ProcessType: [],
    IntegrationRiskMgmt: [],
    TimeHorizon: [],
    ValueChainCoverage: [],
    ValueChainCoverageExclusions: [],
    FrequencyAssessment: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          RiskMgmtProcessName,
          ProcessDescription,
          ProcessType,
          IntegrationRiskMgmt,
          TimeHorizon,
          ValueChainCoverage,
          ValueChainCoverageExclusions,
          FrequencyAssessment,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new RiskManagementProcesses(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "RiskManagementProcessesCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Risk Management Process"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 Paragraph 18a, 18b, 19a-e."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Create a record that describes a process used to identify, assess, or manage climate-related risks, and how this process is integrated into existing risk management processes."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Divider>
      <TextField
        label="Risk management process name"
        isRequired={false}
        isReadOnly={false}
        value={RiskMgmtProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskMgmtProcessName: value,
              ProcessDescription,
              ProcessType,
              IntegrationRiskMgmt,
              TimeHorizon,
              ValueChainCoverage,
              ValueChainCoverageExclusions,
              FrequencyAssessment,
            };
            const result = onChange(modelFields);
            value = result?.RiskMgmtProcessName ?? value;
          }
          if (errors.RiskMgmtProcessName?.hasError) {
            runValidationTasks("RiskMgmtProcessName", value);
          }
          setRiskMgmtProcessName(value);
        }}
        onBlur={() =>
          runValidationTasks("RiskMgmtProcessName", RiskMgmtProcessName)
        }
        errorMessage={errors.RiskMgmtProcessName?.errorMessage}
        hasError={errors.RiskMgmtProcessName?.hasError}
        {...getOverrideProps(overrides, "RiskMgmtProcessName")}
      ></TextField>
      <TextAreaField
        label="Process description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskMgmtProcessName,
              ProcessDescription: value,
              ProcessType,
              IntegrationRiskMgmt,
              TimeHorizon,
              ValueChainCoverage,
              ValueChainCoverageExclusions,
              FrequencyAssessment,
            };
            const result = onChange(modelFields);
            value = result?.ProcessDescription ?? value;
          }
          if (errors.ProcessDescription?.hasError) {
            runValidationTasks("ProcessDescription", value);
          }
          setProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ProcessDescription", ProcessDescription)
        }
        errorMessage={errors.ProcessDescription?.errorMessage}
        hasError={errors.ProcessDescription?.hasError}
        {...getOverrideProps(overrides, "ProcessDescription")}
      ></TextAreaField>
      <SelectField
        label="Process type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ProcessType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskMgmtProcessName,
              ProcessDescription,
              ProcessType: value,
              IntegrationRiskMgmt,
              TimeHorizon,
              ValueChainCoverage,
              ValueChainCoverageExclusions,
              FrequencyAssessment,
            };
            const result = onChange(modelFields);
            value = result?.ProcessType ?? value;
          }
          if (errors.ProcessType?.hasError) {
            runValidationTasks("ProcessType", value);
          }
          setProcessType(value);
        }}
        onBlur={() => runValidationTasks("ProcessType", ProcessType)}
        errorMessage={errors.ProcessType?.errorMessage}
        hasError={errors.ProcessType?.hasError}
        {...getOverrideProps(overrides, "ProcessType")}
      >
        <option
          children="Identify"
          value="Identify"
          {...getOverrideProps(overrides, "ProcessTypeoption0")}
        ></option>
        <option
          children="Assess"
          value="Assess"
          {...getOverrideProps(overrides, "ProcessTypeoption1")}
        ></option>
        <option
          children="Manage"
          value="Manage"
          {...getOverrideProps(overrides, "ProcessTypeoption2")}
        ></option>
        <option
          children="Report"
          value="Report"
          {...getOverrideProps(overrides, "ProcessTypeoption3")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Describe integration into overall risk management processes"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskMgmtProcessName,
              ProcessDescription,
              ProcessType,
              IntegrationRiskMgmt: value,
              TimeHorizon,
              ValueChainCoverage,
              ValueChainCoverageExclusions,
              FrequencyAssessment,
            };
            const result = onChange(modelFields);
            value = result?.IntegrationRiskMgmt ?? value;
          }
          if (errors.IntegrationRiskMgmt?.hasError) {
            runValidationTasks("IntegrationRiskMgmt", value);
          }
          setIntegrationRiskMgmt(value);
        }}
        onBlur={() =>
          runValidationTasks("IntegrationRiskMgmt", IntegrationRiskMgmt)
        }
        errorMessage={errors.IntegrationRiskMgmt?.errorMessage}
        hasError={errors.IntegrationRiskMgmt?.hasError}
        {...getOverrideProps(overrides, "IntegrationRiskMgmt")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <SelectField
          label="Time horizon process operates over"
          placeholder="Please select an option"
          isDisabled={false}
          value={TimeHorizon}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskMgmtProcessName,
                ProcessDescription,
                ProcessType,
                IntegrationRiskMgmt,
                TimeHorizon: value,
                ValueChainCoverage,
                ValueChainCoverageExclusions,
                FrequencyAssessment,
              };
              const result = onChange(modelFields);
              value = result?.TimeHorizon ?? value;
            }
            if (errors.TimeHorizon?.hasError) {
              runValidationTasks("TimeHorizon", value);
            }
            setTimeHorizon(value);
          }}
          onBlur={() => runValidationTasks("TimeHorizon", TimeHorizon)}
          errorMessage={errors.TimeHorizon?.errorMessage}
          hasError={errors.TimeHorizon?.hasError}
          {...getOverrideProps(overrides, "TimeHorizon")}
        >
          <option
            children="Current"
            value="Current"
            {...getOverrideProps(overrides, "TimeHorizonoption0")}
          ></option>
          <option
            children="Short term"
            value="Short_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption1")}
          ></option>
          <option
            children="Medium term"
            value="Medium_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption2")}
          ></option>
          <option
            children="Long term"
            value="Long_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption3")}
          ></option>
          <option
            children="All climate planning horizons"
            value="All_Climate_Planning_Horizons"
            {...getOverrideProps(overrides, "TimeHorizonoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Value chain coverage"
          placeholder="Please select an option"
          isDisabled={false}
          value={ValueChainCoverage}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskMgmtProcessName,
                ProcessDescription,
                ProcessType,
                IntegrationRiskMgmt,
                TimeHorizon,
                ValueChainCoverage: value,
                ValueChainCoverageExclusions,
                FrequencyAssessment,
              };
              const result = onChange(modelFields);
              value = result?.ValueChainCoverage ?? value;
            }
            if (errors.ValueChainCoverage?.hasError) {
              runValidationTasks("ValueChainCoverage", value);
            }
            setValueChainCoverage(value);
          }}
          onBlur={() =>
            runValidationTasks("ValueChainCoverage", ValueChainCoverage)
          }
          errorMessage={errors.ValueChainCoverage?.errorMessage}
          hasError={errors.ValueChainCoverage?.hasError}
          {...getOverrideProps(overrides, "ValueChainCoverage")}
        >
          <option
            children="Upstream"
            value="Upstream"
            {...getOverrideProps(overrides, "ValueChainCoverageoption0")}
          ></option>
          <option
            children="Direct operations"
            value="Direct_Operations"
            {...getOverrideProps(overrides, "ValueChainCoverageoption1")}
          ></option>
          <option
            children="Downstream"
            value="Downstream"
            {...getOverrideProps(overrides, "ValueChainCoverageoption2")}
          ></option>
          <option
            children="All segments"
            value="All_Segments"
            {...getOverrideProps(overrides, "ValueChainCoverageoption3")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "ValueChainCoverageoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <SelectField
          label="Value chain coverage exclusions"
          placeholder="Please select an option"
          isDisabled={false}
          value={ValueChainCoverageExclusions}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskMgmtProcessName,
                ProcessDescription,
                ProcessType,
                IntegrationRiskMgmt,
                TimeHorizon,
                ValueChainCoverage,
                ValueChainCoverageExclusions: value,
                FrequencyAssessment,
              };
              const result = onChange(modelFields);
              value = result?.ValueChainCoverageExclusions ?? value;
            }
            if (errors.ValueChainCoverageExclusions?.hasError) {
              runValidationTasks("ValueChainCoverageExclusions", value);
            }
            setValueChainCoverageExclusions(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ValueChainCoverageExclusions",
              ValueChainCoverageExclusions
            )
          }
          errorMessage={errors.ValueChainCoverageExclusions?.errorMessage}
          hasError={errors.ValueChainCoverageExclusions?.hasError}
          {...getOverrideProps(overrides, "ValueChainCoverageExclusions")}
        >
          <option
            children="Upstream"
            value="Upstream"
            {...getOverrideProps(
              overrides,
              "ValueChainCoverageExclusionsoption0"
            )}
          ></option>
          <option
            children="Direct operations"
            value="Direct_Operations"
            {...getOverrideProps(
              overrides,
              "ValueChainCoverageExclusionsoption1"
            )}
          ></option>
          <option
            children="Downstream"
            value="Downstream"
            {...getOverrideProps(
              overrides,
              "ValueChainCoverageExclusionsoption2"
            )}
          ></option>
          <option
            children="All segments"
            value="All_Segments"
            {...getOverrideProps(
              overrides,
              "ValueChainCoverageExclusionsoption3"
            )}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(
              overrides,
              "ValueChainCoverageExclusionsoption4"
            )}
          ></option>
        </SelectField>
        <SelectField
          label="Frequency of assessment"
          placeholder="Please select an option"
          isDisabled={false}
          value={FrequencyAssessment}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskMgmtProcessName,
                ProcessDescription,
                ProcessType,
                IntegrationRiskMgmt,
                TimeHorizon,
                ValueChainCoverage,
                ValueChainCoverageExclusions,
                FrequencyAssessment: value,
              };
              const result = onChange(modelFields);
              value = result?.FrequencyAssessment ?? value;
            }
            if (errors.FrequencyAssessment?.hasError) {
              runValidationTasks("FrequencyAssessment", value);
            }
            setFrequencyAssessment(value);
          }}
          onBlur={() =>
            runValidationTasks("FrequencyAssessment", FrequencyAssessment)
          }
          errorMessage={errors.FrequencyAssessment?.errorMessage}
          hasError={errors.FrequencyAssessment?.hasError}
          {...getOverrideProps(overrides, "FrequencyAssessment")}
        >
          <option
            children="Daily"
            value="Daily"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption0")}
          ></option>
          <option
            children="Weekly"
            value="Weekly"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption1")}
          ></option>
          <option
            children="Fortnightly"
            value="Fortnightly"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption2")}
          ></option>
          <option
            children="Monthly"
            value="Monthly"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption3")}
          ></option>
          <option
            children="Quarterly"
            value="Quarterly"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption4")}
          ></option>
          <option
            children="Half yearly"
            value="Half_Yearly"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption5")}
          ></option>
          <option
            children="Yearly"
            value="Yearly"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption6")}
          ></option>
          <option
            children="Every two years"
            value="Every_Two_Years"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption7")}
          ></option>
          <option
            children="Every three years"
            value="Every_Three_Years"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption8")}
          ></option>
          <option
            children="Adhoc"
            value="Adhoc"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption9")}
          ></option>
          <option
            children="Never"
            value="Never"
            {...getOverrideProps(overrides, "FrequencyAssessmentoption10")}
          ></option>
        </SelectField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
