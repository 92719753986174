import Ajv from "ajv";
import addErrorMessages from "ajv-errors";
import addFormats from "ajv-formats";
import addKeywords from "ajv-keywords";
import S, { ArraySchema, BaseSchema, JSONSchema, ObjectSchema } from "fluent-json-schema";
export const ajv = addKeywords(
  addFormats(new Ajv({ discriminator: true, strict: false, allErrors: true }), [
    "date-time",
    "time",
    "date",
    "email",
    "hostname",
    "ipv4",
    "ipv6",
    "uri",
    "uri-reference",
    "uuid",
    "uri-template",
    "json-pointer",
    "relative-json-pointer",
    "regex"
  ])
);
addErrorMessages(ajv);

// Shortcut types for frequently used ones
export const String = S.string();
export const RequiredString = String.required();
export const Float = S.number();
export const RequiredFloat = Float.required();
export const Int = S.integer();
export const RequiredInt = Int.required();
export const Bool = S.boolean();
export const RequiredBool = Bool.required();
export const AWSDate = S.string().format("date");
export const RequiredAWSDate = AWSDate.required();
export const AWSDateTime = S.string().format("date-time");
export const RequiredAWSDateTime = AWSDateTime.required();
export const Enum = (enumType: Record<string, any>) => S.enum(Object.values(enumType));
export const RequiredEnum = (enumType: Record<string, any>) => Enum(enumType).required();

// Formatted types
export const Year = S.string()
  .pattern("^\\d{4}$")
  .raw({ errorMessage: { pattern: "should have format YYYY" } });
export const RequiredYear = Year.required();

export const Latitude = Float.minimum(-90.0).maximum(90.0);
export const Longitude = Float.minimum(-180.0).maximum(180);

// Helpers for uischema json schema overrides, for common idioms
export function isArraySchema(s: JSONSchema): s is ArraySchema {
  return (s.valueOf() as { type: string })?.type === "array";
}
export function isObjectSchema(s: JSONSchema): s is ObjectSchema {
  return (s.valueOf() as { type: string })?.type === "object";
}

/**
 *
 * @param array Make elements of Array optional (by default we assume at least one)
 * @returns
 */
export const AsOptionalArray = (array: JSONSchema) => (array as ArraySchema).minItems(0); // Make array optional

/**
 *
 * @param fields Narrow to given set of fields
 * @returns
 */
export function WithFields(...fields: string[]) {
  return (object: ObjectSchema) => object.only(fields);
}

export const AsOptional = (item: JSONSchema) => {
  const unrequired = (item as BaseSchema<any>).raw({ required: [] });
  return isArraySchema(unrequired) ? AsOptionalArray(unrequired) : unrequired;
};
