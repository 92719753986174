import { Button, HighlightMatch } from "@aws-amplify/ui-react";
import React, { ReactNode, useState } from "react";
import { Card, CardBody, CardHeader, CardProps } from "react-bootstrap";
import styles from "../../shared/wizard/Wizard.module.css";
import { omit, startCase, truncate } from "lodash";
import { BsCheckCircleFill } from "react-icons/bs";
import clsx from "clsx";
export function RecordCard({
  truncateField = 100,
  header,
  record,
  matched = "",
  selected,
  className,
  renderFieldTitle = (title: string) => startCase(title),
  ...rest
}: CardProps & {
  header: string;
  record: Record<string, ReactNode>;
  matched?: string;
  selected?: boolean;
  truncateField?: number;
  renderFieldTitle?: (title: string) => string;
}) {
  function Field({ value, matched }: { value: any; matched: string }) {
    const [more, setMore] = useState(false);
    return (
      <span>
        {typeof value === "string" ? (
          <HighlightMatch query={matched}>
            {more ? value : truncate(value, { length: truncateField, separator: " " })}
          </HighlightMatch>
        ) : (
          value
        )}
        {value.length > truncateField && (
          <span>
            &nbsp;
            <Button
              variation="link"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setMore(!more);
              }}
            >
              See {more ? "Less" : "More"}
            </Button>
          </span>
        )}
      </span>
    );
  }
  return (
    <Card className={clsx(className, styles.recordCard)} {...rest}>
      <CardHeader>
        <div className="d-flex gap-2">
          {record[header] && <Field value={record[header]} matched={matched} />}
          {selected && (
            <div className="ms-auto">
              <BsCheckCircleFill className={styles.check} size={"2em"} />
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <dl>
          {Object.entries(omit(record, header))
            .filter(([_, v]) => !!v)
            .map(([field, value], i) => (
              <React.Fragment key={i}>
                <dt>{renderFieldTitle(field)}</dt>
                <dd>
                  <Field value={value} matched={matched} />
                </dd>
              </React.Fragment>
            ))}
        </dl>
      </CardBody>
    </Card>
  );
}
