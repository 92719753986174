import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { EnumField } from "./EnumField";
import style from "./renderField.module.css";
/**
 * Return a function which will render values of the given schema field
 * @param {*} field
 * @returns
 */
export function renderField({ type = undefined, isArray, customRenderer }, { editor } = {}) {
  if (customRenderer) {
    return customRenderer;
  } else {
    switch (typeof type) {
      case "string":
        switch (type) {
          case "Boolean":
            return ({ value: bool }) => <div className={style.booleanField}>{bool ? <BsCheckCircle /> : <BsCircle />}</div>;
          case "AWSDate":
            return ({ value: date }) => date && new Date(date).toLocaleDateString();
          case "AWSDateTime":
            return ({ value: dateTime }) => dateTime && new Date(dateTime).toLocaleString();
          case "AWSURL":
            return ({ value: url }) =>
              url && (
                <a href={url} target="_blank" rel="noreferrer">
                  {url}
                </a>
              );
          default:
            break;
        }
        break;
      case "object": {
        if (type.enum) {
          return ({ value: enumValues, onChange }) =>
            enumValues && (
              <EnumField current={enumValues} onChange={onChange} enumName={type.enum} multiple={isArray} editor={editor} />
            );
        }
        break;
      }
      default:
        break;
    }

    return ({ value }) => value;
  }
}
