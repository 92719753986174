import HelpBox from "./HelpBox";

export default function InformGovernanceProcessHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Inform Governance Process Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to create a record that describes a process used by the governance body to stay informed about climate-related risks and opportunities.
                </p>
                <p>
                    Follow these steps to define your inform governance process record:
                </p>
                <ol>
                    <li>Enter a name for the inform governance process.</li>
                    <li>Enter a description of the inform governance process.</li>
                    <li>Select a value for how often this process operates from the <i>Process frequency</i> dropdown menu.</li>
                    <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of your inform governance process.</li>
                    <li>Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
