import React, { PropsWithChildren, useContext } from "react";
import { post } from "aws-amplify/api";
import { chunk, isEmpty, isEqual, omit } from "lodash";

const drainPeriod = 10000; // can get more fancy by adjusting!
const logEventsChunk = 100;
const disabled = process.env.NODE_ENV !== "production"; //  disable in debug

type LogEvent = {
  level: "DEBUG" | "INFO" | "WARN" | "ERROR";
  timestamp: number;
  message: string;
  [key: string]: any;
};
export const LoggerContext = React.createContext<Partial<LogEvent>>({});

let logBuffer: LogEvent[] = [];

window.addEventListener("load", () => {
  const logWorkerId = window.setInterval(() => {
    (async () => {
      if (!isEmpty(logBuffer)) {
        await Promise.all(
          chunk(logBuffer, logEventsChunk).map(async (eventsChunk) => {
            try {
              await post({
                apiName: "support",
                path: "/log",
                options: {
                  body: {
                    logEvents: eventsChunk
                  }
                }
              }).response;
            } catch (e) {
              console.debug(`Log failed ${(e as Error).message}`, eventsChunk);
            }
          })
        );
        logBuffer = [];
      }
    })();
  }, drainPeriod);
  window.addEventListener("unload", () => window.clearTimeout(logWorkerId));
});
export class Logger {
  public context: Partial<LogEvent>;
  public filter: ((event: LogEvent) => boolean) | undefined;
  constructor(context?: Partial<LogEvent>) {
    this.context = context || {};
    this.filter = undefined;
  }
  log(level: LogEvent["level"], message: string, extra?: Partial<LogEvent>): void {
    if (logBuffer) {
      const event: Partial<LogEvent> = {
        ...this.context,
        ...extra,
        level,
        message
      };
      if (
        (!disabled && logBuffer.length === 0) ||
        !isEqual(omit(logBuffer.at(-1), "timestamp"), event) // ignore dup
      ) {
        event.timestamp = new Date().getTime();
        if (!this.filter || this.filter(event as LogEvent)) {
          logBuffer.push(event as LogEvent);
        }
      }
    }
  }
  debug(message: string, extra?: Partial<LogEvent>) {
    this.log("DEBUG", message, extra);
  }
  info(message: string, extra?: Partial<LogEvent>) {
    this.log("INFO", message, extra);
  }
  warn(message: string, extra?: Partial<LogEvent>) {
    this.log("WARN", message, extra);
  }
  error(message: string, extra?: Partial<LogEvent>) {
    this.log("ERROR", message, extra);
  }
}

export const logger = new Logger();
// Global error handling
window.addEventListener("error", (errorEvent) => {
  logger.error("Error", errorEvent);
});
window.addEventListener("unhandledrejection", ({ reason }) => {
  logger.error("Unhandled Promise Rejection", reason);
});

const _log = console.log;
const _error = console.error;
const _warn = console.warn;

console.error = function (...args) {
  logger.error(args[0], { args: args.slice(1) });
  _error.apply(console, args);
};

console.log = function (...args) {
  logger.info(args[0], { args: args.slice(1) });
  _log.apply(console, args);
};

console.warn = function (...args) {
  logger.warn(args[0], { args: args.slice(1) });
  _warn.apply(console, args);
};
export type LoggerProviderProps = Partial<
  PropsWithChildren<{
    extra: { [key: string]: any };
  }>
>;

const LoggerProvider: React.FC<LoggerProviderProps> = ({ extra, children }) => {
  const context = useContext(LoggerContext);
  return <LoggerContext.Provider value={{ ...context, ...(extra || {}) }}>{children}</LoggerContext.Provider>;
};

export default LoggerProvider;

export function useLogger() {
  const context = useContext(LoggerContext);
  return new Logger(context);
}
