/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Organisation } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function OrganisationCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    OrganisationName: "",
    Description: "",
    PrimaryOperatingCountry: "",
    SecondaryOperatingCountry: "",
    Currency: "",
    OrganisationIdentifierType: "",
    OrganisationIdentifier: "",
    AggregateWithParent: "",
  };
  const [OrganisationName, setOrganisationName] = React.useState(
    initialValues.OrganisationName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [PrimaryOperatingCountry, setPrimaryOperatingCountry] = React.useState(
    initialValues.PrimaryOperatingCountry
  );
  const [SecondaryOperatingCountry, setSecondaryOperatingCountry] =
    React.useState(initialValues.SecondaryOperatingCountry);
  const [Currency, setCurrency] = React.useState(initialValues.Currency);
  const [OrganisationIdentifierType, setOrganisationIdentifierType] =
    React.useState(initialValues.OrganisationIdentifierType);
  const [OrganisationIdentifier, setOrganisationIdentifier] = React.useState(
    initialValues.OrganisationIdentifier
  );
  const [AggregateWithParent, setAggregateWithParent] = React.useState(
    initialValues.AggregateWithParent
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setOrganisationName(initialValues.OrganisationName);
    setDescription(initialValues.Description);
    setPrimaryOperatingCountry(initialValues.PrimaryOperatingCountry);
    setSecondaryOperatingCountry(initialValues.SecondaryOperatingCountry);
    setCurrency(initialValues.Currency);
    setOrganisationIdentifierType(initialValues.OrganisationIdentifierType);
    setOrganisationIdentifier(initialValues.OrganisationIdentifier);
    setAggregateWithParent(initialValues.AggregateWithParent);
    setErrors({});
  };
  const validations = {
    OrganisationName: [{ type: "Required" }],
    Description: [],
    PrimaryOperatingCountry: [],
    SecondaryOperatingCountry: [],
    Currency: [],
    OrganisationIdentifierType: [],
    OrganisationIdentifier: [],
    AggregateWithParent: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          OrganisationName,
          Description,
          PrimaryOperatingCountry,
          SecondaryOperatingCountry,
          Currency,
          OrganisationIdentifierType,
          OrganisationIdentifier,
          AggregateWithParent,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Organisation(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "OrganisationCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Reporting Entity"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS3 Paragraphs 21, 23."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record describing your organisation in the fields below."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Organisation name"
        isRequired={true}
        isReadOnly={false}
        value={OrganisationName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OrganisationName: value,
              Description,
              PrimaryOperatingCountry,
              SecondaryOperatingCountry,
              Currency,
              OrganisationIdentifierType,
              OrganisationIdentifier,
              AggregateWithParent,
            };
            const result = onChange(modelFields);
            value = result?.OrganisationName ?? value;
          }
          if (errors.OrganisationName?.hasError) {
            runValidationTasks("OrganisationName", value);
          }
          setOrganisationName(value);
        }}
        onBlur={() => runValidationTasks("OrganisationName", OrganisationName)}
        errorMessage={errors.OrganisationName?.errorMessage}
        hasError={errors.OrganisationName?.hasError}
        {...getOverrideProps(overrides, "OrganisationName")}
      ></TextField>
      <TextAreaField
        label="Describe your organisation"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OrganisationName,
              Description: value,
              PrimaryOperatingCountry,
              SecondaryOperatingCountry,
              Currency,
              OrganisationIdentifierType,
              OrganisationIdentifier,
              AggregateWithParent,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Primary operating country"
          placeholder="Please select an option"
          isDisabled={false}
          value={PrimaryOperatingCountry}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OrganisationName,
                Description,
                PrimaryOperatingCountry: value,
                SecondaryOperatingCountry,
                Currency,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                AggregateWithParent,
              };
              const result = onChange(modelFields);
              value = result?.PrimaryOperatingCountry ?? value;
            }
            if (errors.PrimaryOperatingCountry?.hasError) {
              runValidationTasks("PrimaryOperatingCountry", value);
            }
            setPrimaryOperatingCountry(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "PrimaryOperatingCountry",
              PrimaryOperatingCountry
            )
          }
          errorMessage={errors.PrimaryOperatingCountry?.errorMessage}
          hasError={errors.PrimaryOperatingCountry?.hasError}
          {...getOverrideProps(overrides, "PrimaryOperatingCountry")}
        >
          <option
            children="New zealand"
            value="New_Zealand"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption0")}
          ></option>
          <option
            children="Australia"
            value="Australia"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption1")}
          ></option>
          <option
            children="Usa"
            value="USA"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption2")}
          ></option>
          <option
            children="China"
            value="China"
            {...getOverrideProps(overrides, "PrimaryOperatingCountryoption3")}
          ></option>
        </SelectField>
        <SelectField
          label="Secondary operating country"
          placeholder="Please select an option"
          isDisabled={false}
          value={SecondaryOperatingCountry}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OrganisationName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry: value,
                Currency,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                AggregateWithParent,
              };
              const result = onChange(modelFields);
              value = result?.SecondaryOperatingCountry ?? value;
            }
            if (errors.SecondaryOperatingCountry?.hasError) {
              runValidationTasks("SecondaryOperatingCountry", value);
            }
            setSecondaryOperatingCountry(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "SecondaryOperatingCountry",
              SecondaryOperatingCountry
            )
          }
          errorMessage={errors.SecondaryOperatingCountry?.errorMessage}
          hasError={errors.SecondaryOperatingCountry?.hasError}
          {...getOverrideProps(overrides, "SecondaryOperatingCountry")}
        >
          <option
            children="New zealand"
            value="New_Zealand"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption0")}
          ></option>
          <option
            children="Australia"
            value="Australia"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption1")}
          ></option>
          <option
            children="Usa"
            value="USA"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption2")}
          ></option>
          <option
            children="China"
            value="China"
            {...getOverrideProps(overrides, "SecondaryOperatingCountryoption3")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <SelectField
          label="Currency"
          placeholder="Please select an option"
          isDisabled={false}
          value={Currency}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OrganisationName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry,
                Currency: value,
                OrganisationIdentifierType,
                OrganisationIdentifier,
                AggregateWithParent,
              };
              const result = onChange(modelFields);
              value = result?.Currency ?? value;
            }
            if (errors.Currency?.hasError) {
              runValidationTasks("Currency", value);
            }
            setCurrency(value);
          }}
          onBlur={() => runValidationTasks("Currency", Currency)}
          errorMessage={errors.Currency?.errorMessage}
          hasError={errors.Currency?.hasError}
          {...getOverrideProps(overrides, "Currency")}
        >
          <option
            children="Aud"
            value="AUD"
            {...getOverrideProps(overrides, "Currencyoption0")}
          ></option>
          <option
            children="Euro"
            value="EURO"
            {...getOverrideProps(overrides, "Currencyoption1")}
          ></option>
          <option
            children="Gbp"
            value="GBP"
            {...getOverrideProps(overrides, "Currencyoption2")}
          ></option>
          <option
            children="Rmb"
            value="RMB"
            {...getOverrideProps(overrides, "Currencyoption3")}
          ></option>
          <option
            children="Usd"
            value="USD"
            {...getOverrideProps(overrides, "Currencyoption4")}
          ></option>
          <option
            children="Nzd"
            value="NZD"
            {...getOverrideProps(overrides, "Currencyoption5")}
          ></option>
        </SelectField>
        <SelectField
          label="Organisation identifier type"
          placeholder="Please select an option"
          isDisabled={false}
          value={OrganisationIdentifierType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OrganisationName,
                Description,
                PrimaryOperatingCountry,
                SecondaryOperatingCountry,
                Currency,
                OrganisationIdentifierType: value,
                OrganisationIdentifier,
                AggregateWithParent,
              };
              const result = onChange(modelFields);
              value = result?.OrganisationIdentifierType ?? value;
            }
            if (errors.OrganisationIdentifierType?.hasError) {
              runValidationTasks("OrganisationIdentifierType", value);
            }
            setOrganisationIdentifierType(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OrganisationIdentifierType",
              OrganisationIdentifierType
            )
          }
          errorMessage={errors.OrganisationIdentifierType?.errorMessage}
          hasError={errors.OrganisationIdentifierType?.hasError}
          {...getOverrideProps(overrides, "OrganisationIdentifierType")}
        >
          <option
            children="Iati"
            value="IATI"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption0"
            )}
          ></option>
          <option
            children="Stock symbol"
            value="Stock_Symbol"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption1"
            )}
          ></option>
          <option
            children="Nzbn"
            value="NZBN"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption2"
            )}
          ></option>
          <option
            children="Abn"
            value="ABN"
            {...getOverrideProps(
              overrides,
              "OrganisationIdentifierTypeoption3"
            )}
          ></option>
        </SelectField>
      </Grid>
      <TextField
        label="Organisation identifier"
        isRequired={false}
        isReadOnly={false}
        value={OrganisationIdentifier}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OrganisationName,
              Description,
              PrimaryOperatingCountry,
              SecondaryOperatingCountry,
              Currency,
              OrganisationIdentifierType,
              OrganisationIdentifier: value,
              AggregateWithParent,
            };
            const result = onChange(modelFields);
            value = result?.OrganisationIdentifier ?? value;
          }
          if (errors.OrganisationIdentifier?.hasError) {
            runValidationTasks("OrganisationIdentifier", value);
          }
          setOrganisationIdentifier(value);
        }}
        onBlur={() =>
          runValidationTasks("OrganisationIdentifier", OrganisationIdentifier)
        }
        errorMessage={errors.OrganisationIdentifier?.errorMessage}
        hasError={errors.OrganisationIdentifier?.hasError}
        {...getOverrideProps(overrides, "OrganisationIdentifier")}
      ></TextField>
      <SelectField
        label="Aggregate with parent (if this organisation is a subsidiary of a parent entity)"
        placeholder="Please select an option"
        isDisabled={false}
        value={AggregateWithParent}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OrganisationName,
              Description,
              PrimaryOperatingCountry,
              SecondaryOperatingCountry,
              Currency,
              OrganisationIdentifierType,
              OrganisationIdentifier,
              AggregateWithParent: value,
            };
            const result = onChange(modelFields);
            value = result?.AggregateWithParent ?? value;
          }
          if (errors.AggregateWithParent?.hasError) {
            runValidationTasks("AggregateWithParent", value);
          }
          setAggregateWithParent(value);
        }}
        onBlur={() =>
          runValidationTasks("AggregateWithParent", AggregateWithParent)
        }
        errorMessage={errors.AggregateWithParent?.errorMessage}
        hasError={errors.AggregateWithParent?.hasError}
        {...getOverrideProps(overrides, "AggregateWithParent")}
      >
        <option
          children="Aggregate with parent"
          value="Aggregate_With_Parent"
          {...getOverrideProps(overrides, "AggregateWithParentoption0")}
        ></option>
        <option
          children="Keep separate from parent"
          value="Keep_Separate_From_Parent"
          {...getOverrideProps(overrides, "AggregateWithParentoption1")}
        ></option>
      </SelectField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
