import HelpBox from "./HelpBox";

export default function ReportingEntityHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Reporting Entity Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    A reporting entity is an organisation that is responsible for creating and submitting a climate-related financial disclosure.
                </p>
                <p>
                    To create or edit a reporting entity, fill in the fields on the left hand side of the window. The entity must have a unique name, and you must also select a location for your reporting entity from the <i>Location</i> table in the middle of the screen.
                </p>
                <p>
                    If the required location is not available, click <b>New</b> at the bottom of the table and enter details for a new location.
                </p>
                <p>Once selected, click the <b>Create</b> or <b>Update</b> button at the top right.</p>
            </HelpBox.Content>
        </HelpBox>
    );
}
