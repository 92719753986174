import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import UserMenu from "../shared/user/UserMenu";
import {useGroups} from "../shared/customer/CustomerSessionProvider";
import NavLink from "../shared/util/NavLink";

export default function RootNavBar({className}) {
  const groups = useGroups();

  return (
    <Navbar variant="dark" className={className}>
      <Nav className="me-auto" style={{width: "100%", padding: "0 1em"}}>
        <NavLink to="/disclosures">Disclosures</NavLink>
        <NavLink to="/model/CarbonAccountingLedgers">
          Carbon Accounting Ledger
        </NavLink>
        <NavLink to="/model/AssetRegister">Asset Register</NavLink>
        <NavLink to="/search">Search</NavLink>
        {groups.has("Administrator") && (
          <NavLink to="/admin">Administration</NavLink>
        )}
        <UserMenu className="ms-auto" />
      </Nav>
    </Navbar>
  );
}
