import HelpBox from "./HelpBox";

export default function ReportingEntityHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Disclosure Reporting Period Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    A disclosure reporting period defines the period covered by a climate-related financial disclosure.
                </p>
                <p>
                    To create or edit a disclosure reporting period, fill in the fields on the left hand side of the window. The disclosure reporting period must have a unique disclosure period name, a description, a start and end date, and a year the period covers.
                </p>
                <p>
                    If the end of the reporting period changes, resulting in a reporting period that is longer or shorter than 12 months, enter the following additional information in the <i>Reporting period change</i> field:
                    <ul>
                        <li>the reason for using a longer or shorter period</li>
                        <li>the fact that the climate-related disclosures are not entirely comparable</li>
                    </ul>
                </p>
                <p>Once selected, click the <b>Create</b> or <b>Update</b> button at the bottom right.</p>
            </HelpBox.Content>
        </HelpBox>
    );
}
