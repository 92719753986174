/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Risks } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function RisksUpdateForm(props) {
  const {
    id: idProp,
    risks: risksModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    RiskName: "",
    Description: "",
    RiskType: "",
    ArisesinValueChain: "",
    LikelihoodofOccurring: "",
    Priority: "",
    TimeHorizon: "",
    PrimaryFinancialImpact: "",
  };
  const [RiskName, setRiskName] = React.useState(initialValues.RiskName);
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [RiskType, setRiskType] = React.useState(initialValues.RiskType);
  const [ArisesinValueChain, setArisesinValueChain] = React.useState(
    initialValues.ArisesinValueChain
  );
  const [LikelihoodofOccurring, setLikelihoodofOccurring] = React.useState(
    initialValues.LikelihoodofOccurring
  );
  const [Priority, setPriority] = React.useState(initialValues.Priority);
  const [TimeHorizon, setTimeHorizon] = React.useState(
    initialValues.TimeHorizon
  );
  const [PrimaryFinancialImpact, setPrimaryFinancialImpact] = React.useState(
    initialValues.PrimaryFinancialImpact
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = risksRecord
      ? { ...initialValues, ...risksRecord }
      : initialValues;
    setRiskName(cleanValues.RiskName);
    setDescription(cleanValues.Description);
    setRiskType(cleanValues.RiskType);
    setArisesinValueChain(cleanValues.ArisesinValueChain);
    setLikelihoodofOccurring(cleanValues.LikelihoodofOccurring);
    setPriority(cleanValues.Priority);
    setTimeHorizon(cleanValues.TimeHorizon);
    setPrimaryFinancialImpact(cleanValues.PrimaryFinancialImpact);
    setErrors({});
  };
  const [risksRecord, setRisksRecord] = React.useState(risksModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Risks, idProp)
        : risksModelProp;
      setRisksRecord(record);
    };
    queryData();
  }, [idProp, risksModelProp]);
  React.useEffect(resetStateValues, [risksRecord]);
  const validations = {
    RiskName: [],
    Description: [],
    RiskType: [],
    ArisesinValueChain: [],
    LikelihoodofOccurring: [],
    Priority: [],
    TimeHorizon: [],
    PrimaryFinancialImpact: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          RiskName,
          Description,
          RiskType,
          ArisesinValueChain,
          LikelihoodofOccurring,
          Priority,
          TimeHorizon,
          PrimaryFinancialImpact,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Risks.copyOf(risksRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "RisksUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate-related Risk"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 14 b-c."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update this record that describes a climate-related risk you have identified."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Climate-related risk name"
        isRequired={false}
        isReadOnly={false}
        value={RiskName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskName: value,
              Description,
              RiskType,
              ArisesinValueChain,
              LikelihoodofOccurring,
              Priority,
              TimeHorizon,
              PrimaryFinancialImpact,
            };
            const result = onChange(modelFields);
            value = result?.RiskName ?? value;
          }
          if (errors.RiskName?.hasError) {
            runValidationTasks("RiskName", value);
          }
          setRiskName(value);
        }}
        onBlur={() => runValidationTasks("RiskName", RiskName)}
        errorMessage={errors.RiskName?.errorMessage}
        hasError={errors.RiskName?.hasError}
        {...getOverrideProps(overrides, "RiskName")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              RiskName,
              Description: value,
              RiskType,
              ArisesinValueChain,
              LikelihoodofOccurring,
              Priority,
              TimeHorizon,
              PrimaryFinancialImpact,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Risk type"
          placeholder="Please select an option"
          isDisabled={false}
          value={RiskType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskName,
                Description,
                RiskType: value,
                ArisesinValueChain,
                LikelihoodofOccurring,
                Priority,
                TimeHorizon,
                PrimaryFinancialImpact,
              };
              const result = onChange(modelFields);
              value = result?.RiskType ?? value;
            }
            if (errors.RiskType?.hasError) {
              runValidationTasks("RiskType", value);
            }
            setRiskType(value);
          }}
          onBlur={() => runValidationTasks("RiskType", RiskType)}
          errorMessage={errors.RiskType?.errorMessage}
          hasError={errors.RiskType?.hasError}
          {...getOverrideProps(overrides, "RiskType")}
        >
          <option
            children="Acute physical"
            value="Acute_Physical"
            {...getOverrideProps(overrides, "RiskTypeoption0")}
          ></option>
          <option
            children="Chronic physical"
            value="Chronic_Physical"
            {...getOverrideProps(overrides, "RiskTypeoption1")}
          ></option>
          <option
            children="Market"
            value="Market"
            {...getOverrideProps(overrides, "RiskTypeoption2")}
          ></option>
          <option
            children="Physical"
            value="Physical"
            {...getOverrideProps(overrides, "RiskTypeoption3")}
          ></option>
          <option
            children="Policy and legal"
            value="Policy_and_Legal"
            {...getOverrideProps(overrides, "RiskTypeoption4")}
          ></option>
          <option
            children="Reputation"
            value="Reputation"
            {...getOverrideProps(overrides, "RiskTypeoption5")}
          ></option>
          <option
            children="Technology"
            value="Technology"
            {...getOverrideProps(overrides, "RiskTypeoption6")}
          ></option>
          <option
            children="Transition"
            value="Transition"
            {...getOverrideProps(overrides, "RiskTypeoption7")}
          ></option>
        </SelectField>
        <SelectField
          label="Where the risk arises in value chain"
          placeholder="Please select an option"
          isDisabled={false}
          value={ArisesinValueChain}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskName,
                Description,
                RiskType,
                ArisesinValueChain: value,
                LikelihoodofOccurring,
                Priority,
                TimeHorizon,
                PrimaryFinancialImpact,
              };
              const result = onChange(modelFields);
              value = result?.ArisesinValueChain ?? value;
            }
            if (errors.ArisesinValueChain?.hasError) {
              runValidationTasks("ArisesinValueChain", value);
            }
            setArisesinValueChain(value);
          }}
          onBlur={() =>
            runValidationTasks("ArisesinValueChain", ArisesinValueChain)
          }
          errorMessage={errors.ArisesinValueChain?.errorMessage}
          hasError={errors.ArisesinValueChain?.hasError}
          {...getOverrideProps(overrides, "ArisesinValueChain")}
        >
          <option
            children="Upstream"
            value="Upstream"
            {...getOverrideProps(overrides, "ArisesinValueChainoption0")}
          ></option>
          <option
            children="Direct operations"
            value="Direct_Operations"
            {...getOverrideProps(overrides, "ArisesinValueChainoption1")}
          ></option>
          <option
            children="Downstream"
            value="Downstream"
            {...getOverrideProps(overrides, "ArisesinValueChainoption2")}
          ></option>
          <option
            children="All segments"
            value="All_Segments"
            {...getOverrideProps(overrides, "ArisesinValueChainoption3")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "ArisesinValueChainoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <SelectField
          label="Likelihood of occurring"
          placeholder="Please select an option"
          isDisabled={false}
          value={LikelihoodofOccurring}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskName,
                Description,
                RiskType,
                ArisesinValueChain,
                LikelihoodofOccurring: value,
                Priority,
                TimeHorizon,
                PrimaryFinancialImpact,
              };
              const result = onChange(modelFields);
              value = result?.LikelihoodofOccurring ?? value;
            }
            if (errors.LikelihoodofOccurring?.hasError) {
              runValidationTasks("LikelihoodofOccurring", value);
            }
            setLikelihoodofOccurring(value);
          }}
          onBlur={() =>
            runValidationTasks("LikelihoodofOccurring", LikelihoodofOccurring)
          }
          errorMessage={errors.LikelihoodofOccurring?.errorMessage}
          hasError={errors.LikelihoodofOccurring?.hasError}
          {...getOverrideProps(overrides, "LikelihoodofOccurring")}
        >
          <option
            children="None"
            value="None"
            {...getOverrideProps(overrides, "LikelihoodofOccurringoption0")}
          ></option>
          <option
            children="Low"
            value="Low"
            {...getOverrideProps(overrides, "LikelihoodofOccurringoption1")}
          ></option>
          <option
            children="Medium"
            value="Medium"
            {...getOverrideProps(overrides, "LikelihoodofOccurringoption2")}
          ></option>
          <option
            children="High"
            value="High"
            {...getOverrideProps(overrides, "LikelihoodofOccurringoption3")}
          ></option>
        </SelectField>
        <SelectField
          label="Priority"
          placeholder="Please select an option"
          isDisabled={false}
          value={Priority}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskName,
                Description,
                RiskType,
                ArisesinValueChain,
                LikelihoodofOccurring,
                Priority: value,
                TimeHorizon,
                PrimaryFinancialImpact,
              };
              const result = onChange(modelFields);
              value = result?.Priority ?? value;
            }
            if (errors.Priority?.hasError) {
              runValidationTasks("Priority", value);
            }
            setPriority(value);
          }}
          onBlur={() => runValidationTasks("Priority", Priority)}
          errorMessage={errors.Priority?.errorMessage}
          hasError={errors.Priority?.hasError}
          {...getOverrideProps(overrides, "Priority")}
        >
          <option
            children="Highest"
            value="Highest"
            {...getOverrideProps(overrides, "Priorityoption0")}
          ></option>
          <option
            children="High"
            value="High"
            {...getOverrideProps(overrides, "Priorityoption1")}
          ></option>
          <option
            children="Medium"
            value="Medium"
            {...getOverrideProps(overrides, "Priorityoption2")}
          ></option>
          <option
            children="Low"
            value="Low"
            {...getOverrideProps(overrides, "Priorityoption3")}
          ></option>
          <option
            children="Lowest"
            value="Lowest"
            {...getOverrideProps(overrides, "Priorityoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <SelectField
          label="Time horizon risk will manifest within"
          placeholder="Please select an option"
          isDisabled={false}
          value={TimeHorizon}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskName,
                Description,
                RiskType,
                ArisesinValueChain,
                LikelihoodofOccurring,
                Priority,
                TimeHorizon: value,
                PrimaryFinancialImpact,
              };
              const result = onChange(modelFields);
              value = result?.TimeHorizon ?? value;
            }
            if (errors.TimeHorizon?.hasError) {
              runValidationTasks("TimeHorizon", value);
            }
            setTimeHorizon(value);
          }}
          onBlur={() => runValidationTasks("TimeHorizon", TimeHorizon)}
          errorMessage={errors.TimeHorizon?.errorMessage}
          hasError={errors.TimeHorizon?.hasError}
          {...getOverrideProps(overrides, "TimeHorizon")}
        >
          <option
            children="Current"
            value="Current"
            {...getOverrideProps(overrides, "TimeHorizonoption0")}
          ></option>
          <option
            children="Short term"
            value="Short_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption1")}
          ></option>
          <option
            children="Medium term"
            value="Medium_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption2")}
          ></option>
          <option
            children="Long term"
            value="Long_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption3")}
          ></option>
          <option
            children="All climate planning horizons"
            value="All_Climate_Planning_Horizons"
            {...getOverrideProps(overrides, "TimeHorizonoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Primary financial impact"
          placeholder="Please select an option"
          isDisabled={false}
          value={PrimaryFinancialImpact}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                RiskName,
                Description,
                RiskType,
                ArisesinValueChain,
                LikelihoodofOccurring,
                Priority,
                TimeHorizon,
                PrimaryFinancialImpact: value,
              };
              const result = onChange(modelFields);
              value = result?.PrimaryFinancialImpact ?? value;
            }
            if (errors.PrimaryFinancialImpact?.hasError) {
              runValidationTasks("PrimaryFinancialImpact", value);
            }
            setPrimaryFinancialImpact(value);
          }}
          onBlur={() =>
            runValidationTasks("PrimaryFinancialImpact", PrimaryFinancialImpact)
          }
          errorMessage={errors.PrimaryFinancialImpact?.errorMessage}
          hasError={errors.PrimaryFinancialImpact?.hasError}
          {...getOverrideProps(overrides, "PrimaryFinancialImpact")}
        >
          <option
            children="Increased access to capital"
            value="Increased_Access_to_Capital"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption0")}
          ></option>
          <option
            children="Increased access to resources"
            value="Increased_Access_to_Resources"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption1")}
          ></option>
          <option
            children="Increased capital expenditures"
            value="Increased_Capital_Expenditures"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption2")}
          ></option>
          <option
            children="Increased credit risk"
            value="Increased_Credit_Risk"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption3")}
          ></option>
          <option
            children="Increased direct costs"
            value="Increased_Direct_Costs"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption4")}
          ></option>
          <option
            children="Increased indirect operating costs"
            value="Increased_Indirect_Operating_Costs"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption5")}
          ></option>
          <option
            children="Increased insurance claims liability"
            value="Increased_Insurance_Claims_Liability"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption6")}
          ></option>
          <option
            children="Increased revenue"
            value="Increased_Revenue"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption7")}
          ></option>
          <option
            children="Decreased access to capital"
            value="Decreased_Access_to_Capital"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption8")}
          ></option>
          <option
            children="Decreased asset value or asset useful life leading to writeoffs asset impairment or early retirement of existing assets"
            value="Decreased_Asset_Value_or_Asset_Useful_Life_Leading_to_Writeoffs_Asset_Impairment_or_Early_Retirement_of_Existing_Assets"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption9")}
          ></option>
          <option
            children="Decreased opex"
            value="Decreased_OPEX"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption10")}
          ></option>
          <option
            children="Decreased revenues due to reduced demand for products and services"
            value="Decreased_Revenues_Due_to_Reduced_Demand_For_Products_and_Services"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption11")}
          ></option>
          <option
            children="Decreased revenues due to reduced production capacity"
            value="Decreased_Revenues_Due_to_Reduced_Production_Capacity"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption12")}
          ></option>
          <option
            children="Devaluation of colltateral or potential for stranded illiquid assets"
            value="Devaluation_of_Colltateral_or_Potential_For_Stranded_Illiquid_Assets"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption13")}
          ></option>
          <option
            children="Increased capital expenditure"
            value="Increased_Capital_Expenditure"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption14")}
          ></option>
          <option
            children="Increased gross margin"
            value="Increased_Gross_Margin"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption15")}
          ></option>
          <option
            children="Increased operating expenditure"
            value="Increased_Operating_Expenditure"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption16")}
          ></option>
          <option
            children="Increased or decreased revenue"
            value="Increased_Or_Decreased_Revenue"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption17")}
          ></option>
          <option
            children="Reduced gross margin"
            value="Reduced_Gross_Margin"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption18")}
          ></option>
          <option
            children="Reduced profitability or investment portfolios"
            value="Reduced_Profitability_or_Investment_Portfolios"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption19")}
          ></option>
          <option
            children="Other please specify"
            value="Other_Please_Specify"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption20")}
          ></option>
        </SelectField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || risksModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || risksModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
