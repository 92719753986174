import HelpBox from "./HelpBox";

export default function ScenarioAnalysisHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Scenario Analysis Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Scenario Analysis is required to identify climate-related risks and opportunities and better understand the resilience of your business model and strategy to these.
                </p>
                <p>
                    Scenario Analysis consists of two elements:
                    <ol>
                        <li>Defining the climate-related scenarios you use to identify climate-related risks and opportunities.</li>
                        <li>Describe how these scenarios have been analysed.</li>
                    </ol>
                </p>
                <p>
                    ClimateTracker allows you to group these elements in different ways for different disclosures. For example, you may update your scenarios as more data and models become available.
                    To group the Scenario Analysis elements you require for this disclosure, in the left hand side, enter a name for the set of Scenario Analysis elements that are relevant to this disclosure, then select from existing elements you have created or create and select new processes.
                    This ensures that only the relevant elements will be used in the disclosure you are currently building.
                </p>
                <p>
                    If you haven't used Scenario Analysis, you may also answer the optional question in the left hand side, to describe why you did not using climate-related scenario analysis to inform your strategy.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
