import { useEffect, useId, useState } from "react";

import { Predicates, SortDirection } from "aws-amplify/datastore";
import clsx from "clsx";
import PropTypes from "prop-types";

import { Autocomplete, Flex } from "@aws-amplify/ui-react";

import { findUniqueFieldName } from "../../shared/amplify/schemaHelpers";
import DeleteButton from "../action/DeleteItemButton";
import { uischema } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import styles from "./SelectDataItem.module.css";

export default function SelectDataItem({
  label,
  model,
  schema = uischema().models[model.name],
  idField = "id",
  valueField = findUniqueFieldName(model) || Object.values(schema?.fields).find(({ type }) => type === "String")?.name,
  ariaLabel = label,
  required,
  multiple = false,
  modal: Modal,
  edit: Edit = Modal && Modal.Edit,
  new: New = Modal && Modal.New,
  delete: Delete = !schema?.noDelete && (({ id }) => <DeleteButton id={id} model={model} />),
  itemActions = [Edit, Delete].filter(Boolean),
  onChange,
  className,
  selection,
  placeholder = `Choose ${uischema().models[model.name]?.displayName}...`
}) {
  const datastore = useCustomerDataStore();
  const [dataItems, setDataItems] = useState(undefined);
  const [selectedOptions, setSelectedOptions] = useState([]);
  function handleSelectionChange(newOptions) {
    setSelectedOptions(newOptions);
    if (onChange) {
      const items = (newOptions || []).map((selectedId) => (dataItems || []).find(({ [idField]: id }) => id === selectedId));
      onChange(items);
    }
  }
  function handleSelectionEvent({ id }) {
    handleSelectionChange([id].flat());
  }

  function mapToOption({ [idField]: id, [valueField]: label }) {
    return { label, id, value: label };
  }
  useEffect(() => {
    const subscription = datastore
      .observeQuery(model, Predicates.ALL, {
        sort: (s) => s[valueField](SortDirection.ASCENDING)
      })
      .subscribe(({ items }) => {
        setDataItems(items);
        if (typeof selection === "function") {
          setSelectedOptions(items.filter(selection).map(({ [idField]: id }) => id));
        } else if (!!selection) {
          const idSet = new Set([selection].flat());
          setSelectedOptions(items.filter(({ [idField]: id }) => idSet.has(id)).map(({ [idField]: id }) => id));
        }
      });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, idField, valueField, required, multiple, datastore]);
  const uuid = useId();
  const selectedId = selectedOptions.at(-1);
  const value = (!!selectedId && mapToOption((dataItems || []).find(({ id }) => id === selectedId) || {})?.label) || "";
  return (
    <div className={clsx(styles.root, className)}>
      {label && <label htmlFor={uuid}>{label}</label>}
      <Flex wrap={"wrap"}>
        <div>
          <div className={styles.hiddenLabel} aria-hidden="true">
            {placeholder}
          </div>
          <Autocomplete
            id={uuid}
            className={styles.select}
            aria-label={ariaLabel}
            onSelect={handleSelectionEvent}
            onClear={() => handleSelectionChange([])}
            required={required}
            multiple={multiple}
            placeholder={placeholder}
            defaultValue=""
            options={dataItems ? dataItems.map(mapToOption) : []}
            value={value}
          />
        </div>
        {itemActions.map((Action, i) => (
          <Action key={i} id={selectedId} />
        ))}

        {New && <New></New>}
      </Flex>
    </div>
  );
}

SelectDataItem.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.any,
  delete: PropTypes.any,
  edit: PropTypes.any,
  idField: PropTypes.string,
  itemActions: PropTypes.func,
  label: PropTypes.any,
  mapToOption: PropTypes.func,
  modal: PropTypes.shape({
    Edit: PropTypes.any,
    New: PropTypes.any
  }),
  model: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  new: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.any,
  schema: PropTypes.shape({
    noDelete: PropTypes.func,
    fields: PropTypes.any
  }),
  selection: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.arrayOf(PropTypes.string)]),
  valueField: PropTypes.string
};
