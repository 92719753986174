import { Col, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Button, Flex } from "@aws-amplify/ui-react";
import { ReactNode, useState } from "react";
import { BsBan, BsGlobe2, BsQuestionCircle, BsFillPencilFill, BsFillTrash3Fill } from "react-icons/bs";
import DeleteItemButton from "../action/DeleteItemButton";
import EditItemButton from "../action/EditItemButton";
import ItemButton from "../action/ItemButton";
import NewItemButton from "../action/NewItemButton";
import { useDataStoreItem } from "../amplify/internal/utils";
import { models } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import DataItemTable from "../table/DataItemTable";
import HelpBox from "../content/HelpBox";
import React from "react";

function canModify(Status: string) {
  return !Status || ["Draft", "Inactive"].includes(Status);
}

function Unpublish({ id }: { id: string }): ReactNode {
  const [showUnpublish, setShowUnpublish] = useState(false);
  const { Disclosure } = models();
  const datastore = useCustomerDataStore();
  const { item } = useDataStoreItem({ model: Disclosure, id });

  const name = item && item?.["DataSetName"];
  async function handleUnpublish() {
    if (item) {
      await datastore.save(
        Disclosure.copyOf(item, (m) => {
          Object.assign(m, { Status: "Draft" });
        })
      );
      setShowUnpublish(false);
    }
  }
  return (
    <>
      <ItemButton
        label="Unpublish"
        model={Disclosure}
        variant="windowed"
        icon={
          <div>
            <BsBan style={{ position: "absolute", color: "red" }} />
            <BsGlobe2 />
          </div>
        }
        onClick={() => setShowUnpublish(true)}
        requiredPermissions={"read"}
        id={undefined}
        isLoading={undefined}
        item={undefined}
        disabled={undefined}
        className={undefined}
      />

      <Modal show={showUnpublish} dialogClassName="shadow-lg rounded" onHide={() => setShowUnpublish(false)}>
        <Modal.Header className="bg-danger" closeButton>
          <Modal.Title>
            <BsQuestionCircle size="2rem" />
            &nbsp; Confirm Unpublish
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to unpublish '{name}'?</Modal.Body>
        <Modal.Footer>
          <Flex>
            <Button autoFocus onClick={() => setShowUnpublish(false)}>
              No
            </Button>
            <Button isLoading={!item} variation="destructive" onClick={handleUnpublish}>
              Yes
            </Button>
          </Flex>
        </Modal.Footer>
      </Modal>
    </>
  );
}

type ActionParams = { id: string; item: Record<string, any> };
function Disclosures() {
  const { Disclosure } = models();

  const navigate = useNavigate();
  return (
    <Row>
      <Col>
        <h1>Disclosure Summary Page</h1>
        <p>The following list provides a summary of your Disclosures.</p>
        <DataItemTable
          fullSize
          select={false}
          model={Disclosure}
          itemActions={[
            ({ id, item: { Status } }: ActionParams) =>
              canModify(Status) && <DeleteItemButton id={id} size="small" model={Disclosure} />,
            ({ id, item: { Status } }: ActionParams) =>
              canModify(Status) && (
                <EditItemButton onClick={() => navigate(`/disclosures/Disclosure/${id}`)} model={Disclosure} variant="windowed" />
              ),
            ({ id, item }: ActionParams) =>
              item?.Status === "Published" ? (
                <Unpublish id={id} />
              ) : (
                <ItemButton
                  label="Publish"
                  model={Disclosure}
                  variant="windowed"
                  icon={<BsGlobe2 />}
                  onClick={() => navigate(`/publish/${id}`)}
                  requiredPermissions={"read"}
                  id={undefined}
                  isLoading={undefined}
                  item={undefined}
                  disabled={undefined}
                  className={undefined}
                />
              )
          ]}
          new={() => (
            <NewItemButton
              onClick={() => navigate(`/disclosures/Disclosure`)}
              label="Create Disclosure"
              model={Disclosure}
              variant="windowed"
            />
          )}
          customFieldRenderers={{
            PublishedTo: { editor: true }
          }}
          onChange={undefined}
          subtype={undefined}
          error={undefined}
        />
      </Col>
      <Col>
        <HelpBox>
          <HelpBox.Header>Disclosure Summary Help</HelpBox.Header>
          <p>This page shows you the list of climate-related disclosures currently managed by ClimateTracker.</p>
          <p>From this screen you can select from the following options:</p>
          <HelpBox.Content>
            <ol>
              <li>
                Create a new disclosure: click the <b>Create Disclosure</b> button.
              </li>
              <li>
                Delete an existing disclosure: click the trash icon <BsFillTrash3Fill /> to delete the disclosure. Note that if you
                have set the status of a disclosure to <i>Published</i> you cannot delete it.
              </li>
              <li>
                Edit an existing disclosure: click the pencil icon <BsFillPencilFill />{" "}
              </li>
              <li>Run a pre-publishing validation check: click the world map {<BsGlobe2 />} icon</li>
              <li>
                Change where it is published to: click on any of the items in the <i>Published To</i> column for a disclosure.
              </li>
            </ol>
          </HelpBox.Content>
        </HelpBox>
      </Col>
    </Row>
  );
}

export default Disclosures;
