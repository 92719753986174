import { Button, Flex } from "@aws-amplify/ui-react";
import { PersistentModelConstructor } from "aws-amplify/dist/esm/datastore";
import { ReactElement, useEffect, useState } from "react";
import { Alert, Container, Spinner } from "react-bootstrap";
import { BsCaretLeftFill, BsCheckCircleFill, BsChevronDoubleRight, BsChevronRight } from "react-icons/bs";
import styles from "../../shared/wizard/Wizard.module.css";
import { useHelpContext } from "../help/HelpContext";
export type WizardProps<T extends Readonly<Record<string, any | null>>> = {
  /**
   * model being constructed
   */
  model: PersistentModelConstructor<any>;
  /**
   * create form
   */
  CreateForm: React.FC<any>;
  /**
   * Values we want to apply on Next click into real create form
   */
  preselection?: T;
  /**
   * Arbitary form displayed before create
   */
  children?: React.ReactNode;
  /**
   * Display error instead
   */
  error?: string;
  /**
   * Loading instead
   */
  isLoading?: boolean;
  /**
   * Finished all steps
   */
  isFinished?: boolean;

  /**
   * pass-thru to normal onError
   * @param fields
   * @returns
   */
  onError?: (fields: Record<string, any>, message: string) => void;
};

/**
 * Helper component for forms which want to pre create fields before passing on to the default Studio Create form
 * @param param0
 * @returns
 */
const Wizard = <T extends Readonly<Record<string, any | null>>>({
  model,
  CreateForm,
  preselection,
  children,
  isLoading,
  isFinished,
  error,
  onError,
  ...rest
}: WizardProps<T>): ReactElement => {
  const [completion, setCompletion] = useState<"skip" | T | undefined>();
  function handleSkip() {
    setCompletion("skip");
  }
  async function handleNext() {
    if (preselection) {
      setCompletion(preselection);
    }
  }
  const { setHelpContext } = useHelpContext();
  useEffect(() => {
    setHelpContext({ view: completion ? "dialog" : "wizard" });
  }, [completion, setHelpContext]);
  return !completion ? (
    <Container fluid>
      <Alert show={!!error} variant="danger">
        {error}
      </Alert>
      <div className={styles.stepGrid}>
        {children}
        {isLoading ? (
          <Spinner />
        ) : isFinished ? (
          <Alert variant="success">
            <BsCheckCircleFill className={styles.currentStepIcon} />
            &nbsp;Complete, choices can be adjusted, or click <b>Next</b> to use these values
          </Alert>
        ) : (
          <BsCaretLeftFill className={styles.currentStepIcon} />
        )}
      </div>
      <hr />
      <Flex justifyContent="flex-end">
        <Button onClick={handleSkip}>
          Skip
          <BsChevronDoubleRight />
        </Button>
        <Button onClick={handleNext} variation="primary" isDisabled={!isFinished}>
          Next
          <BsChevronRight />
        </Button>
      </Flex>
      <small>
        Click <b>Skip</b> to abandon the wizard and enter all values manually
      </small>
    </Container>
  ) : (
    // pass on to default create form as we aren't supplying anything special
    <CreateForm {...rest} presets={completion === "skip" ? undefined : completion} />
  );
};

export default Wizard;
