import { Button, Flex, Grid, Input, Label, TextAreaField } from "@aws-amplify/ui-react";
import { uploadData } from "aws-amplify/storage";
import React, { useEffect, useState } from "react";
import { ProgressBar, Toast } from "react-bootstrap";
import { BsExclamation } from "react-icons/bs";
import { Evidence } from "../../models";
import { models } from "../backend";
import { useCustomerAccount } from "../customer/CustomerSessionProvider";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { Writeable } from "../util/typeUtils";
import style from "./Evidence.module.css";
import { useLogger } from "../logger/LoggerProvider";
const percentFormat = new Intl.NumberFormat(undefined, { style: "percent" });

export type MutableEvidence = Writeable<Partial<Evidence>>;
export interface EvidenceFormProps {
  id?: string;
  initialState: MutableEvidence;
  onSubmit?: (evidence: MutableEvidence) => void;
  onSuccess?: (evidence: MutableEvidence) => void;
}

export default function EvidenceForm({ id, initialState, onSubmit, onSuccess }: EvidenceFormProps): JSX.Element {
  const [{ error: uploadError, fractionComplete }, setUpload] = useState<
    Partial<{
      fractionComplete: number;
      error: string;
    }>
  >({});
  const EvidenceModel = models().Evidence;
  const { appUser: { CustomerID } = {} } = useCustomerAccount();
  const datastore = useCustomerDataStore();
  const [existingEvidence, setExistingEvidence] = useState<MutableEvidence>();
  const [evidence, setEvidence] = useState<MutableEvidence>(initialState);
  const [file, setFile] = useState<File>();
  const { TargetEntityModel, TargetEntityID, TargetSubtype, Name, AttachmentKey, Description, ValidFrom, ValidTo, Url } =
    evidence || {};
  const logger = useLogger();
  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (!fractionComplete) {
      if (file) {
        // file upload needed, first load file into local blob
        setUpload({ fractionComplete: 0.1 });
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              await save(reader.result as ArrayBuffer);
              setUpload({});
            } catch (e) {
              logger.error(`Exception uploading: ${file?.name}`, { detail: e });
              setUpload({ error: e ? e.toString() : "Unknown error" });
            }
          },
          false
        );
        reader.addEventListener(
          "error",
          (error) => {
            setUpload({ error: JSON.stringify(error) });
          },
          false
        );
        reader.readAsArrayBuffer(file);
      } else {
        // Just save. no data to upload
        await save();
      }
    }
  }
  async function save(fileContent?: ArrayBuffer) {
    if (CustomerID && TargetEntityModel && TargetEntityID) {
      const fullEvidence = {
        ...evidence
      };
      if (fileContent) {
        const { name, type, size } = file as File;
        const prefix = prefixFor({
          CustomerID,
          TargetEntityModel,
          TargetEntityID,
          TargetSubtype
        });
        // We have file info - update record
        const key = `${prefix}/${name}`;
        Object.assign(fullEvidence, {
          id: crypto.randomUUID(),
          Name: name,
          AttachmentKey: key,
          ContentType: type,
          Size: size
        });
        // new file selected, upload it and with metadata
        // NOTE: if other evidence fields change without file change, the file's metadata will not be updated
        // as there is no way of just setting the properties in amplify storage API
        await uploadData({
          key,
          data: fileContent,
          options: {
            onProgress: ({ totalBytes, transferredBytes }) => {
              !uploadError &&
                setUpload({
                  fractionComplete: Math.max(fractionComplete || 0, transferredBytes / (totalBytes || 1))
                });
            },
            accessLevel: defaultAccessLevel,
            contentType: type,
            metadata: Object.fromEntries(
              Object.entries(fullEvidence)
                .filter(([_k, v]) => v !== null)
                .map(([k, v]) => [k, v && v.toString()])
            ) as Record<string, string>
          }
        }).result;
      }

      // Save db entity
      const result = await datastore.save(
        existingEvidence
          ? EvidenceModel.copyOf(existingEvidence, (newEvidence) => Object.assign(newEvidence, fullEvidence))
          : new EvidenceModel(fullEvidence)
      );
      onSuccess && onSuccess(result);
      return result;
    } else {
      throw new Error("Expected values set");
    }
  }

  function resetStateValues() {
    setEvidence(existingEvidence || initialState);
  }
  useEffect(() => {
    (async () => {
      if (!!id) {
        const newExisting = await datastore.query(EvidenceModel, id);
        setExistingEvidence(newExisting);
      }
    })();
  }, [id, EvidenceModel, datastore]);
  useEffect(() => {
    if (existingEvidence) setEvidence(existingEvidence);
  }, [existingEvidence]);
  return (
    <Grid as="form" maxWidth="50em" margin="auto" rowGap="15px" columnGap="15px" padding="20px" onSubmit={handleSubmit}>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description || ""}
        onChange={({ target: { value: Description } }) => {
          setEvidence({ ...evidence, Description });
        }}
      />
      <Label htmlFor="validFrom">Valid From</Label>
      <Input
        type="date"
        id="validFrom"
        value={ValidFrom || ""}
        onChange={({ target: { value: ValidFrom } }) => {
          setEvidence({ ...evidence, ValidFrom });
        }}
      />
      <Label htmlFor="validTo">Valid From</Label>
      <Input
        type="date"
        id="validTo"
        value={ValidTo || ""}
        onChange={({ target: { value: ValidTo } }) => {
          setEvidence({ ...evidence, ValidTo });
        }}
      />
      <span>Either select and upload a file from your device as evidence, or enter a well-known url for it</span>
      <Label htmlFor="upload-file">File{Name && <span>:{Name}</span>}</Label>
      <Input
        required={!AttachmentKey && !Url}
        id="upload-file"
        type="file"
        onClick={() => {
          setFile(undefined);
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const files = event.target.files;
          if (files) {
            setFile(files[0] as File);
            setEvidence({ ...evidence, Url: undefined });
          }
        }}
      />
      <Label htmlFor="url">Custom Url</Label>
      <Input
        id="url"
        required={!file}
        type="url"
        value={Url || ""}
        onChange={({ target: { value: Url } }) => {
          setEvidence({ ...evidence, Name: undefined, ContentType: undefined, AttachmentKey: undefined, Url });
          setFile(undefined);
        }}
      />
      <hr />
      <Flex justifyContent="space-between">
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
        ></Button>
        <Flex gap="15px">
          <Button
            isLoading={!!fractionComplete}
            children={id ? "Update" : "Create"}
            type="submit"
            variation="primary"
            isDisabled={!!fractionComplete}
          ></Button>
        </Flex>
      </Flex>
      <Toast
        show={!!fractionComplete || !!uploadError}
        autohide={false}
        bg={uploadError ? "danger" : "light"}
        onClose={() => setUpload({})}
      >
        <Toast.Header>Uploading {file && file.name}</Toast.Header>
        <Toast.Body>
          {!uploadError ? (
            <ProgressBar
              className={style.progress}
              min={0}
              max={1}
              now={fractionComplete}
              variant="info"
              label={percentFormat.format(Math.max(fractionComplete || 0, 0))}
            />
          ) : (
            <span>
              <BsExclamation size={"2em"} />
              &nbsp;
              {uploadError}
            </span>
          )}
        </Toast.Body>
      </Toast>
    </Grid>
  );
}

export function prefixFor({
  CustomerID,
  TargetEntityModel,
  TargetEntityID,
  TargetSubtype
}: {
  CustomerID: string;
  TargetEntityModel: string;
  TargetEntityID: string;
  TargetSubtype?: string | null;
}) {
  return (
    CustomerID &&
    TargetEntityID &&
    TargetEntityModel &&
    [CustomerID, TargetEntityModel, TargetEntityID, TargetSubtype].filter(Boolean).join("/")
  );
}
export const defaultAccessLevel = "guest";
