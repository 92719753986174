import { DataStore } from "aws-amplify/datastore";
import { identity, isEmpty, merge, omit } from "lodash";
import { Alert, Col, Container, Row } from "react-bootstrap";

import { Accordion, Button, Flex } from "@aws-amplify/ui-react";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { BsExclamationTriangleFill, BsLink45Deg } from "react-icons/bs";
import { combinedSchemaFor, parseManyMany, relatedFields } from "../../shared/amplify/schemaHelpers";
import { useDataStoreItem } from "../amplify/internal/utils";
import { models, uischema } from "../backend";
import { amplifyFormName } from "../form/amplifyForms";
import DataItemTable from "../table/DataItemTable.jsx";
import RelatedField from "./RelatedField.jsx";

import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import useLocalStorage from "../storage/useLocalStorage";
import styles from "./RelatedField.module.css";
import { LayoutProvider } from "../modal/LayoutContext";
/**
 *
 * @param {*} parent
 * @param {*} schemaRelations
 * @param {*} newRelationsByFieldKey
 * @returns {
 *    Promise[] promises
 *    {*} updates   to fields on parent
 * }
 */
function changes(parent, schemaRelations, newRelationsByFieldKey) {
  return schemaRelations.reduce(
    (acc, relation) => {
      const {
        fieldKey,
        name,
        type,
        type: { model: fieldModelName = undefined } = {},
        targetModel, // custom schema decuartion as amplify doesn't tell us
        association: {
          connectionType = undefined,
          associatedWith: [idFieldName = undefined] = [],
          targetNames: [targetFieldIdName = undefined] = []
        } = {},
        manyMany,
        subtype
      } = relation;
      const newRelation = newRelationsByFieldKey[fieldKey];
      const relationModel = models()[fieldModelName || targetModel];
      if (newRelation !== undefined) {
        if (connectionType === "HAS_ONE") {
          // Conventional HAS_ONE - studio deoesn't seem to akllow this anyway
          acc.updatedParent[name] = isEmpty(newRelation) ? null : newRelation;
        } else if (
          type === "ID" &&
          !!targetModel // ID present 1-n , we set the target model in uischema ao must be at the one end of a one-to-many
        ) {
          // Target is set to id
          acc.updatedParent[name] = isEmpty(newRelation) ? null : newRelation?.id;
        } else {
          // target is many
          if (manyMany) {
            // source is many (Many-Many
            const newRelationsIds = new Set((newRelation || []).map(({ id }) => id));
            const { sourceFieldIdName, targetFieldIdName, targetModelSchema, targetFieldName } = manyMany;
            const discriminatorField = targetModelSchema.discriminatorField;
            const existingPredicate =
              discriminatorField && subtype
                ? (c) => {
                    return c.and((c1) => [
                      c1[targetFieldName][discriminatorField].eq(subtype),
                      c1[sourceFieldIdName].eq(parent.id)
                    ]);
                  }
                : (c) => c[sourceFieldIdName].eq(parent.id);

            acc.promises.push(
              // First find the existing relation items related to parent (join table entities)
              DataStore.query(relationModel, existingPredicate).then((existingRelations) => {
                const existingRelationsIds = new Set((existingRelations || []).map(({ id }) => id));
                // delete all the now unneeded relations
                const deletes = [...existingRelationsIds]
                  .filter((id) => !newRelationsIds.has(id))
                  .map((id) => DataStore.delete(relationModel, id));
                // Create newly required relations
                const creates = [...newRelationsIds]
                  .filter((id) => !existingRelationsIds.has(id))
                  .map((id) => {
                    return DataStore.save(
                      new relationModel({
                        [sourceFieldIdName]: parent.id,
                        [targetFieldIdName]: id
                      })
                    );
                  });
                return Promise.all([...creates, ...deletes]);
              })
            );
          } else if (connectionType === "HAS_MANY") {
            // one-many
            const newRelationsIds = new Set((newRelation || []).map(({ id }) => id));
            const targetModelSchema = uischema().models[relationModel.name];
            const matchExisting =
              targetModelSchema.discriminatorField && ((c) => c[targetModelSchema.discriminatorField].eq(subtype));
            acc.promises.push(
              DataStore.query(relationModel, matchExisting).then((items) => {
                const { association: { targetNames: [targetFieldIdName = idFieldName] = [] } = {} } =
                  targetModelSchema.fields[idFieldName];
                return Promise.all(
                  (items || [])
                    .map((item) => {
                      // For all items of related model type
                      const currentOwner = item[targetFieldIdName];
                      if (newRelationsIds.has(item.id) && currentOwner !== parent.id) {
                        // Required as a relation - update id
                        return DataStore.save(
                          relationModel.copyOf(item, (updated) => {
                            updated[targetFieldIdName] = parent.id;
                          })
                        );
                      } else if (!newRelationsIds.has(item.id) && currentOwner === parent.id) {
                        // Not required as a relation - nullify id
                        return DataStore.save(
                          relationModel.copyOf(item, (updated) => {
                            updated[targetFieldIdName] = null;
                          })
                        );
                      } else return undefined;
                    })
                    .filter((p) => !!p)
                );
              })
            );
          } else if (connectionType === "BELONGS_TO") {
            // bidirectional one-one
            acc.updatedParent[targetFieldIdName] = isEmpty(newRelation) ? null : newRelation.id;
          }
        }
      }
      return acc;
    },
    {
      updatedParent: {},
      promises: []
    }
  );
}

function toRelationValue({ isArray }, value) {
  const asArray = [value].flat().filter(Boolean);
  return isArray ? asArray : asArray?.[0];
}

export function withRelations(Form, { model, subtype, renderRelatedField }) {
  const schemaRelations = relatedFields(model, subtype).map((field) => ({
    ...field,
    manyMany: parseManyMany(model, field)
  })); // decorate with many-many information if needed as schema is rather obtuse
  const schemaRelationsByFieldKey = schemaRelations.reduce((acc, field) => ({ ...acc, [field.fieldKey]: field }), {});
  const { Relations: relationsArea, Alert: alertArea } = {
    ...combinedSchemaFor({ model, subtype })?.layout,
    ...{ Relations: "Secondary", Alert: "Footer" }
  };

  async function handleSave({ parent, relationFields }) {
    const changeSet = changes(parent, schemaRelations, relationFields);
    await Promise.all(changeSet.promises); // update all the other itemes, but return the parent field updates for the onSave
    return changeSet.updatedParent;
  }

  const Field = renderRelatedField || RelatedField;
  const Component = ({ id, onSave = identity, onError, onValidate, onChange, overrides, decorations, ...rest }) => {
    const formName = amplifyFormName(model, id ? "Update" : "Create", subtype);
    const formRef = useRef();
    const alertRef = useRef();
    const [newRelationValues, setNewRelationValues] = useState({});
    const formFieldValues = useRef({});
    const [relationFieldValues, setRelationFieldValues] = useState({});
    const customerDataStore = useCustomerDataStore();
    const { item: parent, isLoading, error } = useDataStoreItem({ model, id });
    const hasRelations = Array.isArray(schemaRelations) && schemaRelations.length > 0;
    const [invalid, setInvalid] = useState({});
    const [busy, setBusy] = useState();
    const [expanded, setExpanded] = useLocalStorage(
      ["Relation", model.name, subtype, "expanded"].filter(Boolean).join("-"),
      schemaRelations.length > 0 ? [schemaRelations[0].fieldKey] : []
    );
    function handleFieldChange(changedValues) {
      formFieldValues.current = { ...changedValues };
      if (!isEmpty(invalid)) setInvalid({});
      return onChange ? onChange(changedValues) : true;
    }
    useEffect(() => {
      (async () => {
        if (parent) {
          const values = await Promise.all(
            schemaRelations.map(async (fieldSchema) => {
              const { fieldKey, name, isArray, manyMany } = fieldSchema;
              let value = name in parent && isArray ? await parent[name].toArray() : await parent[name];
              if (manyMany) {
                value = await Promise.all(value.map(async (valueElement) => await valueElement[manyMany.targetFieldName]));
              }
              return [fieldKey, toRelationValue(fieldSchema, value)];
            })
          );
          setRelationFieldValues(Object.fromEntries(values));
        }
      })();
    }, [parent]);
    useEffect(() => {
      if (error) {
        onError && onError(relationFieldValues, error);
        console.error(error);
      }
    }, [error, onError, relationFieldValues]);
    const formErrorResponse = invalid?.["*"];
    const formError = useMemo(
      () => (formErrorResponse && formErrorResponse?.hasError ? formErrorResponse : {}),
      [formErrorResponse]
    );
    useLayoutEffect(() => {
      if (formError && alertRef.current) {
        alertRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    }, [formError]);

    const submitDisabled = Object.values(invalid).some(Boolean) || formError.hasError;
    /**
     * Submission proceeds as follows:
     * 1. Submit button onClick is overridden to call interceptClick
     * 2. interceptClick:
     *    a) stops propogation/default,
     *    b) performs per-relation field validation if specced
     *    c) performs all-form validation if specced (i.e. with relations)
     *    d) triggers a submit on the form if validation successful
     *
     * 3. Usual form submit handler saves form fields,
     * 4. We implment onSave to save the relations
     * @returns
     */
    async function interceptClick(event) {
      event.preventDefault();
      event.stopPropagation();
      let newInvalid = {};
      setBusy(true);
      if (!isEmpty(schemaRelations)) {
        newInvalid = Object.fromEntries(
          schemaRelations.map(async ({ fieldKey, isRequired, isArray, displayName, displayPluralName }) => {
            const currentValue = newRelationValues[fieldKey] || relationFieldValues[fieldKey];
            return [
              fieldKey,
              (isRequired &&
                (!currentValue || isEmpty(currentValue)) && {
                  hasError: true,
                  errorMessage: isArray
                    ? `Please select one or more ${displayPluralName} items`
                    : `Please select one ${displayName} item`
                }) ||
                (onValidate?.[fieldKey] && (await onValidate[fieldKey](currentValue))) // call custom validator for relation
            ];
          })
        );
      }

      if (onValidate?.["*"]) {
        // form validation specified for submission
        try {
          const { fields } = combinedSchemaFor({ model, subtype });
          const formValidationResult = await onValidate["*"]({
            modelFields: omit(
              { ...parent, ...relationFieldValues, ...formFieldValues.current, ...newRelationValues },
              Object.values(fields) // make sure we aren't trying to change readonly fields
                .filter(({ isReadOnly }) => isReadOnly)
                .map(({ name }) => name)
            ),
            customerDataStore
          });
          newInvalid = {
            ...newInvalid,
            "*": formValidationResult
          };
        } catch (e) {
          newInvalid = {
            ...newInvalid,
            "*": { hasError: true, errorMessage: e?.message || "Validation failed" }
          };
        }
      }

      // Set submit button disabled due to error state
      const overrideDisabled = Object.values(newInvalid).some(Boolean);
      setInvalid(newInvalid);
      if (!overrideDisabled) {
        // Trigger proper submit
        if (formRef.current) formRef.current.requestSubmit();
        else throw new Error("Unable to submit form");
      }
      setBusy(false);
      return false;
    }
    function clearButtonShim({ onClick, ...rest }) {
      function interceptClick(event) {
        onClick && onClick(event);
        setNewRelationValues({});
        setInvalid({});
        setRelationFieldValues({ ...relationFieldValues });
      }
      return <Button onClick={interceptClick} {...rest} />;
    }
    const save = useCallback(
      async (parent) => onSave(await handleSave({ parent, relationFields: newRelationValues })),
      [newRelationValues, onSave]
    );

    const clearButtonCallback = useCallback(clearButtonShim, [relationFieldValues]);
    const newOverrides = merge(
      {
        [formName]: {
          ref: (form) => (formRef.current = form) // callback to set the window ref of the form, used to submit
        }
      },
      overrides,
      {
        ClearButton: {
          as: clearButtonCallback
        },
        SubmitButton: {
          onClick: interceptClick,
          isDisabled: !busy && submitDisabled,
          isLoading: busy
        }
      },
      formError?.overrides // this allows us to target error messages against any field we like in the validator, for example
    );
    const relationsDecorations = hasRelations && (
      <Accordion.Container
        className={styles.relations}
        value={expanded}
        type="multiple"
        onValueChange={setExpanded}
        testId="relations"
        role="list"
      >
        {schemaRelations.map((field, i) => {
          const { fieldKey, displayName, subtype } = field;
          const errorMessage =
            (invalid[fieldKey]?.hasError && invalid[fieldKey].errorMessage) ||
            (formError && formError?.overrides?.[fieldKey]?.hasError && formError?.overrides?.[fieldKey]?.errorMessage);
          const isExpanded = (expanded || []).includes(fieldKey);
          return (
            <Accordion.Item role="list-item" value={fieldKey} key={fieldKey}>
              <Accordion.Trigger className={styles.heading}>
                <Container>
                  <Row>
                    <Col>
                      <BsLink45Deg />
                      &nbsp;{displayName}
                    </Col>

                    {!isExpanded && errorMessage && (
                      <Col className={styles.headingError}>
                        {"\u26a0"} {errorMessage}
                      </Col>
                    )}
                  </Row>
                </Container>
                <Accordion.Icon />
              </Accordion.Trigger>
              <Accordion.Content>
                <Field
                  sourceId={id}
                  model={model}
                  subtype={subtype}
                  defaultComponent={DataItemTable}
                  field={field}
                  fieldValue={newRelationValues[fieldKey] || relationFieldValues[fieldKey]}
                  isLoading={isLoading || (parent && !(fieldKey in relationFieldValues))}
                  onChange={(newValue) => {
                    setInvalid({});
                    /**
                     * WARNING: deep state is mutated here
                     * to avoid rerenders on value changes - the state is used only by callback functions from the
                     * nested forms so they will pull the new value
                     */
                    // Store value as array if schema requires array else scalar
                    newRelationValues[fieldKey] = toRelationValue(schemaRelationsByFieldKey[fieldKey], newValue);
                    // setNewValues({...newValues, [name]: newValue}); *** this would be correct normally but it forces Form to rerender, losing any control values
                  }}
                  error={isExpanded && errorMessage}
                />
              </Accordion.Content>
            </Accordion.Item>
          );
        })}
      </Accordion.Container>
    );
    const alertDecoration = formError?.errorMessage && (
      <Alert ref={alertRef} variant="danger" className={styles.formValidationResult}>
        <LayoutProvider layout="dense">
          <h5>
            <BsExclamationTriangleFill size="1.5em" />
            &nbsp;Validation Report
          </h5>
          <hr />
          &nbsp;{formError?.errorMessage}
          {formRef.current && (
            <div>
              <hr />
              <Flex gap={"1em"} justifyContent="flex-end">
                <Button
                  onClick={() => {
                    formRef.current.requestSubmit();
                  }}
                >
                  Save, Ignoring Errors
                </Button>
                <Button
                  onClick={() => {
                    setInvalid({});
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variation="primary"
                  onClick={(event) => {
                    setInvalid({});
                    interceptClick(event);
                  }}
                >
                  Retry
                </Button>
              </Flex>
            </div>
          )}
        </LayoutProvider>
      </Alert>
    );
    return (
      <Form
        id={id}
        onSave={save}
        onError={onError}
        onValidate={onValidate}
        onChange={handleFieldChange}
        overrides={newOverrides}
        decorations={[
          decorations,
          relationsDecorations && { [relationsArea]: relationsDecorations },
          alertDecoration && { [alertArea]: alertDecoration }
        ]}
        {...rest}
      />
    );
  };
  Component.displayName = "withRelations";
  return Component;
}
