import HelpBox from "./HelpBox";

export default function ClimateRelatedOpportunitiesHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Climate Related Opportunities Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Climate-related Opportunities are ppportunities identified through the Climate-related Scenario Analysis process, that have the potential to make a substantive financial or strategic impact on your business.
                </p>
                <p>
                    Create opportunities identified during your scenario analysis, using the table.
                </p>
                <p>
                    ClimateTracker then allows you to group these Opportunities in different ways for different disclosures. For example, you may add Opportunities over time in response to climate-related events or as your scenario modeling becomes more sophisticated.
                    To group the Opportunities you require for this disclosure, in the left hand side, enter a name for the set of Risks that are relevant to this disclosure, then select from existing Opportunities in the table that you have created, or create and select new elements.
                    This ensures that only the relevant Risks data will be used in the disclosure you are currently building.
                </p>
                <p>
                    You are also required to answer the question on the left hand side, to describe how the risks you have selected for this disclosure provide input into capital deployment and funding processes.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
