import HelpBox from "./HelpBox";

export default function ManagementEngagementProcessHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Management Engagement Process Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to create a record that describes a process by which management-level positions or committees engage with the governance body.
                </p>
                <p>
                    Follow these steps to define your management engagement process record:
                </p>
                <ol>
                    <li>Enter a name for the management engagement process.</li>
                    <li>Enter a description of the management engagement process.</li>
                    <li>Select a value for how often this process operates from the <i>Process frequency</i> dropdown menu.</li>
                    <li>Describe the position of committee that is engaging with the governance body in the field provided.</li>
                    <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of your management engagement process.</li>
                    <li>Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
