import HelpBox from "./HelpBox";

export default function GovernanceDataSetHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Governance Records Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to collate records on Governance data required for a climate-related disclosure.
        </p>
        <p>
          The purpose of your Governance data is to understand how your
          governance body oversees climate-related risks and climate-related
          opportunities, and the role management plays in assessing and managing
          those climate-related risks and opportunities.
        </p>
        <p>Follow these steps to collate the required Governance records:</p>
        <ol>
          <li>
            Create a name for the set of records in the <i>Governance record name</i> field.
          </li>
          <li>Next, enter a <i>Description</i> for these records.</li>
          <li>
            Finally, select or create then select, the appropriate climate governance records from the tables in the center of the screen.
          </li>
          <li>
            Once selected, click the <b>Create</b> or <b>Update</b> button at the bottom right.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
