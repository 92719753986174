import React from "react";

import { Outlet } from "react-router-dom";

import AdminNavbar from "./AdminNavbar";
import styles from "./AdminLayout.module.css";
import { FormStackProvider } from "../util/FormStack";
export default function AdminLayout() {
  return (
    <div className={styles.root}>
      <AdminNavbar className={styles.nav} />
      <main className={styles.page}>
        <FormStackProvider base={<Outlet />}></FormStackProvider>
      </main>
    </div>
  );
}
