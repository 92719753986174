import HelpBox from "./HelpBox";

export default function ManagementAccountabilitiesHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Management Accountabilities Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to collate records describing management’s role in assessing and managing climate-related risks and opportunities.
                </p>
                <p>
                    Follow these steps to collate the required management accountability records:
                </p>
                <ol>
                    <li>Enter a name for the management accountability records.</li>
                    <li>Enter a description of how climate-related responsibilities are assigned to management-level positions or committees.</li>
                    <li>Describe the organisational structure, including where these management-level positions or committees lie in the wider structure.</li>
                    <li>Select one or more items from the two tables in the middle of the screen to link these management process records into your disclosure. If no records are present, click the <i>New</i> button at the bottom of each table to create new records.</li>
                    <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of your management accountabilities.</li>
                    <li>Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
