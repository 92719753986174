import {Button, Flex} from "@aws-amplify/ui-react";
import {BsFillTrash3Fill, BsQuestionCircle} from "react-icons/bs";
import {useToast} from "../util/Toast";
import React, {useState} from "react";
import {DataStore} from "aws-amplify/datastore";
import {Modal} from "react-bootstrap";
import {uischema} from "../backend";
import {useDataStoreItem} from "../amplify/internal/utils";
import {findUniqueFieldName} from "../amplify/schemaHelpers";
import styles from "./ItemButtons.module.css";
import clsx from "clsx";
import ItemButton from "./ItemButton";

export default function DeleteItemButton({
  id,
  model,
  name = undefined,
  variation = "destructive",
  buttonClass = undefined,
  ...rest
}) {
  const schema = uischema().models[model.name];
  const {displayName, noDelete} = schema;
  const deletionName = name || displayName;
  const [alertOpen, setAlertOpen] = useState(false);
  const showToast = useToast();
  async function handleDelete() {
    await DataStore.delete(model, id);
    showToast({bg: "warning", content: `${deletionName} Deleted`});
    setAlertOpen(false);
  }
  const {item} = useDataStoreItem({model, id});
  const nameField = findUniqueFieldName(model);
  const description =
    (item && nameField && item[nameField] && (
      <b>&quot;{item[nameField]}&quot;</b>
    )) ||
    `this '${deletionName}' item`;

  return (
    id &&
    !noDelete && (
      <>
        <ItemButton
          id={id}
          model={model}
          variation={variation}
          onClick={() => setAlertOpen(true)}
          buttonClass={clsx(buttonClass, styles.deleteButton)}
          icon={<BsFillTrash3Fill />}
          label="Delete"
          requiredPermissions="delete"
          {...rest}
        />
        <Modal
          show={alertOpen}
          dialogClassName="shadow-lg rounded"
          onHide={() => setAlertOpen(false)}
        >
          <Modal.Header className="bg-danger" closeButton>
            <Modal.Title>
              <BsQuestionCircle size="2rem" />
              &nbsp; Confirm Delete of {deletionName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete {description}?
          </Modal.Body>
          <Modal.Footer>
            <Flex>
              <Button autoFocus onClick={() => setAlertOpen(false)}>
                No
              </Button>
              <Button variation="destructive" onClick={handleDelete}>
                <BsFillTrash3Fill />
                Yes
              </Button>
            </Flex>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
}
