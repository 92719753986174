import { Button } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import { Collapse, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from "react-bootstrap";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useLogger } from "./LoggerProvider";

const AppError: React.FC<{}> = () => {
  const [modalOpen, setModalOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const error = useRouteError();
  const message = isRouteErrorResponse(error)
    ? error.status + " " + error.statusText
    : error instanceof Error
    ? error?.message
    : JSON.stringify(error);
  const stack = error instanceof Error ? error.stack : null;
  const logger = useLogger();
  useEffect(() => logger.error(message, { stack }), [message, stack, logger]);
  return (
    <Modal
      show={modalOpen}
      onHide={() => {
        setModalOpen(false);
        window.location.reload();
      }}
    >
      <ModalHeader closeButton>
        <ModalTitle>Application Error</ModalTitle>
      </ModalHeader>
      <ModalBody>
        Unfortunately we've experienced an issue with the application. Please refresh your browser to continue. If the problem
        persists please contact your administrator
        <Collapse in={open}>
          <div>
            {message && <h5>{message}</h5>}
            {stack && (
              <div>
                <small>{stack}</small>
              </div>
            )}
          </div>
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <Button size="small" onClick={() => setOpen(!open)}>
          {open ? "Hide" : "Show"} Support Details
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AppError;
