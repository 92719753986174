import { DataStore } from "aws-amplify/datastore";

import { Portal } from "@restart/ui";
import { merge } from "lodash";
import { Col, Container, Stack } from "react-bootstrap";
import { findUniqueFieldName } from "../../shared/amplify/schemaHelpers";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { uischema } from "../backend";
import { useToast } from "../util/Toast";
import styles from "./formHelpers.module.css";
import { useLogger } from "../logger/LoggerProvider";
export function uniqueValidator({ model, nameField = findUniqueFieldName(model), id = undefined, dataStore = DataStore }) {
  return nameField
    ? {
        [nameField]: async (value, response) => {
          if (!!value) {
            try {
              const unique = await dataStore.query(model, (c) =>
                c.and((c) => [c.id.ne(id), nameField in c && c[nameField].eq(value)])
              );
              if (unique && unique.length > 0)
                return {
                  hasError: true,
                  errorMessage: "A unique value is required"
                };
            } catch (e) {
              return {
                hasError: true,
                errorMessage: "Unable to check uniqueness"
              };
            }
          } else
            return {
              hasError: true,
              errorMessage: "A non-empty value is required"
            };
          return response;
        }
      }
    : {};
}

export function combineValidators(...validators) {
  return validators.filter(Boolean).reduce(
    (acc, validator) =>
      validator
        ? {
            ...acc,
            ...Object.fromEntries(
              Object.entries(validator).map(([fieldName, validator]) => {
                const prevValidator = acc[fieldName];
                return [
                  fieldName,
                  prevValidator && validator
                    ? async (value, response) => await validator(value, await prevValidator(value, response))
                    : validator || prevValidator
                ];
              })
            )
          }
        : acc,
    {}
  );
}

/**
 * HOC Combine two dialog components - render createDialog if no id prop supplied, else updateDilaog, passing it the id
 * @param {*} param0
 * @returns
 */
export function asCreateOrUpdate({ createDialog, updateDialog }) {
  const Component = ({ id, children, ...rest }) => (
    <Stack>
      {id ? updateDialog({ id, ...rest }) : createDialog(rest)}
      <Container>{children}</Container>
    </Stack>
  );
  Component.displayName = "asCreateOrUpdate";
  return Component;
}

export function withCustomActionPosition(Form, { actionsRef: globalActionsRef }) {
  const Component = ({ overrides, actionsRef = globalActionsRef, ...rest }) => {
    const overridesMerged = actionsRef
      ? merge({}, overrides, {
          CTAFlex: {
            as: ({ children }) => (
              <Portal container={actionsRef}>
                <Col as="form" className="d-inline-flex p-2 gap-2">
                  {children}
                </Col>
              </Portal>
            )
          }
        })
      : overrides;
    return <Form className={styles.customActions} overrides={overridesMerged} {...rest} />;
  };
  Component.displayName = "withCustomActionPosition";
  return Component;
}

/**
 * HOC Apply unique validator to the given field on the form for the given model
 * @param {*} form
 * @param {*} param1
 * @returns
 */
export function withUniqueNameEnforced(Form, { model, nameField = findUniqueFieldName(model) }) {
  const Component = ({ id, onValidate, ...rest }) => {
    const customerDataStore = useCustomerDataStore();
    return (
      <Form
        id={id}
        onValidate={combineValidators(onValidate, uniqueValidator({ model, nameField, id, dataStore: customerDataStore }))}
        {...rest}
      />
    );
  };
  Component.displayName = "withUniqueNameEnforced";
  return Component;
}

export function withToast(Form, { model, nameField = findUniqueFieldName(model) }) {
  const Component = ({ onSuccess = undefined, onError = undefined, ...rest }) => {
    const showToast = useToast();
    const logger = useLogger();
    function handleSuccess(fields) {
      onSuccess && onSuccess(fields);
      setTimeout(() => {
        showToast({
          content: `Saved ${model && uischema().models[model.name].displayName} ${nameField ? `'${fields[nameField]}'` : "item"}
        `
        });
      }, 1000);
    }
    function handleError(fields, err) {
      onError && onError(fields, err);
      const message = `Error Saving ${model && uischema().models[model.name].displayName} ${
        nameField ? `'${fields[nameField]}'` : "item"
      }`;
      logger.error(message, { ...fields, detail: err });
      setTimeout(() => {
        showToast({
          bg: "danger",
          header: message,
          content: err.toString()
        });
      }, 1000);
    }
    return <Form {...rest} onSuccess={handleSuccess} onError={handleError} />;
  };
  Component.displayName = "withToast";
  return Component;
}
