import {Button as AmplifyButton} from "@aws-amplify/ui-react";
import {Predicates} from "aws-amplify/datastore";
import clsx from "clsx";
import {pick, isArray as lodashIsArray} from "lodash";
import {unparse} from "papaparse";
import PropTypes from "prop-types";
import {ButtonGroup, Dropdown} from "react-bootstrap";
import {BsDownload} from "react-icons/bs";
import {combinedSchemaFor, exportFields} from "../amplify/schemaHelpers";
import {useCustomerDataStore} from "../customer/useCustomerDataStore";
import useDownload from "../import/useDownload";
import useLocalStorage from "../storage/useLocalStorage";
import ItemButton from "./ItemButton";
import styles from "./ItemButtons.module.css";

function mapToCsvField(
  value,
  {name, type, isArray} = {type: "String", isArray: false}
) {
  if (value) {
    if (isArray && lodashIsArray(value)) {
      return value
        .map((element) => mapToCsvField(element, {name, type, isArray: false}))
        .join(",");
    } else {
      return value.toString();
    }
  } else return "";
}

function mapToCsvFields(item, {fields, name}) {
  return Object.fromEntries(
    Object.entries(item)
      .filter(([fieldName]) => fieldName in fields)
      .map(([fieldName, value]) => [
        fieldName,
        mapToCsvField(value, fields[fieldName])
      ])
  );
}

export default function DownloadButton({
  id,
  item,
  model,
  subtype,
  icon = <BsDownload />,
  label = "Export",
  buttonClass,
  ...rest
}) {
  const [format, setFormat] = useLocalStorage("Download-format", "json");
  const contentType = {json: "application/json", csv: "text/csv"}?.[format];
  const {handleClick, busy} = useDownload({
    data: fetchData,
    contentType,
    filename: [model.name, subtype, format].filter(Boolean).join(".")
  });
  const datastore = useCustomerDataStore();
  const schema = combinedSchemaFor({model, subtype});
  const {discriminatorField, noDownload} = schema;
  async function fetchData() {
    const items = await datastore.query(
      model,
      (subtype &&
        discriminatorField &&
        ((c) => c[discriminatorField].eq(subtype))) ||
        Predicates.ALL
    );
    // Strip out fields that we don't want to save (i.e. pretty much all except the columns shown in the table - keep discriminatorType and ID)
    const showFields = exportFields({modelSchema: schema, subtype});
    const output = items.map((item) => ({
      ...pick(item, showFields),
      model: model.name
    }));
    if (format === "csv") {
      return new Blob(
        [
          unparse(
            output.map((item) => mapToCsvFields(item, schema)),
            {quotes: true}
          )
        ],
        {type: contentType}
      );
    } else return output;
  }

  return (
    !noDownload && (
      <Dropdown as={ButtonGroup}>
        <ItemButton
          id={id}
          item={item}
          variant="windowed"
          variation="secondary"
          isLoading={busy}
          model={model}
          icon={icon}
          label={label}
          requiredPermissions="read"
          buttonClass={clsx(buttonClass, styles.downloadButton)}
          onClick={handleClick}
          title={`${label} ${schema?.displayPluralName}`}
          {...rest}
        />
        <Dropdown.Toggle
          split
          variant="outline-primary"
          id="dropdown-split-basic"
          className={styles.splitButton}
          as={AmplifyButton}
        >
          {format}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{zIndex: "2000"}}>
          <Dropdown.Item
            active={format === "json"}
            onClick={() => setFormat("json")}
          >
            Json
          </Dropdown.Item>
          <Dropdown.Item
            active={format === "csv"}
            onClick={() => setFormat("csv")}
          >
            Csv
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  );
}

DownloadButton.propTypes = {
  ...ItemButton.propTypes,
  model: PropTypes.func.isRequired,
  subtype: PropTypes.string
};
