import { Button, Loader, View } from "@aws-amplify/ui-react";
import { PersistentModel } from "aws-amplify/dist/esm/datastore";
import { Hub } from "aws-amplify/utils";
import clsx from "clsx";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Alert, Card, CardBody, CardHeader, Spinner, Stack } from "react-bootstrap";
import { BsCheckAll, BsExclamationTriangleFill, BsGlobe2, BsHourglass, BsPencilFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { models } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { FormStackProvider } from "../util/FormStack";
import { createDisclosureValidator } from "../validators/DisclosureValidators";
import { FormValidationResponse } from "../validators/withValidators";
import styles from "./PublishDisclosure.module.css";
export type PublishDisclosureProps = {};

const validationDelay = 3000; // ms
const validationGranularity = 100; // ms

export type ValidationResponseProps = {
  response?: FormValidationResponse;
  header?: ReactNode;
};
const ValidationResponseCard: React.FC<ValidationResponseProps> = ({ response, header }) => {
  return (
    response &&
    response?.hasError && (
      <Card>
        {header && (
          <CardHeader className={clsx(styles.header, "d-flex align-items-center gap-2")}>
            <BsExclamationTriangleFill className="icon error" />
            &nbsp;{header}
          </CardHeader>
        )}
        <CardBody>{response.errorMessage}</CardBody>
      </Card>
    )
  );
};

type PendingValidate = { state: "PendingValidate"; busy?: true; timeLeft?: number };
type Validate = { state: "Validate"; busy?: true; error?: string; result?: FormValidationResponse };
type PendingPublish = { state: "PendingPublish" };
type Publish = { state: "Publish"; busy?: true; error?: string };
type Complete = { state: "Complete"; disclosure: PersistentModel };

const disclosureValidator = createDisclosureValidator({ deep: true });
const PublishDisclosure: React.FC<PublishDisclosureProps> = () => {
  const { id } = useParams();
  const [currentState, setState] = useState<PendingValidate | Validate | PendingPublish | Publish | Complete>({
    state: "PendingValidate",
    timeLeft: 0
  });
  const { state } = currentState;
  const { timeLeft } = currentState as PendingValidate;
  const { result } = currentState as Validate;
  const { error } = currentState as Validate | Publish;
  const { busy } = currentState as Validate | Publish | PendingValidate;
  const { disclosure } = currentState as Complete;

  const customerDataStore = useCustomerDataStore();
  const enqueueRecheck = useCallback(() => {
    if ((state === "PendingValidate" && timeLeft === undefined) || state === "Validate") {
      setState({ state: "PendingValidate", busy: true, timeLeft: validationDelay });
    }
  }, [state, timeLeft]);
  async function publish() {
    const { Disclosure } = models();
    try {
      setState({ state: "Publish", busy: true });
      const item: PersistentModel = await customerDataStore.query(Disclosure, id);
      const disclosure = await customerDataStore.save(
        Disclosure.copyOf(item, (item) => Object.assign(item, { Status: "Published" }))
      );
      setState({ state: "Complete", disclosure });
    } catch (e) {
      setState({ state: "Publish", error: (e as Error).message });
    }
  }

  useEffect(() => {
    const subscriptionCancel = Hub.listen("datastore", ({ payload: { event, data } }) => {
      if (event === "outboxMutationProcessed") {
        enqueueRecheck();
      }
    });
    return subscriptionCancel;
  }, [enqueueRecheck]);

  useEffect(() => {
    if (!error) {
      switch (state) {
        case "PendingValidate": {
          if (typeof timeLeft === "number") {
            if (timeLeft === 0) {
              setState({ state: "Validate" });
            } else {
              const cancel = window.setTimeout(
                () => setState({ state: "PendingValidate", busy: true, timeLeft: timeLeft - validationGranularity }),
                validationGranularity
              );
              return () => {
                window.clearTimeout(cancel);
              };
            }
          }
          break;
        }
        case "Validate":
          if (!busy && !result) {
            (async () => {
              try {
                setState({ state: "Validate", busy: true });
                const result = await disclosureValidator({ modelFields: { id }, customerDataStore });
                setState(result ? { state: "Validate", result } : { state: "PendingPublish" });
              } catch (e) {
                setState({ state: "Validate", error: (e as Error).message });
              }
            })();
          }
          break;
        default:
          break;
      }
    }
  }, [id, customerDataStore, busy, error, result, state, timeLeft, enqueueRecheck]);

  const recheckCompletion = timeLeft && Math.round((100.0 * (validationDelay - timeLeft)) / validationDelay);
  return (
    <Stack className="page p-2">
      <FormStackProvider
        onChange={(currentIndex: number) => {
          if (currentIndex === 0) enqueueRecheck();
        }}
        base={
          <View>
            <h2>Publish Disclosure</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {!busy && !error ? (
              state === "Validate" ? (
                result && (
                  <ValidationResponseCard
                    response={result}
                    header={
                      <div>
                        <h5>Disclosure Publication Report</h5>
                        <span>
                          Click <BsPencilFill /> to edit the relevant records or, to continue with a partial Disclosure, click&nbsp;
                          <Button size="small" onClick={publish} variation="warning">
                            Publish Ignoring Errors
                          </Button>{" "}
                        </span>
                      </div>
                    }
                  />
                )
              ) : state === "PendingPublish" ? (
                <Card>
                  <CardHeader className={styles.header}>
                    <BsCheckAll className="icon success" />
                    Validation Success
                  </CardHeader>
                  <CardBody>
                    Pre-Publish validation completed with no issues. Proceed to&nbsp;
                    <Button variation="primary" onClick={publish}>
                      Publish Now
                    </Button>
                  </CardBody>
                </Card>
              ) : (
                state === "Complete" && (
                  <Card className="m-auto">
                    <CardHeader className={styles.header}>
                      <BsGlobe2 className="icon success" />
                      &nbsp; Publishing Complete
                    </CardHeader>
                    <CardBody>
                      The '{disclosure.DataSetName}' disclosure will now be available in the ClimateTracker Portal
                    </CardBody>
                  </Card>
                )
              ) // busy
            ) : state === "PendingValidate" || state === "Validate" ? (
              <Card className="m-auto">
                <CardHeader className={styles.header}>
                  <BsHourglass className="icon" />
                  &nbsp;Checking validity of Disclosure before publishing...
                </CardHeader>
                <CardBody>
                  <Loader variation="linear" percentage={recheckCompletion} isDeterminate />
                </CardBody>
              </Card>
            ) : (
              <Card className="m-auto">
                <CardHeader className={styles.header}>
                  <BsHourglass className="icon" />
                  &nbsp;Publishing...
                </CardHeader>
                <CardBody>
                  <Spinner></Spinner>
                </CardBody>
              </Card>
            )}
          </View>
        }
        baseBreadcrumbName={"Publish"}
      />
    </Stack>
  );
};

export default PublishDisclosure;
