/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Icon,
  ScrollView,
  SelectField,
  Text,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { Disclosure } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
function ArrayField({
  items = [],
  onChange,
  label,
  inputFieldRef,
  children,
  hasError,
  setFieldValue,
  currentFieldValue,
  defaultFieldValue,
  lengthLimit,
  getBadgeText,
  runValidationTasks,
  errorMessage,
}) {
  const labelElement = <Text>{label}</Text>;
  const {
    tokens: {
      components: {
        fieldmessages: { error: errorStyles },
      },
    },
  } = useTheme();
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
  const [isEditing, setIsEditing] = React.useState();
  React.useEffect(() => {
    if (isEditing) {
      inputFieldRef?.current?.focus();
    }
  }, [isEditing]);
  const removeItem = async (removeIndex) => {
    const newItems = items.filter((value, index) => index !== removeIndex);
    await onChange(newItems);
    setSelectedBadgeIndex(undefined);
  };
  const addItem = async () => {
    const { hasError } = runValidationTasks();
    if (
      currentFieldValue !== undefined &&
      currentFieldValue !== null &&
      currentFieldValue !== "" &&
      !hasError
    ) {
      const newItems = [...items];
      if (selectedBadgeIndex !== undefined) {
        newItems[selectedBadgeIndex] = currentFieldValue;
        setSelectedBadgeIndex(undefined);
      } else {
        newItems.push(currentFieldValue);
      }
      await onChange(newItems);
      setIsEditing(false);
    }
  };
  const arraySection = (
    <React.Fragment>
      {!!items?.length && (
        <ScrollView height="inherit" width="inherit" maxHeight={"7rem"}>
          {items.map((value, index) => {
            return (
              <Badge
                key={index}
                style={{
                  cursor: "pointer",
                  alignItems: "center",
                  marginRight: 3,
                  marginTop: 3,
                  backgroundColor:
                    index === selectedBadgeIndex ? "#B8CEF9" : "",
                }}
                onClick={() => {
                  setSelectedBadgeIndex(index);
                  setFieldValue(items[index]);
                  setIsEditing(true);
                }}
              >
                {getBadgeText ? getBadgeText(value) : value.toString()}
                <Icon
                  style={{
                    cursor: "pointer",
                    paddingLeft: 3,
                    width: 20,
                    height: 20,
                  }}
                  viewBox={{ width: 20, height: 20 }}
                  paths={[
                    {
                      d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                      stroke: "black",
                    },
                  ]}
                  ariaLabel="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    removeItem(index);
                  }}
                />
              </Badge>
            );
          })}
        </ScrollView>
      )}
      <Divider orientation="horizontal" marginTop={5} />
    </React.Fragment>
  );
  if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
    return (
      <React.Fragment>
        {labelElement}
        {arraySection}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {labelElement}
      {isEditing && children}
      {!isEditing ? (
        <>
          <Button
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Add item
          </Button>
          {errorMessage && hasError && (
            <Text color={errorStyles.color} fontSize={errorStyles.fontSize}>
              {errorMessage}
            </Text>
          )}
        </>
      ) : (
        <Flex justifyContent="flex-end">
          {(currentFieldValue || isEditing) && (
            <Button
              children="Cancel"
              type="button"
              size="small"
              onClick={() => {
                setFieldValue(defaultFieldValue);
                setIsEditing(false);
                setSelectedBadgeIndex(undefined);
              }}
            ></Button>
          )}
          <Button size="small" variation="link" onClick={addItem}>
            {selectedBadgeIndex !== undefined ? "Save" : "Add"}
          </Button>
        </Flex>
      )}
      {arraySection}
    </React.Fragment>
  );
}
export default function DisclosureUpdateForm(props) {
  const {
    id: idProp,
    disclosure: disclosureModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    DataSetName: "",
    Description: "",
    Author: "",
    Status: "",
    PublishedTo: [],
  };
  const [DataSetName, setDataSetName] = React.useState(
    initialValues.DataSetName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [Author, setAuthor] = React.useState(initialValues.Author);
  const [Status, setStatus] = React.useState(initialValues.Status);
  const [PublishedTo, setPublishedTo] = React.useState(
    initialValues.PublishedTo
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = disclosureRecord
      ? { ...initialValues, ...disclosureRecord }
      : initialValues;
    setDataSetName(cleanValues.DataSetName);
    setDescription(cleanValues.Description);
    setAuthor(cleanValues.Author);
    setStatus(cleanValues.Status);
    setPublishedTo(cleanValues.PublishedTo ?? []);
    setCurrentPublishedToValue("");
    setErrors({});
  };
  const [disclosureRecord, setDisclosureRecord] =
    React.useState(disclosureModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Disclosure, idProp)
        : disclosureModelProp;
      setDisclosureRecord(record);
    };
    queryData();
  }, [idProp, disclosureModelProp]);
  React.useEffect(resetStateValues, [disclosureRecord]);
  const [currentPublishedToValue, setCurrentPublishedToValue] =
    React.useState("");
  const PublishedToRef = React.createRef();
  const getDisplayValue = {
    PublishedTo: (r) => {
      const enumDisplayValueMap = {
        Public: "Public",
        Stakeholder: "Stakeholder",
        Assurance: "Assurance",
        Regulator: "Regulator",
      };
      return enumDisplayValueMap[r];
    },
  };
  const validations = {
    DataSetName: [{ type: "Required" }],
    Description: [],
    Author: [],
    Status: [],
    PublishedTo: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DataSetName,
          Description,
          Author,
          Status,
          PublishedTo,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Disclosure.copyOf(disclosureRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "DisclosureUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate-related Disclosure Report"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update this climate disclosure report that links all required disclosure records the climate-related risks and opportunities that could reasonably be expected to affect your cash flows, access to finance or cost of capital over the short, medium or long term."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Disclosure name"
        isRequired={true}
        isReadOnly={false}
        value={DataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName: value,
              Description,
              Author,
              Status,
              PublishedTo,
            };
            const result = onChange(modelFields);
            value = result?.DataSetName ?? value;
          }
          if (errors.DataSetName?.hasError) {
            runValidationTasks("DataSetName", value);
          }
          setDataSetName(value);
        }}
        onBlur={() => runValidationTasks("DataSetName", DataSetName)}
        errorMessage={errors.DataSetName?.errorMessage}
        hasError={errors.DataSetName?.hasError}
        {...getOverrideProps(overrides, "DataSetName")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description: value,
              Author,
              Status,
              PublishedTo,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <TextField
        label="Disclosure author"
        isRequired={false}
        isReadOnly={false}
        value={Author}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description,
              Author: value,
              Status,
              PublishedTo,
            };
            const result = onChange(modelFields);
            value = result?.Author ?? value;
          }
          if (errors.Author?.hasError) {
            runValidationTasks("Author", value);
          }
          setAuthor(value);
        }}
        onBlur={() => runValidationTasks("Author", Author)}
        errorMessage={errors.Author?.errorMessage}
        hasError={errors.Author?.hasError}
        {...getOverrideProps(overrides, "Author")}
      ></TextField>
      <SelectField
        label="Disclosure status"
        placeholder="Please select an option"
        isDisabled={false}
        value={Status}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description,
              Author,
              Status: value,
              PublishedTo,
            };
            const result = onChange(modelFields);
            value = result?.Status ?? value;
          }
          if (errors.Status?.hasError) {
            runValidationTasks("Status", value);
          }
          setStatus(value);
        }}
        onBlur={() => runValidationTasks("Status", Status)}
        errorMessage={errors.Status?.errorMessage}
        hasError={errors.Status?.hasError}
        {...getOverrideProps(overrides, "Status")}
      >
        <option
          children="Draft"
          value="Draft"
          {...getOverrideProps(overrides, "Statusoption0")}
        ></option>
        <option
          children="Published"
          value="Published"
          {...getOverrideProps(overrides, "Statusoption1")}
        ></option>
        <option
          children="Inactive"
          value="Inactive"
          {...getOverrideProps(overrides, "Statusoption2")}
        ></option>
      </SelectField>
      <ArrayField
        onChange={async (items) => {
          let values = items;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description,
              Author,
              Status,
              PublishedTo: values,
            };
            const result = onChange(modelFields);
            values = result?.PublishedTo ?? values;
          }
          setPublishedTo(values);
          setCurrentPublishedToValue("");
        }}
        currentFieldValue={currentPublishedToValue}
        label={"Published to"}
        items={PublishedTo}
        hasError={errors?.PublishedTo?.hasError}
        runValidationTasks={async () =>
          await runValidationTasks("PublishedTo", currentPublishedToValue)
        }
        errorMessage={errors?.PublishedTo?.errorMessage}
        getBadgeText={getDisplayValue.PublishedTo}
        setFieldValue={setCurrentPublishedToValue}
        inputFieldRef={PublishedToRef}
        defaultFieldValue={""}
      >
        <SelectField
          label="Published to"
          placeholder="Please select an option"
          isDisabled={false}
          value={currentPublishedToValue}
          onChange={(e) => {
            let { value } = e.target;
            if (errors.PublishedTo?.hasError) {
              runValidationTasks("PublishedTo", value);
            }
            setCurrentPublishedToValue(value);
          }}
          onBlur={() =>
            runValidationTasks("PublishedTo", currentPublishedToValue)
          }
          errorMessage={errors.PublishedTo?.errorMessage}
          hasError={errors.PublishedTo?.hasError}
          ref={PublishedToRef}
          labelHidden={true}
          {...getOverrideProps(overrides, "PublishedTo")}
        >
          <option
            children="Public"
            value="Public"
            {...getOverrideProps(overrides, "PublishedTooption0")}
          ></option>
          <option
            children="Stakeholder"
            value="Stakeholder"
            {...getOverrideProps(overrides, "PublishedTooption1")}
          ></option>
          <option
            children="Assurance"
            value="Assurance"
            {...getOverrideProps(overrides, "PublishedTooption2")}
          ></option>
          <option
            children="Regulator"
            value="Regulator"
            {...getOverrideProps(overrides, "PublishedTooption3")}
          ></option>
        </SelectField>
      </ArrayField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || disclosureModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || disclosureModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
