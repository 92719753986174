import {cloneDeepWith, isArray, startCase} from "lodash";

/**
 * Given an array of strings, return an object keyed by the strings, with default startcase displayNames set. Given an object
 * set return a copy with the displayName key of each object value to the startcase
 *
 * e.g.
 * ["FooBar","Bob"] => { FooBar: { displayName: "Foo Bar" }, "Bob: {displayName: "Bob"} }
 * { FooBar: { a: 1}, Bob: { displayName: "already set"}}=> { FooBar: { displayName: "Foo Bar" }, "Bob: {displayName: "already set"} }
 *
 * @param objectOrArray
 * @returns
 */
function addDefaultDisplayNames<T extends object>(
  objectOrArray?: Record<string, T> | string[]
) {
  return !objectOrArray
    ? undefined
    : isArray(objectOrArray)
    ? objectOrArray.reduce(
        (acc, k) => ({...acc, [k]: {displayName: startCase(k)}}),
        {}
      ) // array of key names -  convert to object keyed by each with displayNames set
    : Object.fromEntries(
        Object.entries(objectOrArray).map(([k, v]) => [
          k,
          v && typeof v === "object" && !v.hasOwnProperty("displayName")
            ? {...v, displayName: startCase(k)}
            : v
        ])
      ); // already keyed object - add in default display name if not specced
}

/**
 * lodash cloneDeepWith customizer to return a copy of schema with displayNames set to startCase versions where not already specified
 * @param value
 * @param object
 * @returns
 */
export function insertDisplayNames(value: any, object: any): any {
  if (object && typeof value === "object") {
    let rv = cloneDeepWith(value, insertDisplayNames);
    if (value.hasOwnProperty("name") && !rv.displayName)
      rv.displayName = startCase(value.name);
    if (value.hasOwnProperty("pluralName") && !rv.displayPluralName)
      rv.displayPluralName = startCase(value.pluralName);
    if (value.hasOwnProperty("subtypes"))
      rv["subtypes"] = addDefaultDisplayNames(rv["subtypes"]);
    return rv;
  }
}
