import {BsFillQuestionCircleFill} from "react-icons/bs";
import styles from "./HelpBox.module.css";

function Box({children}) {
  return <div className={styles.helpBox}>{children}</div>;
}
function Header({children}) {
  return (
    <h4>
      <span className={styles.helpHeader}>
        <BsFillQuestionCircleFill />
        &nbsp;
        {children}
      </span>
    </h4>
  );
}

function Content({children}) {
  return <span className={styles.helpContent}>{children}</span>;
}

export default Object.assign(Box, {
  Box,
  Header,
  Content
});
