/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Asset } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function AssetUpdateForm(props) {
  const {
    id: idProp,
    asset: assetModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    AssetName: "",
    AssetID: "",
    Description: "",
    AssetClass: "",
    AssetType: "",
    AssetComponent: "",
    Quantity: "",
    CapitalisationDate: "",
    ValueAtAcquisition: "",
    ValueClimateAffected: "",
    ValueClimateAffectedType: "",
    CurrentValue: "",
    Condition: "",
    Lifespan: "",
  };
  const [AssetName, setAssetName] = React.useState(initialValues.AssetName);
  const [AssetID, setAssetID] = React.useState(initialValues.AssetID);
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [AssetClass, setAssetClass] = React.useState(initialValues.AssetClass);
  const [AssetType, setAssetType] = React.useState(initialValues.AssetType);
  const [AssetComponent, setAssetComponent] = React.useState(
    initialValues.AssetComponent
  );
  const [Quantity, setQuantity] = React.useState(initialValues.Quantity);
  const [CapitalisationDate, setCapitalisationDate] = React.useState(
    initialValues.CapitalisationDate
  );
  const [ValueAtAcquisition, setValueAtAcquisition] = React.useState(
    initialValues.ValueAtAcquisition
  );
  const [ValueClimateAffected, setValueClimateAffected] = React.useState(
    initialValues.ValueClimateAffected
  );
  const [ValueClimateAffectedType, setValueClimateAffectedType] =
    React.useState(initialValues.ValueClimateAffectedType);
  const [CurrentValue, setCurrentValue] = React.useState(
    initialValues.CurrentValue
  );
  const [Condition, setCondition] = React.useState(initialValues.Condition);
  const [Lifespan, setLifespan] = React.useState(initialValues.Lifespan);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = assetRecord
      ? { ...initialValues, ...assetRecord }
      : initialValues;
    setAssetName(cleanValues.AssetName);
    setAssetID(cleanValues.AssetID);
    setDescription(cleanValues.Description);
    setAssetClass(cleanValues.AssetClass);
    setAssetType(cleanValues.AssetType);
    setAssetComponent(cleanValues.AssetComponent);
    setQuantity(cleanValues.Quantity);
    setCapitalisationDate(cleanValues.CapitalisationDate);
    setValueAtAcquisition(cleanValues.ValueAtAcquisition);
    setValueClimateAffected(cleanValues.ValueClimateAffected);
    setValueClimateAffectedType(cleanValues.ValueClimateAffectedType);
    setCurrentValue(cleanValues.CurrentValue);
    setCondition(cleanValues.Condition);
    setLifespan(cleanValues.Lifespan);
    setErrors({});
  };
  const [assetRecord, setAssetRecord] = React.useState(assetModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Asset, idProp)
        : assetModelProp;
      setAssetRecord(record);
    };
    queryData();
  }, [idProp, assetModelProp]);
  React.useEffect(resetStateValues, [assetRecord]);
  const validations = {
    AssetName: [],
    AssetID: [],
    Description: [],
    AssetClass: [],
    AssetType: [],
    AssetComponent: [],
    Quantity: [],
    CapitalisationDate: [],
    ValueAtAcquisition: [],
    ValueClimateAffected: [],
    ValueClimateAffectedType: [],
    CurrentValue: [],
    Condition: [],
    Lifespan: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          AssetName,
          AssetID,
          Description,
          AssetClass,
          AssetType,
          AssetComponent,
          Quantity,
          CapitalisationDate,
          ValueAtAcquisition,
          ValueClimateAffected,
          ValueClimateAffectedType,
          CurrentValue,
          Condition,
          Lifespan,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Asset.copyOf(assetRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AssetUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Asset"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 22c-e."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update a record describing an asset subject to climate-related risks and opportunities. This information is used to compute analytics and visualisations (e.g. assets at risk), but is not used in a formal climate-related disclosure."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <TextField
          label="Asset name"
          isRequired={false}
          isReadOnly={false}
          value={AssetName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName: value,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.AssetName ?? value;
            }
            if (errors.AssetName?.hasError) {
              runValidationTasks("AssetName", value);
            }
            setAssetName(value);
          }}
          onBlur={() => runValidationTasks("AssetName", AssetName)}
          errorMessage={errors.AssetName?.errorMessage}
          hasError={errors.AssetName?.hasError}
          {...getOverrideProps(overrides, "AssetName")}
        ></TextField>
        <TextField
          label="Asset id"
          isRequired={false}
          isReadOnly={false}
          value={AssetID}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID: value,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.AssetID ?? value;
            }
            if (errors.AssetID?.hasError) {
              runValidationTasks("AssetID", value);
            }
            setAssetID(value);
          }}
          onBlur={() => runValidationTasks("AssetID", AssetID)}
          errorMessage={errors.AssetID?.errorMessage}
          hasError={errors.AssetID?.hasError}
          {...getOverrideProps(overrides, "AssetID")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AssetName,
              AssetID,
              Description: value,
              AssetClass,
              AssetType,
              AssetComponent,
              Quantity,
              CapitalisationDate,
              ValueAtAcquisition,
              ValueClimateAffected,
              ValueClimateAffectedType,
              CurrentValue,
              Condition,
              Lifespan,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Asset class"
          isRequired={false}
          isReadOnly={false}
          value={AssetClass}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass: value,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.AssetClass ?? value;
            }
            if (errors.AssetClass?.hasError) {
              runValidationTasks("AssetClass", value);
            }
            setAssetClass(value);
          }}
          onBlur={() => runValidationTasks("AssetClass", AssetClass)}
          errorMessage={errors.AssetClass?.errorMessage}
          hasError={errors.AssetClass?.hasError}
          {...getOverrideProps(overrides, "AssetClass")}
        ></TextField>
        <TextField
          label="Asset type"
          isRequired={false}
          isReadOnly={false}
          value={AssetType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType: value,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.AssetType ?? value;
            }
            if (errors.AssetType?.hasError) {
              runValidationTasks("AssetType", value);
            }
            setAssetType(value);
          }}
          onBlur={() => runValidationTasks("AssetType", AssetType)}
          errorMessage={errors.AssetType?.errorMessage}
          hasError={errors.AssetType?.hasError}
          {...getOverrideProps(overrides, "AssetType")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Asset component"
          isRequired={false}
          isReadOnly={false}
          value={AssetComponent}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent: value,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.AssetComponent ?? value;
            }
            if (errors.AssetComponent?.hasError) {
              runValidationTasks("AssetComponent", value);
            }
            setAssetComponent(value);
          }}
          onBlur={() => runValidationTasks("AssetComponent", AssetComponent)}
          errorMessage={errors.AssetComponent?.errorMessage}
          hasError={errors.AssetComponent?.hasError}
          {...getOverrideProps(overrides, "AssetComponent")}
        ></TextField>
        <TextField
          label="Quantity"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Quantity}
          onChange={(e) => {
            let value = isNaN(parseInt(e.target.value))
              ? e.target.value
              : parseInt(e.target.value);
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity: value,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.Quantity ?? value;
            }
            if (errors.Quantity?.hasError) {
              runValidationTasks("Quantity", value);
            }
            setQuantity(value);
          }}
          onBlur={() => runValidationTasks("Quantity", Quantity)}
          errorMessage={errors.Quantity?.errorMessage}
          hasError={errors.Quantity?.hasError}
          {...getOverrideProps(overrides, "Quantity")}
        ></TextField>
      </Grid>
      <TextField
        label="Capitalisation date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={CapitalisationDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AssetName,
              AssetID,
              Description,
              AssetClass,
              AssetType,
              AssetComponent,
              Quantity,
              CapitalisationDate: value,
              ValueAtAcquisition,
              ValueClimateAffected,
              ValueClimateAffectedType,
              CurrentValue,
              Condition,
              Lifespan,
            };
            const result = onChange(modelFields);
            value = result?.CapitalisationDate ?? value;
          }
          if (errors.CapitalisationDate?.hasError) {
            runValidationTasks("CapitalisationDate", value);
          }
          setCapitalisationDate(value);
        }}
        onBlur={() =>
          runValidationTasks("CapitalisationDate", CapitalisationDate)
        }
        errorMessage={errors.CapitalisationDate?.errorMessage}
        hasError={errors.CapitalisationDate?.hasError}
        {...getOverrideProps(overrides, "CapitalisationDate")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Value at acquisition"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={ValueAtAcquisition}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition: value,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.ValueAtAcquisition ?? value;
            }
            if (errors.ValueAtAcquisition?.hasError) {
              runValidationTasks("ValueAtAcquisition", value);
            }
            setValueAtAcquisition(value);
          }}
          onBlur={() =>
            runValidationTasks("ValueAtAcquisition", ValueAtAcquisition)
          }
          errorMessage={errors.ValueAtAcquisition?.errorMessage}
          hasError={errors.ValueAtAcquisition?.hasError}
          {...getOverrideProps(overrides, "ValueAtAcquisition")}
        ></TextField>
        <TextField
          label="Value affected"
          isRequired={false}
          isReadOnly={false}
          value={ValueClimateAffected}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected: value,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.ValueClimateAffected ?? value;
            }
            if (errors.ValueClimateAffected?.hasError) {
              runValidationTasks("ValueClimateAffected", value);
            }
            setValueClimateAffected(value);
          }}
          onBlur={() =>
            runValidationTasks("ValueClimateAffected", ValueClimateAffected)
          }
          errorMessage={errors.ValueClimateAffected?.errorMessage}
          hasError={errors.ValueClimateAffected?.hasError}
          {...getOverrideProps(overrides, "ValueClimateAffected")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid10")}
      >
        <SelectField
          label="Value affected type"
          placeholder="Please select an option"
          isDisabled={false}
          value={ValueClimateAffectedType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType: value,
                CurrentValue,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.ValueClimateAffectedType ?? value;
            }
            if (errors.ValueClimateAffectedType?.hasError) {
              runValidationTasks("ValueClimateAffectedType", value);
            }
            setValueClimateAffectedType(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ValueClimateAffectedType",
              ValueClimateAffectedType
            )
          }
          errorMessage={errors.ValueClimateAffectedType?.errorMessage}
          hasError={errors.ValueClimateAffectedType?.hasError}
          {...getOverrideProps(overrides, "ValueClimateAffectedType")}
        >
          <option
            children="Amount"
            value="Amount"
            {...getOverrideProps(overrides, "ValueClimateAffectedTypeoption0")}
          ></option>
          <option
            children="Percent"
            value="Percent"
            {...getOverrideProps(overrides, "ValueClimateAffectedTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Current value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={CurrentValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue: value,
                Condition,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.CurrentValue ?? value;
            }
            if (errors.CurrentValue?.hasError) {
              runValidationTasks("CurrentValue", value);
            }
            setCurrentValue(value);
          }}
          onBlur={() => runValidationTasks("CurrentValue", CurrentValue)}
          errorMessage={errors.CurrentValue?.errorMessage}
          hasError={errors.CurrentValue?.hasError}
          {...getOverrideProps(overrides, "CurrentValue")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <TextField
          label="Condition"
          isRequired={false}
          isReadOnly={false}
          value={Condition}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition: value,
                Lifespan,
              };
              const result = onChange(modelFields);
              value = result?.Condition ?? value;
            }
            if (errors.Condition?.hasError) {
              runValidationTasks("Condition", value);
            }
            setCondition(value);
          }}
          onBlur={() => runValidationTasks("Condition", Condition)}
          errorMessage={errors.Condition?.errorMessage}
          hasError={errors.Condition?.hasError}
          {...getOverrideProps(overrides, "Condition")}
        ></TextField>
        <TextField
          label="Lifespan"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Lifespan}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                AssetName,
                AssetID,
                Description,
                AssetClass,
                AssetType,
                AssetComponent,
                Quantity,
                CapitalisationDate,
                ValueAtAcquisition,
                ValueClimateAffected,
                ValueClimateAffectedType,
                CurrentValue,
                Condition,
                Lifespan: value,
              };
              const result = onChange(modelFields);
              value = result?.Lifespan ?? value;
            }
            if (errors.Lifespan?.hasError) {
              runValidationTasks("Lifespan", value);
            }
            setLifespan(value);
          }}
          onBlur={() => runValidationTasks("Lifespan", Lifespan)}
          errorMessage={errors.Lifespan?.errorMessage}
          hasError={errors.Lifespan?.hasError}
          {...getOverrideProps(overrides, "Lifespan")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || assetModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || assetModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
