import React, { useEffect, useState } from "react";
import { BsBanFill, BsExclamationOctagon } from "react-icons/bs";
import { Link } from "react-router-dom";
import { combinedSchemaFor, findUniqueFieldName } from "../amplify/schemaHelpers";
import { models } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";

export function createLinkRenderer({
  idField = "id",
  modelName,
  modelField,
  subtype,
  subtypeField
}: {
  idField?: string;
  modelName?: string;
  modelField?: string;
  subtypeField?: string;
  subtype?: string;
}) {
  const Renderer: React.FC<{
    row: Record<string, any>;
  }> = ({ row }) => {
    const actualModelName = modelName || (modelField && row[modelField]);
    const actualSubtype = subtype || (subtypeField && row[subtypeField]);
    const id = row[idField];
    const [linkText, setLinkText] = useState<JSX.Element>();
    const datastore = useCustomerDataStore();
    const model = models()?.[actualModelName];
    const { displayName } = actualModelName
      ? combinedSchemaFor({
          model: models()[actualModelName],
          subtype: actualSubtype
        })
      : { displayName: "Unknown" };
    useEffect(() => {
      if (id && actualModelName && model) {
        const uniqueNameField = findUniqueFieldName(model);
        if (uniqueNameField) {
          (async () => {
            try {
              const item = await datastore.query(model, id);
              if (item) setLinkText(item[uniqueNameField]);
              else throw new Error("Not found or inaccessible");
            } catch (e) {
              setLinkText(
                <>
                  <BsBanFill />
                  &nbsp;{displayName}:{(e as Error).message}
                </>
              );
            }
          })();
        }
      }
    }, [id, actualModelName, datastore, displayName, model]);
    if (id && actualModelName) {
      return (
        <Link to={`/model/${actualModelName}${actualSubtype ? `-${actualSubtype}` : ""}/${id}`}>{linkText || displayName}</Link>
      );
    } else {
      return (
        <>
          <BsExclamationOctagon />
          {displayName}: No linked item
        </>
      );
    }
  };
  return Renderer;
}
