import { ComboBoxOption, HighlightMatch } from "@aws-amplify/ui-react";
import clsx from "clsx";
import { isEqual, isEmpty, uniq } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { BsPeopleFill, BsPersonFill } from "react-icons/bs";
import { AppUsers } from "../../models";
import { models, uischema } from "../backend";
import useCustomerQuery from "../customer/useCustomerQuery";
import { shortDisplayName } from "../field/AppUserField";
import MultiToken, { MultiTokenProps } from "./MultiToken";
import styles from "./UsersSelector.module.css";

export type UserOrGroupOpt =
  | (ComboBoxOption & { type: "group"; group: string })
  | (ComboBoxOption & { type: "user"; appUserId: string });

export type UsersSelectorProps = Partial<{
  label: ReactNode;
  onChange: MultiTokenProps["onChange"];
  value: MultiTokenProps["value"];
  initialUsers: string[];
  initialGroups: string[];
}>;

function sortByLabel({ label: l1 }: { label: string }, { label: l2 }: { label: string }) {
  return l1.localeCompare(l2);
}
const allUsersGroup = "$AllUsers$";

const UsersSelector: React.FC<UsersSelectorProps> = ({ label, onChange, initialUsers, initialGroups, value }) => {
  const { result, loading: appUsersLoading } = useCustomerQuery(models().AppUsers, (user: any) => user.Enabled.eq(true));
  const allUsers = (result || []) as AppUsers[];
  const allGroups = uischema().enums.AppGroup?.values || [];
  const options = [
    { label: "All Users", group: allUsersGroup, type: "group" },
    ...allGroups.map((group) => ({ label: group.replace(/([^s])$/, "$1s"), group, type: "group" })).sort(sortByLabel),
    ...allUsers.map((appUser) => ({ label: shortDisplayName(appUser), appUserId: appUser.id, type: "user" })).sort(sortByLabel)
  ].map((v, i) => ({ ...v, id: i.toString() })) as UserOrGroupOpt[];
  const optionsById = Object.fromEntries(options.map((option) => [option.id, option]));
  const [selection, setSelection] = useState<string[]>([]);
  function changeSelection(newSelection: string[]) {
    if (!isEqual(new Set(selection), new Set(newSelection))) {
      setSelection(newSelection);
      if (onChange) {
        const expandedSelection = uniq(
          newSelection
            .map((id) => optionsById[id])
            .map((option) =>
              option.type === "group"
                ? allUsers
                    .filter(
                      ({ Groups }) =>
                        option.group === allUsersGroup || (Array.isArray(Groups) && (Groups as string[]).includes(option.group))
                    )
                    .map(({ id }) => id)
                : option.appUserId
            )
            .flat()
            .filter(Boolean)
        );
        onChange(expandedSelection);
      }
    }
  }

  useEffect(
    () => {
      const newSelection = [
        ...((initialUsers || [])
          .filter(Boolean)
          .map((user: string) => {
            const confirmedOption = options.find(({ type, appUserId }) => type === "user" && appUserId === user);
            return confirmedOption ? confirmedOption.id : undefined;
          })
          .filter(Boolean) as string[]),
        ...((initialGroups || [])
          .map((groupName: string) => {
            const confirmedOption = options.find(({ type, group }) => type === "group" && groupName === group);
            return confirmedOption ? confirmedOption.id : undefined;
          })
          .filter(Boolean) as string[])
      ];
      changeSelection(newSelection);
    },
    // need to do this after users/groups loaded but otherwise ignore changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allUsers, allGroups, initialUsers, initialGroups]
  );
  function renderOption({ label, type }: ComboBoxOption, value?: any) {
    return (
      <span className={clsx(styles.user, type)}>
        {type === "group" ? <BsPeopleFill /> : <BsPersonFill />}
        &nbsp;
        {value ? <HighlightMatch query={value}>{label}</HighlightMatch> : label}
      </span>
    );
  }
  return (
    <MultiToken
      label={label}
      placeholder="User or group name"
      isLoading={appUsersLoading || isEmpty(allGroups)}
      options={options}
      value={selection}
      onChange={changeSelection}
      renderOption={renderOption}
      renderBadge={renderOption}
    />
  );
};

export default UsersSelector;
