import { PersistentModelConstructor } from "aws-amplify/dist/esm/datastore";
import { FormAction } from "../form/amplifyForms";
import React, { PropsWithChildren, useContext, useState } from "react";

/**
 *  contextual information to guide what help is displayed
 */
export type HelpContextType = {
  /** The application */
  application?: "ClimateTracker";
  /** The version (.e.g NZCS1) */
  version?: string;
  /** Model entity */
  model?: PersistentModelConstructor<any>;
  /** Subtype of Model entity */
  subtype?: string;
  /** Action being performed */
  action?: FormAction;
  /** view of the action */
  view?: "dialog" | "wizard";
  /** function to change contextual information on the nearest ancestor provider */
  setHelpContext: (context: Partial<HelpContextType>) => void;
};

const HelpContext = React.createContext<HelpContextType>({
  application: "ClimateTracker",
  setHelpContext: () => {
    throw new Error("No root HelpContentProvider");
  }
});

export type HelpProviderProps = Partial<PropsWithChildren<HelpContextType>>;
export const HelpProvider: React.FC<HelpProviderProps> = ({ children, ...rest }) => {
  const parentContext = useContext(HelpContext);
  const [helpContext, setHelpContext] = useState<HelpProviderProps>({ ...parentContext, ...rest });
  return (
    <HelpContext.Provider value={{ ...helpContext, setHelpContext: (extra) => setHelpContext({ ...helpContext, ...extra }) }}>
      {children}
    </HelpContext.Provider>
  );
};

export function useHelpContext(): HelpContextType {
  const context = useContext(HelpContext);
  return context;
}

export function withHelp(Form: React.FC<any>, { ...context }: HelpContextType): React.FC<any> {
  const Component: React.FC<any> = ({ id, ...rest }) => (
    <HelpProvider {...context} action={id ? "Update" : "Create"} view="dialog">
      <Form id={id} {...rest} />
    </HelpProvider>
  );
  Component.displayName = "withHelp";
  return Component;
}
