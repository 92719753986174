import {Col, Row} from "react-bootstrap";

import HelpBox from "../content/HelpBox";
import DataItemTable from "../table/DataItemTable";
import {models} from "../backend";
import {useModal} from "../modal/useModal";

export default function Users() {
  const {AppUsers} = models();
  const modal = useModal({model: AppUsers});
  return (
    <Row>
      <Col>
        <h1>ClimateTracker Users</h1>
        <DataItemTable fullSize select={false} model={AppUsers} modal={modal} />
      </Col>
      <Col>
        <HelpBox>
          <HelpBox.Header>Users Help</HelpBox.Header>
          <HelpBox.Content>
            <p>Allows you to manage users for your Climate Tracker instance</p>
          </HelpBox.Content>
        </HelpBox>
      </Col>
    </Row>
  );
}
