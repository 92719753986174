import { useEffect, useState } from "react";
import { ObserveQueryParameters, useCustomerDataStore } from "./useCustomerDataStore";

export default function useCustomerObserveQuery<T>(
  model: ObserveQueryParameters[0],
  predicateOrId: ObserveQueryParameters[1],
  opts: ObserveQueryParameters[2]
) {
  const [result, setResult] = useState<T[]>([]);
  const datastore = useCustomerDataStore();
  useEffect(() => {
    const subscription = datastore.observeQuery(model, predicateOrId, opts).subscribe(({ items }) => {
      setResult(items as T[]);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [model, predicateOrId, opts, datastore]);

  return result;
}
