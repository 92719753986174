import { findUniqueFieldName } from "../../shared/amplify/schemaHelpers";
import { withRelations } from "../field/withRelations.jsx";
import { asCreateOrUpdate, withCustomActionPosition, withToast, withUniqueNameEnforced } from "./formHelpers.jsx";
import { withControlledSubmit } from "./withControlledSubmit.jsx";
import withCustomer from "./withCustomer";
import { withDecorations } from "./withDecorations";
import withEvidence from "./withEvidence";
import withOnSave from "./withOnSave";
import withStandardReferences from "./withStandardReferences";
import withSubtype from "./withSubtype.jsx";
import withTools from "./withTools";
import { withHelp } from "../help/HelpContext";
import { withValidators } from "../validators/withValidators";

/**
 * The amplify-studio generated forms are very primitive. To extend their  in a declarative way we wrap the exported components
 * with a set of HOCs which add various features to the forms (e.g. tools, subtyping, relations editors)
 * Combine all the above HOCs for given create/update dialog forms
 * @param {*} param0
 * @returns
 */

export function withAll({
  createDialog,
  updateDialog,
  model,
  subtype,
  nameField = findUniqueFieldName(model),
  renderRelatedField,
  actionsRef
}) {
  const hocWrappers = [
    [withHelp],
    [withTools],
    [withEvidence],
    [withStandardReferences],
    [withSubtype, { renderRelatedField }],
    [withToast],
    [withValidators],
    [withRelations, { renderRelatedField }],
    [withCustomer],
    [withControlledSubmit],
    [withCustomActionPosition, { actionsRef }],
    [withDecorations],
    [withUniqueNameEnforced, { nameField }],
    [withOnSave]
  ];
  const wrappedHoc = hocWrappers.reduceRight(
    (inner, [hoc, args]) => hoc(inner, { model, subtype, ...args }),
    asCreateOrUpdate({ createDialog, updateDialog })
  );
  return wrappedHoc;
}
