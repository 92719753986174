import { useEffect, useState } from "react";
import { uischema } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { Alert } from "@aws-amplify/ui-react";
import { Spinner } from "react-bootstrap";
import React from "react";
import { useCustomerAccount } from "../customer/CustomerSessionProvider";

const customerField = "CustomerID";
export default function withCustomer(Form, { model }) {
  const modelSchema = uischema().models[model.name];
  const Component = (function () {
    if (customerField in modelSchema.fields) {
      return ({ id, ...rest }) => {
        const datastore = useCustomerDataStore();
        const { appUser: { CustomerID: customerId } = {} } = useCustomerAccount();
        const [{ error, busy, item }, setItem] = useState({});
        const { displayName } = modelSchema;

        useEffect(() => {
          (async () => {
            if (!!id && customerId && !item && !error && !busy) {
              try {
                setItem({ busy: true });
                const checkItem = await datastore.query(model, id);
                if (checkItem) setItem({ item: checkItem });
                else throw new Error();
              } catch (e) {
                setItem({
                  error: `The requested ${displayName} is not accessible to this customer, you may continue filling in the form to create a new item or select another`
                });
              }
            }
          })();
        }, [item, datastore, error, id, busy, customerId, displayName]);

        return (
          <>
            {busy ? (
              <Spinner />
            ) : (
              <>
                {error && (
                  <Alert variation="error" isDismissible margin="1em">
                    {error}
                  </Alert>
                )}
                <Form id={item && customerId ? id : undefined} {...rest} />
              </>
            )}
          </>
        );
      };
    } else {
      return Form; // no CustomerID on this model
    }
  })();
  Component.displayName = "withCustomer";
  return Component;
}
