import React, { ChangeEventHandler, ReactNode, useEffect, useState } from "react";

import { Button, CloseButton } from "react-bootstrap";
import { BsFillPencilFill } from "react-icons/bs";

import { Autocomplete, AutocompleteProps, Badge, ComboBoxOption, Flex } from "@aws-amplify/ui-react";
import styles from "./MultiToken.module.css";

function EditIconButton(props: any) {
  return (
    <Button variant="" size="sm" {...props}>
      <BsFillPencilFill />
    </Button>
  );
}

export type MultiTokenProps = Omit<AutocompleteProps, "onChange" | "value"> &
  Partial<{
    onChange: (selection: string[]) => void;
    edit: React.FC<any>;
    single: boolean;
    value: string[];
    renderBadge: (option: ComboBoxOption) => ReactNode;
  }>;

const MultiToken: React.FC<MultiTokenProps> = ({
  options,
  onChange,
  edit: Edit = undefined,
  label,
  single = undefined,
  value,
  renderBadge = ({ label }) => label,
  ...rest
}) => {
  const optionById = Object.fromEntries(options.map((option) => [option.id, option]));
  const selection = value ? [value].flat().filter(Boolean) : [];
  const [current, setCurrent] = useState("");
  useEffect(() => {
    setCurrent("");
  }, [value]);
  function handleSelect({ id, label }: ComboBoxOption) {
    setCurrent(label);
    if (selection.includes(id)) {
      handleDeselect(id);
    } else {
      const newSelection = single ? [id] : [...selection, id];
      onChange && onChange(newSelection);
    }
  }
  function handleDeselect(optionId: string) {
    const newSelection = selection.filter((id) => id !== optionId);
    onChange && onChange(newSelection);
  }
  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setCurrent(value || "");
  };
  return (
    <Flex direction={"column"} grow={1}>
      <Autocomplete
        options={options || []}
        labelHidden={!label}
        label={label}
        onSelect={handleSelect}
        onClear={() => setCurrent("")}
        value={current}
        onChange={handleChange}
        {...rest}
      />
      <Flex wrap="wrap">
        {selection &&
          selection.map((id) => {
            return (
              <Badge key={id} className={styles.badge}>
                {Edit && <Edit action={EditIconButton} id={id} />}
                {renderBadge(optionById?.[id])}
                <CloseButton onClick={() => handleDeselect(id)} />
              </Badge>
            );
          })}
      </Flex>
    </Flex>
  );
};

export default MultiToken;
