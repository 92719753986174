import { Hub } from "aws-amplify/utils";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { BsCheckCircleFill } from "react-icons/bs";
import { jsonSchema } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import { ToolProps } from "../form/withTools";
import { schemaFormValidator } from "./jsonSchemaValidators";
import { FormValidationResponse } from "./withValidators";

const ModelValidatorTool: React.FC<ToolProps> = ({ id, model }) => {
  const [result, setResult] = useState<FormValidationResponse | "busy" | "valid" | undefined>();
  const customerDataStore = useCustomerDataStore();
  const refresh = useCallback(() => {
    setResult(undefined);
  }, [setResult]);
  // useDataStoreMutationEvents(refresh);

  useEffect(() => {
    const subscriptionCancel = Hub.listen("datastore", ({ payload: { event, data } }) => {
      if (event === "outboxMutationProcessed") {
        refresh();
      }
    });
    return subscriptionCancel;
  }, [refresh]);

  useEffect(() => {
    (async () => {
      if (id && !result) {
        setResult("busy");
        const item = await customerDataStore.query(model, id);
        if (item) {
          const publishValidator = schemaFormValidator(jsonSchema().ref(`#/definitions/models/${model.name}`), {
            deep: true
          });
          // Check against publishing schema
          const schemaValidationResult = await publishValidator({
            modelFields: item,
            customerDataStore
          });
          // check against required evidence requirements
          //const evidenceValidationResult = await evidenceValidator(item, customerDataStore);

          setResult(schemaValidationResult || "valid");
        }
      }
    })();
  }, [customerDataStore, id, model, result]);
  return (
    <div>
      {result === "busy" ? (
        <Spinner className="m-auto" />
      ) : result === "valid" ? (
        <span>
          <BsCheckCircleFill style={{ color: "green" }} />
          &nbsp;<b>Valid for publication</b>
        </span>
      ) : (
        typeof result === "object" && (
          <>
            <div>The following require resolution before publishing</div>
            {result.hasError && result?.errorMessage}
          </>
        )
      )}
    </div>
  );
};
export default ModelValidatorTool;
