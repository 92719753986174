import { DataStore, DataStoreClass, MutableModel, PersistentModelConstructor, Predicates } from "aws-amplify/datastore";
import { useMemo } from "react";
import { uischema } from "../backend";
import { useCustomerAccount } from "./CustomerSessionProvider";

export type DataStoreInterface = Pick<DataStoreClass, "save" | "delete" | "query" | "observeQuery">;
export type SaveParameters = Parameters<typeof DataStore.save>;
export type ObserveQueryParameters = Parameters<typeof DataStore.observeQuery>;
export type QueryParameters = Parameters<typeof DataStore.query>;

export function useCustomerDataStore() {
  const { appUser: { CustomerID: customerId } = {} } = useCustomerAccount();

  return useMemo<DataStoreInterface>(() => {
    const datastore = new Proxy(
      DataStore,
      customerId
        ? {
            get(obj: Record<string, any>, prop: string) {
              return (
                {
                  query: async (
                    model: QueryParameters[0],
                    predicateOrId: QueryParameters[1],
                    opts?: QueryParameters[2]
                  ): ReturnType<typeof DataStore.query> => {
                    const fields = uischema()?.models?.[model.name]?.fields;
                    if (customerId && model && fields && "CustomerID" in fields) {
                      if (!predicateOrId || typeof predicateOrId === "function" || predicateOrId === Predicates.ALL) {
                        // for query, always wrap with 'and customerId=context customer'
                        return DataStore.query(model, andCustomer(predicateOrId, customerId), opts);
                      } else {
                        const item = await DataStore.query(model, predicateOrId, opts);
                        if ("CustomerID" in item && item.CustomerID === customerId) {
                          return item;
                        } else {
                          return undefined as unknown as ReturnType<typeof DataStore.query>;
                          /*
                            throw new Error(
                              `Customer item ${predicateOrId} not found`
                            );
                            */
                        }
                      }
                    } else return DataStore.query(model, predicateOrId, opts); // non-customer schema default to normal
                  },
                  observeQuery: (
                    model: ObserveQueryParameters[0],
                    predicateOrId: ObserveQueryParameters[1],
                    opts?: ObserveQueryParameters[2]
                  ) => {
                    const fields = uischema()?.models?.[model.name]?.fields;
                    if (
                      (!predicateOrId || typeof predicateOrId === "function" || predicateOrId === Predicates.ALL) &&
                      model &&
                      fields &&
                      "CustomerID" in fields
                    ) {
                      // for query, always wrap with 'and customerId=context customer'
                      return DataStore.observeQuery(model, andCustomer(predicateOrId, customerId), opts);
                    } else return DataStore.observeQuery(model, predicateOrId, opts);
                  },
                  save: async (item: SaveParameters[0], condition: SaveParameters[1]) => {
                    const model = item.constructor as PersistentModelConstructor<MutableModel<any>>; // schema model
                    const fields = uischema()?.models?.[model.name]?.fields;
                    if (model && fields && "CustomerID" in fields) {
                      // Object has CustomerID field, force to id of this context
                      // To do this we have to do a double save as we can't clone the immutable object
                      const initial = await DataStore.save(item, condition);
                      const clone = model.copyOf(initial, (update) => {
                        update.CustomerID = customerId;
                      });

                      return DataStore.save(clone, condition);
                    } else return DataStore.save(item, condition);
                  }
                }[prop] || obj[prop]
              );
            }
          }
        : contextLessDatastore
    );
    return datastore as DataStoreInterface;
  }, [customerId]);
}
export function andCustomer(predicate: QueryParameters[1], customerId: string): NonNullable<QueryParameters[1]> {
  return !predicate || predicate === Predicates.ALL
    ? // @ts-ignore
      (c) => c.CustomerID.eq(customerId)
    : // @ts-ignore
      (c) => c.and((c1) => [c1.CustomerID.eq(customerId), predicate(c1)]);
}

/**
 *
 * Return this when there is no customer selected
 */
export const contextLessDatastore = {
  get(obj: Record<string, any>, prop: string) {
    return (
      {
        query: (_model: any, idOrPred: any) => Promise.resolve(typeof idOrPred === "function" ? [] : undefined),
        observeQuery: () => ({ subscribe: () => ({ unsubscribe: () => {} }) }),
        save: () => {
          throw new Error("Attempt to save with no customer context");
        },
        delete: () => {
          throw new Error("Attempt to delete with no customer context");
        }
      }[prop] || obj[prop]
    );
  }
};
