import {capitalize} from "lodash";

export function fullDisplayName({email, family_name, given_name} = {}) {
  if (family_name && given_name)
    return `${capitalize(given_name)} ${capitalize(family_name)}`;
  else return email || "";
}

export function shortDisplayName({email, given_name} = {}) {
  if (given_name) return capitalize(given_name);
  else return email || "";
}
