import { Button, TextAreaField } from "@aws-amplify/ui-react";
import { post } from "aws-amplify/api";
import { Predicates, SortDirection } from "aws-amplify/datastore";
import clsx from "clsx";
import { isArray, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Card, Spinner, Stack } from "react-bootstrap";
import { BsReplyFill } from "react-icons/bs";
import stc from "string-to-color";
import { AppUsers, Comment, LazyAppUsers, LazyComment } from "../../models";
import DeleteItemButton from "../../shared/action/DeleteItemButton";
import NewItemButton from "../../shared/action/NewItemButton";
import { combinedSchemaFor } from "../amplify/schemaHelpers";
import { models } from "../backend";
import { useCustomerAccount, useUser } from "../customer/CustomerSessionProvider";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
import useCustomerObserveQuery from "../customer/useCustomerObserveQuery";
import useCustomerQuery from "../customer/useCustomerQuery";
import { shortDisplayName } from "../field/AppUserField";
import { ToolProps } from "../form/withTools";
import UsersSelector from "../select/UsersSelector";
import styles from "./Comments.module.css";

export declare interface CommentsProps extends ToolProps {}

const commentDateFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: "full",
  timeStyle: "short"
});

declare type CommentLocal = LazyComment & {
  id: string;
  userDisplayName?: string;
  Comment?: string | null | undefined;
  ownerUser?: LazyAppUsers;
};

function CommentCard({
  comment,
  comment: { id, Comment: commentText, userDisplayName, updatedAt },
  onReplyClick,
  selfAuthored
}: {
  comment: CommentLocal;
  selfAuthored: boolean;
  onReplyClick: (comment: CommentLocal) => void;
}): JSX.Element {
  const { Comment } = models();

  return (
    <Card role="comment" className={clsx(styles.comment, selfAuthored ? styles.selfAuthoredComment : styles.otherAuthoredComment)}>
      <Card.Header className="d-flex justify-content-between gap-2">
        <div>
          <div className={styles.user}>{userDisplayName}</div>
          {updatedAt && <div className={styles.date}>{commentDateFormat.format(new Date(updatedAt))}</div>}
        </div>
        <DeleteItemButton
          className="ms-auto"
          id={id}
          model={Comment}
          size="small"
          variant="plain"
          label={""}
          forbiddenStyle="hidden"
        />
        <span>
          <Button variation="primary" onClick={() => onReplyClick(comment)} size="small">
            <BsReplyFill />
          </Button>
        </span>
      </Card.Header>
      <Card.Body
        style={
          !selfAuthored
            ? {
                backgroundColor: stc(userDisplayName) + "20"
              }
            : undefined
        }
      >
        <span>{commentText}</span>
      </Card.Body>
    </Card>
  );
}
const initialItems = 5;

const commentQueryOpts = { sort: (s: any) => s.updatedAt(SortDirection.DESCENDING) }; // at top level to avoid function recalc in hook
export default function Comments({ id, model, subtype }: CommentsProps): JSX.Element {
  const [notifyUsers, setNotifyUsers] = useState<string[]>([]);
  const [itemLimit, setItemLimit] = useState<number>(initialItems);
  const [newComment, setNewComment] = useState<string>();
  const [comments, setComments] = useState<CommentLocal[]>([]);
  const datastore = useCustomerDataStore();
  const { appUser: thisAppUser } = useCustomerAccount();
  const { username: thisUserName } = useUser();
  const { Comment } = models();
  const itemPredicate = useCallback((p: any) => p.TargetEntityID.eq(id), [id]);
  const existingComments = useCustomerObserveQuery<Comment>(Comment, itemPredicate, commentQueryOpts);
  const { result, error: appUsersError, loading: appUsersLoading } = useCustomerQuery(models().AppUsers, Predicates.ALL);
  const appUsers = result as AppUsers[] | undefined;
  const { displayName } = combinedSchemaFor({ model, subtype });
  const [initialUsers, setInitialUsers] = useState<string[]>();
  async function addComment() {
    const targetUrl = `${document.location.origin}/model/${model.name}/${id}?preferredTool=comments`;
    const notification = {
      subject: "[ClimateTracker] New Comment Added",
      body: {
        text: `${shortDisplayName(
          thisAppUser
        )}  has commented on ${displayName}. Please browse to ${targetUrl} to view the comment..`,
        html: `${shortDisplayName(
          thisAppUser
        )} has commented on <a href="${targetUrl}">${displayName}</a>. Please <a href="${targetUrl}">click<a> to view the comment.`
      }
    };

    await Promise.all([
      ...notifyUsers.map(
        async (userId) =>
          await post({
            apiName: "support",
            path: `/user/${userId}`,
            options: {
              body: notification
            }
          }).response
      ),
      await datastore.save(
        new Comment({
          Comment: newComment,
          TargetEntityID: id,
          TargetEntityModel: model.name
        })
      )
    ]);
    setNewComment("");
  }
  useEffect(() => {
    if (!appUsersLoading && !appUsersError && appUsers && isArray(existingComments)) {
      const appUsersByUserName = appUsers && Object.fromEntries(appUsers.map((appUser) => [appUser.Username, appUser]));
      const comments = existingComments
        .slice(0, itemLimit)
        .map((item) => {
          const ownerUser =
            appUsersByUserName[("owner" in item && typeof item.owner === "string" ? item.owner : "").replace(/:.*/, "")];

          return {
            ...item,
            userDisplayName: ownerUser && shortDisplayName(ownerUser),
            ownerUser
          };
        })
        .reverse();

      setComments(comments);
    }
  }, [appUsersLoading, appUsersError, appUsers, existingComments, itemLimit]);

  return (
    <Stack gap={3}>
      {appUsersLoading ? (
        <Spinner />
      ) : (
        <Stack gap={3} className={styles.wrapper}>
          {comments &&
            comments.map((comment, i) => (
              <CommentCard
                key={i}
                comment={comment}
                selfAuthored={comment?.ownerUser?.Username === thisUserName}
                onReplyClick={({ ownerUser }) => ownerUser && setInitialUsers([ownerUser.id])}
              />
            ))}
        </Stack>
      )}
      {comments && comments.length === itemLimit && (
        <Button onClick={() => setItemLimit(itemLimit + initialItems)}>Load older comments...</Button>
      )}
      {isEmpty(comments) && <div>No comments found.</div>}
      <TextAreaField label="New Comment:" value={newComment} onChange={({ target: { value } }) => setNewComment(value)} />
      <UsersSelector
        label="Send Email Notification To:"
        onChange={setNotifyUsers}
        value={notifyUsers}
        initialUsers={initialUsers}
      />
      <NewItemButton
        disabled={!newComment}
        onClick={async () => addComment()}
        label="Add"
        variant="plain"
        model={Comment}
        className="align-self-end"
      />
    </Stack>
  );
}
