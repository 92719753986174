import {AccountSettings} from "@aws-amplify/ui-react";
import {Container, Row} from "react-bootstrap";
import {useToast} from "../util/Toast";
import {useUser} from "../customer/CustomerSessionProvider";
import {fullDisplayName} from "../amplify/cognitoHelpers";

export default function ChangePassword() {
  const showToast = useToast();
  const user = useUser();
  function handleSuccess() {
    showToast({
      content: `Password changed for ${fullDisplayName(user)}`
    });
  }

  return (
    <Container fluid>
      <h1>Change Password</h1>
      <Row className="justify-content-center">
        <AccountSettings.ChangePassword onSuccess={handleSuccess} />
      </Row>
    </Container>
  );
}
