import { Autocomplete, ComboBoxOption, HighlightMatch, TextField } from "@aws-amplify/ui-react";
import { memoize, startCase, uniq } from "lodash";
import { useMemo, useState } from "react";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { EnumField } from "../field/EnumField";
import { useSpreadsheet } from "../file/useSpreadsheet";
import { utils } from "../file/xlsxWrapper";

import { formComponents, models } from "../backend";
import { RecordCard } from "./RecordCard";
import Wizard from "./Wizard";

const { REACT_APP_REFERENCE_SITE } = process.env;
const url = new URL(`${REACT_APP_REFERENCE_SITE}/ifrss2/Emission-factors-flat-file-Measuring-emissions-guidance.xlsx`);

const unitMappings: Record<string, RegExp> = {
  Employees: /employee per day/i,
  Kg: /Kg/i,
  Kw: /Kw/i,
  Kwh: /Kwh/i,
  Litres: /l(itre)?/i,
  MetresCubed: /m3( of .*)?/i,
  MillionDollarsRevenue: /\$/i,
  Nights: /(room per night|nights)/i,
  PassengerPerKm: /pkm/i,
  PerCapita: /per capita/i,
  Percentage: /%/i,
  Quantity: /Quantity/i, // FIXME
  Tonnes: /tonne( of .*)?/i,
  TonnesPerKm: /tkm/i
  // : /GJ/i gigajoules // FIXME
  // : /ha/i hectares   // FIXME
  // : /hours/i         // FIXME
};
const mapUnit = memoize((unit) => unit && Object.entries(unitMappings).find(([_unit, regexp]) => regexp.test(unit))?.[0]);

const co2EmissionFormat = new Intl.NumberFormat(undefined, { maximumFractionDigits: 3 });
const emissionConversionFactorFormat = new Intl.NumberFormat(undefined, { maximumFractionDigits: 5 });

type PreselectedEntry = Partial<Record<string, any>>;
type RawEmissionFactorRow = {
  ["Column Text"]: string;
  GHG: string;
  ["GHG Emission factor"]: number;
  Level: string;
  Level_1: string;
  Level_2: string;
  Scope: string;
  Unit: string;
};
type EmissionFactorRow = Omit<RawEmissionFactorRow, "Column Text" | "GHG Emission factor" | "Unit" | "Scope"> & {
  emissionScope: string;
  emissionUnit: string;
  emissionSource: string;
  emissionFactor: number;
};

const EmissionAccountEntryWizard: React.FC<any> = ({ ...rest }) => {
  const [optionValue, setOptionValue] = useState("");
  const [emissionEntry, setEmissionEntry] = useState<PreselectedEntry | undefined>();
  const { error, loading, workbook: emissionFactors } = useSpreadsheet(url);
  const { EmissionAccountEntry } = models();
  const { EmissionAccountEntryCreateForm } = formComponents();
  const { options, optionsBySource } = useMemo(() => {
    if (emissionFactors) {
      const rows = utils
        .sheet_to_json<RawEmissionFactorRow>(emissionFactors.Sheets[emissionFactors.SheetNames[0]])
        .map(
          ({
            "Column Text": emissionSource,
            GHG,
            "GHG Emission factor": emissionFactor,
            Scope,
            Unit,
            Level,
            Level_1,
            Level_2
          }) => ({
            GHG,
            emissionScope: Scope && /1|2|3/.test(Scope) ? `Scope_${Scope}` : undefined,
            emissionUnit: mapUnit(Unit),
            Level,
            Level_1,
            Level_2,
            emissionSource,
            emissionFactor
          })
        );
      const optionsBySource = rows.reduce<
        Record<
          EmissionFactorRow["emissionSource"],
          Omit<EmissionFactorRow, "GHG" | "emissionFactor"> & {
            emissionFactors: Record<RawEmissionFactorRow["GHG"], EmissionFactorRow["emissionFactor"]>;
          }
        >
      >((acc, rec) => {
        const { emissionSource, GHG, emissionFactor, emissionScope, emissionUnit, Level, Level_1, Level_2 } = rec;
        const key = [Level, Level_1, Level_2, emissionSource, emissionUnit].join("-");
        const prev = acc[key];
        return {
          ...acc,
          [key]: {
            ...(prev || { emissionScope, emissionUnit, Level, Level_1, Level_2, emissionSource }),
            emissionFactors: {
              ...prev?.emissionFactors,
              [GHG]: emissionFactor
            }
          }
        };
      }, {});
      const options = Object.entries(optionsBySource).map(
        ([k, { emissionScope, emissionUnit, Level, Level_1, Level_2, emissionSource }]) => ({
          id: k,
          label: uniq([emissionScope, emissionUnit, Level, Level_1, Level_2, emissionSource].filter(Boolean)).join(":")
        })
      );
      return { options, optionsBySource };
    } else return {};
  }, [emissionFactors]);
  function handleSelect(option?: string) {
    const selected = option && optionsBySource && optionsBySource[option];
    if (selected) {
      const { emissionScope, Level, Level_1, Level_2, emissionSource, emissionUnit, emissionFactors } = selected;

      const entry = {
        EmissionScope: emissionScope,
        //readonly EmissionCategory?: EmissionCategoryEnum | keyof typeof EmissionCategoryEnum | null;
        EmissionGroup: [emissionSource, Level_2, Level_1, Level].filter(Boolean).join(":"),
        //readonly EmissionDescription?: string | null;
        //readonly DataSource?: string | null;
        //readonly EmissionDate?: string | null;
        EmissionUnit: emissionUnit,
        //readonly EmissionUsageValue?: number | null;
        EmissionConversionFactor: emissionFactors["CO2e"]
        //readonly GrossEmission?: number | null;
        //readonly EmissionDateRangeStart?: string | null;
        //readonly EmissionDateRangeEnd?: string | null;
      };
      setOptionValue(entry.EmissionGroup);
      handleChange(entry);
    } else {
      setOptionValue("");
      handleChange(undefined);
    }
  }
  function handleChange(emissionEntry?: PreselectedEntry) {
    setEmissionEntry(emissionEntry);
  }
  const renderOption = (option: ComboBoxOption, value: string) => {
    const actualOption = optionsBySource && optionsBySource[option?.id];
    if (actualOption) {
      const { emissionSource, Level_2, Level_1, Level, emissionUnit, emissionScope } = actualOption;
      const labels = uniq(
        [
          Level_2,
          Level_1,
          Level,
          emissionUnit && `Measured in ${startCase(emissionUnit)}`,
          emissionScope && startCase(emissionScope)
        ].filter(Boolean)
      );
      return (
        <Card>
          {emissionSource && (
            <CardHeader>
              <div>
                <HighlightMatch query={value}>{emissionSource}</HighlightMatch>
              </div>
            </CardHeader>
          )}
          <CardBody>
            <ul>
              {labels.map((label, i) => (
                <li key={i}>
                  <HighlightMatch key={i} query={value}>
                    {label}
                  </HighlightMatch>
                </li>
              ))}
            </ul>
          </CardBody>
        </Card>
      );
    }
  };
  const finished =
    !!emissionEntry && !!emissionEntry.GrossEmission && isFinite(emissionEntry.GrossEmission) && emissionEntry.GrossEmission > 0;
  return (
    <Wizard
      isLoading={loading}
      isFinished={finished}
      error={error}
      model={EmissionAccountEntry}
      CreateForm={EmissionAccountEntryCreateForm}
      preselection={emissionEntry}
      {...rest}
    >
      <>
        <Autocomplete
          isLoading={loading}
          size="large"
          labelHidden={false}
          label="Choose a template for the emissions you want to enter:"
          placeholder="Type keywords"
          renderOption={renderOption}
          value={optionValue}
          onChange={(event) => setOptionValue(event.target.value)}
          onClear={() => {
            handleSelect();
          }}
          onSelect={(option) => {
            handleSelect(option.id);
          }}
          options={options || []}
        />
        {emissionEntry && (
          <>
            <RecordCard
              header="EmissionGroup"
              record={{
                EmissionGroup: emissionEntry.EmissionGroup,
                EmissionScope: <EnumField current={emissionEntry.EmissionScope} enumName="EmissionScopeEnum" />,
                EmissionUnit: <EnumField current={emissionEntry.EmissionUnit} enumName="EmissionUnitEnum" />,
                ConversionFactor:
                  emissionEntry.EmissionConversionFactor &&
                  emissionConversionFactorFormat.format(emissionEntry.EmissionConversionFactor)
              }}
              selected
            />
            <TextField
              label={`Enter Usage Value (${emissionEntry.EmissionUnit || "units unknown"})`}
              id="emissionUsageValue"
              type="number"
              min={0}
              value={emissionEntry.EmissionUsageValue || ""}
              onChange={({ target: { value } }) => {
                const usageValue = parseFloat(value);
                const { EmissionConversionFactor } = emissionEntry;
                handleChange({
                  ...emissionEntry,
                  EmissionUsageValue: usageValue,
                  GrossEmission:
                    EmissionConversionFactor && isFinite(EmissionConversionFactor)
                      ? (usageValue * EmissionConversionFactor) / 1000.0 // note scale down to convert to tonnes
                      : undefined
                });
              }}
            />
            {finished && (
              <RecordCard
                header="Title"
                record={{
                  Title: "Emissions",
                  GrossEmissions: `${co2EmissionFormat.format(emissionEntry.GrossEmission)} Tonnes (equivalent)`
                }}
                selected
              />
            )}
          </>
        )}
      </>
    </Wizard>
  );
};

export default EmissionAccountEntryWizard;
