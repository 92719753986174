import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  AuthModeStrategyType,
  DataStore,
  ModelField,
  NonModelTypeConstructor,
  PersistentModelConstructor,
  Schema,
  SchemaModel,
  syncExpression
} from "aws-amplify/datastore";
import { ObjectSchema } from "fluent-json-schema";
import { difference, isEmpty } from "lodash";
import { Settings } from "luxon";
import React, { ReactNode } from "react";
import { FormAction } from "./form/amplifyForms";
import { logger } from "./logger/LoggerProvider";
import createJsonSchema, { JsonSchemaFieldMapper, JsonSchemaModelMapper } from "./validators/createJsonSchema";
import { ValidationSchema } from "./validators/jsonSchemaValidators";
import { FieldValidator, FormValidator, Validators } from "./validators/withValidators";

/**
 * Options common to various features (e.g. Model entities and fields)
 */
export type UIDecorations = {
  description?: string;
  displayName?: string;
  standardReference?: string;
  helpContent?: React.FC<any> | ReactNode;
  /**
   * Hide from search - disregard fields/entities in search
   */
  hideFromSearch?: boolean;

  validator?: ValidationSchema;
};

/**
 * Model Field-specific options
 */
export type UISchemaField = UIDecorations & {
  component?: any; // TODO type , component type use to display (always using DataTable now, default)
  /**
   * override default show
   */
  hideInTable?: boolean;
  /**
   * override default hide of ID fields etc
   */
  showInTable?: boolean;

  /**
   * Hide field in list of relations on forms
   */
  hideInRelations?: boolean;

  /**
   * Make field required (use this in preference to making required in amplify studio as the studio version fails with relations)
   */
  isRequired?: boolean;
  /**
   * Make form insist on a unique vaule *for the current customer* for this field
   */
  isUnique?: boolean;

  /**
   *  When cloning an item, uses this value (set to null/undefined to ignore original value)
   */
  valueOnClone?: any | null | undefined;
  /**
   * Relative numerical order to display this field relative to others in table headings
   */
  order?: number;
  /**
   * Fix amplify schema - specify target model when this is the one end of one to many
   */
  targetModel?: string;
  /**
   * If this field is an array, the maximum length (i.e. how many items selectable in ui)
   */
  maxItems?: number;
  /**
   *  How to display different subtypes of the target that this relation points to, omit keys for subtypes not needed
   */
  targetSubtypes?: Record<string, UIDecorations> | string[]; //
  /**
   * Functional component to use to display this field
   */
  customRenderer?: React.FC<{ value: any; row: Record<string, any> }>;

  validator?: FieldValidator | ValidationSchema;

  /**
   * Custom schema to map the default json schema for this field to
   */
  publishSchema?: JsonSchemaFieldMapper;
};

/**
 * Panels that may decorate entity forms
 */
export const formDecorators = ["Alert", "Evidence", "Form", "Tools", "Subtypes", "Relations"] as const;

export declare type FormDecorator = (typeof formDecorators)[number];

/**
 * Areas on entity forms
 */
export const layoutAreas = ["Header", "Primary", "Secondary", "Tertiary", "Sidebar", "Footer"] as const;
/**
 * HOC wrapper for form
 */
export type FormWrapperOptions = {
  [key: string]: any;
  model: PersistentModelConstructor<any>;
  subtype?: string;
  action: FormAction;
  propertyMap?: Record<string, string>;
};
export type FormWrapper = (Form: React.FC<any>, options: FormWrapperOptions) => React.FC<any>;
export type FormWrapperOpts = { wrapper: FormWrapper; extraOptions: Record<string, any> } | FormWrapper;
export declare type LayoutArea = (typeof layoutAreas)[number];

/**
 * Model entity-specific options
 */
export type UIEntity = UIDecorations & {
  /**
   *  never show clone option
   */
  noClone?: boolean;
  /**
   *  never show delete option
   */
  noDelete?: boolean;
  /**
   * never show download option
   **/
  noDownload?: boolean;
  /**
   * never show upload option
   */
  noUpload?: boolean;
  /**
   *  True to not show commenting tool on form
   */
  hideComments?: boolean;
  /**
   *  True to not show validation tool on form
   */
  hideValidationTool?: boolean;
  /**
   * set of allowed fields as a regexp matching relation field key - "fieldName:subtype". Default all
   */
  includeRelations?: RegExp; //
  /**
   * set of allowed fields as a regexp matching field "fieldName". Default all
   */
  includeColumns?: RegExp; //
  /**
   * Map of form decorator components to layout areas when displying this item
   */
  layout?: Partial<Record<FormDecorator, LayoutArea>>;
  formOptions?: Partial<
    Record<
      FormAction,
      {
        /**
         * Override the default AmplifyStudio forms <entityname><Create|Update>Form with custom component(s)
         */
        formOverride?: React.FC<any>;

        /**
         * Form wrapper(s) applied in sequence (last wraps first)
         */
        formWrappers?: FormWrapperOpts | FormWrapperOpts[];
      }
    >
  >;
  /**
   * True to show evidence list on form
   */
  showEvidence?: boolean;

  validator?: ValidationSchema | FormValidator;
  /**
   * Custom schema to map the default json schema for this field to
   */
  publishSchema?: JsonSchemaModelMapper;
};
export type UISchemaModel = UIEntity & {
  fields?: Record<string, UISchemaField>;
  discriminatorField?: string; // If set to a fieldname, entity will only have one type, which will be stored in this field and form will show accordingly
  displayComposed?: boolean; // True to show forms combined with all subtype forms as (for example) tabs, if discriminatorField is set, user must choose which subtype
  subtypes?: Record<
    string,
    UIEntity & {
      subtypeOrder?: number; // ordering within lists of subtypes for this entity
      fields?: Record<string, UISchemaField>; // overrides for field display
    }
  >; // values to override when being dusplayed as subtype view
} & UIDecorations;

export type UISchemaEnum = {
  displayColor?: string;
};

export type UISchema = {
  wrappedSchema: Schema;
  models: Record<string, UISchemaModel>;
  enums: Record<
    string,
    UISchemaEnum & Partial<{ name: string; values: string[] }> //SchemaEnum not exported by amplify stupidly
  >;
};

export type UISchemaAggregate = Schema & UISchema;

export type Forms = {
  entities: Array<{
    name: string;
    sectionalElements: Record<string, { type: string; text?: string }>;
  }>;
};

/**
 * Schema for amplify's generated configuration file
 */
export interface AmplifyConfiguration {
  aws_project_region: string;
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_cloud_logic_custom: AwsCloudLogicCustom[];
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: Oauth;
  aws_cognito_username_attributes: string[];
  aws_cognito_social_providers: any[];
  aws_cognito_signup_attributes: any[];
  aws_cognito_mfa_configuration: string;
  aws_cognito_mfa_types: any[];
  aws_cognito_password_protection_settings: AwsCognitoPasswordProtectionSettings;
  aws_cognito_verification_mechanisms: string[];
}

export interface AwsCloudLogicCustom {
  name: string;
  endpoint: string;
  region: string;
}

export interface AwsCognitoPasswordProtectionSettings {
  passwordPolicyMinLength: number;
  passwordPolicyCharacters: string[];
}

export interface Oauth {}

/**
 * Global backend objects
 */

export type ExtendedField = UISchemaField & ModelField;
export type ExtendedModel = UISchemaModel & SchemaModel & { fields: Record<string, ExtendedField> };

const backend: {
  customerId?: string;
  uischema?: UISchemaAggregate;
  jsonSchema?: ObjectSchema;
  validators?: Record<string, Validators>;
  models?: Record<string, PersistentModelConstructor<any>>;
  enums?: Record<string, NonModelTypeConstructor<unknown>>;
  forms?: Forms;
  formComponents?: Record<string, React.FC<any>>;
  amplifyConfig?: AmplifyConfiguration;
} = {};

export function validateUiSchema(uischema: UISchemaAggregate) {
  if (uischema && uischema.wrappedSchema) {
    const amplifySchema = uischema.wrappedSchema;
    // Check ui schema overlay for consistency with generated schema
    const redundantModels = difference(Object.keys(uischema.models), Object.keys(amplifySchema.models));
    if (!isEmpty(redundantModels)) console.error(`Please remove redundant models ${redundantModels.join()} from uischema`);
    const redundantFields = Object.keys(uischema.models).map((name) => {
      const uimodel = uischema.models?.[name];
      const amplifyModel = amplifySchema.models?.[name];
      const redundantFields = difference(Object.keys(uimodel?.fields || {}) || [], Object.keys(amplifyModel?.fields) || []);
      if (!isEmpty(redundantFields)) {
        console.error(`Please remove redundant fields for model ${name}: ${redundantFields.join()} from uischema`);
        return true;
      }
      // Check subtypes for redundant fields
      if (uimodel.subtypes) {
        const redundantSubtypeFields = Object.entries(uimodel.subtypes).map(([subtype, { fields }]) => {
          const redundantFields = difference(Object.keys(fields || {}) || [], Object.keys(amplifyModel?.fields) || []);
          if (!isEmpty(redundantFields)) {
            console.error(
              `Please remove redundant fields for model ${name} subtype ${subtype}: ${redundantFields.join()} from uischema`
            );
            return true;
          }
          return false;
        });
        if (redundantSubtypeFields.find(Boolean)) return true;
      }
      return false;
    });
    const redundantEnums = difference(Object.keys(uischema.enums), Object.keys(amplifySchema.enums));
    if (!isEmpty(redundantEnums)) throw new Error(`Please remove redundant enums ${redundantEnums.join()} from uischema`);
    if (!isEmpty(redundantModels) || redundantFields.find(Boolean)) throw new Error("Uischema is inconsistent");
  }
}

/**
 * Configure datastore to only sync current customer's data, if known
 */
export function configure(config: typeof backend): void {
  Object.assign(backend, config);

  const { customerId, uischema, models, amplifyConfig } = config;

  /**
   * Configure main amplify library
   */
  Amplify.configure(
    {
      ...amplifyConfig
    },
    {
      API: {
        REST: {
          headers: async () => {
            const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
            return {
              Authorization: `Bearer ${authToken}`,
              "Cache-Control": "no-cache"
            };
          }
        }
      }
    }
  );
  DataStore.configure({
    // need this to get public entities! see https://github.com/aws-amplify/amplify-js/issues/9369
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    syncExpressions:
      !!customerId && !!uischema && !!models
        ? Object.values(models || {})
            .filter(({ name }) => name !== "AppUsers" && "CustomerID" in (uischema?.models?.[name]?.fields || {}))
            .map((model) =>
              syncExpression(model, () => (item) => {
                return item.CustomerID.eq(customerId);
              })
            )
        : undefined
  });

  Settings.throwOnInvalid = true;

  logger.debug("Backend initialised");
}

export function uischema() {
  if (!backend.uischema) throw new Error("Ui schema not configured");
  return backend.uischema;
}

export function jsonSchema() {
  if (!backend.jsonSchema) {
    backend.jsonSchema = createJsonSchema();
  }
  return backend.jsonSchema;
}

export function forms() {
  if (!backend.forms) throw new Error("forms not configured");
  return backend.forms;
}
export function models() {
  if (!backend.models) throw new Error("models not configured");
  return backend.models;
}
export function validators() {
  if (!backend.validators) throw new Error("validators not configured");
  return backend.validators;
}
export function formComponents() {
  if (!backend.formComponents) throw new Error("form components not configured");
  return backend.formComponents;
}
export function amplifyConfig() {
  if (!backend.amplifyConfig) throw new Error("amplifyConfig not configured");
  return backend.amplifyConfig;
}
