/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { DisclosurePeriod } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function DisclosurePeriodCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    DisclosurePeriodName: "",
    Notes: "",
    StartDate: "",
    EndDate: "",
    DisclosurePeriodYear: "",
    ReportingPeriodChange: "",
  };
  const [DisclosurePeriodName, setDisclosurePeriodName] = React.useState(
    initialValues.DisclosurePeriodName
  );
  const [Notes, setNotes] = React.useState(initialValues.Notes);
  const [StartDate, setStartDate] = React.useState(initialValues.StartDate);
  const [EndDate, setEndDate] = React.useState(initialValues.EndDate);
  const [DisclosurePeriodYear, setDisclosurePeriodYear] = React.useState(
    initialValues.DisclosurePeriodYear
  );
  const [ReportingPeriodChange, setReportingPeriodChange] = React.useState(
    initialValues.ReportingPeriodChange
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDisclosurePeriodName(initialValues.DisclosurePeriodName);
    setNotes(initialValues.Notes);
    setStartDate(initialValues.StartDate);
    setEndDate(initialValues.EndDate);
    setDisclosurePeriodYear(initialValues.DisclosurePeriodYear);
    setReportingPeriodChange(initialValues.ReportingPeriodChange);
    setErrors({});
  };
  const validations = {
    DisclosurePeriodName: [],
    Notes: [],
    StartDate: [],
    EndDate: [],
    DisclosurePeriodYear: [],
    ReportingPeriodChange: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DisclosurePeriodName,
          Notes,
          StartDate,
          EndDate,
          DisclosurePeriodYear,
          ReportingPeriodChange,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new DisclosurePeriod(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "DisclosurePeriodCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Reporting Period"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 Appendix B1, NZCS3 Paragraphs 24-26."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Heading>
      <Text
        children="Create a record describing the reporting period applicable to this climate-related disclosure."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Disclosure period name"
        isRequired={false}
        isReadOnly={false}
        value={DisclosurePeriodName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DisclosurePeriodName: value,
              Notes,
              StartDate,
              EndDate,
              DisclosurePeriodYear,
              ReportingPeriodChange,
            };
            const result = onChange(modelFields);
            value = result?.DisclosurePeriodName ?? value;
          }
          if (errors.DisclosurePeriodName?.hasError) {
            runValidationTasks("DisclosurePeriodName", value);
          }
          setDisclosurePeriodName(value);
        }}
        onBlur={() =>
          runValidationTasks("DisclosurePeriodName", DisclosurePeriodName)
        }
        errorMessage={errors.DisclosurePeriodName?.errorMessage}
        hasError={errors.DisclosurePeriodName?.hasError}
        {...getOverrideProps(overrides, "DisclosurePeriodName")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DisclosurePeriodName,
              Notes: value,
              StartDate,
              EndDate,
              DisclosurePeriodYear,
              ReportingPeriodChange,
            };
            const result = onChange(modelFields);
            value = result?.Notes ?? value;
          }
          if (errors.Notes?.hasError) {
            runValidationTasks("Notes", value);
          }
          setNotes(value);
        }}
        onBlur={() => runValidationTasks("Notes", Notes)}
        errorMessage={errors.Notes?.errorMessage}
        hasError={errors.Notes?.hasError}
        {...getOverrideProps(overrides, "Notes")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={StartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DisclosurePeriodName,
                Notes,
                StartDate: value,
                EndDate,
                DisclosurePeriodYear,
                ReportingPeriodChange,
              };
              const result = onChange(modelFields);
              value = result?.StartDate ?? value;
            }
            if (errors.StartDate?.hasError) {
              runValidationTasks("StartDate", value);
            }
            setStartDate(value);
          }}
          onBlur={() => runValidationTasks("StartDate", StartDate)}
          errorMessage={errors.StartDate?.errorMessage}
          hasError={errors.StartDate?.hasError}
          {...getOverrideProps(overrides, "StartDate")}
        ></TextField>
        <TextField
          label="End date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={EndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DisclosurePeriodName,
                Notes,
                StartDate,
                EndDate: value,
                DisclosurePeriodYear,
                ReportingPeriodChange,
              };
              const result = onChange(modelFields);
              value = result?.EndDate ?? value;
            }
            if (errors.EndDate?.hasError) {
              runValidationTasks("EndDate", value);
            }
            setEndDate(value);
          }}
          onBlur={() => runValidationTasks("EndDate", EndDate)}
          errorMessage={errors.EndDate?.errorMessage}
          hasError={errors.EndDate?.hasError}
          {...getOverrideProps(overrides, "EndDate")}
        ></TextField>
      </Grid>
      <TextField
        label="Disclosure period year"
        isRequired={false}
        isReadOnly={false}
        value={DisclosurePeriodYear}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DisclosurePeriodName,
              Notes,
              StartDate,
              EndDate,
              DisclosurePeriodYear: value,
              ReportingPeriodChange,
            };
            const result = onChange(modelFields);
            value = result?.DisclosurePeriodYear ?? value;
          }
          if (errors.DisclosurePeriodYear?.hasError) {
            runValidationTasks("DisclosurePeriodYear", value);
          }
          setDisclosurePeriodYear(value);
        }}
        onBlur={() =>
          runValidationTasks("DisclosurePeriodYear", DisclosurePeriodYear)
        }
        errorMessage={errors.DisclosurePeriodYear?.errorMessage}
        hasError={errors.DisclosurePeriodYear?.hasError}
        {...getOverrideProps(overrides, "DisclosurePeriodYear")}
      ></TextField>
      <Text
        children="If your reporting period has changed, enter reasons for this change and any relevant information that occured between changed periods in the field below."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <TextAreaField
        label="Reporting period change"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DisclosurePeriodName,
              Notes,
              StartDate,
              EndDate,
              DisclosurePeriodYear,
              ReportingPeriodChange: value,
            };
            const result = onChange(modelFields);
            value = result?.ReportingPeriodChange ?? value;
          }
          if (errors.ReportingPeriodChange?.hasError) {
            runValidationTasks("ReportingPeriodChange", value);
          }
          setReportingPeriodChange(value);
        }}
        onBlur={() =>
          runValidationTasks("ReportingPeriodChange", ReportingPeriodChange)
        }
        errorMessage={errors.ReportingPeriodChange?.errorMessage}
        hasError={errors.ReportingPeriodChange?.hasError}
        {...getOverrideProps(overrides, "ReportingPeriodChange")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
