import { identity, merge } from "lodash";
import { BsFillSave2Fill } from "react-icons/bs";
import ItemButton from "../action/ItemButton";
import styles from "./formHelpers.module.css";
import buttonStyles from "../action/ItemButtons.module.css";
import { useState } from "react";
/**
 * Control whether the submission button is enabled, and decorate accordingly if permissions don't allow create/update
 * @param {*} Form
 * @param {*} param1
 * @returns
 */
export function withControlledSubmit(Form, { model }) {
  const Component = ({ overrides, id, onSubmit = identity, onSuccess = identity, onError = identity, ...rest }) => {
    const [busy, setBusy] = useState();
    const overridesMerged = merge(overrides || {}, {
      SubmitButton: {
        as: ({ children, isLoading, className, ...rest }) => (
          <ItemButton
            buttonClass={id ? buttonStyles.editButton : buttonStyles.newButton}
            isLoading={busy || isLoading}
            label={id ? "Update" : "Create"}
            icon={<BsFillSave2Fill />}
            variant="plain"
            id={id}
            model={model}
            requiredPermissions={id ? "update" : "create"}
            {...rest}
          />
        )
      }
    });
    return (
      <Form
        onSubmit={(...args) => {
          setBusy(true);
          return onSubmit(...args);
        }}
        onSuccess={(...args) => {
          setBusy(false);
          return onSuccess(...args);
        }}
        onError={(...args) => {
          setBusy(false);
          return onError(...args);
        }}
        className={styles.customActions}
        overrides={overridesMerged}
        id={id}
        {...rest}
      />
    );
  };
  Component.displayName = "withControlledSubmit";
  return Component;
}
