import HelpBox from "./HelpBox";

export default function ScenarioHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Scenario Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Each scenario you create requires the complete set of information on the left hand side.
                </p>
                <p>
                    You will also need to enter data about the emissions reduction pathways that apply to your scenario.
                </p>
                <p>
                    Note that it is likely that there will be different emissions reduction pathways per-scenario, as each scenario should be informed by different drives.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
