import { DataStore } from "aws-amplify/dist/esm/datastore";
import { useEffect, useState } from "react";
import { QueryParameters, useCustomerDataStore } from "./useCustomerDataStore";

export default function useCustomerQuery(model: QueryParameters[0], predicateOrId: QueryParameters[1], opts?: QueryParameters[2]) {
  const [queryState, setQueryState] = useState<{
    result?: Awaited<ReturnType<typeof DataStore.query>>;
    error?: any;
    loading?: true;
    fetched?: true;
  }>({});
  const datastore = useCustomerDataStore();
  useEffect(() => {
    setQueryState({});
  }, [datastore]);
  const { error, loading, fetched } = queryState;
  useEffect(() => {
    if (!(error || loading || fetched)) {
      (async () => {
        try {
          setQueryState({ loading: true });
          const newResult = await datastore.query(model, predicateOrId, opts);
          setQueryState({ result: newResult, fetched: true });
        } catch (e) {
          setQueryState({ error: e });
        }
      })();
    }
  }, [error, loading, fetched, datastore, model, opts, predicateOrId]);

  return queryState;
}
