import HelpBox from "./HelpBox";

export default function CarbonAccountingLedgersHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Carbon Accounting Ledger</HelpBox.Header>
      <HelpBox.Content>
        <p>
          Enter data to collate the one or more carbon accounting ledgers, which
          will be used to categories carbon emissions, offsets and reductions
          and calculate metrics and KPI’s in your Stakeholder Portal:
        </p>
        <ol type="1">
          <li>
            <b>Emissions ledger</b> - your Greenhouse Gas emissions, emissions
            factors, scopes and categories, sources, units and dates
          </li>
          <li>
            <b>Offset ledger</b> - your emission offset purchases, schemes, CO2
            offsets, and dates
          </li>
          <li>
            <b>Reductions ledger</b> - your planned and achieved emissions
            reductions categorised into two sub-ledgers:
          </li>
          <ol type="a">
            <li>
              <b>Achieved Target Performance</b> - actual reductions you have
              made against specific targets
            </li>
            <li>
              <b>Emission Reduction Initiatives</b> - any planned and/or in
              progress emissions reduction initiatives
            </li>
          </ol>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
