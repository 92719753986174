import { ToggleButton, ToggleButtonGroup } from "@aws-amplify/ui-react";
import { PersistentModelConstructor } from "aws-amplify/datastore";
import { startCase } from "lodash";
import { CloseButton, Col, Stack } from "react-bootstrap";
import { IconType } from "react-icons";
import { BsChatDotsFill, BsCheck2Circle, BsQuestionCircleFill } from "react-icons/bs";
import { combinedSchemaFor } from "../../shared/amplify/schemaHelpers";
import Comments from "../comment/Comments";
import HelpBox from "../content/HelpBox";
import useLocalStorage from "../storage/useLocalStorage";
import useQueryParam from "../util/useQueryParam";
import ModelValidatorTool from "../validators/ModelValidatorTool";
import styles from "./formHelpers.module.css";
import { DecoratedFormProps } from "./withDecorations";

interface ExpectedProps {
  id?: string;
}
export interface ToolProps {
  id?: string;
  model: PersistentModelConstructor<any>;
  subtype?: string;
  children?: any;
}

export default function withTools(
  Form: React.FC<ExpectedProps & DecoratedFormProps>,
  {
    model,
    subtype
  }: {
    model: PersistentModelConstructor<any>;
    subtype?: string;
  }
): React.FC<ToolProps & DecoratedFormProps> {
  const buttonsArea = "Header"; // fixed default
  const toolsArea = combinedSchemaFor({ model, subtype })?.layout?.Tools ?? "Sidebar";

  const Component: React.FC<any> = ({ id, decorations, ...rest }) => {
    const [savedTool, setSelectedTool] = useLocalStorage(`SelectedTool-${model.name}${subtype ? `-${subtype}` : ""}`, false);
    const [preferredTool, setPreferredTool] = useQueryParam("preferredTool", savedTool);
    function setTool(tool: string | false) {
      setPreferredTool("");
      setSelectedTool(tool);
    }
    const { helpContent, hideComments, hideValidationTool } = combinedSchemaFor({
      model,
      subtype
    });
    const tools: Record<string, { component: React.FC<ToolProps>; icon: IconType }> = {
      ...(!!helpContent && {
        help: {
          component: () => {
            if (typeof helpContent === "function") {
              return helpContent({});
            } else {
              return (
                <HelpBox>
                  <HelpBox.Header>{model.name} Help</HelpBox.Header>
                  <HelpBox.Content>{helpContent}</HelpBox.Content>
                </HelpBox>
              );
            }
          },
          icon: BsQuestionCircleFill
        }
      }),
      ...(!!id && !hideComments && { comments: { component: Comments, icon: BsChatDotsFill } }),
      ...(!!id && !hideValidationTool && { publishValidation: { component: ModelValidatorTool, icon: BsCheck2Circle } })
    };
    const selectedTool = [preferredTool, savedTool].find((tool) => tool && tools[tool]);
    function handleChange(k: any) {
      setTool(k && tools[k] ? k : false);
    }
    const { component: Tool, icon: Icon } = (selectedTool && tools[selectedTool]) || {};
    const headerDecorations = (
      <ToggleButtonGroup
        className={styles.toolButtons}
        id={`${model.name}-tools`}
        isExclusive
        onChange={handleChange}
        value={selectedTool || ""}
      >
        {Object.entries(tools)
          .filter(([_, tool]) => !!tool)
          .map(([k, { icon: Icon }]) => (
            <ToggleButton
              className=""
              key={`${model.name}-${k}`}
              id={`tgl-${model.name}-${k}`}
              isPressed={selectedTool === k}
              value={k}
            >
              <Icon size="1em" />
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    );
    const toolDecorations = Tool && (
      <Col className="shadow p-3 mb-5 rounded">
        <Stack>
          <div className="d-inline-flex justify-content-between mb-2">
            <span>
              {Icon && <Icon size="1.5em" />}
              &nbsp;
              {selectedTool && startCase(selectedTool)}
            </span>
            <CloseButton className="align-self-end" onClick={() => setTool(false)} />
          </div>
          {<Tool id={id} model={model} subtype={subtype} />}
        </Stack>
      </Col>
    );

    return (
      <Form
        id={id}
        decorations={[decorations, { [buttonsArea]: headerDecorations }, toolDecorations && { [toolsArea]: toolDecorations }]}
        {...rest}
      />
    );
  };
  Component.displayName = "withTools";
  return Component;
}
