import HelpBox from "./HelpBox";

export default function GovernanceBaseHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Governance Body Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to create a record that describes your governance body, which is responsible for overseeing climate-related risks and climate-related
          opportunities.
        </p>
        <p>Follow these steps to define your governance body record:</p>
        <ol>
          <li>Enter a name for the governance body.</li>
          <li>Enter a description for the governance body.</li>
          <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of your governance body.</li>
          <li>
            Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
