import { Alert, Button, View } from "@aws-amplify/ui-react";
import { post } from "aws-amplify/api";
import { useState } from "react";
import { useCustomerAccount } from "../customer/CustomerSessionProvider";

const ExportCustomerData: React.FC<{}> = () => {
  const [{ pending, requested }, setExportRequest] = useState<Partial<{ pending: true; requested: true }>>({});
  const { appUser: { id: appUserId } = {} } = useCustomerAccount();
  return (
    <View maxWidth={"80em"} className="m-auto">
      <h1>Export</h1>
      <p>
        You may request a full snapshot archive of the data currently stored for your organisation. This will be extracted from our
        servers and emailed to you as a download link to a Zip file.
      </p>
      <p>
        The contents of the Zip file is a number of folders:
        <ul>
          <li>
            <b>Disclosure</b>&nbsp;JSON files for all of the data entities entered in the Disclosure forms
          </li>
          <li>
            <b>Evidence</b>&nbsp;All files uploaded for evidence for your organisation
          </li>
          <li>
            <b>Logs</b>&nbsp;JSON files containing audit trail entries for all editing activities for your organisation
          </li>
        </ul>
      </p>
      <div className="d-flex p-2">
        <Button
          className="ms-auto"
          variation="primary"
          isDisabled={!appUserId || pending || requested}
          isLoading={pending}
          onClick={async () => {
            setExportRequest({ pending: true });
            await post({
              apiName: "support",
              path: `/task/DataExport/${appUserId}/`
            }).response;
            setExportRequest({ requested: true });
          }}
        >
          Request Export
        </Button>
      </div>
      {requested && (
        <Alert variation="info">
          An export archive has been requested. You will receive a download link by email within the next business day
        </Alert>
      )}
    </View>
  );
};
export default ExportCustomerData;
