import {filesize} from "filesize";

const FileSize: React.FC<{
  value: string | number;
}> = ({value}) => {
  return (
    <span>{filesize(typeof value === "string" ? parseInt(value) : value)}</span>
  );
};

export default FileSize;
