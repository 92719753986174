import {isArray} from "lodash";
import {useEffect, useMemo, useState} from "react";
import {uischema} from "../backend";
import {useGroups, useUser} from "./CustomerSessionProvider";
import {useCustomerDataStore} from "./useCustomerDataStore";

export const permissions = ["create", "read", "update", "delete"];

export default function usePermissions({
  id,
  item,
  model = item?.constructor,
  modelName = model?.name
}) {
  const {username} = useUser();
  const membership = useGroups();
  const datastore = useCustomerDataStore();
  const [{existingItem, itemPending, itemError}, setExisting] = useState(
    item ? {existingItem: item} : {}
  );
  // If we are only given the id and model and not already looked up, lookup the item in the datastore - this allows us to test against owner rules on it
  useEffect(() => {
    (async () => {
      if (
        datastore &&
        id &&
        model &&
        !existingItem &&
        !itemError &&
        !itemPending
      ) {
        try {
          setExisting({itemPending: true});
          const existingItem = await datastore.query(model, id);
          setExisting({existingItem});
        } catch (e) {
          setExisting({itemError: e});
        }
      }
    })();
  }, [datastore, existingItem, id, itemError, itemPending, model]);

  return useMemo(() => {
    const rules =
      modelName &&
      uischema().models[modelName]?.attributes?.find(
        ({type}) => type === "auth"
      )?.properties?.rules;
    if (isArray(rules)) {
      const permissions = rules.reduce(
        (permissionsAcc, {allow, operations, provider, groups, ownerField}) => {
          if (
            (allow === "private" && username) || // private rule - match if any logged in user
            (allow === "groups" &&
              provider === "userPools" &&
              isArray(groups) &&
              groups.some((group) => membership.has(group))) || // groups rule  - match if user is a member of any of the listed groups
            (allow === "owner" &&
              provider === "userPools" &&
              existingItem?.[ownerField || "owner"] === username) // owner rule. match if item is present and the logged in user is named in the owner field
          ) {
            // matched - include the listed permissions
            if (operations) operations.forEach((p) => permissionsAcc.add(p));
          }
          return permissionsAcc;
        },
        new Set()
      );
      return permissions;
    } else {
      console.warn(`No auth information available for ${modelName}`);
      return new Set();
    }
  }, [existingItem, membership, modelName, username]);
}
