import {Badge} from "@aws-amplify/ui-react";
import clsx from "clsx";
import {startCase, uniq} from "lodash";
import parseCSSColor from "parse-css-color";
import PropTypes from "prop-types";
import {useState} from "react";
import {Dropdown} from "react-bootstrap";
import {BsCheck, BsPlus} from "react-icons/bs";
import stc from "string-to-color";
import {uischema} from "../backend";
import styles from "./EnumField.module.css";

function contrastingColor(color) {
  return luma(color) >= 165 ? "000" : "fff";
}
function luma(color) {
  const {
    values: [red, green, blue]
  } = parseCSSColor(color);

  return 0.2126 * red + 0.7152 * green + 0.0722 * blue; // SMPTE C, Rec. 709 weightings
}

function enumColour(name) {
  const backgroundColor = uischema().enums?.[name]?.displayColor || stc(name);
  return {
    "--enum-context-color": backgroundColor,
    "--enum-context-contrast-color": `#${contrastingColor(backgroundColor)}`
  };
}

function toDisplayValue(enumValue) {
  return startCase(enumValue || "");
}
export function EnumField({current, onChange, enumName, multiple, editor}) {
  const style = enumColour(enumName);
  const allValues = uniq(uischema().enums[enumName].values);
  const [selected, setSelected] = useState(uniq([current].flat()) || []);
  return (
    <span className={styles.badges} style={style}>
      {editor ? (
        multiple ? (
          allValues.map((value, i) => {
            const isSelected = selected.includes(value);
            return (
              <Badge
                key={i}
                size="small"
                className={clsx(styles.editableBadge, isSelected && "selected")}
                onClick={() => {
                  const newSelection = isSelected
                    ? selected.filter((v) => v !== value)
                    : [...selected, value];

                  setSelected(newSelection);
                  onChange && onChange(newSelection);
                }}
              >
                {isSelected ? <BsCheck /> : <BsPlus />}
                {toDisplayValue(value)}
              </Badge>
            );
          })
        ) : (
          <Dropdown>
            <Dropdown.Toggle
              as={Badge}
              size="small"
              className={clsx(styles.editableBadge, "selected")}
            >
              {current || "[unset]"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {allValues.map((value, i) => (
                <Dropdown.Item key={i} onClick={() => onChange(value)}>
                  {toDisplayValue(value)}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      ) : (
        [selected].flat().map(
          (enumValue, i) =>
            enumValue && (
              <Badge key={i} size="small" className={styles.badge}>
                {toDisplayValue(enumValue)}
              </Badge>
            )
        )
      )}
    </span>
  );
}

EnumField.propTypes = {
  current: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  enumName: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  editor: PropTypes.bool
};
