/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { CapitalDeploymentFundingProcess } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function CapitalDeploymentFundingProcessUpdateForm(props) {
  const {
    id: idProp,
    capitalDeploymentFundingProcess: capitalDeploymentFundingProcessModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ProcessName: "",
    Description: "",
    StartofProcess: "",
    EndofProcess: "",
  };
  const [ProcessName, setProcessName] = React.useState(
    initialValues.ProcessName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [StartofProcess, setStartofProcess] = React.useState(
    initialValues.StartofProcess
  );
  const [EndofProcess, setEndofProcess] = React.useState(
    initialValues.EndofProcess
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = capitalDeploymentFundingProcessRecord
      ? { ...initialValues, ...capitalDeploymentFundingProcessRecord }
      : initialValues;
    setProcessName(cleanValues.ProcessName);
    setDescription(cleanValues.Description);
    setStartofProcess(cleanValues.StartofProcess);
    setEndofProcess(cleanValues.EndofProcess);
    setErrors({});
  };
  const [
    capitalDeploymentFundingProcessRecord,
    setCapitalDeploymentFundingProcessRecord,
  ] = React.useState(capitalDeploymentFundingProcessModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(CapitalDeploymentFundingProcess, idProp)
        : capitalDeploymentFundingProcessModelProp;
      setCapitalDeploymentFundingProcessRecord(record);
    };
    queryData();
  }, [idProp, capitalDeploymentFundingProcessModelProp]);
  React.useEffect(resetStateValues, [capitalDeploymentFundingProcessRecord]);
  const validations = {
    ProcessName: [],
    Description: [],
    StartofProcess: [],
    EndofProcess: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ProcessName,
          Description,
          StartofProcess,
          EndofProcess,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            CapitalDeploymentFundingProcess.copyOf(
              capitalDeploymentFundingProcessRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "CapitalDeploymentFundingProcessUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Capital Deployment Plans"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 14a."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update this record that describes the organisations' capital deployment plans."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Capital deployment plan name"
        isRequired={false}
        isReadOnly={false}
        value={ProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName: value,
              Description,
              StartofProcess,
              EndofProcess,
            };
            const result = onChange(modelFields);
            value = result?.ProcessName ?? value;
          }
          if (errors.ProcessName?.hasError) {
            runValidationTasks("ProcessName", value);
          }
          setProcessName(value);
        }}
        onBlur={() => runValidationTasks("ProcessName", ProcessName)}
        errorMessage={errors.ProcessName?.errorMessage}
        hasError={errors.ProcessName?.hasError}
        {...getOverrideProps(overrides, "ProcessName")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ProcessName,
              Description: value,
              StartofProcess,
              EndofProcess,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Start of process"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={StartofProcess}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ProcessName,
                Description,
                StartofProcess: value,
                EndofProcess,
              };
              const result = onChange(modelFields);
              value = result?.StartofProcess ?? value;
            }
            if (errors.StartofProcess?.hasError) {
              runValidationTasks("StartofProcess", value);
            }
            setStartofProcess(value);
          }}
          onBlur={() => runValidationTasks("StartofProcess", StartofProcess)}
          errorMessage={errors.StartofProcess?.errorMessage}
          hasError={errors.StartofProcess?.hasError}
          {...getOverrideProps(overrides, "StartofProcess")}
        ></TextField>
        <TextField
          label="End of process"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={EndofProcess}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ProcessName,
                Description,
                StartofProcess,
                EndofProcess: value,
              };
              const result = onChange(modelFields);
              value = result?.EndofProcess ?? value;
            }
            if (errors.EndofProcess?.hasError) {
              runValidationTasks("EndofProcess", value);
            }
            setEndofProcess(value);
          }}
          onBlur={() => runValidationTasks("EndofProcess", EndofProcess)}
          errorMessage={errors.EndofProcess?.errorMessage}
          hasError={errors.EndofProcess?.hasError}
          {...getOverrideProps(overrides, "EndofProcess")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || capitalDeploymentFundingProcessModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || capitalDeploymentFundingProcessModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
