import clsx from "clsx";
import { findLast, trim } from "lodash";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styles from "./StandardReference.module.css";

const ifrsbaseUrl =
  "https://www.ifrs.org/issued-standards/ifrs-sustainability-standards-navigator/ifrs-s2-climate-related-disclosures.html/content/dam/ifrs/publications/html-standards-issb/english/2023/issued";
const nzcsbaseUrl = "https://standards.xrb.govt.nz/standards-navigator";

type ReferenceParams = { paragraph?: string; paragraphEnd?: string; section?: string };
function renderLink(url: string, standard: string, { paragraph, paragraphEnd, section }: ReferenceParams) {
  return (
    <Link className={clsx(styles.link, styles[standard.toLowerCase().replaceAll(/\W+/g, "-")])} to={url} target="_blank">
      <span className={styles.logo}>{standard}</span>
      <span className={styles.reference}>
        {paragraph && (
          <>
            Paragraph {paragraph}
            {paragraphEnd ? `-${paragraphEnd}` : ""}
            &nbsp;
          </>
        )}
        {section && `Section ${section}`}
      </span>
    </Link>
  );
}

const standardLinks: Record<string, (params: ReferenceParams) => ReactNode> = {
  "IFRS S2": (params) => renderLink(`${ifrsbaseUrl}/issbs2/#IFRS-S2_${params.paragraph}`, "IFRS S2", params),
  "IFRS S2-AG": (params) =>
    renderLink(`${ifrsbaseUrl}/issbs2-ag/#ifrs-s2_${params.paragraph}`, "IFRS S2-Accompanying Guidance", params),
  "IFRS S2-IG": (params) => renderLink(`${ifrsbaseUrl}/ibg/#${params.paragraph}`, "IFRS S2-Industry Guidance", params),
  NZCS1: (params) => renderLink(`${nzcsbaseUrl}/nz-cs-1/#${params.paragraph}`, "NZCS1", params),
  NZCS2: (params) => renderLink(`${nzcsbaseUrl}/nz-cs-2/#${params.paragraph}`, "NZCS2", params),
  NZCS3: (params) => renderLink(`${nzcsbaseUrl}/nz-cs-3/#${params.paragraph}`, "NZCS3", params)
};

const standards = Object.keys(standardLinks).join("|");
const stdMatchRe = new RegExp(`(${standards})([^']|'[^']*')*?((?=${standards})|\\.|$)`); // re to find a list of refers

// All matches case-blind
// references  are a comma separated list of STANDARD [paragraph|appendix] <n>[-<m>] [section] <n> ending in '[.;,]'
// paragraphs (<n>) are either \d+ or [a-z]\w* (i.e 21,A35b,Abc,A are, 21a is not - that will match as paragraph 21, section a)
// section is \w+
const stdSplitRe = new RegExp(
  String.raw`((?<standard>${standards})?\s*(?:paragraph(s)?|appendi(x|ices))?\s*(?<paragraph>\d+|(?:[a-z]\w*)|(?:'[^']*'))(?:-(?<paragraphEnd>\w+|(?:'[^']*')))?\s*(?:section\s+)?(?<section>\w[^,;.]*|(?:'[^']*)')?)(?:[;,.]|$)`,
  "ig"
); // split that list into individuals

export type StandardReferenceProps = {
  text: string;
};
const StandardReference: React.FC<StandardReferenceProps> = ({ text, ...rest }) => {
  const spans = [];
  const re = new RegExp(stdMatchRe, "g"); // clone with g
  let match,
    lastIndex = 0;
  while ((match = re.exec(text)) !== null) {
    // found a possible set of standards refs
    if (match.index > lastIndex) spans.push(text.slice(lastIndex, match.index));
    const matchedRefs = match[0].trim();
    spans.push(
      ...[...matchedRefs.matchAll(stdSplitRe)].map(({ 1: text, groups }, i, all) => {
        const matchingStandard = findLast(
          all.slice(0, i + 1).map((match) => match?.groups?.standard),
          Boolean
        );
        if (matchingStandard) {
          const standard = standardLinks?.[matchingStandard];
          const unquote = groups ? Object.fromEntries(Object.entries(groups).map(([k, v]) => [k, trim(v, "'")])) : {};
          return <>{standard(unquote)}</>;
        } else return <>{text}</>;
      })
    );
    lastIndex = re.lastIndex;
  }
  spans.push(text.slice(lastIndex));

  return (
    <span {...rest}>
      {spans.map((span, i) => (
        <span className={styles.root} key={i}>
          {span}&nbsp;
        </span>
      ))}
    </span>
  );
};

export function isStandardReference(text: any) {
  return typeof text === "string" && stdMatchRe.test(text);
}
export default StandardReference;
