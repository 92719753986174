import {
  ModelField,
  PersistentModel,
  PersistentModelConstructor,
  SchemaModel,
  isAssociatedWith,
  isEnumFieldType,
  isModelFieldType,
  isTargetNameAssociation
} from "aws-amplify/datastore";
import { isArray, isEmpty, merge, omit, pick } from "lodash";
import { ExtendedField, ExtendedModel, models, uischema } from "../backend";
import { Writeable } from "../util/typeUtils";

const scalarFieldTypes = /ID|AWSDate|AWSDateTime|AWSURL|Float|Int|String/;
const maxExpectedSubtypes = 10;
export function isScalar({ type, isArray }: ModelField) {
  return !isArray && (isEnumFieldType(type) || (typeof type === "string" && scalarFieldTypes.test(type)));
}
/**
 * Work out a field that is used as a (sort of) unique key in our model in addition to the id
 * @returns name of unique (naming field) or undefined
 */
export function findUniqueFieldName(model: PersistentModelConstructor<any>): string | undefined {
  const fields = uischema().models[model.name].fields;
  const explicit = Object.values(fields).find(({ isUnique }) => isUnique);
  return explicit?.name || fields?.DataSetName?.name || fields?.[`${model.name}Name`]?.name || undefined;
}
export function parseManyMany(sourceModelSchema: SchemaModel, { isArray, type, association }: ModelField) {
  if (isArray && isModelFieldType(type) && isAssociatedWith(association)) {
    const relationModelSchema = uischema().models[type.model];
    const associatedField = relationModelSchema.fields[association.associatedWith as string];
    if (isModelFieldType(associatedField.type) && associatedField.type.model === sourceModelSchema.name) {
      const targetRelationField = Object.values(relationModelSchema.fields).find(
        (field) =>
          field?.association?.connectionType === "BELONGS_TO" &&
          isModelFieldType(field?.type) &&
          sourceModelSchema.name !== field?.type?.model
      );
      const targetModelSchema =
        targetRelationField && isModelFieldType(targetRelationField.type) && uischema().models[targetRelationField.type.model];

      const targetFieldIdName =
        targetRelationField &&
        isTargetNameAssociation(targetRelationField.association) &&
        targetRelationField.association?.targetNames?.[0];

      const sourceRelationField = Object.values(relationModelSchema.fields).find(
        (field) =>
          field?.association?.connectionType === "BELONGS_TO" &&
          isModelFieldType(field?.type) &&
          sourceModelSchema.name === field?.type?.model
      );

      const sourceFieldIdName = sourceRelationField?.association?.targetNames?.[0];
      if (targetModelSchema && sourceFieldIdName && targetFieldIdName && relationModelSchema) {
        return {
          targetFieldName: targetRelationField.name,
          targetFieldIdName, // name of field on relationship entity containing id of target object
          sourceFieldIdName, // name of field on relationship entity containing id of source object
          relationModelSchema, // schema model of relationship object
          targetModelSchema, // schema model of target iobject
          targetModel: isModelFieldType(targetRelationField?.type) && models()[targetRelationField?.type?.model]
        };
      }
    }
  }
  return undefined; // not many-many
}

export function combinedSchemaFor({
  model,
  modelName = model && model.name,
  modelSchema = modelName ? uischema().models?.[modelName] : undefined,
  subtype
}: {
  model?: PersistentModelConstructor<any>;
  modelName?: string;
  modelSchema?: ExtendedModel;
  subtype?: string;
}): ExtendedModel {
  if (!modelSchema) throw new Error("model,modelName or modelSchema required");
  const subtypeSchema = subtype && modelSchema?.subtypes?.[subtype];
  return subtypeSchema ? merge({}, modelSchema, subtypeSchema) : modelSchema;
}

export function allSubtypes({
  model,
  modelName,
  modelSchema,
  enumName,
  enumValues
}: {
  model?: PersistentModelConstructor<any>;
  modelName?: string;
  modelSchema?: ExtendedModel;
  enumName?: string;
  enumValues?: string[];
}) {
  const modelNameOrdefault = model ? model.name : modelName;
  const modelSchemaOrDefault = modelSchema || (modelNameOrdefault && uischema().models[modelNameOrdefault]);
  if (!modelSchemaOrDefault) throw new Error(`Could not find model schema for ${modelNameOrdefault}`);
  const enumField =
    modelSchemaOrDefault.discriminatorField &&
    modelSchemaOrDefault.fields &&
    modelSchemaOrDefault.fields[modelSchemaOrDefault.discriminatorField];
  const enumNameOrDefault = enumName || (enumField && isEnumFieldType(enumField.type) && enumField.type.enum);
  const enumValuesOrDefault = enumValues || (enumNameOrDefault && uischema().enums[enumNameOrDefault]?.values) || [];
  const subtypesSchema = modelSchemaOrDefault.subtypes;
  const allSubtypes = [...enumValuesOrDefault] || [];
  // If we have ui schema definitions for the subtype display, it may specify sort order, so sort here
  return allSubtypes && subtypesSchema
    ? allSubtypes.sort(
        (subtypeA, subtypeB) => (subtypesSchema?.[subtypeA]?.subtypeOrder || 0) - (subtypesSchema?.[subtypeB]?.subtypeOrder || 0)
      )
    : allSubtypes;
}

/**
 * Return a key compounding the given related field name whith the given fiels subtype as a string. This key is matched by the includeRelations RE
 * - format is fieldname[:subtype]
 * @param {} fieldName
 * @param {*} subtype optional subtype
 * @returns
 */
export function toFieldKey(fieldName: string, subtype?: string) {
  return subtype ? `${fieldName}:${subtype}` : fieldName;
}

/**
 * Return the related fields for the given model that should be visible in the UI
 * @param {*} model
 * @param {string} [subtype]
 * @returns array or related fields. many-many fields are decorated with a manyMany
 */
export function relatedFields(model: PersistentModelConstructor<any>, subtype?: string) {
  const displayFields = ["displayName", "displayPluralName", "helpContent", "standardReference", "description"];
  const schemaModel = combinedSchemaFor({ model, subtype });
  const { includeRelations } = schemaModel;
  const relatedBaseFields = Object.values(schemaModel.fields || {}).filter(
    ({ name, hideInRelations, type, targetModel }) =>
      !hideInRelations && // use relation fields not explicitly marked hidden
      name && // and has a name
      name[0] !== "_" && // and name not prefixed with _ (we use that to mark the unused end of many many)
      (isModelFieldType(type) || // is officially a model type
        targetModel) // is a model type but we've had to explicitly tell it in UIschema (amplify crapness)
  );
  // expand each model type that has subtypes to one relation for each
  return relatedBaseFields
    .map((field, i) => {
      const { name, type, targetModel, targetSubtypes = {}, order = i } = field;
      const { targetModelSchema } = parseManyMany(schemaModel, field) || {
        targetModelSchema: uischema().models[isModelFieldType(type) ? type.model : (targetModel as string)]
      };
      const orderScaled = parseInt("0" + order) * maxExpectedSubtypes;
      const targetModelSubtypes = allSubtypes({ modelSchema: targetModelSchema });
      if (!isEmpty(targetSubtypes) && targetModelSubtypes) {
        const includedSubtypes = new Set(isArray(targetSubtypes) ? targetSubtypes : Object.keys(targetSubtypes)); // list of subtypes to include (if targetSubtypes is array, else use keys as list)
        return targetModelSubtypes
          .filter((subtype) => includedSubtypes.has(subtype)) // uischema can block out subtype relations by omitting from targetSubtypes
          .map((subtype, i) => {
            const subtypeSchema = targetModelSchema?.subtypes?.[subtype] || {};
            return {
              fieldKey: toFieldKey(name, subtype),
              subtype,
              order: orderScaled + i,
              // Override with relation settings
              ...field,
              // Override with target model ui decorations
              ...pick(subtypeSchema, displayFields),
              // Overrides from targetSubtype object id defined
              ...(isArray(targetSubtypes) ? {} : targetSubtypes[subtype])
            };
          });
      } else {
        return {
          fieldKey: name,
          order: orderScaled,
          // relation settings
          ...field
        };
      }
    })
    .flat()
    .filter(({ fieldKey }) => !includeRelations || includeRelations.test(fieldKey))
    .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);
}

export function defaultFilter(modelSchema: ExtendedModel, { name, type, showInTable }: ExtendedField) {
  return (
    showInTable || // always show
    !(
      // Don't show...
      (
        !name ||
        [modelSchema.discriminatorField, "createdAt", "updatedAt", "CustomerID"].includes(name) || // common system fields
        type === "ID" || // association // ID fields
        isModelFieldType(type)
      ) // nested objects
    )
  );
}

/**
 * Check that the given item is a legitamate model object for our (extended) amplify schema
 * @param {*} item
 * @returns true if so
 */
export function validateDataItem(item: any) {
  if (typeof item !== "object") return false; // is not a data item
  const model = item.constructor;
  if (!model) return false; // has no known model
  const modelSchema = uischema().models[model.name];
  if (!modelSchema) return false; // has no known model schema
  const { discriminatorField } = modelSchema;
  if (discriminatorField && !allSubtypes({ modelSchema }).includes(item[discriminatorField])) return false; // item is corrupt - no legal type field set
  return true;
}

export function exportFields(modelSubtype: Parameters<typeof combinedSchemaFor>[0]) {
  const schema = combinedSchemaFor(modelSubtype);
  const { fields, discriminatorField } = schema;

  const showFields = Object.values(fields)
    .filter((f) => [discriminatorField, "id"].includes(f.name) || defaultFilter(schema, f))
    .map(({ name }) => name);
  return showFields;
}

/**
 * Eagerly resolve all objects given a root object
 * @param item
 * @param objectById
 * @returns Promise{ graph, byId }
 */
export async function toObjectGraph(
  item: PersistentModel,
  objectById?: Record<string, PersistentModel>
): Promise<{ graph: Record<string, any>; objectById: Record<string, PersistentModel> }> {
  const byId = objectById || {};
  if (!item || !item.id) return { graph: item, objectById: byId }; // given null/undefined/id-less object return just itself
  const { id } = item;
  if (id in byId) {
    return { graph: byId[id], objectById: byId }; // item already seen
  } else {
    // Resolve related items
    const model = item.constructor as PersistentModelConstructor<any>;
    const modelSchema = uischema().models[model.name];
    const related = relatedFields(model, modelSchema?.discriminatorField && item?.[modelSchema.discriminatorField]);
    const newItem = omit(
      item,
      related.map((f) => f.name)
    ) as Writeable<PersistentModel>;
    Object.setPrototypeOf(newItem, { constructor: Object.getPrototypeOf(item).constructor });
    byId[id] = newItem;
    const resolved = Object.fromEntries(
      (
        await Promise.all(
          related.map(async (field) => {
            const { name, isArray } = field;
            const relation = item[name];
            if (relation) {
              if (isArray) {
                const relatedItems = (await relation.toArray()) as PersistentModel[];
                if (!isEmpty(relatedItems)) {
                  const manyMany = parseManyMany(modelSchema, field);
                  const resolvedItems = manyMany
                    ? await Promise.all(relatedItems.map(async (joinItem) => await joinItem[manyMany.targetFieldName])) // for many to many resolve through to joined item
                    : relatedItems;

                  return [
                    name,
                    await Promise.all(resolvedItems.map(async (resolvedItem) => (await toObjectGraph(resolvedItem, byId)).graph))
                  ];
                } else {
                  return [name, []];
                }
              } else {
                const relatedItem = await relation;
                return [name, relatedItem ? (await toObjectGraph(relatedItem, byId)).graph : undefined];
              }
            } else return undefined;
          })
        )
      ).filter(Boolean) as [[string, Record<string, any>]]
    );
    Object.assign(newItem, resolved);
    Object.keys(newItem).forEach((k: string) => (newItem[k] === undefined || newItem[k] === null) && delete newItem[k]); // remove any undefined properties (confuses schema check requiredness)
    return {
      graph: newItem,
      objectById: byId
    };
  }
}
