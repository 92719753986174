import { Stack } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

import clsx from "clsx";
import { ReactNode } from "react";
import { useHelpContext } from "../help/HelpContext";
import styles from "./SharedLayout.module.css";

export type SharedLayoutProps = {
  navBar?: ReactNode;
};
const SharedLayout: React.FC<SharedLayoutProps> = ({ navBar }) => {
  const { version } = useHelpContext();
  return (
    <div className={styles.app}>
      <div className={styles.headerNav}>
        <header className={clsx(styles.header, "d-flex")}>
          <img src="/ClimateTrackerLogo.svg" width="30%" alt="Climate Tracker App" />
          <small className="ms-auto">{version} Version</small>
        </header>
        {navBar}
      </div>
      <hr className={styles.headerLine} />
      <main className={styles.main}>
        <Outlet />
      </main>
      <footer className={styles.footer}>
        <div>&copy; ClimateTracker Limited</div>
        <Stack direction="horizontal" className="ms-auto" gap={3}>
          <img src="/ClimateTrackerLogoIcon.svg" style={{ height: "3em", width: "3em" }} alt="Climate Tracker App" />
          <Link
            className="nav-link"
            to="https://graphresearchlabs.atlassian.net/servicedesk/customer/topic/27760763-e8ce-4c03-9503-91ed747bda53"
            target="_blank"
          >
            Documentation
          </Link>
          <Link className="nav-link" to="https://graphresearchlabs.atlassian.net/servicedesk/customer/portals" target="_blank">
            Support
          </Link>
          <Link className="nav-link" to="https://graphresearchlabs.atlassian.net/servicedesk/customer/portals" target="_blank">
            Contact
          </Link>
          <Link className="nav-link" to="https://climatetracker.com/privacy-policy/" target="_blank">
            Privacy &amp; Terms
          </Link>
        </Stack>
      </footer>
    </div>
  );
};
export default SharedLayout;
