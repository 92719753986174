/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { AvoidedEmissionAccountEntry } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function AvoidedEmissionAccountEntryCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    AvoidedEmissionTransactionName: "",
    AvoidedEmissionTransactionDescription: "",
    AvoidedEmissionTransactionDataSource: "",
    GrossAmountCO2Avoided: "",
    AvoidedEmissionTransactionDate: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [AvoidedEmissionTransactionName, setAvoidedEmissionTransactionName] =
    React.useState(initialValues.AvoidedEmissionTransactionName);
  const [
    AvoidedEmissionTransactionDescription,
    setAvoidedEmissionTransactionDescription,
  ] = React.useState(initialValues.AvoidedEmissionTransactionDescription);
  const [
    AvoidedEmissionTransactionDataSource,
    setAvoidedEmissionTransactionDataSource,
  ] = React.useState(initialValues.AvoidedEmissionTransactionDataSource);
  const [GrossAmountCO2Avoided, setGrossAmountCO2Avoided] = React.useState(
    initialValues.GrossAmountCO2Avoided
  );
  const [AvoidedEmissionTransactionDate, setAvoidedEmissionTransactionDate] =
    React.useState(initialValues.AvoidedEmissionTransactionDate);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setAvoidedEmissionTransactionName(
      initialValues.AvoidedEmissionTransactionName
    );
    setAvoidedEmissionTransactionDescription(
      initialValues.AvoidedEmissionTransactionDescription
    );
    setAvoidedEmissionTransactionDataSource(
      initialValues.AvoidedEmissionTransactionDataSource
    );
    setGrossAmountCO2Avoided(initialValues.GrossAmountCO2Avoided);
    setAvoidedEmissionTransactionDate(
      initialValues.AvoidedEmissionTransactionDate
    );
    setErrors({});
  };
  const validations = {
    AvoidedEmissionTransactionName: [],
    AvoidedEmissionTransactionDescription: [],
    AvoidedEmissionTransactionDataSource: [],
    GrossAmountCO2Avoided: [],
    AvoidedEmissionTransactionDate: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          AvoidedEmissionTransactionName,
          AvoidedEmissionTransactionDescription,
          AvoidedEmissionTransactionDataSource,
          GrossAmountCO2Avoided,
          AvoidedEmissionTransactionDate,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new AvoidedEmissionAccountEntry(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AvoidedEmissionAccountEntryCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Avoided Emission Transaction"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Create a record of a transaction that avoids emissions that othewise would have occurred."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Avoided emission transaction name"
        isRequired={false}
        isReadOnly={false}
        value={AvoidedEmissionTransactionName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AvoidedEmissionTransactionName: value,
              AvoidedEmissionTransactionDescription,
              AvoidedEmissionTransactionDataSource,
              GrossAmountCO2Avoided,
              AvoidedEmissionTransactionDate,
            };
            const result = onChange(modelFields);
            value = result?.AvoidedEmissionTransactionName ?? value;
          }
          if (errors.AvoidedEmissionTransactionName?.hasError) {
            runValidationTasks("AvoidedEmissionTransactionName", value);
          }
          setAvoidedEmissionTransactionName(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AvoidedEmissionTransactionName",
            AvoidedEmissionTransactionName
          )
        }
        errorMessage={errors.AvoidedEmissionTransactionName?.errorMessage}
        hasError={errors.AvoidedEmissionTransactionName?.hasError}
        {...getOverrideProps(overrides, "AvoidedEmissionTransactionName")}
      ></TextField>
      <TextAreaField
        label="Avoided emission transaction description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AvoidedEmissionTransactionName,
              AvoidedEmissionTransactionDescription: value,
              AvoidedEmissionTransactionDataSource,
              GrossAmountCO2Avoided,
              AvoidedEmissionTransactionDate,
            };
            const result = onChange(modelFields);
            value = result?.AvoidedEmissionTransactionDescription ?? value;
          }
          if (errors.AvoidedEmissionTransactionDescription?.hasError) {
            runValidationTasks("AvoidedEmissionTransactionDescription", value);
          }
          setAvoidedEmissionTransactionDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AvoidedEmissionTransactionDescription",
            AvoidedEmissionTransactionDescription
          )
        }
        errorMessage={
          errors.AvoidedEmissionTransactionDescription?.errorMessage
        }
        hasError={errors.AvoidedEmissionTransactionDescription?.hasError}
        {...getOverrideProps(
          overrides,
          "AvoidedEmissionTransactionDescription"
        )}
      ></TextAreaField>
      <TextField
        label="Avoided emission transaction data source"
        isRequired={false}
        isReadOnly={false}
        value={AvoidedEmissionTransactionDataSource}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AvoidedEmissionTransactionName,
              AvoidedEmissionTransactionDescription,
              AvoidedEmissionTransactionDataSource: value,
              GrossAmountCO2Avoided,
              AvoidedEmissionTransactionDate,
            };
            const result = onChange(modelFields);
            value = result?.AvoidedEmissionTransactionDataSource ?? value;
          }
          if (errors.AvoidedEmissionTransactionDataSource?.hasError) {
            runValidationTasks("AvoidedEmissionTransactionDataSource", value);
          }
          setAvoidedEmissionTransactionDataSource(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AvoidedEmissionTransactionDataSource",
            AvoidedEmissionTransactionDataSource
          )
        }
        errorMessage={errors.AvoidedEmissionTransactionDataSource?.errorMessage}
        hasError={errors.AvoidedEmissionTransactionDataSource?.hasError}
        {...getOverrideProps(overrides, "AvoidedEmissionTransactionDataSource")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Gross amount CO2e avoided"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={GrossAmountCO2Avoided}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                AvoidedEmissionTransactionName,
                AvoidedEmissionTransactionDescription,
                AvoidedEmissionTransactionDataSource,
                GrossAmountCO2Avoided: value,
                AvoidedEmissionTransactionDate,
              };
              const result = onChange(modelFields);
              value = result?.GrossAmountCO2Avoided ?? value;
            }
            if (errors.GrossAmountCO2Avoided?.hasError) {
              runValidationTasks("GrossAmountCO2Avoided", value);
            }
            setGrossAmountCO2Avoided(value);
          }}
          onBlur={() =>
            runValidationTasks("GrossAmountCO2Avoided", GrossAmountCO2Avoided)
          }
          errorMessage={errors.GrossAmountCO2Avoided?.errorMessage}
          hasError={errors.GrossAmountCO2Avoided?.hasError}
          {...getOverrideProps(overrides, "GrossAmountCO2Avoided")}
        ></TextField>
        <TextField
          label="Avoided emission transaction date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={AvoidedEmissionTransactionDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                AvoidedEmissionTransactionName,
                AvoidedEmissionTransactionDescription,
                AvoidedEmissionTransactionDataSource,
                GrossAmountCO2Avoided,
                AvoidedEmissionTransactionDate: value,
              };
              const result = onChange(modelFields);
              value = result?.AvoidedEmissionTransactionDate ?? value;
            }
            if (errors.AvoidedEmissionTransactionDate?.hasError) {
              runValidationTasks("AvoidedEmissionTransactionDate", value);
            }
            setAvoidedEmissionTransactionDate(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "AvoidedEmissionTransactionDate",
              AvoidedEmissionTransactionDate
            )
          }
          errorMessage={errors.AvoidedEmissionTransactionDate?.errorMessage}
          hasError={errors.AvoidedEmissionTransactionDate?.hasError}
          {...getOverrideProps(overrides, "AvoidedEmissionTransactionDate")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
