import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavLink from "../util/NavLink";

export default function AdminNavbar({ className }) {
  return (
    <Navbar variant="dark" className={className}>
      <Nav className="me-auto">
        <NavLink to="/admin/AppUsers">Manage Users</NavLink>
        <NavLink to="/admin/AuditLog">Audit Log</NavLink>
        <NavLink to="/admin/Evidence">Evidence</NavLink>
        <NavLink to="/admin/Export">Export</NavLink>
      </Nav>
    </Navbar>
  );
}
