import { camelCase, upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { utils } from "xlsx";
import { useSpreadsheet } from "./useSpreadsheet";

const { REACT_APP_REFERENCE_SITE } = process.env;
const url = new URL(`${REACT_APP_REFERENCE_SITE}/shared/SASB_Codified_Standards_Level_III.xlsx`);

export const Sheets = {
  Sector: "Sector",
  Industry: "Industry",
  Topics: "Topics",
  TopicsMM: "Topics-MM",
  Metrics: "Metrics",
  SubMetrics: "Sub-Metrics",
  TechnicalProtocol: "Technical Protocol",
  ActivityMetrics: "Activity Metrics",
  SubActivityMetrics: "Sub-Activity Metrics"
} as const;
export type SheetName = keyof typeof Sheets;

export type DisclosureRow = {
  DisclosureTopicCode: string;
  DisclosureTopic: string;
};

export type MetricRow = {
  MetricCode: string;
  MetricCategory: string;
  MetricUnitOfMeasure: string;
  Metric: string;
  MetricNote: string;
  MetricNoteDisclosure: string;
};

export type SubMetricRow = {
  SubMetricCode: string;
  SubMetricCategory: string;
  SubMetricUnitOfMeasure: string;
  SubMetric: string;
};

export type SectorRow = {
  SectorCode: string;
  Sector: string;
};
export type SectorSheetRow = SectorRow;

export type IndustryRow = {
  IndustryCode: string;
  Industry: string;
};

export type ActivityMetricRow = {
  ActivityMetricCode: string;
  ActivityMetric: string;
  ActivityMetricCategory: string;
  ActivityMetricUnitOfMeasure: string;
};
export type SubActivityMetricRow = {
  SubActivityMetricCode: string;
  SubActivityMetric: string;
  SubActivityMetricCategory: string;
  SubActivityMetricUnitOfMeasure: string;
};

export type IndustrySheetRow = SectorRow &
  IndustryRow & {
    IndustryDescription: string;
  };
export type TopicsSheetRow = SectorRow &
  IndustryRow &
  DisclosureRow & {
    DisclosureTopicDescription: string;
  };

export type TopicsMMSheetRow = SectorRow &
  IndustryRow &
  DisclosureRow & {
    SustainablityDimension: string;
    GeneralIssueCategory: string;
  };

export type MetricsSheetRow = SectorRow &
  IndustryRow &
  DisclosureRow &
  MetricRow & {
    GeneralIssueCategory: string;
  };

export type TechnicalProtocolSheetRow = SectorRow &
  IndustryRow &
  DisclosureRow &
  MetricRow & {
    TechnicalProtocolCode: string;
    TechnicalProtocol: string;
    TpFootnoteNumber: string;
    TpFootnote: string;
    /* for the time being ignoring all the rest, not used*/
  };
export type SubMetricsSheetRow = SectorRow & IndustryRow & DisclosureRow & SubMetricRow;
export type SubActivityMetricsSheetRow = SectorRow & IndustryRow & DisclosureRow & ActivityMetricRow & SubActivityMetricRow;

export type SASBSheets = {
  Sector?: SectorSheetRow[];
  Industry?: IndustrySheetRow[];
  Topics?: TopicsSheetRow[];
  TopicsMM?: TopicsMMSheetRow[];
  Metrics?: MetricsSheetRow[];
  SubMetrics?: SubMetricsSheetRow[];
  TechnicalProtocol?: TechnicalProtocolSheetRow[];
  ActivityMetrics?: ActivityMetricRow[];
  SubActivityMetrics?: SubActivityMetricsSheetRow[];
};

export function useSASBStandardsSheets(sheetKeys: SheetName[]) {
  const { error, loading, workbook } = useSpreadsheet(url, { sheets: sheetKeys.map((k) => Sheets[k]) });
  const [workbookSheets, setWorkbookSheets] = useState<SASBSheets | undefined>();
  useEffect(() => {
    if (workbook) {
      const workbookSheets = Object.fromEntries(
        sheetKeys.map((sheetKey) => [
          sheetKey,
          utils
            .sheet_to_json<Record<string, any>[]>(workbook.Sheets[Sheets[sheetKey]])
            .map((row) => Object.fromEntries(Object.entries(row).map(([column, value]) => [upperFirst(camelCase(column)), value])))
        ])
      );
      console.timeLog("work", "mapped");
      setWorkbookSheets(workbookSheets);
    } else setWorkbookSheets(undefined);
  }, [workbook, sheetKeys]);
  return { error, loading: loading || !workbookSheets, workbook: workbookSheets };
}
