import { PersistentModelConstructor } from "aws-amplify/datastore";
import { Falsey, mergeWith } from "lodash";
import React, { Fragment } from "react";
import { combinedSchemaFor } from "../amplify/schemaHelpers";
import { LayoutArea, layoutAreas } from "../backend";
import styles from "./formHelpers.module.css";
import clsx from "clsx";

export declare type Decorations = Partial<Record<LayoutArea, Array<React.JSX.Element> | React.JSX.Element>>;

type RecursiveDecorations = Decorations | Array<RecursiveDecorations | Falsey>;
export declare type DecoratedFormProps = {
  decorations: RecursiveDecorations;
  className: string;
};
export function withDecorations(
  Form: React.FC<any>,
  { model, subtype }: { model?: PersistentModelConstructor<any>; subtype?: string } = {}
) {
  const formArea = (model && combinedSchemaFor({ model, subtype })?.layout?.Form) || "Primary";
  const Component = ({ decorations, className, ...rest }: DecoratedFormProps): React.JSX.Element => {
    const decorationsFlattened = mergeWith(
      {
        [formArea]: [<Form className={clsx(className, styles.amplifyForm)} {...rest} />]
      },
      // @ts-ignore
      ...[decorations].flat(Infinity).filter(Boolean),
      (objValue: any, srcValue: any) => [objValue, srcValue].flat().filter(Boolean)
    ) as Decorations;

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {layoutAreas.map((area, i) => (
            <div key={i} className={clsx(styles.area, styles[area])}>
              {decorationsFlattened[area] &&
                ([decorationsFlattened[area]] || []).flat().map((component, i) => <Fragment key={i}>{component}</Fragment>)}
            </div>
          ))}
        </div>
      </div>
    );
  };
  Component.displayName = "withDecorations";
  return Component;
}
