/*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
* GRL_MODIFY: Modified from codegen version. GRL_MODIFY marks changes
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Comment as Comment0 } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function CommentCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    presets, // GRL_MODIFY: add presets prop
    ...rest
  } = props;
  const initialValues = {
    CustomerID: "",
    TargetEntityModel: "",
    TargetEntityID: "",
    Comment: "",
    ...presets // GRL_MODIFY: add presets to initial values
  };
  const [CustomerID, setCustomerID] = React.useState(initialValues.CustomerID);
  const [TargetEntityModel, setTargetEntityModel] = React.useState(
    initialValues.TargetEntityModel
  );
  const [TargetEntityID, setTargetEntityID] = React.useState(
    initialValues.TargetEntityID
  );
  const [comment, setComment] = React.useState(initialValues.Comment);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setCustomerID(initialValues.CustomerID);
    setTargetEntityModel(initialValues.TargetEntityModel);
    setTargetEntityID(initialValues.TargetEntityID);
    setComment(initialValues.Comment);
    setErrors({});
  };
  const validations = {
    CustomerID: [],
    TargetEntityModel: [],
    TargetEntityID: [],
    Comment: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CustomerID,
          TargetEntityModel,
          TargetEntityID,
          Comment: comment,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Comment0(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "CommentCreateForm")}
      {...rest}
    >
      <TextField
        label="Customer id"
        isRequired={false}
        isReadOnly={false}
        value={CustomerID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID: value,
              TargetEntityModel,
              TargetEntityID,
              Comment: comment,
            };
            const result = onChange(modelFields);
            value = result?.CustomerID ?? value;
          }
          if (errors.CustomerID?.hasError) {
            runValidationTasks("CustomerID", value);
          }
          setCustomerID(value);
        }}
        onBlur={() => runValidationTasks("CustomerID", CustomerID)}
        errorMessage={errors.CustomerID?.errorMessage}
        hasError={errors.CustomerID?.hasError}
        {...getOverrideProps(overrides, "CustomerID")}
      ></TextField>
      <TextField
        label="Target entity model"
        isRequired={false}
        isReadOnly={false}
        value={TargetEntityModel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel: value,
              TargetEntityID,
              Comment: comment,
            };
            const result = onChange(modelFields);
            value = result?.TargetEntityModel ?? value;
          }
          if (errors.TargetEntityModel?.hasError) {
            runValidationTasks("TargetEntityModel", value);
          }
          setTargetEntityModel(value);
        }}
        onBlur={() =>
          runValidationTasks("TargetEntityModel", TargetEntityModel)
        }
        errorMessage={errors.TargetEntityModel?.errorMessage}
        hasError={errors.TargetEntityModel?.hasError}
        {...getOverrideProps(overrides, "TargetEntityModel")}
      ></TextField>
      <TextField
        label="Target entity id"
        isRequired={false}
        isReadOnly={false}
        value={TargetEntityID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID: value,
              Comment: comment,
            };
            const result = onChange(modelFields);
            value = result?.TargetEntityID ?? value;
          }
          if (errors.TargetEntityID?.hasError) {
            runValidationTasks("TargetEntityID", value);
          }
          setTargetEntityID(value);
        }}
        onBlur={() => runValidationTasks("TargetEntityID", TargetEntityID)}
        errorMessage={errors.TargetEntityID?.errorMessage}
        hasError={errors.TargetEntityID?.hasError}
        {...getOverrideProps(overrides, "TargetEntityID")}
      ></TextField>
      <TextField
        label="Comment"
        isRequired={false}
        isReadOnly={false}
        value={comment}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              Comment: value,
            };
            const result = onChange(modelFields);
            value = result?.Comment ?? value;
          }
          if (errors.Comment?.hasError) {
            runValidationTasks("Comment", value);
          }
          setComment(value);
        }}
        onBlur={() => runValidationTasks("Comment", comment)}
        errorMessage={errors.Comment?.errorMessage}
        hasError={errors.Comment?.hasError}
        {...getOverrideProps(overrides, "Comment")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
