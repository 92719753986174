/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Targets } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function TargetsUpdateForm(props) {
  const {
    id: idProp,
    targets: targetsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    TargetName: "",
    TargetType: "",
    TargetGroup: "",
    TargetDescription: "",
    TargetBaseYear: "",
    RelianceonOffsetsType: "",
    ExtentRelianceOnOffsets: "",
    OffsetSchemeType: "",
    OffsetCertificationSchemeName: "",
    TargetTimeframeStartDate: "",
    TargetTimeframeEndDate: "",
    PerformanceAgainstTargetDesc: "",
    TargetUnit: "",
    TargetValue: "",
    ContributionToLimitingWarming: "",
    BasisOfView: "",
  };
  const [TargetName, setTargetName] = React.useState(initialValues.TargetName);
  const [TargetType, setTargetType] = React.useState(initialValues.TargetType);
  const [TargetGroup, setTargetGroup] = React.useState(
    initialValues.TargetGroup
  );
  const [TargetDescription, setTargetDescription] = React.useState(
    initialValues.TargetDescription
  );
  const [TargetBaseYear, setTargetBaseYear] = React.useState(
    initialValues.TargetBaseYear
  );
  const [RelianceonOffsetsType, setRelianceonOffsetsType] = React.useState(
    initialValues.RelianceonOffsetsType
  );
  const [ExtentRelianceOnOffsets, setExtentRelianceOnOffsets] = React.useState(
    initialValues.ExtentRelianceOnOffsets
  );
  const [OffsetSchemeType, setOffsetSchemeType] = React.useState(
    initialValues.OffsetSchemeType
  );
  const [OffsetCertificationSchemeName, setOffsetCertificationSchemeName] =
    React.useState(initialValues.OffsetCertificationSchemeName);
  const [TargetTimeframeStartDate, setTargetTimeframeStartDate] =
    React.useState(initialValues.TargetTimeframeStartDate);
  const [TargetTimeframeEndDate, setTargetTimeframeEndDate] = React.useState(
    initialValues.TargetTimeframeEndDate
  );
  const [PerformanceAgainstTargetDesc, setPerformanceAgainstTargetDesc] =
    React.useState(initialValues.PerformanceAgainstTargetDesc);
  const [TargetUnit, setTargetUnit] = React.useState(initialValues.TargetUnit);
  const [TargetValue, setTargetValue] = React.useState(
    initialValues.TargetValue
  );
  const [ContributionToLimitingWarming, setContributionToLimitingWarming] =
    React.useState(initialValues.ContributionToLimitingWarming);
  const [BasisOfView, setBasisOfView] = React.useState(
    initialValues.BasisOfView
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = targetsRecord
      ? { ...initialValues, ...targetsRecord }
      : initialValues;
    setTargetName(cleanValues.TargetName);
    setTargetType(cleanValues.TargetType);
    setTargetGroup(cleanValues.TargetGroup);
    setTargetDescription(cleanValues.TargetDescription);
    setTargetBaseYear(cleanValues.TargetBaseYear);
    setRelianceonOffsetsType(cleanValues.RelianceonOffsetsType);
    setExtentRelianceOnOffsets(cleanValues.ExtentRelianceOnOffsets);
    setOffsetSchemeType(cleanValues.OffsetSchemeType);
    setOffsetCertificationSchemeName(cleanValues.OffsetCertificationSchemeName);
    setTargetTimeframeStartDate(cleanValues.TargetTimeframeStartDate);
    setTargetTimeframeEndDate(cleanValues.TargetTimeframeEndDate);
    setPerformanceAgainstTargetDesc(cleanValues.PerformanceAgainstTargetDesc);
    setTargetUnit(cleanValues.TargetUnit);
    setTargetValue(cleanValues.TargetValue);
    setContributionToLimitingWarming(cleanValues.ContributionToLimitingWarming);
    setBasisOfView(cleanValues.BasisOfView);
    setErrors({});
  };
  const [targetsRecord, setTargetsRecord] = React.useState(targetsModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Targets, idProp)
        : targetsModelProp;
      setTargetsRecord(record);
    };
    queryData();
  }, [idProp, targetsModelProp]);
  React.useEffect(resetStateValues, [targetsRecord]);
  const validations = {
    TargetName: [],
    TargetType: [],
    TargetGroup: [],
    TargetDescription: [],
    TargetBaseYear: [],
    RelianceonOffsetsType: [],
    ExtentRelianceOnOffsets: [],
    OffsetSchemeType: [],
    OffsetCertificationSchemeName: [],
    TargetTimeframeStartDate: [],
    TargetTimeframeEndDate: [],
    PerformanceAgainstTargetDesc: [],
    TargetUnit: [],
    TargetValue: [],
    ContributionToLimitingWarming: [],
    BasisOfView: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          TargetName,
          TargetType,
          TargetGroup,
          TargetDescription,
          TargetBaseYear,
          RelianceonOffsetsType,
          ExtentRelianceOnOffsets,
          OffsetSchemeType,
          OffsetCertificationSchemeName,
          TargetTimeframeStartDate,
          TargetTimeframeEndDate,
          PerformanceAgainstTargetDesc,
          TargetUnit,
          TargetValue,
          ContributionToLimitingWarming,
          BasisOfView,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Targets.copyOf(targetsRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "TargetsUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Target"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 23a-e."
        {...getOverrideProps(overrides, "SectionalElement8")}
      ></Heading>
      <Text
        children="Update this record describing a specific Target used to manage climate-related risks and opportunities."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Target name"
        isRequired={false}
        isReadOnly={false}
        value={TargetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName: value,
              TargetType,
              TargetGroup,
              TargetDescription,
              TargetBaseYear,
              RelianceonOffsetsType,
              ExtentRelianceOnOffsets,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              PerformanceAgainstTargetDesc,
              TargetUnit,
              TargetValue,
              ContributionToLimitingWarming,
              BasisOfView,
            };
            const result = onChange(modelFields);
            value = result?.TargetName ?? value;
          }
          if (errors.TargetName?.hasError) {
            runValidationTasks("TargetName", value);
          }
          setTargetName(value);
        }}
        onBlur={() => runValidationTasks("TargetName", TargetName)}
        errorMessage={errors.TargetName?.errorMessage}
        hasError={errors.TargetName?.hasError}
        {...getOverrideProps(overrides, "TargetName")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <SelectField
          label="Target type"
          placeholder="Please select an option"
          isDisabled={false}
          value={TargetType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType: value,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.TargetType ?? value;
            }
            if (errors.TargetType?.hasError) {
              runValidationTasks("TargetType", value);
            }
            setTargetType(value);
          }}
          onBlur={() => runValidationTasks("TargetType", TargetType)}
          errorMessage={errors.TargetType?.errorMessage}
          hasError={errors.TargetType?.hasError}
          {...getOverrideProps(overrides, "TargetType")}
        >
          <option
            children="Absolute target"
            value="Absolute_Target"
            {...getOverrideProps(overrides, "TargetTypeoption0")}
          ></option>
          <option
            children="Intensity target"
            value="Intensity_Target"
            {...getOverrideProps(overrides, "TargetTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Target group"
          isRequired={false}
          isReadOnly={false}
          value={TargetGroup}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup: value,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.TargetGroup ?? value;
            }
            if (errors.TargetGroup?.hasError) {
              runValidationTasks("TargetGroup", value);
            }
            setTargetGroup(value);
          }}
          onBlur={() => runValidationTasks("TargetGroup", TargetGroup)}
          errorMessage={errors.TargetGroup?.errorMessage}
          hasError={errors.TargetGroup?.hasError}
          {...getOverrideProps(overrides, "TargetGroup")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Target description"
        isRequired={false}
        isReadOnly={false}
        value={TargetDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetType,
              TargetGroup,
              TargetDescription: value,
              TargetBaseYear,
              RelianceonOffsetsType,
              ExtentRelianceOnOffsets,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              PerformanceAgainstTargetDesc,
              TargetUnit,
              TargetValue,
              ContributionToLimitingWarming,
              BasisOfView,
            };
            const result = onChange(modelFields);
            value = result?.TargetDescription ?? value;
          }
          if (errors.TargetDescription?.hasError) {
            runValidationTasks("TargetDescription", value);
          }
          setTargetDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("TargetDescription", TargetDescription)
        }
        errorMessage={errors.TargetDescription?.errorMessage}
        hasError={errors.TargetDescription?.hasError}
        {...getOverrideProps(overrides, "TargetDescription")}
      ></TextAreaField>
      <TextField
        label="Target base year"
        isRequired={false}
        isReadOnly={false}
        value={TargetBaseYear}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetType,
              TargetGroup,
              TargetDescription,
              TargetBaseYear: value,
              RelianceonOffsetsType,
              ExtentRelianceOnOffsets,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              PerformanceAgainstTargetDesc,
              TargetUnit,
              TargetValue,
              ContributionToLimitingWarming,
              BasisOfView,
            };
            const result = onChange(modelFields);
            value = result?.TargetBaseYear ?? value;
          }
          if (errors.TargetBaseYear?.hasError) {
            runValidationTasks("TargetBaseYear", value);
          }
          setTargetBaseYear(value);
        }}
        onBlur={() => runValidationTasks("TargetBaseYear", TargetBaseYear)}
        errorMessage={errors.TargetBaseYear?.errorMessage}
        hasError={errors.TargetBaseYear?.hasError}
        {...getOverrideProps(overrides, "TargetBaseYear")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Heading
        children="Reliance on Offsets"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Describe the extent to which the target relies on offsets."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <SelectField
          label="Relianceon offsets type"
          placeholder="Please select an option"
          isDisabled={false}
          value={RelianceonOffsetsType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType: value,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.RelianceonOffsetsType ?? value;
            }
            if (errors.RelianceonOffsetsType?.hasError) {
              runValidationTasks("RelianceonOffsetsType", value);
            }
            setRelianceonOffsetsType(value);
          }}
          onBlur={() =>
            runValidationTasks("RelianceonOffsetsType", RelianceonOffsetsType)
          }
          errorMessage={errors.RelianceonOffsetsType?.errorMessage}
          hasError={errors.RelianceonOffsetsType?.hasError}
          {...getOverrideProps(overrides, "RelianceonOffsetsType")}
        >
          <option
            children="Percentage"
            value="Percentage"
            {...getOverrideProps(overrides, "RelianceonOffsetsTypeoption0")}
          ></option>
          <option
            children="Value"
            value="Value"
            {...getOverrideProps(overrides, "RelianceonOffsetsTypeoption1")}
          ></option>
          <option
            children="None"
            value="None"
            {...getOverrideProps(overrides, "RelianceonOffsetsTypeoption2")}
          ></option>
        </SelectField>
        <TextField
          label="Value of extent of reliance on offsets"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={ExtentRelianceOnOffsets}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets: value,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.ExtentRelianceOnOffsets ?? value;
            }
            if (errors.ExtentRelianceOnOffsets?.hasError) {
              runValidationTasks("ExtentRelianceOnOffsets", value);
            }
            setExtentRelianceOnOffsets(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ExtentRelianceOnOffsets",
              ExtentRelianceOnOffsets
            )
          }
          errorMessage={errors.ExtentRelianceOnOffsets?.errorMessage}
          hasError={errors.ExtentRelianceOnOffsets?.hasError}
          {...getOverrideProps(overrides, "ExtentRelianceOnOffsets")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid12")}
      >
        <SelectField
          label="Offset scheme type"
          placeholder="Please select an option"
          isDisabled={false}
          value={OffsetSchemeType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType: value,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.OffsetSchemeType ?? value;
            }
            if (errors.OffsetSchemeType?.hasError) {
              runValidationTasks("OffsetSchemeType", value);
            }
            setOffsetSchemeType(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetSchemeType", OffsetSchemeType)
          }
          errorMessage={errors.OffsetSchemeType?.errorMessage}
          hasError={errors.OffsetSchemeType?.hasError}
          {...getOverrideProps(overrides, "OffsetSchemeType")}
        >
          <option
            children="Certified"
            value="Certified"
            {...getOverrideProps(overrides, "OffsetSchemeTypeoption0")}
          ></option>
          <option
            children="Verified"
            value="Verified"
            {...getOverrideProps(overrides, "OffsetSchemeTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Offset certification scheme name"
          isRequired={false}
          isReadOnly={false}
          value={OffsetCertificationSchemeName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName: value,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.OffsetCertificationSchemeName ?? value;
            }
            if (errors.OffsetCertificationSchemeName?.hasError) {
              runValidationTasks("OffsetCertificationSchemeName", value);
            }
            setOffsetCertificationSchemeName(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OffsetCertificationSchemeName",
              OffsetCertificationSchemeName
            )
          }
          errorMessage={errors.OffsetCertificationSchemeName?.errorMessage}
          hasError={errors.OffsetCertificationSchemeName?.hasError}
          {...getOverrideProps(overrides, "OffsetCertificationSchemeName")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid14")}
      >
        <TextField
          label="Target timeframe start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={TargetTimeframeStartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate: value,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.TargetTimeframeStartDate ?? value;
            }
            if (errors.TargetTimeframeStartDate?.hasError) {
              runValidationTasks("TargetTimeframeStartDate", value);
            }
            setTargetTimeframeStartDate(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "TargetTimeframeStartDate",
              TargetTimeframeStartDate
            )
          }
          errorMessage={errors.TargetTimeframeStartDate?.errorMessage}
          hasError={errors.TargetTimeframeStartDate?.hasError}
          {...getOverrideProps(overrides, "TargetTimeframeStartDate")}
        ></TextField>
        <TextField
          label="Target timeframe end date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={TargetTimeframeEndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate: value,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.TargetTimeframeEndDate ?? value;
            }
            if (errors.TargetTimeframeEndDate?.hasError) {
              runValidationTasks("TargetTimeframeEndDate", value);
            }
            setTargetTimeframeEndDate(value);
          }}
          onBlur={() =>
            runValidationTasks("TargetTimeframeEndDate", TargetTimeframeEndDate)
          }
          errorMessage={errors.TargetTimeframeEndDate?.errorMessage}
          hasError={errors.TargetTimeframeEndDate?.hasError}
          {...getOverrideProps(overrides, "TargetTimeframeEndDate")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Description of performance against target (alternative: add an Achieved Target Performance)"
        isRequired={false}
        isReadOnly={false}
        value={PerformanceAgainstTargetDesc}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetType,
              TargetGroup,
              TargetDescription,
              TargetBaseYear,
              RelianceonOffsetsType,
              ExtentRelianceOnOffsets,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              PerformanceAgainstTargetDesc: value,
              TargetUnit,
              TargetValue,
              ContributionToLimitingWarming,
              BasisOfView,
            };
            const result = onChange(modelFields);
            value = result?.PerformanceAgainstTargetDesc ?? value;
          }
          if (errors.PerformanceAgainstTargetDesc?.hasError) {
            runValidationTasks("PerformanceAgainstTargetDesc", value);
          }
          setPerformanceAgainstTargetDesc(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "PerformanceAgainstTargetDesc",
            PerformanceAgainstTargetDesc
          )
        }
        errorMessage={errors.PerformanceAgainstTargetDesc?.errorMessage}
        hasError={errors.PerformanceAgainstTargetDesc?.hasError}
        {...getOverrideProps(overrides, "PerformanceAgainstTargetDesc")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid16")}
      >
        <SelectField
          label="Target unit"
          placeholder="Please select an option"
          isDisabled={false}
          value={TargetUnit}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit: value,
                TargetValue,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.TargetUnit ?? value;
            }
            if (errors.TargetUnit?.hasError) {
              runValidationTasks("TargetUnit", value);
            }
            setTargetUnit(value);
          }}
          onBlur={() => runValidationTasks("TargetUnit", TargetUnit)}
          errorMessage={errors.TargetUnit?.errorMessage}
          hasError={errors.TargetUnit?.hasError}
          {...getOverrideProps(overrides, "TargetUnit")}
        >
          <option
            children="Employees"
            value="Employees"
            {...getOverrideProps(overrides, "TargetUnitoption0")}
          ></option>
          <option
            children="Kg"
            value="Kg"
            {...getOverrideProps(overrides, "TargetUnitoption1")}
          ></option>
          <option
            children="Kw"
            value="Kw"
            {...getOverrideProps(overrides, "TargetUnitoption2")}
          ></option>
          <option
            children="Kwh"
            value="Kwh"
            {...getOverrideProps(overrides, "TargetUnitoption3")}
          ></option>
          <option
            children="Litres"
            value="Litres"
            {...getOverrideProps(overrides, "TargetUnitoption4")}
          ></option>
          <option
            children="Metres cubed"
            value="MetresCubed"
            {...getOverrideProps(overrides, "TargetUnitoption5")}
          ></option>
          <option
            children="Million dollars revenue"
            value="MillionDollarsRevenue"
            {...getOverrideProps(overrides, "TargetUnitoption6")}
          ></option>
          <option
            children="Nights"
            value="Nights"
            {...getOverrideProps(overrides, "TargetUnitoption7")}
          ></option>
          <option
            children="Passenger per km"
            value="PassengerPerKm"
            {...getOverrideProps(overrides, "TargetUnitoption8")}
          ></option>
          <option
            children="Per capita"
            value="PerCapita"
            {...getOverrideProps(overrides, "TargetUnitoption9")}
          ></option>
          <option
            children="Percentage"
            value="Percentage"
            {...getOverrideProps(overrides, "TargetUnitoption10")}
          ></option>
          <option
            children="Quantity"
            value="Quantity"
            {...getOverrideProps(overrides, "TargetUnitoption11")}
          ></option>
          <option
            children="Tonnes"
            value="Tonnes"
            {...getOverrideProps(overrides, "TargetUnitoption12")}
          ></option>
          <option
            children="Tonnes per km"
            value="TonnesPerKm"
            {...getOverrideProps(overrides, "TargetUnitoption13")}
          ></option>
        </SelectField>
        <TextField
          label="Target value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={TargetValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                TargetName,
                TargetType,
                TargetGroup,
                TargetDescription,
                TargetBaseYear,
                RelianceonOffsetsType,
                ExtentRelianceOnOffsets,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
                TargetTimeframeStartDate,
                TargetTimeframeEndDate,
                PerformanceAgainstTargetDesc,
                TargetUnit,
                TargetValue: value,
                ContributionToLimitingWarming,
                BasisOfView,
              };
              const result = onChange(modelFields);
              value = result?.TargetValue ?? value;
            }
            if (errors.TargetValue?.hasError) {
              runValidationTasks("TargetValue", value);
            }
            setTargetValue(value);
          }}
          onBlur={() => runValidationTasks("TargetValue", TargetValue)}
          errorMessage={errors.TargetValue?.errorMessage}
          hasError={errors.TargetValue?.hasError}
          {...getOverrideProps(overrides, "TargetValue")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Describe the contribution to limiting warming the target will make"
        isRequired={false}
        isReadOnly={false}
        value={ContributionToLimitingWarming}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetType,
              TargetGroup,
              TargetDescription,
              TargetBaseYear,
              RelianceonOffsetsType,
              ExtentRelianceOnOffsets,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              PerformanceAgainstTargetDesc,
              TargetUnit,
              TargetValue,
              ContributionToLimitingWarming: value,
              BasisOfView,
            };
            const result = onChange(modelFields);
            value = result?.ContributionToLimitingWarming ?? value;
          }
          if (errors.ContributionToLimitingWarming?.hasError) {
            runValidationTasks("ContributionToLimitingWarming", value);
          }
          setContributionToLimitingWarming(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ContributionToLimitingWarming",
            ContributionToLimitingWarming
          )
        }
        errorMessage={errors.ContributionToLimitingWarming?.errorMessage}
        hasError={errors.ContributionToLimitingWarming?.hasError}
        {...getOverrideProps(overrides, "ContributionToLimitingWarming")}
      ></TextAreaField>
      <TextAreaField
        label="Basis of view"
        isRequired={false}
        isReadOnly={false}
        value={BasisOfView}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              TargetName,
              TargetType,
              TargetGroup,
              TargetDescription,
              TargetBaseYear,
              RelianceonOffsetsType,
              ExtentRelianceOnOffsets,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
              TargetTimeframeStartDate,
              TargetTimeframeEndDate,
              PerformanceAgainstTargetDesc,
              TargetUnit,
              TargetValue,
              ContributionToLimitingWarming,
              BasisOfView: value,
            };
            const result = onChange(modelFields);
            value = result?.BasisOfView ?? value;
          }
          if (errors.BasisOfView?.hasError) {
            runValidationTasks("BasisOfView", value);
          }
          setBasisOfView(value);
        }}
        onBlur={() => runValidationTasks("BasisOfView", BasisOfView)}
        errorMessage={errors.BasisOfView?.errorMessage}
        hasError={errors.BasisOfView?.hasError}
        {...getOverrideProps(overrides, "BasisOfView")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || targetsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || targetsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
