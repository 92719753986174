import HelpBox from "./HelpBox";

export default function AssetRegisterHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Asset Register Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    The Asset Register allows you to enter data about your assets which may be affected by your climate-related risks and opportunities.
                </p>
                <p>
                    Each Asset must have a unique name, and can be linked to a location where the asset resides.
                </p>
                <p>
                    It is recommended to enter assets you believe may be affected by your risks and opportunities, as this allows ClimateTracker to generate additional insights about your climate data in the Stakeholder Portal.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
