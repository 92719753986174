import HelpBox from "./HelpBox";

export default function StrategyDevelopmentProcessesHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Strategy Development Process Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to create a record that describes a process used by the governance body when it considers climate-related risks and opportunities in developing and overseeing implementation of the organisation's strategy.
                </p>
                <p>
                    Follow these steps to define your strategy development process record:
                </p>
                <ol>
                    <li>Enter a name for the strategy development process.</li>
                    <li>Enter a description of the strategy development process.</li>
                    <li>Select a value for how often this process operates from the <i>Process frequency</i> dropdown menu.</li>
                    <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of your strategy development process.</li>
                    <li>Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
