import { PersistentModelConstructor } from "aws-amplify/dist/esm/datastore";
import { ReactNode } from "react";
import { combinedSchemaFor } from "../amplify/schemaHelpers";
import { DataStoreInterface } from "../customer/useCustomerDataStore";
import { amplifyFormName } from "../form/amplifyForms";
import { combineValidators } from "../form/formHelpers";
import { isSchemaValidator, schemaFieldValidator, schemaFormValidator } from "./jsonSchemaValidators";
import { validators } from "../backend";

// As defined by studio for every form!
export type FieldValidationResponse = {
  hasError: boolean;
  errorMessage?: string;
};

export type FormValidationResponse = {
  hasError: boolean;
  errorMessage?: ReactNode;
  /** extra overrides to apply to form to render error condition */
  overrides?: Record<string, any>;
};
export type FieldValidator = (value: any, response: FieldValidationResponse) => Promise<FieldValidationResponse | undefined>;

/**
 * Exetnd the existing notion of a validator for the whole form (all fields), when called the validator will be given
 * the values of *all* the form's fields that the user is about to submit
 */
export type FormValidator = ({
  modelFields,
  customerDataStore
}: {
  /** the fields being submitted, including expanded relation fields */
  modelFields: Record<string, any>;
  /** convenience pass datastore for querying data for constraints */
  customerDataStore: DataStoreInterface;
}) => Promise<FormValidationResponse | undefined>;

export type Validators = {
  [fieldKey: string]: FieldValidator | FormValidator | undefined;
  "*"?: FormValidator | undefined;
};

export function withValidators(
  Form: React.FC<any>,
  { model, subtype }: { model: PersistentModelConstructor<any>; subtype?: string }
): React.FC<any> {
  const { fields, validator } = combinedSchemaFor({ model, subtype });
  const uischemaValidators = Object.fromEntries(
    [
      ["*", isSchemaValidator(validator) ? schemaFormValidator(validator, { generateOverrides: true }) : validator],
      ...Object.entries(fields).map(([name, { validator, isRequired }]) => [
        name,
        isSchemaValidator(validator) ? schemaFieldValidator(validator, isRequired) : validator
      ])
    ].filter(([_, validator]) => !!validator)
  );
  const component: React.FC<any> = ({ id, onValidate, ...rest }) => {
    const formValidatorsName = `${amplifyFormName(model, id ? "Update" : "Create", subtype)}Validators`;
    const configuredValidators = combineValidators(validators()?.[formValidatorsName], uischemaValidators, onValidate);

    return <Form id={id} onValidate={configuredValidators} {...rest} />;
  };
  component.displayName = "withValidators";
  return component;
}
