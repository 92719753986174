import HelpBox from "./HelpBox";

export default function ClimateRelatedImpactsHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Climate Related Impact Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to enter a record that describes an Impact that is likely to occur from a climate-related Risk or Opportunity that you have identified during your scenario analysis.
                </p>
                <p>
                    This form is divided into two parts to capture any current climate-related impacts, and any anticipated climate-related impacts.
                </p>
                <p>
                    Follow these steps to define your Impact record:
                </p>
                <ol>
                    <li>Enter a name and select the type of the Impact.</li>
                    <li>If the Impact is currently affecting the organisation, provide a description of the Impact and a range of values for the financial impact currently being experienced.</li>
                    <li>If you cannot provide quantitative information on the current financial impact for this record, enter an explanation for why this is the case.</li>
                    <li>If you anticipate a future impact, enter a range of values for the anticipated financial impact, a description of what the future impact will be, and select the time horizon the impact will be expected to occur.</li>
                    <li>If you cannot provide quantitative information on the anticipated financial impact for this record, enter an explanation for why this is the case.</li>
                    <li>
                        You should also attach evidence supporting this record, using the <i>Evidence Attachments</i> form. You can create a new evidence record by clicking the <b>New</b> button then fill in the required fields.</li>
                    <li>Next, select one or more risks and/or opportunities from the tables in the middle of the screen that give rise to the impact.</li>
                    <li>
                        Finally, click <b>Create</b> or <b>Update</b> at the bottom right of the window.
                    </li>
                </ol>
                <p>
                    Note that this section of your disclosure is intended to record impacts separately from risks or opportunities, and capture financial data (where possible) on these impacts. Impacts are recorded separately from risks or opportunities because these may give rise to more than one impact. For example, a risk may include "Higher carbon prices", which could lead to two Impacts: "Decreased margins on existing high carbon products", and "Increased sales of low carbon products".
                </p>
                <p>
                    If you cannot quantify financial data for this specific Impact, you must provide a reason in the last field.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
