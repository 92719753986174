/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Scenarios } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ScenariosUpdateForm(props) {
  const {
    id: idProp,
    scenarios: scenariosModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ScenarioName: "",
    ScenarioDescription: "",
    ScenarioAnalysisMethod: "",
    ScenarioBusinessCoverage: "",
    ScenarioTemperatureAlignment: "",
    TimeHorizon: "",
    EndpointType: "",
    EndpointValue: "",
    RelevanceDescription: "",
    ScenarioDataSource: "",
  };
  const [ScenarioName, setScenarioName] = React.useState(
    initialValues.ScenarioName
  );
  const [ScenarioDescription, setScenarioDescription] = React.useState(
    initialValues.ScenarioDescription
  );
  const [ScenarioAnalysisMethod, setScenarioAnalysisMethod] = React.useState(
    initialValues.ScenarioAnalysisMethod
  );
  const [ScenarioBusinessCoverage, setScenarioBusinessCoverage] =
    React.useState(initialValues.ScenarioBusinessCoverage);
  const [ScenarioTemperatureAlignment, setScenarioTemperatureAlignment] =
    React.useState(initialValues.ScenarioTemperatureAlignment);
  const [TimeHorizon, setTimeHorizon] = React.useState(
    initialValues.TimeHorizon
  );
  const [EndpointType, setEndpointType] = React.useState(
    initialValues.EndpointType
  );
  const [EndpointValue, setEndpointValue] = React.useState(
    initialValues.EndpointValue
  );
  const [RelevanceDescription, setRelevanceDescription] = React.useState(
    initialValues.RelevanceDescription
  );
  const [ScenarioDataSource, setScenarioDataSource] = React.useState(
    initialValues.ScenarioDataSource
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = scenariosRecord
      ? { ...initialValues, ...scenariosRecord }
      : initialValues;
    setScenarioName(cleanValues.ScenarioName);
    setScenarioDescription(cleanValues.ScenarioDescription);
    setScenarioAnalysisMethod(cleanValues.ScenarioAnalysisMethod);
    setScenarioBusinessCoverage(cleanValues.ScenarioBusinessCoverage);
    setScenarioTemperatureAlignment(cleanValues.ScenarioTemperatureAlignment);
    setTimeHorizon(cleanValues.TimeHorizon);
    setEndpointType(cleanValues.EndpointType);
    setEndpointValue(cleanValues.EndpointValue);
    setRelevanceDescription(cleanValues.RelevanceDescription);
    setScenarioDataSource(cleanValues.ScenarioDataSource);
    setErrors({});
  };
  const [scenariosRecord, setScenariosRecord] =
    React.useState(scenariosModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Scenarios, idProp)
        : scenariosModelProp;
      setScenariosRecord(record);
    };
    queryData();
  }, [idProp, scenariosModelProp]);
  React.useEffect(resetStateValues, [scenariosRecord]);
  const validations = {
    ScenarioName: [],
    ScenarioDescription: [],
    ScenarioAnalysisMethod: [],
    ScenarioBusinessCoverage: [],
    ScenarioTemperatureAlignment: [],
    TimeHorizon: [],
    EndpointType: [],
    EndpointValue: [],
    RelevanceDescription: [],
    ScenarioDataSource: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ScenarioName,
          ScenarioDescription,
          ScenarioAnalysisMethod,
          ScenarioBusinessCoverage,
          ScenarioTemperatureAlignment,
          TimeHorizon,
          EndpointType,
          EndpointValue,
          RelevanceDescription,
          ScenarioDataSource,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Scenarios.copyOf(scenariosRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ScenariosUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Scenario"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 13, NZCS3 paragraph 51a."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Heading>
      <Text
        children="Update this record that describes a climate-related scenario you have used to identify climate-related risks and opportunities and better understand the resilience of your business model and strategy, including descriptions of the various emissions reduction pathways in each scenario and the assumptions."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Scenario name"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName: value,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioBusinessCoverage,
              ScenarioTemperatureAlignment,
              TimeHorizon,
              EndpointType,
              EndpointValue,
              RelevanceDescription,
              ScenarioDataSource,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioName ?? value;
          }
          if (errors.ScenarioName?.hasError) {
            runValidationTasks("ScenarioName", value);
          }
          setScenarioName(value);
        }}
        onBlur={() => runValidationTasks("ScenarioName", ScenarioName)}
        errorMessage={errors.ScenarioName?.errorMessage}
        hasError={errors.ScenarioName?.hasError}
        {...getOverrideProps(overrides, "ScenarioName")}
      ></TextField>
      <TextAreaField
        label="Scenario description"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription: value,
              ScenarioAnalysisMethod,
              ScenarioBusinessCoverage,
              ScenarioTemperatureAlignment,
              TimeHorizon,
              EndpointType,
              EndpointValue,
              RelevanceDescription,
              ScenarioDataSource,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioDescription ?? value;
          }
          if (errors.ScenarioDescription?.hasError) {
            runValidationTasks("ScenarioDescription", value);
          }
          setScenarioDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ScenarioDescription", ScenarioDescription)
        }
        errorMessage={errors.ScenarioDescription?.errorMessage}
        hasError={errors.ScenarioDescription?.hasError}
        {...getOverrideProps(overrides, "ScenarioDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Scenario analysis method"
          placeholder="Please select an option"
          isDisabled={false}
          value={ScenarioAnalysisMethod}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod: value,
                ScenarioBusinessCoverage,
                ScenarioTemperatureAlignment,
                TimeHorizon,
                EndpointType,
                EndpointValue,
                RelevanceDescription,
                ScenarioDataSource,
              };
              const result = onChange(modelFields);
              value = result?.ScenarioAnalysisMethod ?? value;
            }
            if (errors.ScenarioAnalysisMethod?.hasError) {
              runValidationTasks("ScenarioAnalysisMethod", value);
            }
            setScenarioAnalysisMethod(value);
          }}
          onBlur={() =>
            runValidationTasks("ScenarioAnalysisMethod", ScenarioAnalysisMethod)
          }
          errorMessage={errors.ScenarioAnalysisMethod?.errorMessage}
          hasError={errors.ScenarioAnalysisMethod?.hasError}
          {...getOverrideProps(overrides, "ScenarioAnalysisMethod")}
        >
          <option
            children="Qualitative"
            value="Qualitative"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption0")}
          ></option>
          <option
            children="Quantitative"
            value="Quantitative"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption1")}
          ></option>
          <option
            children="Both"
            value="Both"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption2")}
          ></option>
          <option
            children="None"
            value="None"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption3")}
          ></option>
          <option
            children="None will add in short term"
            value="None_Will_Add_In_Short_Term"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption4")}
          ></option>
          <option
            children="None will add in medium term"
            value="None_Will_Add_In_Medium_Term"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption5")}
          ></option>
          <option
            children="None will add in long term"
            value="None_Will_Add_In_Long_Term"
            {...getOverrideProps(overrides, "ScenarioAnalysisMethodoption6")}
          ></option>
        </SelectField>
        <SelectField
          label="Scenario business coverage"
          placeholder="Please select an option"
          isDisabled={false}
          value={ScenarioBusinessCoverage}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod,
                ScenarioBusinessCoverage: value,
                ScenarioTemperatureAlignment,
                TimeHorizon,
                EndpointType,
                EndpointValue,
                RelevanceDescription,
                ScenarioDataSource,
              };
              const result = onChange(modelFields);
              value = result?.ScenarioBusinessCoverage ?? value;
            }
            if (errors.ScenarioBusinessCoverage?.hasError) {
              runValidationTasks("ScenarioBusinessCoverage", value);
            }
            setScenarioBusinessCoverage(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ScenarioBusinessCoverage",
              ScenarioBusinessCoverage
            )
          }
          errorMessage={errors.ScenarioBusinessCoverage?.errorMessage}
          hasError={errors.ScenarioBusinessCoverage?.hasError}
          {...getOverrideProps(overrides, "ScenarioBusinessCoverage")}
        >
          <option
            children="Company wide"
            value="Company_Wide"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption0")}
          ></option>
          <option
            children="Value chain segment"
            value="Value_Chain_Segment"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption1")}
          ></option>
          <option
            children="Business division"
            value="Business_Division"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption2")}
          ></option>
          <option
            children="Facility"
            value="Facility"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption3")}
          ></option>
          <option
            children="Country or area"
            value="Country_or_Area"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption4")}
          ></option>
          <option
            children="Product level"
            value="Product_Level"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption5")}
          ></option>
          <option
            children="Portfolio level"
            value="Portfolio_Level"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption6")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "ScenarioBusinessCoverageoption7")}
          ></option>
        </SelectField>
      </Grid>
      <SelectField
        label="Scenario temperature alignment"
        placeholder="Please select an option"
        isDisabled={false}
        value={ScenarioTemperatureAlignment}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioBusinessCoverage,
              ScenarioTemperatureAlignment: value,
              TimeHorizon,
              EndpointType,
              EndpointValue,
              RelevanceDescription,
              ScenarioDataSource,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioTemperatureAlignment ?? value;
          }
          if (errors.ScenarioTemperatureAlignment?.hasError) {
            runValidationTasks("ScenarioTemperatureAlignment", value);
          }
          setScenarioTemperatureAlignment(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ScenarioTemperatureAlignment",
            ScenarioTemperatureAlignment
          )
        }
        errorMessage={errors.ScenarioTemperatureAlignment?.errorMessage}
        hasError={errors.ScenarioTemperatureAlignment?.hasError}
        {...getOverrideProps(overrides, "ScenarioTemperatureAlignment")}
      >
        <option
          children="One point five degrees celsius"
          value="One_Point_Five_Degrees_Celsius"
          {...getOverrideProps(
            overrides,
            "ScenarioTemperatureAlignmentoption0"
          )}
        ></option>
        <option
          children="One point six to two degrees celsius"
          value="One_Point_Six_to_Two_Degrees_Celsius"
          {...getOverrideProps(
            overrides,
            "ScenarioTemperatureAlignmentoption1"
          )}
        ></option>
        <option
          children="Two point one to three degrees celsius"
          value="Two_Point_One_to_Three_Degrees_Celsius"
          {...getOverrideProps(
            overrides,
            "ScenarioTemperatureAlignmentoption2"
          )}
        ></option>
        <option
          children="Three point one to four degrees celsius"
          value="Three_Point_One_to_Four_Degrees_Celsius"
          {...getOverrideProps(
            overrides,
            "ScenarioTemperatureAlignmentoption3"
          )}
        ></option>
        <option
          children="Four point one degrees and above"
          value="Four_Point_One_Degrees_and_Above"
          {...getOverrideProps(
            overrides,
            "ScenarioTemperatureAlignmentoption4"
          )}
        ></option>
        <option
          children="Unknown"
          value="Unknown"
          {...getOverrideProps(
            overrides,
            "ScenarioTemperatureAlignmentoption5"
          )}
        ></option>
      </SelectField>
      <Text
        children="Enter data about the methods and assumptions underlying your scenario, and the scenario analysis process employed."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <SelectField
          label="Time horizon the scenario operates over"
          placeholder="Please select an option"
          isDisabled={false}
          value={TimeHorizon}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod,
                ScenarioBusinessCoverage,
                ScenarioTemperatureAlignment,
                TimeHorizon: value,
                EndpointType,
                EndpointValue,
                RelevanceDescription,
                ScenarioDataSource,
              };
              const result = onChange(modelFields);
              value = result?.TimeHorizon ?? value;
            }
            if (errors.TimeHorizon?.hasError) {
              runValidationTasks("TimeHorizon", value);
            }
            setTimeHorizon(value);
          }}
          onBlur={() => runValidationTasks("TimeHorizon", TimeHorizon)}
          errorMessage={errors.TimeHorizon?.errorMessage}
          hasError={errors.TimeHorizon?.hasError}
          {...getOverrideProps(overrides, "TimeHorizon")}
        >
          <option
            children="Current"
            value="Current"
            {...getOverrideProps(overrides, "TimeHorizonoption0")}
          ></option>
          <option
            children="Short term"
            value="Short_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption1")}
          ></option>
          <option
            children="Medium term"
            value="Medium_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption2")}
          ></option>
          <option
            children="Long term"
            value="Long_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption3")}
          ></option>
          <option
            children="All climate planning horizons"
            value="All_Climate_Planning_Horizons"
            {...getOverrideProps(overrides, "TimeHorizonoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Scenario endpoint type"
          placeholder="Please select an option"
          isDisabled={false}
          value={EndpointType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioName,
                ScenarioDescription,
                ScenarioAnalysisMethod,
                ScenarioBusinessCoverage,
                ScenarioTemperatureAlignment,
                TimeHorizon,
                EndpointType: value,
                EndpointValue,
                RelevanceDescription,
                ScenarioDataSource,
              };
              const result = onChange(modelFields);
              value = result?.EndpointType ?? value;
            }
            if (errors.EndpointType?.hasError) {
              runValidationTasks("EndpointType", value);
            }
            setEndpointType(value);
          }}
          onBlur={() => runValidationTasks("EndpointType", EndpointType)}
          errorMessage={errors.EndpointType?.errorMessage}
          hasError={errors.EndpointType?.hasError}
          {...getOverrideProps(overrides, "EndpointType")}
        >
          <option
            children="Temperature"
            value="Temperature"
            {...getOverrideProps(overrides, "EndpointTypeoption0")}
          ></option>
          <option
            children="Year"
            value="Year"
            {...getOverrideProps(overrides, "EndpointTypeoption1")}
          ></option>
        </SelectField>
      </Grid>
      <TextField
        label="Scenario endpoint value"
        isRequired={false}
        isReadOnly={false}
        value={EndpointValue}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioBusinessCoverage,
              ScenarioTemperatureAlignment,
              TimeHorizon,
              EndpointType,
              EndpointValue: value,
              RelevanceDescription,
              ScenarioDataSource,
            };
            const result = onChange(modelFields);
            value = result?.EndpointValue ?? value;
          }
          if (errors.EndpointValue?.hasError) {
            runValidationTasks("EndpointValue", value);
          }
          setEndpointValue(value);
        }}
        onBlur={() => runValidationTasks("EndpointValue", EndpointValue)}
        errorMessage={errors.EndpointValue?.errorMessage}
        hasError={errors.EndpointValue?.hasError}
        {...getOverrideProps(overrides, "EndpointValue")}
      ></TextField>
      <TextAreaField
        label="Explanation of scenario relevance to business model and strategy"
        isRequired={false}
        isReadOnly={false}
        value={RelevanceDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioBusinessCoverage,
              ScenarioTemperatureAlignment,
              TimeHorizon,
              EndpointType,
              EndpointValue,
              RelevanceDescription: value,
              ScenarioDataSource,
            };
            const result = onChange(modelFields);
            value = result?.RelevanceDescription ?? value;
          }
          if (errors.RelevanceDescription?.hasError) {
            runValidationTasks("RelevanceDescription", value);
          }
          setRelevanceDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("RelevanceDescription", RelevanceDescription)
        }
        errorMessage={errors.RelevanceDescription?.errorMessage}
        hasError={errors.RelevanceDescription?.hasError}
        {...getOverrideProps(overrides, "RelevanceDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Sources of data used to construct scenario"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioDataSource}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioName,
              ScenarioDescription,
              ScenarioAnalysisMethod,
              ScenarioBusinessCoverage,
              ScenarioTemperatureAlignment,
              TimeHorizon,
              EndpointType,
              EndpointValue,
              RelevanceDescription,
              ScenarioDataSource: value,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioDataSource ?? value;
          }
          if (errors.ScenarioDataSource?.hasError) {
            runValidationTasks("ScenarioDataSource", value);
          }
          setScenarioDataSource(value);
        }}
        onBlur={() =>
          runValidationTasks("ScenarioDataSource", ScenarioDataSource)
        }
        errorMessage={errors.ScenarioDataSource?.errorMessage}
        hasError={errors.ScenarioDataSource?.hasError}
        {...getOverrideProps(overrides, "ScenarioDataSource")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || scenariosModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || scenariosModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
