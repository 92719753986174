import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import clsx from "clsx";
import { defaultFilter, parseManyMany } from "../../shared/amplify/schemaHelpers";
import StandardReference from "../content/StandardReference";
import { useModal } from "../modal/useModal";
import MultiSelectDataItems from "../select/MultiSelectDataItems";
import SelectDataItem from "../select/SelectDataItem";
import DataItemTable from "../table/DataItemTable";
import { uischema } from "../backend";
import styles from "./RelatedField.module.css";

import { models } from "../backend";

export default function RelatedField({
  sourceId,
  model,
  subtype,
  field,
  isLoading,
  fieldValue,
  error,
  onChange,
  defaultComponent,
  ...rest
}) {
  const { name, description, standardReference, isArray, isRequired, maxItems, component } = field;
  const { targetModelSchema } = parseManyMany(model, field) || {};
  const fieldModelName = targetModelSchema?.name || field?.type?.model || field?.targetModel;
  const fieldModel = models()[fieldModelName];
  const fieldModal = useModal({ model: fieldModel, subtype }); // Lookup optional modal using XXXModal naming convention

  const selection = (Array.isArray(fieldValue) ? fieldValue : [fieldValue])
    .map((v) => (typeof v === "object" ? v?.id : v))
    .filter(Boolean);
  const fields = uischema().models[fieldModel.name]?.fields;
  const columns = Object.values(fields).filter(defaultFilter).length;
  const RelationComponent =
    component ||
    defaultComponent ||
    (fields && columns === 1 // would only display one column
      ? isArray
        ? MultiSelectDataItems // Use autocomplete with multiple badges
        : SelectDataItem // Single item being selected, use dropdown
      : DataItemTable); // Is a 'dataset' - display as table
  const predicates = [];
  const selfReferencing = fieldModel === model; // child relation is to myself, we don't allow add/remove/editing children, just selecting existing
  if (selfReferencing) {
    // Special case, self referencing model so don't show source object in list
    predicates.push((c) => c.id.ne(sourceId));
    if (field.association.targetNames) {
      // Only allow association with other targets that aren't already associated (restrict to single level)
      // We might want to make this configurable
      predicates.push(...field.association.targetNames.map((idFieldName) => (c) => c[idFieldName].eq(null)));
    }
  }

  return (
    <div className={clsx(styles.root, !!error && styles.error)}>
      {description && <div>{description}</div>}
      {standardReference && <StandardReference className={styles.standardReference} text={standardReference} />}
      {isLoading ? (
        <Spinner key={name} />
      ) : (
        <RelationComponent
          key={name}
          model={fieldModel}
          subtype={subtype}
          required={isRequired}
          onChange={onChange}
          selection={isLoading ? undefined : selection}
          modal={fieldModal}
          multiple={isArray && (maxItems || true)} // if an array use maxItems if specified
          error={error}
          predicates={predicates}
          itemActions={selfReferencing ? [] : undefined} // no edit/delete
          globalActions={selfReferencing ? [] : undefined} // no new etc
        />
      )}
    </div>
  );
}

RelatedField.propTypes = {
  sourceId: PropTypes.string,
  defaultComponent: PropTypes.any,
  error: PropTypes.any,
  field: PropTypes.shape({
    component: PropTypes.any,
    description: PropTypes.any,
    displayName: PropTypes.any,
    isArray: PropTypes.any,
    isRequired: PropTypes.any,
    name: PropTypes.any,
    type: PropTypes.oneOfType([
      PropTypes.shape({
        model: PropTypes.any
      }),
      PropTypes.string
    ])
  }),
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.any,
  model: PropTypes.any,
  onChange: PropTypes.func
};
