import { DataStore } from "aws-amplify/datastore";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { uischema } from "../backend";
import { asCreateOrUpdate } from "./formHelpers";
import { amplifyForm } from "./amplifyForms";
import styles from "./formHelpers.module.css";
import { withRelations } from "../field/withRelations";
import { allSubtypes, combinedSchemaFor } from "../amplify/schemaHelpers";
import withStandardReferences from "./withStandardReferences";
import { withDecorations } from "./withDecorations";
import { identity } from "lodash";

export default function withSubtype(Form, { model, subtype = undefined, renderRelatedField = undefined }) {
  const modelSchema = uischema().models[model.name];
  const { discriminatorField, displayComposed, subtypes } = modelSchema || {};
  const subtypesArea = combinedSchemaFor({ model, subtype })?.layout?.Subtypes ?? "Secondary";

  const Component = (function () {
    if (subtypes) {
      if (subtype) {
        // Specific subtype given - display form as it is but makesure subtype is set on submit
        return ({ onSave = identity, onChange, ...rest }) => {
          return (
            <Form onSave={(modelFields) => onSave({ ...modelFields, [discriminatorField]: subtype })} subtype={subtype} {...rest} />
          );
        };
      } else if (displayComposed) {
        // No specific subtype given, so augment form with all it's subtype forms
        // Create list of subforms outside render to minimize DOM reordering)
        const subtypeNames = allSubtypes({
          modelSchema,
          enumValues: Object.keys(subtypes) // use subtype key values as we may not have a discrimminator enum
        }).filter((s) => s in subtypes); // ensures ordering
        const subforms = subtypeNames.map((subtype, i) =>
          withStandardReferences(
            withRelations(
              withDecorations(
                asCreateOrUpdate({
                  createDialog: amplifyForm(model, "Create", subtype),
                  updateDialog: amplifyForm(model, "Update", subtype)
                }),
                { model, subtype }
              ),
              { model, subtype, renderRelatedField }
            ),
            { model, subtype }
          )
        );
        return ({ onSave = identity, onChange, children, id, decorations, ...rest }) => {
          const [composedModelFields, setComposedModelFields] = useState({});
          const [activeSubtype, setActiveSubtype] = useState(discriminatorField && id ? undefined : subtypeNames[0]);
          function handleChange(modelFields) {
            // Save any subform changes
            setComposedModelFields({ ...composedModelFields, ...modelFields });
            return (onChange && onChange(modelFields)) || modelFields;
          }
          const selectSubtype = !!discriminatorField; // If single subtype is set we will only allow the user to select one type, and save on submit
          useEffect(() => {
            if (!activeSubtype && id && discriminatorField) {
              // If a discrimminated type an existing item, initialise with current type
              (async () => {
                const existing = await DataStore.query(model, id);
                setActiveSubtype(existing[discriminatorField] || subtypeNames[0]);
              })();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [id, discriminatorField]);
          const subtypesDecoration =
            subtypeNames.length > 1 ? (
              <Tabs variant={selectSubtype ? "pills" : "tabs"} activeKey={activeSubtype} onSelect={setActiveSubtype}>
                {subtypeNames.map((subtype, i) => {
                  const SubForm = subforms[i];
                  const isActive = activeSubtype === subtype;
                  return (
                    <Tab
                      key={subtype}
                      tabClassName={styles.subtypeTab}
                      eventKey={subtype}
                      title={
                        <>
                          {!!discriminatorField && (
                            <>
                              <input type="radio" checked={isActive} readOnly value={subtype} />
                              &nbsp;
                            </>
                          )}
                          {subtypes[subtype].displayName}
                        </>
                      }
                    >
                      <SubForm
                        onChange={handleChange}
                        id={id}
                        overrides={{ CTAFlex: { style: { display: "none" } } }}
                        className={styles.amplifySubform}
                      />
                    </Tab>
                  );
                })}
              </Tabs>
            ) : (
              <Form onChange={handleChange} id={id} />
            );
          return (
            <>
              <Form
                onSave={(modelFields) =>
                  onSave({
                    ...modelFields,
                    ...composedModelFields,
                    ...(discriminatorField && {
                      [discriminatorField]: activeSubtype
                    })
                  })
                }
                id={id}
                decorations={[
                  {
                    [subtypesArea]: subtypesDecoration
                  },
                  decorations
                ]}
                {...rest}
              />
            </>
          );
        };
      }
    }
    return Form; // default vanilla form
  })();
  Component.displayName = "withSubtype";
  return Component;
}
