import React, { ReactNode } from "react";

import { Outlet } from "react-router-dom";

import styles from "./DisclosureLayout.module.css";

export type DisclosureLayoutProps = {
  navBar?: ReactNode;
};
const DisclosureLayout: React.FC<DisclosureLayoutProps> = ({ navBar }) => {
  return (
    <section className={styles.root}>
      <header className={styles.nav}>{navBar}</header>
      <main className={styles.page}>
        <Outlet />
      </main>
    </section>
  );
};
export default DisclosureLayout;
