import { PersistentModel, PersistentModelConstructor } from "aws-amplify/datastore";
import { isEmpty } from "lodash";
import { DateTime, Interval } from "luxon";
import { toObjectGraph } from "../amplify/schemaHelpers";
import { models, uischema } from "../backend";
import { DataStoreInterface } from "../customer/useCustomerDataStore";
import { createLinkRenderer } from "../field/linkRenderer";
import { useModal } from "../modal/useModal";
import { Flex } from "@aws-amplify/ui-react";
import { FormValidationResponse } from "../validators/withValidators";
import { ReactNode } from "react";
import styles from "../validators/Validators.module.css";
import { LayoutProvider } from "../modal/LayoutContext";

type Evidence = {
  id: string;
  TargetEntityID: string;
  ValidFrom?: string;
  ValidTo?: string;
};

function EvidenceError({ errorItem, error }: { errorItem: PersistentModel; error: string }): ReactNode {
  const model = errorItem.constructor as PersistentModelConstructor<any>;
  const modelName = model.name; // can use root model if at root and no model on root object
  const subTypeField = uischema().models[modelName]?.discriminatorField;
  const subtype = subTypeField && errorItem?.[subTypeField];
  const Link = createLinkRenderer({ modelName, subtype });
  const Modal = useModal({ model, subtype });
  return (
    <Flex>
      <span>
        <Link row={errorItem} />
        &nbsp;{error}
      </span>
      {Modal && <Modal.Edit id={errorItem.id} className="ms-auto" />}
    </Flex>
  );
}

function isDateRangeCovered(range: Interval, evidenceItems: Evidence[]) {
  if (!isEmpty(evidenceItems)) {
    const mergedIntervals = Interval.merge(
      evidenceItems
        .map(({ ValidFrom, ValidTo }) =>
          Interval.fromDateTimes(
            !!ValidFrom ? DateTime.fromISO(ValidFrom).startOf("day") : range.start,
            !!ValidTo ? DateTime.fromISO(ValidTo).plus({ days: 1 }).startOf("day") : range.end
          )
        )
        .filter(Boolean) as Interval[]
    );
    return mergedIntervals.length === 1 && mergedIntervals[0].engulfs(range); // if all intervals can be merged into one
  } else return false;
}

export async function evidenceValidator(
  disclosure: PersistentModel,
  customerDataStore: DataStoreInterface
): Promise<FormValidationResponse | undefined> {
  const { graph, objectById } = await toObjectGraph(disclosure);
  try {
    if (!graph?.DisclosurePeriod?.StartDate || !graph?.DisclosurePeriod?.EndDate)
      throw new Error("Evidence Check could not proceed as no valid period for disclosure has been defined");
    const dislosurePeriodAsRange: Interval = Interval.fromDateTimes(
      DateTime.fromISO(graph.DisclosurePeriod.StartDate),
      DateTime.fromISO(graph.DisclosurePeriod.EndDate)
    );
    const evidence = (await customerDataStore.query(models().Evidence)) as Evidence[]; // oh for a join ;-)
    const evidenceById = evidence
      .filter(({ TargetEntityID }) => TargetEntityID && TargetEntityID in objectById)
      .reduce((evidenceById, evidence) => {
        const id = evidence.TargetEntityID as string;
        return { ...evidenceById, [id]: id in evidenceById ? [...evidenceById[id], evidence] : [evidence] };
      }, {} as Record<string, Evidence[]>);
    const missingEvidence = Object.values(objectById)
      .filter((item) => item?.constructor?.name && uischema().models[item.constructor.name].showEvidence)
      .map((item) => {
        try {
          const evidenceItems = evidenceById?.[item.id];
          return [
            item,
            isEmpty(evidenceItems)
              ? "missing evidence"
              : !isDateRangeCovered(dislosurePeriodAsRange, evidenceItems)
              ? "evidence present does not cover date range"
              : undefined
          ];
        } catch (e) {
          return [item, e ? e.toString() : "evidence not found"];
        }
      })
      .filter(([_, message]) => !!message) as [Evidence, string][];

    const evidenceResult = !isEmpty(missingEvidence)
      ? {
          hasError: true,
          errorMessage: (
            <LayoutProvider layout="dense">
              <section className={styles.formValidationResult}>
                <header>Required evidence covering period {dislosurePeriodAsRange.toLocaleString()}:</header>
                <main>
                  <ul>
                    {missingEvidence.map(([item, message], i) => (
                      <li key={i}>
                        <EvidenceError errorItem={item} error={message} />
                      </li>
                    ))}
                  </ul>
                </main>
              </section>
            </LayoutProvider>
          )
        }
      : undefined;
    return evidenceResult;
  } catch (e) {
    return {
      hasError: true,
      errorMessage: (e as Error).message
    };
  }
}
