import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavLink from "../../shared/util/NavLink";

function DisclosureNavbar({ className }) {
  return (
    <Navbar variant="dark" className={className}>
      <Nav className="me-auto">
        <NavLink to="/disclosures/Disclosure">Disclosure Builder</NavLink>
        <NavLink to="/disclosures/GovernanceBaseDataSet">Governance</NavLink>
        <NavLink to="/disclosures/StrategyBaseData">Strategy</NavLink>
        <NavLink to="/disclosures/RiskManagementProcessesRecords">Risk Management</NavLink>
        <NavLink to="/disclosures/MetricsAndTargetsRecords">Metrics and Targets</NavLink>
      </Nav>
    </Navbar>
  );
}

export default DisclosureNavbar;
