import { cloneDeepWith, merge } from "lodash";

import { Schema } from "aws-amplify/datastore";
import S, { ArraySchema } from "fluent-json-schema";
import { InitiativeStatusEnum } from "../models";
import { schema } from "../models/schema";
import { insertDisplayNames } from "../shared/amplify/schemaDisplayNames";
import { UISchema, UISchemaAggregate } from "../shared/backend";
import uishared from "../shared/uischema-shared";
import { AsOptional, Latitude, Longitude, RequiredAWSDate, RequiredString, WithFields, Year } from "../shared/validators/ajv";
import EmissionAccountEntryWizard from "../shared/wizard/EmissionAccountEntryWizard";
import withIndustryMetricsWizard from "../shared/wizard/withIndustryMetricsWizard";
import AssetRegisterHelp from "./content/AssetRegisterHelp";
import CarbonAccountingLedgersHelp from "./content/CarbonAccountingLedgersHelp";
import ClimatePlanningHorizonsHelp from "./content/ClimatePlanningHorizons";
import ClimateRelatedImpactsHelp from "./content/ClimateRelatedImpactsHelp";
import ClimateRelatedOpportunitiesHelp from "./content/ClimateRelatedOpportunitiesHelp";
import ClimateRelatedRisksHelp from "./content/ClimateRelatedRisksHelp";
import DisclosureBuilderHelp from "./content/DisclosureBuilderHelp";
import EmissionsNarrativeHelp from "./content/EmissionsNarrativeHelp";
import GovernanceBodyHelp from "./content/GovernanceBodyHelp";
import GovernanceDataSetHelp from "../shared/content/GovernanceDataSetHelp";
import ManagementEngagementProcessHelp from "./content/ManagementEngagementProcessHelp";
import ManagementOversightProcessHelp from "./content/ManagementOversightProcessHelp";
import MetricsTargetsGovernanceProcessHelp from "./content/MetricsTargetsGovernanceProcessHelp";
import GovernanceOversightActivitiesHelp from "./content/GovernanceOversightActivitiesHelp";
import ImpactRecordsHelp from "./content/ImpactRecordsHelp.jsx";
import InformGovernanceProcessHelp from "./content/InformGovernanceProcessHelp";
import ManagementAccountabilitiesHelp from "./content/ManagementAccountabilitiesHelp";
import MetricsTargetsBaseHelp from "./content/MetricsTargetsBaseHelp";
import PlanningHorizonsHelp from "./content/PlanningHorizonsHelp";
import ReportingEntityHelp from "../shared/content/ReportingEntityHelp";
import ReportingPeriodHelp from "./content/ReportingPeriodHelp";
import RiskManagementProcessesBaseHelp from "./content/RiskManagementProcessesBaseHelp";
import ScenarioAnalysisHelp from "./content/ScenarioAnalysisHelp";
import ScenarioHelp from "./content/ScenarioHelp";
import StrategyDataSetHelp from "../shared/content/StrategyDataSetHelp";
import StrategyDevelopmentProcessHelp from "./content/StrategyDevelopmentProcessHelp";
import TargetsHelp from "./content/TargetsHelp";

const uiextensions: UISchema = {
  wrappedSchema: schema,
  enums: {
    AdoptionProvisionEnum: { displayColor: "rgba(2, 73, 87, 0.8)" },
    AmountPercentEnum: { displayColor: "rgba(128, 108, 59, 0.8)" },
    ClimatePlanningHorizonsEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    CountriesEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    CurrencyEnum: { displayColor: "rgb(218, 108, 85,0.8)" },
    DisclosureStatusEnum: { displayColor: "rgba(60, 134, 108, 0.8)" },
    EmissionCategoryEnum: { displayColor: "rgba(49, 66, 111)" },
    EmissionsConsolidationApproachEnum: { displayColor: "rgba(175, 4, 4, 0.64)" },
    EmissionScopeEnum: { displayColor: "rgba(187, 104, 95)" },
    EmissionUnitEnum: { displayColor: "rgba(49, 66, 111)" },
    FinancialImpactCategoryEnum: { displayColor: "rgba(218, 108, 85,0.8)" },
    InitiativeStatusEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    LikelihoodofOccurringEnum: { displayColor: "rgba(49, 66, 111)" },
    OffsetSchemeTypeEnum: { displayColor: "rgba(49, 66, 111)" },
    OpportunityEnum: { displayColor: "rgba(2, 73, 87, 0.8)" },
    OrgidTypeEnum: { displayColor: "rgba(49, 66, 111, 0.8)" },
    PrimaryPotentialFinancialImpactEnum: {
      displayColor: "rgba(235, 87, 87, 0.78)"
    },
    PriorityEnum: { displayColor: "rgba(175, 4, 4, 0.64)" },
    ProcessFrequencyEnum: { displayColor: "rgba(49, 66, 111)" },
    ProcessTypeEnum: { displayColor: "rgba(2, 73, 87, 0.8)" },
    PublishedToEnum: { displayColor: "rgba(49, 66, 111)" },
    OffsetRelianceEnum: { displayColor: "rgba(218, 108, 85,0.8)" },
    RiskMgmtProcessTypeEnum: { displayColor: "rgba(2, 73, 87, 0.8)" },
    RiskType: { displayColor: "rgba(175, 4, 4, 0.64)" },
    ScenarioAnalysisCoverageEnum: { displayColor: "rgba(49, 66, 111)" },
    ScenarioAnalysisMethodEnum: { displayColor: "rgba(96, 119, 80, 1)" },
    ScenarioTemperatureAlignmentEnum: { displayColor: "rgba(100, 155, 133, 1)" },
    TargetTypeEnum: { displayColor: "rgba(2, 73, 87, 0.8)" },
    ValueChainEnum: { displayColor: "rgba(128, 108, 59, 0.8)" }
  },
  models: {
    AdoptionProvision: {
      showEvidence: true,
      noClone: true,
      fields: {
        AdoptionProvisionType: { displayName: "Provision Type" },
        AdoptionProvision3Description: { displayName: "Provision 3 Description" },
        AdoptionProvision4Description: { displayName: "Provision 4 Description" }
      }
    },
    Asset: {
      fields: {
        AssetComponent: { hideInTable: true },
        AssetName: { isUnique: true },
        AssetID: { publishSchema: AsOptional },
        AssetClass: { publishSchema: AsOptional },

        CapitalisationDate: { hideInTable: true },
        Condition: { hideInTable: true, publishSchema: AsOptional },
        CurrentValue: { hideInTable: true },
        Lifespan: { hideInTable: true },
        ValueAtAcquisition: { hideInTable: true },
        ValueClimateAffectedType: { hideInTable: true },
        Location: {
          description: "Optional: link to the location of the asset.",
          displayName: "Location of Asset"
        }
      }
    },
    AssetRegister: {
      helpContent: AssetRegisterHelp,
      showEvidence: true,
      fields: {
        RegisterName: { isUnique: true }
      }
    },
    AvoidedEmissionAccountEntry: {
      showEvidence: true,
      fields: {
        AvoidedEmissionTransactionDataSource: {
          displayName: "Data Source"
        },
        AvoidedEmissionTransactionDate: {
          displayName: "Date"
        },
        AvoidedEmissionTransactionDescription: {
          displayName: "Description"
        },
        AvoidedEmissionTransactionName: {
          displayName: "Name",
          isUnique: true
        },
        GrossAmountCO2Avoided: {
          displayName: "Tonnes CO2 Avoided"
        }
      }
    },
    BiogenicAccountEntry: {
      showEvidence: true,
      fields: {
        BiogenicTransactionDataSource: {
          displayName: "Data Source"
        },
        BiogenicTransactionDescription: {
          displayName: "Description"
        },
        BiogenicTransactionName: {
          displayName: "Name",
          isUnique: true
        },
        GrossAmountCO2Sequestered: {
          displayName: "CO2 Sequestered"
        },
        SequestrationDate: {
          displayName: "Date"
        }
      }
    },
    BusinessActivity: {
      showEvidence: true,
      fields: {
        ActivityName: {
          isUnique: true,
          order: 1
        },
        ActivityID: {
          displayName: "ID",
          order: 2
        },
        Description: {
          displayName: "Description",
          order: 3
        },
        Locations: {
          description: "Optional: link to one or more locations where the activity occurs.",
          displayName: "Location of Activities"
        }
      }
    },
    BusinessPlanningHorizon: {
      showEvidence: true,
      fields: {
        BusinessPlanningHorizonName: {
          displayName: "Horizon Name",
          isUnique: true
        },
        Description: {
          displayName: "Description"
        }
      }
    },
    CapitalDeploymentFundingProcess: {
      showEvidence: true,
      fields: {
        ProcessName: { displayName: "Process Name", isUnique: true },
        Description: { displayName: "Description" },
        StartofProcess: { displayName: "Start of Process" },
        EndofProcess: { displayName: "End of Process" }
      }
    },
    CarbonAccount: {
      discriminatorField: "CarbonAccountSelectType",
      fields: {
        AccountName: { displayName: "Name", isUnique: true }
      },
      subtypes: {
        AvoidedEmissionAccount: {
          description: "Account to record planned and achieved emissions that have been avoided (i.e. not emitted).",
          displayName: "Avoided Emission Account",
          fields: {
            AccountName: { displayName: "Avoided Account Name" },
            Description: { displayName: "Avoided Account Description" }
          },
          includeRelations: /^AvoidedEmissionAccountEntries$/,
          publishSchema: WithFields("CarbonAccountSelectType", "AccountName", "Description", "AvoidedEmissionAccountEntries")
        },
        BiogenicAccount: {
          description: "Account to record planned and achieved emissions reductions using biogenic sequestration.",
          displayName: "Biogenic Sequestration Account",
          fields: {
            AccountName: { displayName: "Biogenic Account Name" },
            Description: { displayName: "Biogenic Account Description" }
          },
          includeRelations: /^BiogenicAccountEntries$/,
          publishSchema: WithFields("CarbonAccountSelectType", "AccountName", "Description", "BiogenicAccountEntries")
        },
        EmissionAccount: {
          description: "Account to record greenhouse gas emissions.",
          displayName: "Emission Account",
          fields: {
            AccountName: { displayName: "Emission Account Name" },
            Description: { displayName: "Emission Account Description" }
          },
          includeRelations: /^EmissionAccountEntries$/,
          publishSchema: WithFields("CarbonAccountSelectType", "AccountName", "Description", "EmissionAccountEntries")
        },
        OffsetAccount: {
          description: "Account to record emission offset purchases / carbon credits.",
          displayName: "Carbon Credits / Offsets Account",
          fields: {
            AccountName: { displayName: "Offset Account Name" },
            Description: { displayName: "Offset Account Description" }
          },
          includeRelations: /^OffsetAccountEntries$/,
          publishSchema: WithFields("CarbonAccountSelectType", "AccountName", "Description", "OffsetAccountEntries")
        },
        ReductionAccount: {
          description: "Account to record initiatives to reduce emissions (scope 1-3).",
          displayName: "Reduction Account",
          fields: {
            AccountName: { displayName: "Reduction Account Name" },
            Description: { displayName: "Reduction Account Description" }
          },
          includeRelations: /^ReductionAccountEntries$/,
          publishSchema: WithFields("CarbonAccountSelectType", "AccountName", "Description", "ReductionAccountEntries")
        }
      }
    },
    CarbonAccountingLedgers: {
      helpContent: CarbonAccountingLedgersHelp,
      fields: {
        CarbonAccountingLedgerName: { displayName: "Ledger Name", isUnique: true },
        CarbonAccounts: {
          publishSchema: AsOptional,
          targetSubtypes: schema.enums.CarbonAccountSelectTypeEnum.values
        }
      }
    },
    ClimatePlanningHorizon: {
      showEvidence: true,
      helpContent: ClimatePlanningHorizonsHelp,
      fields: {
        BusinessPlanningHorizons: {
          displayName: "Strategic Planning Horizons",
          description: "Enter/select record(s) to describe your business planning processes.",
          standardReference: "NZCS1 paragraph 14a."
        },
        CapitalDeploymentFundingProcesses: {
          description: "Enter/select record(s) to describe your capital deployment planning processes.",
          displayName: "Capital Deployment Planning Processes",
          standardReference: "NZCS1 paragraph 14a."
        },
        HorizonPeriodType: {
          displayName: "Horizon Period",
          isUnique: true
        }
      }
    },
    Disclosure: {
      helpContent: DisclosureBuilderHelp,
      fields: {
        PublishedTo: {
          valueOnClone: []
        },
        Status: {
          valueOnClone: "Draft"
        },
        CarbonAccountingLedgers: {
          description: "The set of Carbon Accounting Ledgers that contain data that will be analysed in the Stakeholder Portal.",
          order: 8,
          publishSchema: AsOptional,
          hideInRelations: true
        },
        DisclosurePeriod: {
          displayName: "Disclosure Reporting Period",
          description: "The reporting period for this Disclosure.",
          isRequired: true,
          order: 2,
          standardReference: "NZCS1 Appendix B1, NZCS3 paragraphs 24-26."
        },
        GovernanceBaseData: {
          description: "The set of Governance records for this Disclosure.",
          displayName: "Governance Records",
          isRequired: true,
          order: 3,
          standardReference: "NZCS1 paragraphs 6-9."
        },
        MetricsAndTargetsRecords: {
          description: "The set of Metrics and Targets records for this Disclosure.",
          displayName: "Metrics and Targets Records",
          isRequired: true,
          order: 6,
          standardReference: "NZCS1 paragraphs 20-24."
        },
        Organisation: {
          description: "Information about the organisation making this Disclosure.",
          displayName: "Reporting Entity",
          isRequired: true,
          order: 1,
          standardReference: "NZCS3 paragraphs 21, 23."
        },
        RiskManagementBaseDataSet: {
          description: "The set of Risk Management process records and how they relate to this Disclosure.",
          displayName: "Risk Management Records",
          isRequired: true,
          order: 5,
          standardReference: "NZCS1 paragraphs 17-19."
        },
        StrategyBaseData: {
          description: "The set of Strategy records for this Disclosure.",
          displayName: "Strategy Records",
          isRequired: true,
          order: 4,
          standardReference: "NZCS1 paragraphs 10-16."
        },
        SupplementalDisclosureData: {
          displayName: "Supplemental Disclosure Records",
          description: "The set of records containing supplementary data for this Disclosure.",
          standardReference: "NZCS3 paragraphs 43-46."
        },
        updatedAt: {
          displayName: "Date",
          hideInTable: false
        }
      }
    },
    DisclosurePeriod: {
      helpContent: ReportingPeriodHelp,
      fields: {
        DisclosurePeriodName: {
          isUnique: true
        },
        DisclosurePeriodYear: {
          validator: Year
        },
        Notes: {
          hideInTable: true,
          publishSchema: AsOptional
        },
        ReportingPeriodChange: {
          publishSchema: AsOptional
        }
      }
    },
    EmissionAccountEntry: {
      showEvidence: true,
      fields: {
        DataSource: {
          displayName: "Data Source"
        },
        EmissionCategory: {
          displayName: "Category"
        },
        EmissionConversionFactor: {
          displayName: "Conversion Factor",
          hideInTable: true
        },
        EmissionDate: {
          displayName: "Date",
          hideInTable: true
        },
        EmissionDateRangeEnd: {
          displayName: "End",
          hideInTable: true
        },
        EmissionDateRangeStart: {
          displayName: "Start",
          hideInTable: true
        },
        EmissionDescription: {
          displayName: "Description"
        },
        EmissionGroup: {
          displayName: "Group",
          hideInTable: true
        },
        EmissionScope: {
          displayName: "Scope"
        },
        EmissionUnit: {
          displayName: "Unit",
          hideInTable: true
        },
        EmissionUsageValue: {
          displayName: "Value"
        },
        GrossEmission: {
          displayName: "Gross Emission"
        }
      },
      formOptions: {
        Create: { formOverride: EmissionAccountEntryWizard }
      }
    },
    EmissionReductionPathway: {
      showEvidence: true,
      fields: {
        PathwayName: {
          displayName: "Pathway Name",
          isUnique: true
        },
        ScopeofOperations: { displayName: "Scope of Operations" },
        PolicyAssumptions: {
          displayName: "Policy Assumptions",
          hideInTable: false
        },
        SocioeconomicAssumptions: {
          displayName: "Socioeconomic Assumptions",
          hideInTable: false
        },
        MacroeconomicTrends: {
          displayName: "Macroeconomic Trends",
          hideInTable: false
        },
        EnergyPathways: { displayName: "Energy Pathways", hideInTable: false },
        CarbonSequestration: {
          displayName: "Carbon Sequestration",
          hideInTable: true
        },
        TechnologyAssumptions: {
          displayName: "Technology Assumptions",
          hideInTable: true
        }
      }
    },
    EmissionsExclusions: {
      showEvidence: true,
      fields: {
        ExcludedSource: {
          isUnique: true
        },
        JustificationForExclusion: {
          displayName: "Justification for Exclusion"
        }
      }
    },
    EmissionsIntensityMeasures: {
      showEvidence: true,
      fields: {
        EmissionsIntensityName: {
          displayName: "Record Name",
          isUnique: true
        },
        metricsdatasets: {
          hideInRelations: true
        }
      }
    },
    EmissionsNarrative: {
      helpContent: EmissionsNarrativeHelp,
      showEvidence: true,
      fields: {
        DataSetName: {
          displayName: "Name"
        },
        EmissionsExclusions: {
          description:
            "Enter/select record(s) to describe exclusions of emissions sources and a justification for why they were excluded.",
          standardReference: "NZCS1 paragraph 24d.",
          publishSchema: AsOptional
        }
      }
    },
    GhgEmissionUncertainty: {
      showEvidence: true,
      fields: {
        UncertaintyName: {
          displayName: "Record Name",
          isUnique: true
        },
        AreaofUncertainty: {
          displayName: "Area of Uncertainty"
        },
        UncertaintyDescription: {
          displayName: "Description of Uncertainty"
        },
        UncertaintyEffects: {
          displayName: "Effects of Uncertainty"
        }
      }
    },
    GhgMethodAssumption: {
      showEvidence: true,
      fields: {
        GHGMethodAssumptionName: {
          displayName: "Record Name",
          isUnique: true
        },
        GHGAssumptionDescription: {
          displayName: "Assumption Description"
        },
        GHGMethodAssumptionLimitation: {
          displayName: "Assumption Limitation"
        },
        GHGMethodChoiceRationale: {
          displayName: "Rationale for Choice of Method"
        },
        GHGMethodDescription: {
          displayName: "Method Description"
        }
      }
    },
    GovernanceBaseDataSet: {
      helpContent: GovernanceDataSetHelp,
      fields: {
        DataSetName: { displayName: "Name" },
        GovernanceData: {
          displayName: "Climate Governance Body",
          targetSubtypes: {
            GovernanceBody: {
              description: "Describe the governance body responsible for oversight of climate-related risks and opportunities.",
              displayName: "Governance Body",
              standardReference: "NZCS1 paragraph 7a."
            },
            GovernanceOversightActivities: {
              description:
                "Enter/select record(s) to describe the governance body’s oversight of climate-related risks and opportunities.",
              displayName: "Governance Oversight Activities Records",
              standardReference: "NZCS1 paragraph 7b, 8a-8d."
            },
            ManagementAccountabilities: {
              description:
                "Enter/select record(s) to describe management’s role in assessing and managing climate-related risks and opportunities.",
              displayName: "Management Accountability Records",
              standardReference: "NZCS1 paragraph 7c, 9a-c; NZCS3 paragraph 51 b ii."
            }
          }
        }
      }
    },
    GovernanceData: {
      discriminatorField: "GovernanceDataType",
      fields: {
        GovernanceProcesses: {
          targetSubtypes: {
            GovernanceBodyEngagement: {
              description:
                "Enter/select record(s) to describe the processes by which management-level positions or committees engage with the governance body.",
              displayName: "Governance Body Engagement Processes",
              standardReference: "NZCS1 paragraph 9a."
            },
            GovernanceMetricsTargets: {
              description:
                "Enter/select record(s) to describe the processes by which the governance body sets, monitors progress against, and oversees achievement of metrics and targets for managing climate-related risks and opportunities.",
              displayName: "Governance of Metrics Targets Processes",
              standardReference: "NZCS1 paragraph 8d."
            },
            InformGovernance: {
              description:
                "Enter/select record(s) to describe the processes by which the governance body is informed about climate-related risks and opportunities.",
              displayName: "Inform Governance Processes",
              standardReference: "NZCS1 paragraph 8a."
            },
            ManagementOversight: {
              description:
                "Enter/select record(s) to describe the processes by which  management is informed about, makes decisions on, and monitors, climate-related risks and opportunities.",
              displayName: "Management Oversight Processes",
              standardReference: "NZCS1 paragraph 9c."
            },
            StrategyDevelopment: {
              description:
                "Enter/select record(s) to describe the processes by which the governance body considers climate-related risks and opportunities when developing and overseeing implementation of strategy.",
              displayName: "Strategy Development Processes",
              standardReference: "NZCS1 paragraph 8c."
            }
          }
        }
      },
      showEvidence: true,
      subtypes: {
        GovernanceBody: {
          helpContent: GovernanceBodyHelp,
          displayName: "Governance Body",
          fields: {
            GovernanceDataDescription: {
              displayName: "Governance Body Description"
            },
            GovernanceDataName: {
              displayName: "Governance Body Name"
            },
            ManagementOrgStructure: {
              hideInTable: true
            },
            ManagementPositionAssignment: {
              hideInTable: true
            }
          },
          includeRelations: /^$/,
          publishSchema: WithFields("GovernanceDataType", "GovernanceDataName", "GovernanceDataDescription")
        },
        GovernanceOversightActivities: {
          helpContent: GovernanceOversightActivitiesHelp,
          displayName: "Governance Oversight Activities",
          fields: {
            GovernanceDataDescription: {
              displayName: "Governance Oversight Activities Description"
            },
            GovernanceDataName: {
              displayName: "Governance Oversight Activities Name"
            },
            ManagementOrgStructure: {
              hideInTable: true
            },
            ManagementPositionAssignment: {
              hideInTable: true
            }
          },
          includeRelations: /^GovernanceProcesses:(InformGovernance|StrategyDevelopment|GovernanceMetricsTargets)$/,
          publishSchema: WithFields("GovernanceDataType", "GovernanceDataName", "GovernanceDataDescription", "GovernanceProcesses")
        },
        ManagementAccountabilities: {
          helpContent: ManagementAccountabilitiesHelp,
          fields: {
            GovernanceDataDescription: {
              displayName: "Management Accountabilities Description"
            },
            GovernanceDataName: {
              displayName: "Management Accountabilities Name"
            }
          },
          includeRelations: /^GovernanceProcesses:(GovernanceBodyEngagement|ManagementOversight)$/,
          publishSchema: WithFields(
            "GovernanceDataType",
            "GovernanceDataName",
            "GovernanceDataDescription",
            "GovernanceProcesses",
            "ManagementPositionAssignment",
            "ManagementOrgStructure"
          )
        }
      }
    },
    GovernanceProcesses: {
      discriminatorField: "GovernanceProcessType",
      showEvidence: true,
      fields: {
        GovernanceProcessName: {
          isUnique: true
        }
      },
      subtypes: {
        GovernanceBodyEngagement: {
          helpContent: ManagementEngagementProcessHelp,
          fields: {
            GovernanceProcessName: {
              displayName: "Governance Body Engagement Process Name"
            },
            PositionorCommittee: {
              displayName: "Position or Committee"
            },
            ProcessType: {
              hideInTable: true
            },
            UseRemunerationPerformanceMetrics: {
              hideInTable: true
            }
          },
          publishSchema: WithFields("GovernanceProcessName", "PositionorCommittee")
        },
        GovernanceMetricsTargets: {
          helpContent: MetricsTargetsGovernanceProcessHelp,
          fields: {
            GovernanceProcessName: {
              displayName: "Governance of Metrics & Targets Process Name"
            },
            PositionorCommittee: {
              hideInTable: true
            },
            ProcessType: {
              hideInTable: true
            }
          },
          publishSchema: WithFields("GovernanceProcessName")
        },
        InformGovernance: {
          helpContent: InformGovernanceProcessHelp,
          fields: {
            GovernanceProcessName: {
              displayName: "Inform Governance Process Name"
            },
            PositionorCommittee: {
              hideInTable: true
            },
            ProcessType: {
              hideInTable: true
            },
            UseRemunerationPerformanceMetrics: {
              hideInTable: true
            }
          },
          publishSchema: WithFields("GovernanceProcessName")
        },
        ManagementOversight: {
          helpContent: ManagementOversightProcessHelp,
          fields: {
            GovernanceProcessName: {
              displayName: "Oversight Process Name"
            },
            PositionorCommittee: {
              hideInTable: true
            },
            UseRemunerationPerformanceMetrics: {
              hideInTable: true
            }
          },
          publishSchema: WithFields("GovernanceProcessName")
        },
        StrategyDevelopment: {
          helpContent: StrategyDevelopmentProcessHelp,
          fields: {
            GovernanceProcessName: {
              displayName: "Strategy Development Process Name"
            },
            PositionorCommittee: {
              hideInTable: true
            },
            ProcessType: {
              hideInTable: true
            },
            UseRemunerationPerformanceMetrics: {
              hideInTable: true
            }
          }
        }
      }
    },
    Impacts: {
      helpContent: ClimateRelatedImpactsHelp,
      showEvidence: true,
      description: "Impacts from Climate-induced Risk",
      fields: {
        Name: { displayName: "", isUnique: true },
        ImpactType: { displayName: "Impact Type", hideInTable: false },
        ImpactDescription: { displayName: "Description", hideInTable: true },
        FinancialImpactCategory: {
          displayName: "Financial Impact Category",
          hideInTable: false
        },
        PrimaryPotentialFinancialImpact: {
          displayName: "Primary Potential Financial Impact",
          hideInTable: true
        },
        CurrentFinancialImpactLower: {
          displayName: "Current Financial Impact Lower",
          hideInTable: true
        },
        CurrentFinancialImpactHigher: {
          displayName: "Current Financial Impact Higher",
          hideInTable: true
        },
        TimeHorizon: { displayName: "Time Horizon", hideInTable: false },
        AnticipatedImpactDescription: {
          displayName: "Anticipated Impact Description",
          hideInTable: true
        },
        AnticipatedFinancialImpactLower: {
          displayName: "Anticipated Financial Impact Lower",
          hideInTable: true
        },
        AnticipatedFinancialImpactHigher: {
          displayName: "Anticipated Financial Impact Higher",
          hideInTable: true
        },
        ImpactGroup: { displayName: "Impact Group", hideInTable: true, publishSchema: AsOptional },
        Opportunities: {
          description: "Link to one or more Opportunities that give rise to this Impact."
        },
        Risks: {
          description: "Link to one or more Risks that give rise to this Impact."
        }
      }
    },
    ImpactsDataSet: {
      helpContent: ImpactRecordsHelp,
      showEvidence: true,
      fields: {
        DataSetName: { displayName: "Name", isUnique: true },
        Description: { displayName: "Description" },
        UnabletoDiscloseImpact: { displayName: "Unable to Disclose Impact", hideInTable: true, publishSchema: AsOptional },
        Impacts: {
          description:
            "Enter/select record(s) to describe the current and anticipated climate-related impacts you have identified.",
          standardReference: "NZCS1 paragraph 12a-b; NZCS1 paragraph 15a-c.",
          publishSchema: AsOptional
        }
      },
      publishSchema: (s) =>
        s.ifThen(S.object().prop("Impacts", S.array().maxItems(0)), S.object().prop("UnabletoDiscloseImpact", RequiredString))
    },
    IndustryMetric: {
      showEvidence: true,
      formOptions: {
        Create: {
          formWrappers: withIndustryMetricsWizard
        }
      },
      fields: {
        Metric: { isUnique: true }
      }
    },
    Kpi: {
      showEvidence: true,
      fields: {
        KpiName: { displayName: "Name" },
        KpiDescription: { displayName: "Description" },
        KpiValue: { displayName: "Value" },
        KpiUnitofMeasure: { displayName: "Unit of Measure" }
      }
    },
    Location: {
      fields: {
        LocationName: { displayName: "Name", isUnique: true },
        LocationID: { displayName: "ID", hideInTable: false, publishSchema: AsOptional },
        FacilityOrSite: { displayName: "Facility or Site", hideInTable: true },
        Latitude: { displayName: "Latitude", hideInTable: true, publishSchema: AsOptional, validator: Latitude },
        Longitude: { displayName: "Longitude", hideInTable: true, publishSchema: AsOptional, validator: Longitude },
        AddressLine1: { displayName: "Address Line 1", hideInTable: false },
        AddressLine2: { displayName: "Address Line 2", hideInTable: false },
        AddressLine3: { displayName: "Address Line 3", hideInTable: false },
        PostCode: { displayName: "Post Code", hideInTable: false },
        City: { displayName: "City", hideInTable: false },
        Country: { displayName: "Country", hideInTable: false }
      }
    },
    MetricsAndTargetsRecords: {
      helpContent: MetricsTargetsBaseHelp,
      fields: {
        DataSetName: { displayName: "Name", isUnique: true },
        MetricsDataSet: {
          description:
            "Enter/select record(s) to describe your climate-related metrics used to measure and manage climate-related risks and opportunities.",
          displayName: "Climate-related Metrics Records",
          order: 1,
          standardReference: "NZCS1 paragraph 21a, 22a-h, NZCS3 paragraph 40,42."
        },
        TargetsDataSet: {
          description:
            "Enter/select record(s) to describe targets used to manage climate-related risks and opportunities, and performance against those targets.",
          displayName: "Climate-related Targets Records",
          order: 4,
          standardReference: "NZCS1 paragraph 21d, 23a-e."
        },
        IndustryMetric: {
          description:
            "Enter/select record(s) to describe industry-based metrics relevant to your industry or business model used to measure and manage climate-related risks and opportunities.",
          displayName: "Industry-based Metrics",
          standardReference: "NZCS1 paragraph 21b.",
          order: 2
        },
        Kpis: {
          description:
            "Enter/select record(s) to describe any other key performance indicators used to measure and manage climate-related risks and opportunities",
          displayName: "Key Performance Indicators",
          standardReference: "NZCS1 paragraph 21c.",
          order: 3,
          publishSchema: AsOptional
        },
        EmissionsNarrative: {
          description: "Enter/select record(s) to describe additional information about your GHG emissions.",
          displayName: "Greenhouse Gas Emissions Narrative",
          order: 4,
          standardReference: "NZCS1 paragraph 24a-d."
        }
      }
    },
    MetricsDataSet: {
      showEvidence: true,
      fields: {
        DataSetName: { displayName: "Record Name", isUnique: true },
        Scope1Emissions: { displayName: "Scope 1", hideInTable: false },
        Scope2Emissions: { displayName: "Scope 2", hideInTable: false },
        Scope3Emissions: { displayName: "Scope 3", hideInTable: false },
        TransitionRisksType: {
          displayName: "Transition Risks Type",
          hideInTable: true
        },
        TransitionRisksValue: {
          displayName: "Transition Risks Value",
          hideInTable: true
        },
        PhysicalRisksType: {
          displayName: "Physical Risks Type",
          hideInTable: true
        },
        PhysicalRisksValue: {
          displayName: "Physical Risks Value",
          hideInTable: true
        },
        OpportunitiesType: {
          displayName: "OpportunitiesType",
          hideInTable: true
        },
        OpportunitiesValue: {
          displayName: "Opportunities Value",
          hideInTable: true
        },
        CapitalDeploymentValue: {
          displayName: "Capital Deployment Value",
          hideInTable: false
        },
        InternalEmissionsPrice: {
          displayName: "Internal Emissions Price",
          hideInTable: false
        },
        RemunerationType: { displayName: "Remuneration Type", hideInTable: true },
        RemunerationValue: {
          displayName: "Remuneration Value",
          hideInTable: true
        },
        RemunerationAlternative: {
          displayName: "Remuneration Alternative",
          hideInTable: true
        },
        TotalEmissions: { displayName: "Total Emissions", hideInTable: false },
        MetricTrendAnalysis: {
          displayName: "Metric Trend Analysis",
          hideInTable: true
        },
        Scope4Emissions: { displayName: "Scope 4 Emissions", hideInTable: true, publishSchema: AsOptional },
        MetricGroup: { displayName: "Metric Group", hideInTable: true, publishSchema: AsOptional },
        EmissionsIntensityMeasures: {
          description: "Enter/select record(s) to describe your emissions intensity metrics.",
          order: 1,
          standardReference: "NZCS1 paragraph 22b."
        },
        Assets: {
          description: "Enter/select record(s) to describe any assets subject to your climate related risks and opportunities.",
          order: 2,
          standardReference: "NZCS1 paragraph 22c-e.",
          publishSchema: AsOptional
        },
        BusinessActivities: {
          description:
            "Enter/select record(s) to describe any business activities subject to your climate related risks and opportunities.",
          order: 3,
          standardReference: "NZCS1 paragraph 22c-e.",
          publishSchema: AsOptional
        },
        GHGMethodAssumptions: {
          description: "Enter/select record(s) to describe assumptions and limitations made in calculating your GHG emissions.",
          order: 4,
          standardReference: "NZCS3 paragraph 52."
        },
        GHGEmissionUncertainties: {
          description: "Enter/select record(s) to describe uncertainties that are relevant to quantifying your GHG emissions.",
          order: 5,
          standardReference: "NZCS3 paragraph 53."
        },
        ComparativeMetrics: {
          description:
            "Enter/select record(s) to provide comparative information for this metric for the immediately preceding two reporting period‍s.",
          order: 6,
          standardReference: "NZCS3 paragraph 40-42.",
          maxItems: 2
        }
      }
    },
    OffsetAccountEntry: {
      showEvidence: true,
      fields: {
        OffsetCertificationSchemeName: {
          displayName: "Scheme Name"
        },
        OffsetDataSource: {
          displayName: "Data Source",
          hideInTable: true
        },
        OffsetDescription: {
          displayName: "Description"
        },
        OffsetName: {
          displayName: "Name",
          isUnique: true
        },
        OffsetPurchaseDate: {
          displayName: "Purchase Date",
          hideInTable: true
        },
        OffsetValidityEndDate: {
          displayName: "End Date",
          hideInTable: true
        },
        OffsetValidityStartDate: {
          displayName: "Start Date",
          hideInTable: true
        }
      }
    },
    Opportunities: {
      helpContent: ClimateRelatedOpportunitiesHelp,
      showEvidence: true,
      fields: {
        OpportunityName: { displayName: "Opportunity Name", isUnique: true },
        Description: { displayName: "Description" },
        OpportunityType: { displayName: "Opportunity Type" },
        ArisesinValueChain: {
          displayName: "Arises in Value Chain",
          hideInTable: true
        },
        Likelihood: { displayName: "Likelihood" },
        Priority: { displayName: "Priority" },
        TimeHorizon: { displayName: "Time Horizon" },
        PrimaryFinancialImpact: {
          displayName: "Primary Financial Impact",
          hideInTable: true
        }
      }
    },
    OpportunityDataSet: {
      showEvidence: true,
      fields: {
        DataSetName: { displayName: "Name", isUnique: true },
        InputIntoCapitalDeploymentAndFunding: {
          displayName: "Capital Deployment & Funding Input"
        },
        Opportunities: {
          description: "Enter/select record(s) to describe the climate-related opportunities you have identified.",
          standardReference: "NZCS1 paragraph 14 b-c."
        }
      }
    },
    Organisation: {
      helpContent: ReportingEntityHelp,
      fields: {
        OrganisationName: { displayName: "Name", isUnique: true },
        Description: { displayName: "Description" },
        PrimaryOperatingCountry: { displayName: "Operating Country" },
        SecondaryOperatingCountry: {
          displayName: "Secondary Operating Country",
          hideInTable: true,
          publishSchema: AsOptional
        },
        Currency: { displayName: "Currency", showInTable: true },
        OrganisationIdentifierType: {
          displayName: "Identifier Type",
          hideInTable: true
        },
        OrganisationIdentifier: { displayName: "Identifier", hideInTable: true },
        AggregateWithParent: {
          displayName: "Aggregate With Parent",
          hideInTable: true,
          publishSchema: AsOptional
        },
        Location: { displayName: "Location", hideInTable: true }
      }
    },
    PlanningHorizon: {
      helpContent: PlanningHorizonsHelp,
      showEvidence: true,
      fields: {
        DataSetName: { displayName: "Horizon Name", isUnique: true },
        ClimatePlanningHorizons: {
          description:
            "Enter/select record(s) to describe the short, medium and long term time horizons used for planning climate strategy, and their links to strategic planning horizons and capital deployment plans.",
          standardReference: "NZCS1 paragraph 14a."
        }
      }
    },
    ReductionAccountEntry: {
      description: "Enter/select record(s) to describe a transaction that is intended to reduce emissions.",
      showEvidence: true,
      fields: {
        ReductionInitiativeName: { displayName: "Initiative Name", isUnique: true },
        ReductionInitiativeStatus: { displayName: "Status", hideInTable: false },
        ReductionInitiativeDescription: {
          displayName: "Description",
          hideInTable: false
        },
        EstimatedAnnualCO2Saving: {
          displayName: "CO2 Saving",
          hideInTable: false
        },
        InitiativeStartDate: { displayName: "Start Date", hideInTable: false },
        InitiativeEndDate: { displayName: "End Date", hideInTable: false },
        EmissionReductionUnit: {
          displayName: "Reduction Unit",
          hideInTable: true
        },
        EmissionReductionValue: {
          displayName: "Reduction Value",
          hideInTable: true
        },
        CO2SavingScope: { displayName: "Saving Scope", hideInTable: false },
        AnnualMonetarySaving: {
          displayName: "Annual Monetary Saving",
          hideInTable: true,
          publishSchema: AsOptional
        },
        PaybackPeriod: { displayName: "Payback Period", hideInTable: true },
        RequiredInvestment: {
          displayName: "Required Investment",
          hideInTable: true
        },
        EstimatedInitiativeLifetime: {
          displayName: "Lifetime",
          hideInTable: true
        },
        GrossEmissionReductionAchieved: {
          displayName: "Gross Reduction Achieved",
          hideInTable: false
        },
        DateAchieved: { displayName: "Date Achieved", hideInTable: true, publishSchema: AsOptional }
      },
      publishSchema: (s) =>
        s.ifThen(
          S.object().prop("ReductionInitiativeStatus", S.const(InitiativeStatusEnum.IMPLEMENTED)),
          S.object().prop("DateAchieved", RequiredAWSDate)
        )
    },
    RestatementofComparatives: {
      showEvidence: true,
      fields: {
        ComparativeStatementName: { displayName: "Name", isUnique: true },
        CorrectedDisclosureElement: { displayName: "Corrected Element" }
      }
    },
    RiskManagementProcesses: {
      showEvidence: true,
      fields: {
        RiskMgmtProcessName: { displayName: "Name", isUnique: true },
        ProcessType: { displayName: "Type" },
        ProcessDescription: { displayName: "Description" },
        IntegrationRiskMgmt: { displayName: "Integration with Risk Management" },
        TimeHorizon: { hideInTable: true },
        ValueChainCoverage: { hideInTable: true },
        ValueChainCoverageExclusions: {
          displayName: "Value Chain Exclusions",
          hideInTable: true
        },
        FrequencyAssessment: { displayName: "Frequency of Assessment" }
      }
    },
    RiskManagementProcessesRecords: {
      showEvidence: true,
      helpContent: RiskManagementProcessesBaseHelp,
      fields: {
        DataSetName: { displayName: "Name" },
        ToolsMethods: { displayName: "Tools & Methods" },
        RelativePrioritisationProcess: { displayName: "Prioritisation Process" },
        RiskManagementProcesses: {
          description:
            "Enter/select record(s) to describe how climate-related risks are identified, assessed, and managed and integrated into existing risk management processes.",
          standardReference: "NZCS1 paragraph 18a, 18b, 19a-e."
        }
      }
    },
    Risks: {
      helpContent: ClimateRelatedRisksHelp,
      showEvidence: true,
      fields: {
        RiskName: { displayName: "Risk Name", isUnique: true },
        Description: { displayName: "Description" },
        RiskType: { displayName: "Risk Type" },
        ArisesinValueChain: {
          displayName: "Arises in Value Chain",
          hideInTable: true
        },
        LikelihoodofOccurring: { displayName: "Likelihood" },
        Priority: { displayName: "Priority" },
        TimeHorizon: { displayName: "Time Horizon" },
        PrimaryFinancialImpact: {
          displayName: "Primary Financial Impact",
          hideInTable: true
        }
      }
    },
    RisksBaseDataSet: {
      showEvidence: true,
      fields: {
        DataSetName: { displayName: "Name", isUnique: true },
        InputIntoCapitalDeploymentAndFunding: {
          displayName: "Capital Deployment & Funding Input"
        },
        Risks: {
          description: "Enter/select record(s) to describe the climate-related risks you have identified.",
          standardReference: "NZCS1 paragraph 14 b-c."
        }
      }
    },
    ScenarioAnalysis: {
      helpContent: ScenarioAnalysisHelp,
      showEvidence: true,
      fields: {
        DataSetName: { displayName: "Record Name", isUnique: true },
        ScenarioAnalysisProcesses: {
          description: "Enter/select record(s) to describe the processes you used to analyse your climate-related scenarios.",
          displayName: "Scenario Analysis Processes",
          standardReference: "NZCS3 paragraph 51 b."
        },
        Scenarios: {
          description:
            "Enter/select record(s) to describe the climate-related scenarios you have used to identify climate-related risks and opportunities.",
          displayName: "Climate-related Scenarios",
          standardReference: "NZCS1 paragraph 13, NZCS3 paragraph 51 a.",
          publishSchema: (s) => (s as ArraySchema).minItems(3)
        }
      }
    },
    ScenarioAnalysisProcess: {
      showEvidence: true,
      fields: {
        ScenarioAnalysisProcessName: {
          displayName: "Process Name",
          isUnique: true
        },
        ScenarioAnalysisProcessDescription: {
          displayName: "Process Description"
        },
        ScenarioAnalysisProcessType: { displayName: "Process Type" },
        GovernanceBodyScenarioAnalysisRole: {
          displayName: "Governance Body Role"
        },
        ManagementScenarioAnalysisRole: { displayName: "Management Role" },
        ModelingUndertakenDescription: {
          displayName: "Modeling Undertaken",
          hideInTable: true
        },
        ReasonSelectionofModel: {
          displayName: "Reason for Model Selected",
          hideInTable: true
        },
        ExternalPartyInvolvement: {
          displayName: "External Party Involvement",
          hideInTable: true
        },
        StakeholderInvolvement: {
          displayName: "Stakeholder Involvement",
          hideInTable: true
        }
      }
    },
    Scenarios: {
      helpContent: ScenarioHelp,
      showEvidence: true,
      fields: {
        ScenarioName: { displayName: "Scenario Name", isUnique: true },
        ScenarioAnalysisMethod: { displayName: "Analysis Method" },
        ScenarioBusinessCoverage: { displayName: "Business Coverage" },
        ScenarioTemperatureAlignment: { displayName: "Temperature Alignment" },
        ScenarioDescription: { displayName: "Description" },
        TimeHorizon: { displayName: "Time Horizon" },
        EndpointType: { displayName: "Endpoint Type", hideInTable: true },
        EndpointValue: { displayName: "Endpoint Value", hideInTable: true },
        RelevanceDescription: { displayName: "Relevance", hideInTable: true },
        ScenarioDataSource: { displayName: "Data Source", hideInTable: true },
        EmissionReductionPathways: {
          displayName: "Emission Reduction Pathways",
          standardReference: "NZCS3 51 a iii",
          description: "Enter/select record(s) to describe the emission reduction pathways for each scenario."
        }
      }
    },
    StrategyBaseData: {
      helpContent: StrategyDataSetHelp,
      fields: {
        DataSetName: { displayName: "Name" },
        ImpactsDataSet: {
          description:
            "Enter/select record(s) to describe the impact of identified climate-related risks and opportunities on your business model and strategy.",
          displayName: "Climate Related Impact Records",
          standardReference: "NZCS1 paragraph 12a-c, 15a-d."
        },
        Opportunities: {
          description: "Enter/select record(s) to describe the climate-related opportunities you have identified.",
          displayName: "Climate Related Opportunity Records",
          standardReference: "NZCS1 paragraph 11c, 14b, 14c."
        },
        PlanningHorizon: {
          displayName: "Planning Horizon Records",
          description: "Enter/select record(s) to describe your business planning horizons and capital deployment plans.",
          standardReference: "NZCS1 paragraph 14a."
        },
        RisksBaseData: {
          description: "Enter/select record(s) to describe the climate-related risks you have identified.",
          displayName: "Climate Related Risk Records",
          standardReference: "NZCS1 paragraph 11c, 14b, 14c."
        },
        ScenarioAnalysis: {
          displayName: "Scenario Analysis Records",
          description: "Enter/select record(s) to describe describe the climate-related scenario analysis you have undertaken.",
          standardReference: "NZCS1 paragraph 11b, 13; NZCS3 paragraph 51a, 51b."
        },
        TransitionPlanningDataSet: {
          description:
            "Enter/select record(s) to describe how you will transition towards a low-emissions, climate-resilient future state.",
          displayName: "Transition Planning",
          standardReference: "NZCS1 paragraph 16a-c."
        }
      }
    },
    SupplementalDisclosureData: {
      fields: {
        SupplementalDataSetName: { displayName: "Name", isUnique: true },
        SupplementalDataSetDescription: { displayName: "Description" },
        RestatementofComparatives: {
          displayName: "Restatement Of Comparatives",
          description:
            "Enter/select record(s) to describe any changes in the content you disclose, or the methods used in your disclosure.",
          standardReference: "NZCS3 paragraphs 43-46",
          publishSchema: AsOptional
        },
        AdoptionProvisions: {
          displayName: "Adoption Provisions",
          description: "Enter/select record(s) to describe adoption provisions that apply to your disclosure.",
          standardReference: "NZCS2 paragraphs 10-22",
          publishSchema: AsOptional
        }
      },
      publishSchema: (s) =>
        s.anyOf([
          S.object().prop("RestatementofComparatives", S.array().minItems(1)),
          S.object().prop("AdoptionProvisions", S.array().minItems(1))
        ])
    },
    Targets: {
      showEvidence: true,
      fields: {
        TargetName: { displayName: "Record Name", isUnique: true },
        TargetDescription: { displayName: "Description" },
        TargetTimeframeStartDate: { displayName: "Start Date", hideInTable: true },
        TargetTimeframeEndDate: { displayName: "End Date", hideInTable: true },
        TargetBaseYear: { displayName: "Base Year" },
        PerformanceAgainstTargetDesc: {
          displayName: "Performance Against Target",
          hideInTable: true,
          publishSchema: AsOptional
        },
        TargetType: { displayName: "Target Type" },
        TargetUnit: { displayName: "Target Unit" },
        TargetValue: { displayName: "Target Value" },
        ContributionToLimitingWarming: {
          displayName: "Contribution to Limiting Warming",
          hideInTable: true
        },
        BasisOfView: { displayName: "Basis of View", hideInTable: true },
        RelianceonOffsetsType: { displayName: "Reliance on Offsets" },
        ExtentRelianceOnOffsets: {
          displayName: "Extent of Reliance",
          hideInTable: true
        },
        OffsetSchemeType: {
          displayName: "Offset Scheme Type",
          hideInTable: true
        },
        OffsetCertificationSchemeName: {
          displayName: "Offset Certification Scheme Name",
          hideInTable: true
        },
        ParentTarget: {
          displayName: "Associated Interim Target",
          description: "Enter/select an Interim Target record associated with this target.",
          publishSchema: AsOptional
        },
        TargetGroup: { displayName: "Target Group", hideInTable: true, publishSchema: AsOptional },
        EmissionsReductionInitiatives: {
          displayName: "Emissions Reduction Initiatives",
          description: "Enter/select record(s) that describe performance against this target.",
          standardReference: "NZCS1 paragraph 23d"
        }
      }
    },
    TargetsDataSet: {
      helpContent: TargetsHelp,
      fields: {
        DataSetName: { displayName: "Name", isUnique: true },
        Description: { displayName: "Description" },
        Targets: {
          description: "Enter/select record(s) to describe your targets used to manage climate-related risks and opportunities.",
          displayName: "Targets",
          standardReference: "NZCS1 paragraph 21d, 23a-e."
        }
      }
    },
    TransitionPlanningDataSet: {
      fields: {
        DataSetName: { displayName: "Name", isUnique: true },
        CurrentVision: { displayName: "Current Business Model", hideInTable: true },
        ProposedVisionChanges: { displayName: "Proposed Business Model Changes", hideInTable: true },
        CurrentMission: { displayName: "Current Strategy", hideInTable: true },
        ProposedMissionChanges: { displayName: "Proposed Strategy Changes", hideInTable: true },
        CapitalFundingAlignment: { displayName: "Capital Funding Alignment" }
      },
      showEvidence: true
    }
  }
};

const withDisplayInfo: UISchemaAggregate = cloneDeepWith(merge({}, schema as Schema, uiextensions, uishared), insertDisplayNames);

console.debug(withDisplayInfo);

export default withDisplayInfo;
