export default function ContentReplace({text, regEx, render}) {
  if (text) {
    const spans = [];
    let match,
      lastIndex = 0;
    while ((match = regEx.exec(text)) !== null) {
      const matchedText = match[0];
      if (match.index > lastIndex)
        spans.push(text.slice(lastIndex, match.index));
      spans.push(render(matchedText));
      lastIndex = regEx.lastIndex;
    }
    spans.push(text.slice(lastIndex));

    return (
      <>
        {spans.map((span, i) => (
          <span key={i}>{span}</span>
        ))}
      </>
    );
  } else return undefined;
}
