import React, {useEffect, useState} from "react";

import {DataStore, Predicates, SortDirection} from "aws-amplify/datastore";
import PropTypes from "prop-types";

import {Flex} from "@aws-amplify/ui-react";

import MultiToken from "../select/MultiToken";
import {uischema} from "../backend";

export default function MultiSelectDataItems({
  label,
  modal,
  model,
  idField = "id",
  schema = uischema().models[model.name],
  valueField = Object.values(uischema().models[model.name].fields).find(
    ({type}) => type === "String"
  )?.name,
  ariaLabel = label,
  onChange,
  selection,
  mapToOption = ({[idField]: id, [valueField]: label}, i) => ({
    id,
    label
  }),
  ...rest
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [dataItems, setDataItems] = useState({});
  function handleSelectionChange(options) {
    setSelectedOptions(options);
    onChange && onChange(options.map((id) => dataItems[id]));
  }
  useEffect(() => {
    const subscription = DataStore.observeQuery(
      model,
      Predicates.ALL,
      valueField && {
        sort: (s) => s[valueField](SortDirection.ASCENDING)
      }
    ).subscribe(({items}) => {
      setDataItems(
        Object.fromEntries(items.map((item) => [item[idField], item]))
      );
      if (typeof selection === "function")
        setSelectedOptions(
          items.filter(selection).map(({[idField]: id}) => id)
        );
      else if (!!selection) {
        const idSet = new Set([selection].flat());
        setSelectedOptions(
          items
            .filter(({[idField]: id}) => idSet.has(id))
            .map(({[idField]: id}) => id)
        );
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [model, idField, valueField, selection]);

  return (
    <Flex direction="column">
      <label>{label}</label>
      <Flex alignItems="flex-start">
        <MultiToken
          aria-label={ariaLabel}
          onChange={handleSelectionChange}
          defaultValue=""
          options={dataItems && Object.values(dataItems).map(mapToOption)}
          edit={modal && modal.Edit}
          value={(dataItems && selectedOptions) || []}
        />
        {modal && <modal.New />}
      </Flex>
    </Flex>
  );
}

MultiSelectDataItems.propTypes = {
  ariaLabel: PropTypes.string,
  idField: PropTypes.string,
  label: PropTypes.any,
  mapToOption: PropTypes.func,
  modal: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      New: PropTypes.any,
      Edit: PropTypes.any
    })
  ]),
  model: PropTypes.func.isRequired,
  onChange: PropTypes.func
};
