import HelpBox from "./HelpBox";

export default function PlanningHorizonsHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Planning Horizons Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Planning Horizons allow your disclosure to link your climate related scenarios into the planning processes and time periods used in your Organisation. This is a key element of planning how you repond to the risks of climate change.
                </p>
                <p>
                    Planning Horizons consists of three elements:
                    <ol>
                        <li>Climate Planning Horizons, the short, medium and long term time horizons used for planning climate strategy.</li>
                        <li>Links to strategic business planning horizons (on the next screen).</li>
                        <li>Links to capital deployment plans (on the next screen).</li>
                    </ol>
                </p>
                <p>
                    ClimateTracker allows you to group these elements in different ways for different disclosures. For example, you may update your business strategic planning horizons in response to climate-related events.
                    To group the Planning Horizons elements you require for this disclosure, in the left hand side, enter a name for the set of Planning Horizons elements that are relevant to this disclosure, then select from existing elements you have created or create and select new elements.
                    This ensures that only the relevant elements will be used in the disclosure you are currently building.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
