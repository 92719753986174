import { createModal } from "./FormModalHelper";
import { uischema } from "../backend";
import { useMemo } from "react";
import { PersistentModelConstructor } from "aws-amplify/dist/esm/datastore";

export type ModalExport = React.FC<any> & { Outer: React.FC<any>; Edit: React.FC<any>; New: React.FC<any> };
export function useModal({
  model,
  subtype,
  overrides = {}
}: {
  model: PersistentModelConstructor<any>;
  subtype?: string;
  overrides?: Record<string, any>;
}): ModalExport {
  const { formOptions: modalOptions } = uischema().models[model.name];
  const modal = useMemo(
    () => createModal({ model, subtype, ...modalOptions, ...overrides }),
    [model, modalOptions, overrides, subtype]
  );
  return modal;
}
