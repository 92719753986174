import { Button } from "@aws-amplify/ui-react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsChevronRight, BsFillPencilFill, BsPersonLock } from "react-icons/bs";
import { shortDisplayName } from "../amplify/cognitoHelpers";
import { uischema } from "../backend";
import { useUser } from "../customer/CustomerSessionProvider";
import usePermissions, { permissions } from "../customer/usePermissions";
import styles from "./ItemButtons.module.css";
import { LayoutContext } from "../modal/LayoutContext";

export default function ItemButton({
  id,
  isLoading,
  item,
  model,
  modelSchema = model && uischema().models[model.name],
  variation = "primary",
  label = "Edit",
  icon = <BsFillPencilFill />,
  variant = "stacked",
  suffix = !!label && variant === "stacked" ? <BsChevronRight /> : !!label && variant === "windowed" ? <>&hellip;</> : undefined,
  disabled,
  className,
  buttonClass = undefined,
  forbiddenStyle = "overlay",
  requiredPermissions,
  title = `${label} ${modelSchema?.displayName}`,
  onClick,
  ...rest
}) {
  const [busy, setBusy] = useState(false);
  const permissions = usePermissions({ id, item, model });
  const requiredPermissionsFlat = [requiredPermissions].flat();
  const allowed = requiredPermissionsFlat.every((permission) => permissions.has(permission));
  const user = useUser();
  const { layout } = useContext(LayoutContext);
  return (
    <>
      {(forbiddenStyle !== "hidden" || allowed) && (
        <span className={clsx(className, styles.buttonWrapper)}>
          <Button
            id={`${model.name}-${id || "new"}`}
            isLoading={busy || isLoading}
            isDisabled={disabled || !allowed}
            variation={variation}
            className={clsx(buttonClass, styles.button)}
            title={title}
            onClick={
              onClick
                ? async (event) => {
                    try {
                      setBusy(true);
                      await onClick(event);
                    } finally {
                      setBusy(false);
                    }
                  }
                : undefined
            }
            {...rest}
          >
            {icon}
            {layout === "normal" && (
              <>
                {label}
                {suffix}
              </>
            )}
          </Button>
          {!allowed && forbiddenStyle === "overlay" && (
            <OverlayTrigger
              trigger={["focus", "hover"]}
              placement="bottom"
              overlay={
                <Tooltip id="permissions" className={styles.prohibitedTooltip}>
                  {shortDisplayName(user)} does not have permission to {requiredPermissionsFlat.join()}{" "}
                  {id || item || !model ? "this" : "a"} {model?.name}
                </Tooltip>
              }
            >
              <span className={styles.prohibited}>
                <BsPersonLock />
              </span>
            </OverlayTrigger>
          )}
        </span>
      )}
    </>
  );
}

ItemButton.propTypes = {
  ...Button.propTypes,
  buttonClass: PropTypes.any,
  icon: PropTypes.node,
  forbiddenStyle: PropTypes.oneOf(["overlay", "disabled", "hidden"]),
  label: PropTypes.node,
  requiredPermissions: PropTypes.oneOfType([PropTypes.oneOf(permissions), PropTypes.arrayOf(PropTypes.oneOf(permissions))]),
  suffix: PropTypes.node,
  variant: PropTypes.oneOf(["stacked", "windowed", "plain"]),
  variation: PropTypes.string
};
