import { jsonSchema, models } from "../backend";
import { evidenceValidator } from "../evidence/EvidenceValidator";
import { schemaFormValidator } from "./jsonSchemaValidators";
import { FormValidator, Validators } from "./withValidators";

export type Options = Parameters<typeof schemaFormValidator>[1];
export function createDisclosureValidator(options: Options): FormValidator {
  return async ({ modelFields, customerDataStore }) => {
    if (modelFields.id) {
      const { Disclosure } = models();
      const disclosure = await customerDataStore.query(Disclosure, modelFields.id);
      if (disclosure) {
        const publishValidator = schemaFormValidator(jsonSchema().ref("#/definitions/models/Disclosure"), options);
        // Check against publishing schema
        const schemaValidationResult = await publishValidator({
          modelFields: Disclosure.copyOf(disclosure, (v) => Object.assign(v, modelFields)),
          customerDataStore
        });
        // check against required evidence requirements
        const evidenceValidationResult = await evidenceValidator(disclosure, customerDataStore);

        return schemaValidationResult || evidenceValidationResult
          ? {
              hasError: true,
              errorMessage: (
                <>
                  {schemaValidationResult && schemaValidationResult.errorMessage}
                  {evidenceValidationResult && evidenceValidationResult.errorMessage}
                </>
              )
            }
          : undefined;
      } else {
        throw new Error("Disclosure not found");
      }
    }
  };
}

const validators: Validators = {
  "*": createDisclosureValidator({ deep: true, generateOverrides: true })
};

export default validators;
