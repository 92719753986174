import React from "react";

import clsx from "clsx";
import {NavLink as RouterNavLink} from "react-router-dom";

export default function NavLink({
  className = undefined,
  children,
  to,
  ...rest
}) {
  return (
    <RouterNavLink className={clsx("nav-link", className)} to={to} {...rest}>
      {children}
    </RouterNavLink>
  );
}

NavLink.propTypes = {
  ...RouterNavLink.propTypes
};
