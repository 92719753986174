import { useEffect, useState } from "react";
import { URL } from "url";
import { XLSX } from "./xlsxWrapper";

export function useSpreadsheet(url: URL, opts?: XLSX.ParsingOptions) {
  const [{ loading, error, workbook }, setWorkbook] = useState<Partial<{ loading: true; error: string; workbook: XLSX.WorkBook }>>(
    {}
  );
  useEffect(() => {
    if (url && !loading && !error && !workbook) {
      (async () => {
        try {
          console.time("work");
          setWorkbook({ loading: true });
          const file = await (
            await fetch(url, {
              headers: {
                Accept: "*/*"
              }
            })
          ).arrayBuffer();
          console.timeLog("work", "fetched");
          const { read } = await import("./xlsxWrapper");
          console.timeLog("work", "imported");
          setWorkbook({ workbook: read(file, opts) });
          console.timeLog("work", "parsed");
        } catch (e) {
          setWorkbook({ error: (e as Error).toString() });
        }
      })();
    }
  }, [url, loading, error, workbook, opts]);
  return { loading, error, workbook };
}
