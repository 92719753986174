/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Location } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function LocationUpdateForm(props) {
  const {
    id: idProp,
    location: locationModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    LocationName: "",
    LocationID: "",
    FacilityOrSite: "",
    AddressLine1: "",
    AddressLine2: "",
    AddressLine3: "",
    PostCode: "",
    City: "",
    Country: "",
    Latitude: "",
    Longitude: "",
  };
  const [LocationName, setLocationName] = React.useState(
    initialValues.LocationName
  );
  const [LocationID, setLocationID] = React.useState(initialValues.LocationID);
  const [FacilityOrSite, setFacilityOrSite] = React.useState(
    initialValues.FacilityOrSite
  );
  const [AddressLine1, setAddressLine1] = React.useState(
    initialValues.AddressLine1
  );
  const [AddressLine2, setAddressLine2] = React.useState(
    initialValues.AddressLine2
  );
  const [AddressLine3, setAddressLine3] = React.useState(
    initialValues.AddressLine3
  );
  const [PostCode, setPostCode] = React.useState(initialValues.PostCode);
  const [City, setCity] = React.useState(initialValues.City);
  const [Country, setCountry] = React.useState(initialValues.Country);
  const [Latitude, setLatitude] = React.useState(initialValues.Latitude);
  const [Longitude, setLongitude] = React.useState(initialValues.Longitude);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = locationRecord
      ? { ...initialValues, ...locationRecord }
      : initialValues;
    setLocationName(cleanValues.LocationName);
    setLocationID(cleanValues.LocationID);
    setFacilityOrSite(cleanValues.FacilityOrSite);
    setAddressLine1(cleanValues.AddressLine1);
    setAddressLine2(cleanValues.AddressLine2);
    setAddressLine3(cleanValues.AddressLine3);
    setPostCode(cleanValues.PostCode);
    setCity(cleanValues.City);
    setCountry(cleanValues.Country);
    setLatitude(cleanValues.Latitude);
    setLongitude(cleanValues.Longitude);
    setErrors({});
  };
  const [locationRecord, setLocationRecord] = React.useState(locationModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Location, idProp)
        : locationModelProp;
      setLocationRecord(record);
    };
    queryData();
  }, [idProp, locationModelProp]);
  React.useEffect(resetStateValues, [locationRecord]);
  const validations = {
    LocationName: [],
    LocationID: [],
    FacilityOrSite: [],
    AddressLine1: [],
    AddressLine2: [],
    AddressLine3: [],
    PostCode: [],
    City: [],
    Country: [],
    Latitude: [],
    Longitude: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          LocationName,
          LocationID,
          FacilityOrSite,
          AddressLine1,
          AddressLine2,
          AddressLine3,
          PostCode,
          City,
          Country,
          Latitude,
          Longitude,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Location.copyOf(locationRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "LocationUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Location"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Update this record of a location of business activities, assets and facilities. This information is used to compute analytics and visualisations (e.g. assets at risk), but is not used in a formal climate-related disclosure."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid3")}
      >
        <TextField
          label="Location name"
          isRequired={false}
          isReadOnly={false}
          value={LocationName}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName: value,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode,
                City,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.LocationName ?? value;
            }
            if (errors.LocationName?.hasError) {
              runValidationTasks("LocationName", value);
            }
            setLocationName(value);
          }}
          onBlur={() => runValidationTasks("LocationName", LocationName)}
          errorMessage={errors.LocationName?.errorMessage}
          hasError={errors.LocationName?.hasError}
          {...getOverrideProps(overrides, "LocationName")}
        ></TextField>
        <TextField
          label="Location ID"
          isRequired={false}
          isReadOnly={false}
          value={LocationID}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID: value,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode,
                City,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.LocationID ?? value;
            }
            if (errors.LocationID?.hasError) {
              runValidationTasks("LocationID", value);
            }
            setLocationID(value);
          }}
          onBlur={() => runValidationTasks("LocationID", LocationID)}
          errorMessage={errors.LocationID?.errorMessage}
          hasError={errors.LocationID?.hasError}
          {...getOverrideProps(overrides, "LocationID")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Description of facility or site"
        isRequired={false}
        isReadOnly={false}
        value={FacilityOrSite}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              LocationName,
              LocationID,
              FacilityOrSite: value,
              AddressLine1,
              AddressLine2,
              AddressLine3,
              PostCode,
              City,
              Country,
              Latitude,
              Longitude,
            };
            const result = onChange(modelFields);
            value = result?.FacilityOrSite ?? value;
          }
          if (errors.FacilityOrSite?.hasError) {
            runValidationTasks("FacilityOrSite", value);
          }
          setFacilityOrSite(value);
        }}
        onBlur={() => runValidationTasks("FacilityOrSite", FacilityOrSite)}
        errorMessage={errors.FacilityOrSite?.errorMessage}
        hasError={errors.FacilityOrSite?.hasError}
        {...getOverrideProps(overrides, "FacilityOrSite")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Address line1"
          isRequired={false}
          isReadOnly={false}
          value={AddressLine1}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1: value,
                AddressLine2,
                AddressLine3,
                PostCode,
                City,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.AddressLine1 ?? value;
            }
            if (errors.AddressLine1?.hasError) {
              runValidationTasks("AddressLine1", value);
            }
            setAddressLine1(value);
          }}
          onBlur={() => runValidationTasks("AddressLine1", AddressLine1)}
          errorMessage={errors.AddressLine1?.errorMessage}
          hasError={errors.AddressLine1?.hasError}
          {...getOverrideProps(overrides, "AddressLine1")}
        ></TextField>
        <TextField
          label="Address line2"
          isRequired={false}
          isReadOnly={false}
          value={AddressLine2}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2: value,
                AddressLine3,
                PostCode,
                City,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.AddressLine2 ?? value;
            }
            if (errors.AddressLine2?.hasError) {
              runValidationTasks("AddressLine2", value);
            }
            setAddressLine2(value);
          }}
          onBlur={() => runValidationTasks("AddressLine2", AddressLine2)}
          errorMessage={errors.AddressLine2?.errorMessage}
          hasError={errors.AddressLine2?.hasError}
          {...getOverrideProps(overrides, "AddressLine2")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <TextField
          label="Address line3"
          isRequired={false}
          isReadOnly={false}
          value={AddressLine3}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3: value,
                PostCode,
                City,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.AddressLine3 ?? value;
            }
            if (errors.AddressLine3?.hasError) {
              runValidationTasks("AddressLine3", value);
            }
            setAddressLine3(value);
          }}
          onBlur={() => runValidationTasks("AddressLine3", AddressLine3)}
          errorMessage={errors.AddressLine3?.errorMessage}
          hasError={errors.AddressLine3?.hasError}
          {...getOverrideProps(overrides, "AddressLine3")}
        ></TextField>
        <TextField
          label="Post code"
          isRequired={false}
          isReadOnly={false}
          value={PostCode}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode: value,
                City,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.PostCode ?? value;
            }
            if (errors.PostCode?.hasError) {
              runValidationTasks("PostCode", value);
            }
            setPostCode(value);
          }}
          onBlur={() => runValidationTasks("PostCode", PostCode)}
          errorMessage={errors.PostCode?.errorMessage}
          hasError={errors.PostCode?.hasError}
          {...getOverrideProps(overrides, "PostCode")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="City"
          isRequired={false}
          isReadOnly={false}
          value={City}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode,
                City: value,
                Country,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.City ?? value;
            }
            if (errors.City?.hasError) {
              runValidationTasks("City", value);
            }
            setCity(value);
          }}
          onBlur={() => runValidationTasks("City", City)}
          errorMessage={errors.City?.errorMessage}
          hasError={errors.City?.hasError}
          {...getOverrideProps(overrides, "City")}
        ></TextField>
        <TextField
          label="Country"
          isRequired={false}
          isReadOnly={false}
          value={Country}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode,
                City,
                Country: value,
                Latitude,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.Country ?? value;
            }
            if (errors.Country?.hasError) {
              runValidationTasks("Country", value);
            }
            setCountry(value);
          }}
          onBlur={() => runValidationTasks("Country", Country)}
          errorMessage={errors.Country?.errorMessage}
          hasError={errors.Country?.hasError}
          {...getOverrideProps(overrides, "Country")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Latitude"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Latitude}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode,
                City,
                Country,
                Latitude: value,
                Longitude,
              };
              const result = onChange(modelFields);
              value = result?.Latitude ?? value;
            }
            if (errors.Latitude?.hasError) {
              runValidationTasks("Latitude", value);
            }
            setLatitude(value);
          }}
          onBlur={() => runValidationTasks("Latitude", Latitude)}
          errorMessage={errors.Latitude?.errorMessage}
          hasError={errors.Latitude?.hasError}
          {...getOverrideProps(overrides, "Latitude")}
        ></TextField>
        <TextField
          label="Longitude"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Longitude}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                LocationName,
                LocationID,
                FacilityOrSite,
                AddressLine1,
                AddressLine2,
                AddressLine3,
                PostCode,
                City,
                Country,
                Latitude,
                Longitude: value,
              };
              const result = onChange(modelFields);
              value = result?.Longitude ?? value;
            }
            if (errors.Longitude?.hasError) {
              runValidationTasks("Longitude", value);
            }
            setLongitude(value);
          }}
          onBlur={() => runValidationTasks("Longitude", Longitude)}
          errorMessage={errors.Longitude?.errorMessage}
          hasError={errors.Longitude?.hasError}
          {...getOverrideProps(overrides, "Longitude")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || locationModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || locationModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
