import HelpBox from "./HelpBox";

export default function ImpactRecordsHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Impact Records Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Climate-related Impacts define the impact on your business model and strategy arising from the climate-related risks and opportunities you identified.
                </p>
                <p>
                    For each climate-related risk or opportunity you have identified, you will need to create one or more impact records, and link these records into your disclosure.
                </p>
                <p>
                    To create a set of records collating the impacts resulting from your climate-related risks and opportunities:
                </p>
                <p>
                    <ol>
                        <li>Enter a name for the set of Impact records that will be included in this disclosure.</li>
                        <li>Enter a brief description of these records.</li>
                        <li>If you cannot provide current and/or anticipated quantitative financial data for <i>all</i> your impact records, enter a reason in the field provided. If you cannot provide this information for <i>some</i> of your impact records, fields are provided in the impacts form to record reasons why just for that impact. Note that if you fill in this field, you will also need to provide evidence justifying why you did not provide quantitative financial impact data by uploading this using the <b>Evidence Attachments</b> table in the middle-bottom of this screen.</li>
                        <li>Either select an existing Impact from the table in the middle of the screen, or click the <b>New</b> button in this table to create new Impacts.</li>
                        <li>Finally click the <b>Create</b> or <b>Update</b> buttons.</li>
                    </ol>
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
