import { DataStore, ModelField, ModelFields, PersistentModel, PersistentModelConstructor } from "aws-amplify/datastore";
import { Hub } from "aws-amplify/utils";
import { camelCase, identity, isEqual } from "lodash";
import { useEffect, useState } from "react";
import { isScalar } from "../amplify/schemaHelpers";
import { uischema } from "../backend";
import { useCustomerDataStore } from "../customer/useCustomerDataStore";
interface ExpectedProps {
  id?: string;
  onError: (modelFields: PersistentModel, message: string) => void;
  onSuccess: (modelFields: PersistentModel) => Promise<PersistentModel>;
  onSave: (item: PersistentModel) => PersistentModel;
}
export interface OnSaveProps {
  model: PersistentModelConstructor<any>;
}

export default function withOnSave(
  Form: React.FC<{ id?: string; onSuccess: (modelFields: ModelFields) => any }>,
  { model }: OnSaveProps
): React.FC<ExpectedProps> {
  const Component: React.FC<any> = ({ id, onError = identity, onSuccess = identity, onSave = identity, ...rest }) => {
    const [mutationById, setMutationById] = useState<Record<string, PersistentModel>>({});
    const [{ item, timeoutId }, setExpectedMutation] = useState<Partial<{ item: PersistentModel; timeoutId: number }>>({});
    const [initialItem, setInitialItem] = useState<PersistentModel | undefined>();
    const customerDataStore = useCustomerDataStore();
    useEffect(() => {
      (async () => {
        if (id) {
          setInitialItem(await customerDataStore.query(model, id));
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      const subscriptionCancel = Hub.listen("datastore", ({ payload: { event, data } }) => {
        if (event === "outboxMutationProcessed") {
          const element = (data as { element: PersistentModel })?.element;
          if (element) {
            setMutationById({ ...mutationById, [element.id]: element });
          }
        }
      });
      return subscriptionCancel;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function handleSuccess(modelFields: PersistentModel) {
      const { id } = modelFields;
      let instance: PersistentModel | undefined = undefined;
      if (!id) {
        // Horrible - we have no way of knowing what the id of the newly created object is so we have to query - look for the most recently create item with the same field
        // simple code change to create Forms for would be to add it to the modelfields for onSuccess but that's generated code!
        const fields = uischema().models[model.name]?.fields || {};

        const fieldsToMatch = Object.entries(modelFields) // compare all fields we know got set
          .filter(([name]) => name in fields && isScalar(fields[name] as ModelField));
        const [newInstance] = await DataStore.query(
          model,

          (c) =>
            c.and((c1) =>
              // @ts-ignore
              fieldsToMatch.map(([field, value]) => c1[field].eq(value))
            ),
          { sort: (s) => s.createdAt("DESCENDING"), limit: 1 }
        );
        instance = newInstance;
      } else {
        instance = await DataStore.query(model, id);
      }

      if (instance) {
        const extraFields = await onSave(instance);
        // Save here with customer datastore (adds in CustomerId) and any changed extraFields from upstream onSave() results
        const changedFields = extraFields
          ? Object.fromEntries(Object.entries(extraFields).filter(([k, v]) => !isEqual(instance?.[k], v)))
          : {};
        const postSave = await customerDataStore.save(model.copyOf(instance, (item) => Object.assign(item, changedFields)));
        if (!initialItem || initialItem._version < postSave._version) {
          const timeoutId = window.setTimeout(() => {
            const message = "Timed out waiting for synchronization";
            onError(postSave, message);
            setExpectedMutation({});
          }, 10000);
          setExpectedMutation({ item: postSave, timeoutId });
        } else {
          onSuccess(postSave);
        }
      } else throw new Error(`Could not find expected instance: ${modelFields}`);
    }
    useEffect(
      () => {
        if (item) {
          const lastMutation = mutationById[item.id];
          if (lastMutation && (lastMutation._version || 0) >= (item._version || 0)) {
            console.debug("Mutation sent to backend for:", item);
            onSuccess(item);
            if (timeoutId) window.clearTimeout(timeoutId);
            setExpectedMutation({});
          }
        }
      },
      // eslint-disable-next-line
      [item, mutationById]
    );

    return <Form id={id} {...{ [camelCase(model.name)]: item }} onSuccess={handleSuccess} {...rest} />;
  };
  Component.displayName = "withOnSave";
  return Component;
}
