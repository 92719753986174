import clsx from "clsx";
import {BsFillPencilFill} from "react-icons/bs";
import ItemButton from "./ItemButton";
import styles from "./ItemButtons.module.css";

export default function EditItemButton({
  label = "Edit",
  icon = <BsFillPencilFill />,
  buttonClass = undefined,
  ...rest
}) {
  return (
    <ItemButton
      icon={icon}
      label={label}
      requiredPermissions="read"
      buttonClass={clsx(buttonClass, styles.editButton)}
      {...rest}
    />
  );
}

EditItemButton.propTypes = {
  ...ItemButton.propTypes
};
