/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { GovernanceProcesses } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function GovernanceProcessesManagementOversightUpdateForm(
  props
) {
  const {
    id: idProp,
    governanceProcesses: governanceProcessesModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GovernanceProcessName: "",
    ProcessDescription: "",
    ProcessType: "",
    ProcessFrequency: "",
  };
  const [GovernanceProcessName, setGovernanceProcessName] = React.useState(
    initialValues.GovernanceProcessName
  );
  const [ProcessDescription, setProcessDescription] = React.useState(
    initialValues.ProcessDescription
  );
  const [ProcessType, setProcessType] = React.useState(
    initialValues.ProcessType
  );
  const [ProcessFrequency, setProcessFrequency] = React.useState(
    initialValues.ProcessFrequency
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = governanceProcessesRecord
      ? { ...initialValues, ...governanceProcessesRecord }
      : initialValues;
    setGovernanceProcessName(cleanValues.GovernanceProcessName);
    setProcessDescription(cleanValues.ProcessDescription);
    setProcessType(cleanValues.ProcessType);
    setProcessFrequency(cleanValues.ProcessFrequency);
    setErrors({});
  };
  const [governanceProcessesRecord, setGovernanceProcessesRecord] =
    React.useState(governanceProcessesModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(GovernanceProcesses, idProp)
        : governanceProcessesModelProp;
      setGovernanceProcessesRecord(record);
    };
    queryData();
  }, [idProp, governanceProcessesModelProp]);
  React.useEffect(resetStateValues, [governanceProcessesRecord]);
  const validations = {
    GovernanceProcessName: [],
    ProcessDescription: [],
    ProcessType: [],
    ProcessFrequency: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GovernanceProcessName,
          ProcessDescription,
          ProcessType,
          ProcessFrequency,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            GovernanceProcesses.copyOf(governanceProcessesRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "GovernanceProcessesManagementOversightUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Management Oversight Process"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 9c."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update this record that describes a process by which management is informed about, makes decisions on, and monitors, climate-related risks and opportunities."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Heading
        children="NZCS3 paragraph 51 b ii."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Heading>
      <Text
        children="You can also use this form to update the process used to oversee and manage the scenario analysis process, including the role of the governance body and management."
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Management oversight process name"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceProcessName: value,
              ProcessDescription,
              ProcessType,
              ProcessFrequency,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceProcessName ?? value;
          }
          if (errors.GovernanceProcessName?.hasError) {
            runValidationTasks("GovernanceProcessName", value);
          }
          setGovernanceProcessName(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceProcessName", GovernanceProcessName)
        }
        errorMessage={errors.GovernanceProcessName?.errorMessage}
        hasError={errors.GovernanceProcessName?.hasError}
        {...getOverrideProps(overrides, "GovernanceProcessName")}
      ></TextField>
      <TextAreaField
        label="Management oversight process description"
        isRequired={false}
        isReadOnly={false}
        value={ProcessDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceProcessName,
              ProcessDescription: value,
              ProcessType,
              ProcessFrequency,
            };
            const result = onChange(modelFields);
            value = result?.ProcessDescription ?? value;
          }
          if (errors.ProcessDescription?.hasError) {
            runValidationTasks("ProcessDescription", value);
          }
          setProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("ProcessDescription", ProcessDescription)
        }
        errorMessage={errors.ProcessDescription?.errorMessage}
        hasError={errors.ProcessDescription?.hasError}
        {...getOverrideProps(overrides, "ProcessDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <SelectField
          label="Process type"
          placeholder="Please select an option"
          isDisabled={false}
          value={ProcessType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                GovernanceProcessName,
                ProcessDescription,
                ProcessType: value,
                ProcessFrequency,
              };
              const result = onChange(modelFields);
              value = result?.ProcessType ?? value;
            }
            if (errors.ProcessType?.hasError) {
              runValidationTasks("ProcessType", value);
            }
            setProcessType(value);
          }}
          onBlur={() => runValidationTasks("ProcessType", ProcessType)}
          errorMessage={errors.ProcessType?.errorMessage}
          hasError={errors.ProcessType?.hasError}
          {...getOverrideProps(overrides, "ProcessType")}
        >
          <option
            children="Assess"
            value="Assess"
            {...getOverrideProps(overrides, "ProcessTypeoption0")}
          ></option>
          <option
            children="Monitor"
            value="Monitor"
            {...getOverrideProps(overrides, "ProcessTypeoption1")}
          ></option>
          <option
            children="Inform"
            value="Inform"
            {...getOverrideProps(overrides, "ProcessTypeoption2")}
          ></option>
          <option
            children="Decide"
            value="Decide"
            {...getOverrideProps(overrides, "ProcessTypeoption3")}
          ></option>
          <option
            children="Integrated"
            value="Integrated"
            {...getOverrideProps(overrides, "ProcessTypeoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Process frequency"
          placeholder="Please select an option"
          isDisabled={false}
          value={ProcessFrequency}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                GovernanceProcessName,
                ProcessDescription,
                ProcessType,
                ProcessFrequency: value,
              };
              const result = onChange(modelFields);
              value = result?.ProcessFrequency ?? value;
            }
            if (errors.ProcessFrequency?.hasError) {
              runValidationTasks("ProcessFrequency", value);
            }
            setProcessFrequency(value);
          }}
          onBlur={() =>
            runValidationTasks("ProcessFrequency", ProcessFrequency)
          }
          errorMessage={errors.ProcessFrequency?.errorMessage}
          hasError={errors.ProcessFrequency?.hasError}
          {...getOverrideProps(overrides, "ProcessFrequency")}
        >
          <option
            children="Daily"
            value="Daily"
            {...getOverrideProps(overrides, "ProcessFrequencyoption0")}
          ></option>
          <option
            children="Weekly"
            value="Weekly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption1")}
          ></option>
          <option
            children="Fortnightly"
            value="Fortnightly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption2")}
          ></option>
          <option
            children="Monthly"
            value="Monthly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption3")}
          ></option>
          <option
            children="Quarterly"
            value="Quarterly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption4")}
          ></option>
          <option
            children="Half yearly"
            value="Half_Yearly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption5")}
          ></option>
          <option
            children="Yearly"
            value="Yearly"
            {...getOverrideProps(overrides, "ProcessFrequencyoption6")}
          ></option>
          <option
            children="Every two years"
            value="Every_Two_Years"
            {...getOverrideProps(overrides, "ProcessFrequencyoption7")}
          ></option>
          <option
            children="Every three years"
            value="Every_Three_Years"
            {...getOverrideProps(overrides, "ProcessFrequencyoption8")}
          ></option>
          <option
            children="Adhoc"
            value="Adhoc"
            {...getOverrideProps(overrides, "ProcessFrequencyoption9")}
          ></option>
          <option
            children="Never"
            value="Never"
            {...getOverrideProps(overrides, "ProcessFrequencyoption10")}
          ></option>
        </SelectField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || governanceProcessesModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || governanceProcessesModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
