import { Autocomplete, ComboBoxOption, TextField } from "@aws-amplify/ui-react";
import React, { useMemo, useState } from "react";
import styles from "../../shared/wizard/Wizard.module.css";

import { mapKeys, pick, startCase } from "lodash";
import { IndustrySheetRow, SheetName, SubMetricsSheetRow, useSASBStandardsSheets } from "../../shared/file/SASBStandardsSheets";
import { RecordCard } from "../../shared/wizard/RecordCard";
import Wizard from "../../shared/wizard/Wizard";
import { FormWrapper } from "../backend";

type PreselectedItem = Partial<Record<string, any>>;

const sheets: SheetName[] = ["Industry", "SubMetrics" /*"TechnicalProtocol"*/, "SubActivityMetrics"];
const activityFieldsToMap = Object.fromEntries(
  ["Metric", "MetricCode", "MetricCategory", "MetricUnitOfMeasure"].map((suffix) => [`SubActivity${suffix}`, `Sub${suffix}`])
);

const display = {
  Industry: {
    long: ["Industry", "IndustryCode", "IndustryDescription"],
    short: ["Industry", "IndustryDescription"]
  },
  SubMetrics: {
    long: ["SubMetric", "SubMetricCategory", "SubMetricCode", "SubMetricUnitOfMeasure"],
    short: ["SubMetric", "SubMetricUnitOfMeasure"]
  }
};

function mapSubMetricToMetricTitle(title: string) {
  return startCase(title.replace(/^SubMetric/, "Metric"));
}

const withIndustryMetricsWizard: FormWrapper = (CreateForm, { model, propertyMap }) => {
  const IndustryMetricsWizard: React.FC<any> = ({ ...rest }) => {
    const [preselection, setPreselection] = useState<PreselectedItem>({});
    const { loading, error, workbook: sasbWorkbook } = useSASBStandardsSheets(sheets);
    const [{ industry, industrySearch }, setIndustry] = useState<{
      industry?: IndustrySheetRow;
      industrySearch: string;
    }>({ industrySearch: "" });
    const [{ subMetric, subMetricSearch }, setSubMetric] = useState<{
      subMetric?: SubMetricsSheetRow;
      subMetricSearch: string;
    }>({ subMetricSearch: "" });

    const { industryOptions, industryByCode } = useMemo(() => {
      if (sasbWorkbook && sasbWorkbook.Industry) {
        return {
          industryByCode: Object.fromEntries(sasbWorkbook.Industry.map((industry) => [industry.IndustryCode, industry])),
          industryOptions: sasbWorkbook.Industry.map(({ Industry, IndustryDescription, IndustryCode }) => ({
            id: IndustryCode,
            label: [Industry, IndustryDescription, IndustryCode].join()
          }))
        };
      } else return { industryOptions: [], industryByCode: {} };
    }, [sasbWorkbook]);
    const { subMetricOptions, subMetricByCode } = useMemo(() => {
      if (industry && sasbWorkbook && sasbWorkbook.SubMetrics) {
        const subMetricsForIndustry = [
          ...(sasbWorkbook?.SubMetrics?.filter(({ IndustryCode }) => industry.IndustryCode === IndustryCode) || []),
          // Map SubActivityMetrics to SubMetrics
          ...(sasbWorkbook?.SubActivityMetrics?.filter(({ IndustryCode }) => industry.IndustryCode === IndustryCode).map(
            (subActivity) => mapKeys(subActivity, (v, k) => activityFieldsToMap[k] || k) as SubMetricsSheetRow
          ) || [])
        ];
        return {
          subMetricByCode: Object.fromEntries(subMetricsForIndustry.map((subMetric) => [subMetric.SubMetricCode, subMetric])),
          subMetricOptions: subMetricsForIndustry.map(({ SubMetricCode, SubMetric, SubMetricCategory }) => ({
            id: SubMetricCode,
            label: [SubMetricCode, SubMetric, SubMetricCategory].join()
          }))
        };
      } else return { subMetricOptions: [], subMetricByCode: {} };
    }, [industry, sasbWorkbook]);

    function handleSelectIndustry(option?: string) {
      if (industryByCode) {
        const industry = option ? industryByCode[option] : undefined;
        handleSelectSubMetric();
        setIndustry({ industry, industrySearch: "" });
        setPreselection({
          IndustryCode: industry && industry.IndustryCode
        });
      }
    }
    function handleSelectSubMetric(option?: string) {
      if (subMetricByCode) {
        const subMetric = option ? subMetricByCode[option] : undefined;
        setSubMetric({ subMetric, subMetricSearch: "" });
        setPreselection({
          ...preselection,
          ...(subMetric && {
            MetricDataSetName: [subMetric.SubMetricCode, subMetric.SubMetric].join(":"),
            Metric: subMetric.SubMetric,
            MetricCode: subMetric.SubMetricCode,
            DisclosureCode: subMetric.DisclosureTopicCode,
            DisclosureTopic: subMetric.DisclosureTopic,
            MetricUnitOfMeasure: subMetric.SubMetricUnitOfMeasure,
            MetricValue: undefined,
            DiscussionAnalysis: undefined
          })
        });
      }
    }
    const renderIndustryOption = (option: ComboBoxOption, value: string) => {
      const industry = option.id && industryByCode?.[option.id];
      return industry && <RecordCard header="Industry" record={pick(industry, display.Industry.short)} matched={value} />;
    };
    const renderSubMetricOption = (option: ComboBoxOption, value: string) => {
      const subMetric = option.id && subMetricByCode?.[option.id];
      return (
        subMetric && (
          <RecordCard
            header="SubMetric"
            record={pick(subMetric, display.SubMetrics.short)}
            matched={value}
            renderFieldTitle={mapSubMetricToMetricTitle}
          />
        )
      );
    };
    const isQuantitativeMetric = subMetric && subMetric.SubMetricCategory === "Quantitative";
    const valueField = isQuantitativeMetric ? "MetricValue" : "DiscussionAnalysis";
    const isFinished = !!preselection[valueField];
    return (
      <Wizard
        isFinished={isFinished}
        isLoading={loading}
        error={error}
        model={model}
        CreateForm={CreateForm}
        preselection={propertyMap ? mapKeys(preselection, (_value, key) => propertyMap?.[key] || key) : preselection}
        {...rest}
      >
        <Autocomplete
          size="large"
          labelHidden={false}
          label="Choose an industry that the metric relates to:"
          placeholder="Type keywords"
          renderOption={renderIndustryOption}
          value={industrySearch}
          onChange={({ target: { value } }) => {
            setIndustry({ industrySearch: value });
          }}
          onClear={() => {
            handleSelectIndustry();
          }}
          onSelect={(option) => {
            handleSelectIndustry(option?.id);
          }}
          options={industryOptions}
        />
        {industry && (
          <RecordCard className={styles.selectedCard} header="Industry" record={pick(industry, display.Industry.long)} selected />
        )}
        {industry && subMetricOptions && (
          <>
            <Autocomplete
              size="large"
              labelHidden={false}
              label={
                <span>
                  Choose a metric for <b>{industry.Industry}</b>
                </span>
              }
              placeholder="Type keywords"
              renderOption={renderSubMetricOption}
              value={subMetricSearch}
              onChange={({ target: { value } }) => {
                setSubMetric({ subMetricSearch: value });
              }}
              onClear={() => {
                handleSelectSubMetric();
              }}
              onSelect={(option) => {
                handleSelectSubMetric(option?.id);
              }}
              options={subMetricOptions}
            />
            {subMetric && (
              <RecordCard
                className={styles.selectedCard}
                header="SubMetric"
                record={pick(subMetric, display.SubMetrics.long)}
                renderFieldTitle={mapSubMetricToMetricTitle}
                selected
              />
            )}
          </>
        )}
        {subMetric && (
          <TextField
            label={
              <span>
                Enter a value for <b>{subMetric.SubMetric}</b>
                {isQuantitativeMetric && (
                  <span>
                    &nbsp;in <b>{subMetric.SubMetricUnitOfMeasure}</b> units
                  </span>
                )}
              </span>
            }
            type={isQuantitativeMetric ? "number" : "text"}
            id="metricValue"
            value={preselection[valueField] || ""}
            onChange={({ target: { value } }) => {
              setPreselection({
                ...preselection,
                [valueField]: value
              });
            }}
          />
        )}
      </Wizard>
    );
  };
  return IndustryMetricsWizard;
};
export default withIndustryMetricsWizard;
