import HelpBox from "./HelpBox";

export default function MetricsTargetsBaseHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Metrics and Targets Data Set Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          Metrics and Targets data helps you to understand how you measure and
          manage your climate-related risks and opportunities. Metrics and
          targets also provide a basis to compare entities within a sector or
          industry.
        </p>
        <p>Enter data in each of the following areas:</p>
        <ol type="1">
          <li>
            <b>Climate-related Metrics</b> - enter data about climate-related
            metrics
          </li>
          <li>
            <b>Climate-related Targets</b> - enter data about targets used to
            manage climate-related risks and opportunities, and performance
            against those targets
          </li>
          <li>
            <b>GHG Emissions Narrative</b> - enter supporting data about your
            GHG emissions
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
