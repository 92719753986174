import HelpBox from "./HelpBox";

export default function RiskManagementProcessesBaseHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Risk Management Data Set Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          Risk Management data helps you to explain and understand how
          climate-related risks are identified, assessed and managed and how
          those processes are integrated into existing organisational risk
          management processes.
        </p>
        <ol type="1">
          <li>
            To create a new Risk Management data set, enter data in the fields
            on the left hand side
          </li>
          <li>
            Next, create or select risk management processes in the “Risk
            Management Processes” table
          </li>
          <li>
            You can edit or delete risk management processes within the table
          </li>
          <li>Set the status of the disclosure from the radio buttons</li>
          <li>
            Finally click the <b>Submit</b> or <b>Update</b> buttons.
          </li>
        </ol>
      </HelpBox.Content>
    </HelpBox>
  );
}
