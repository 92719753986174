/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ImpactsDataSet } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ImpactsDataSetCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    DataSetName: "",
    Description: "",
    UnabletoDiscloseImpact: "",
  };
  const [DataSetName, setDataSetName] = React.useState(
    initialValues.DataSetName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [UnabletoDiscloseImpact, setUnabletoDiscloseImpact] = React.useState(
    initialValues.UnabletoDiscloseImpact
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDataSetName(initialValues.DataSetName);
    setDescription(initialValues.Description);
    setUnabletoDiscloseImpact(initialValues.UnabletoDiscloseImpact);
    setErrors({});
  };
  const validations = {
    DataSetName: [],
    Description: [],
    UnabletoDiscloseImpact: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DataSetName,
          Description,
          UnabletoDiscloseImpact,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new ImpactsDataSet(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ImpactsDataSetCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Impacts from Climate-related Risks and Opportunities"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 12,15."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Create a record that collates the impacts of identified climate-related risks and opportunities on your business model and strategy."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Climate-related impacts records (e.g. 2025 climate-related impacts records)"
        isRequired={false}
        isReadOnly={false}
        value={DataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName: value,
              Description,
              UnabletoDiscloseImpact,
            };
            const result = onChange(modelFields);
            value = result?.DataSetName ?? value;
          }
          if (errors.DataSetName?.hasError) {
            runValidationTasks("DataSetName", value);
          }
          setDataSetName(value);
        }}
        onBlur={() => runValidationTasks("DataSetName", DataSetName)}
        errorMessage={errors.DataSetName?.errorMessage}
        hasError={errors.DataSetName?.hasError}
        {...getOverrideProps(overrides, "DataSetName")}
      ></TextField>
      <TextAreaField
        label="Description (e.g. Climate-related impacts records for 2025)"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description: value,
              UnabletoDiscloseImpact,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <TextAreaField
        label="If you cannot provide quantitative financial impact information for your impacts, please provide a reason"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Description,
              UnabletoDiscloseImpact: value,
            };
            const result = onChange(modelFields);
            value = result?.UnabletoDiscloseImpact ?? value;
          }
          if (errors.UnabletoDiscloseImpact?.hasError) {
            runValidationTasks("UnabletoDiscloseImpact", value);
          }
          setUnabletoDiscloseImpact(value);
        }}
        onBlur={() =>
          runValidationTasks("UnabletoDiscloseImpact", UnabletoDiscloseImpact)
        }
        errorMessage={errors.UnabletoDiscloseImpact?.errorMessage}
        hasError={errors.UnabletoDiscloseImpact?.hasError}
        {...getOverrideProps(overrides, "UnabletoDiscloseImpact")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
