import {useCallback, useEffect, useState} from "react";
import {useToast} from "../util/Toast";

interface DownloadProps {
  data: object | (() => Promise<any>) | null | undefined;
  contentType?: string;
  filename: string;
}

function download(url: string, filename: string) {
  // Create a new link
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
}

export default function useDownload({
  data: dataOrFetcher,
  contentType = "application/json",
  filename
}: DownloadProps) {
  const showToast: (opts: object) => void = useToast();
  const [url, setUrl] = useState<string>();
  const [busy, setBusy] = useState<boolean>(false);
  const isLazyData = typeof dataOrFetcher === "function";
  const cleanup = useCallback(() => {
    url && URL.revokeObjectURL(url);
  }, [url]);
  async function handleClick() {
    setBusy(true);
    try {
      const data = isLazyData ? await dataOrFetcher() : dataOrFetcher;
      if (data) {
        const newUrl = createBlobUrl(data);
        cleanup();
        setUrl(newUrl);
        download(newUrl, filename);
      }
    } catch (error) {
      showToast({
        bg: "danger",
        heading: "Failed Download",
        content: JSON.stringify(error)
      });
    } finally {
      setBusy(false);
    }
  }
  function createBlobUrl(data: any) {
    const blob =
      data instanceof Blob
        ? data
        : new Blob([JSON.stringify(data, undefined, 2)], {
            type: contentType
          });
    const url = URL.createObjectURL(blob);
    return url;
  }
  useEffect(() => {
    // Cleanup if we have a url
    return cleanup;
  }, [cleanup]);

  return {handleClick, busy};
}
