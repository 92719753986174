import React from "react";

import { PersistentModelConstructor, RecursiveModelPredicateExtender } from "aws-amplify/datastore";
import { isEmpty, memoize, merge } from "lodash";
import { BsPaperclip } from "react-icons/bs";

import { SelectField, View } from "@aws-amplify/ui-react";

import { Evidence } from "../../models";
import DeleteItemButton from "../action/DeleteItemButton";
import { allSubtypes, combinedSchemaFor } from "../amplify/schemaHelpers";
import { models, uischema } from "../backend";
import { useModal } from "../modal/useModal";
import useLocalStorage from "../storage/useLocalStorage";
import DataItemTable from "../table/DataItemTable";
import DownloadButton from "./DownloadButton";

type EvidenceTarget = {
  model: PersistentModelConstructor<any>;
  subtype?: string;
  displayName: string;
};
export default function EvidencePage(): React.JSX.Element {
  const allModels = models();
  const evidenceModel = allModels.Evidence;
  const { Edit } = useModal({ model: evidenceModel, subtype: undefined });
  // Memoized list of all models and subtypes with display name
  const allModelsAndSubtypes = memoize((modelNames: string[]) =>
    (
      modelNames
        .map((modelName) => {
          const model = allModels?.[modelName];
          if (model) {
            const subtypes = allSubtypes({ model });
            return (isEmpty(subtypes) ? [undefined] : subtypes).map((subtype) => {
              const { displayName, showEvidence } = combinedSchemaFor({
                model,
                subtype
              });
              return showEvidence ? { displayName, model, subtype } : undefined;
            });
          } else return undefined;
        })
        .flat()
        .filter(Boolean) as EvidenceTarget[]
    ).sort(({ displayName: displayName1 }, { displayName: displayName2 }) => displayName1.localeCompare(displayName2))
  )(Object.keys(uischema().models));
  const [selectionIndex, setSelectionIndex] = useLocalStorage("EvidencePage", 0);
  const { model, subtype } = (selectionIndex > 0 && allModelsAndSubtypes[selectionIndex - 1]) || {};
  const predicates: RecursiveModelPredicateExtender<Evidence>[] = [];
  if (model) {
    predicates.push((c) => c.TargetEntityModel.eq(model.name));
    if (subtype) {
      predicates.push((c) => c.TargetSubtype.eq(subtype));
    }
  }

  return (
    <View padding={1}>
      <h2>
        <BsPaperclip />
        &nbsp; Evidence Summary
      </h2>
      <SelectField
        maxWidth="30rem"
        label="Filter By Attachment Target Type"
        value={selectionIndex}
        onChange={(e) => setSelectionIndex(e.target.value)}
      >
        <option value={0}>{"[any]"}</option>
        {allModelsAndSubtypes.map(({ displayName }, i) => (
          <option key={i + 1} value={i + 1}>
            {displayName}
          </option>
        ))}
      </SelectField>
      <hr></hr>
      <DataItemTable
        model={evidenceModel}
        schema={merge({}, uischema().models.Evidence, {
          includeColumns: /^(?!AttachmentKey|TargetEntityModel|TargetSubtype)/,
          fields: {
            TargetEntityID: { showInTable: true },
            createdAt: { showInTable: true },
            updatedAt: { showInTable: true }
          }
        })}
        itemActions={[
          DownloadButton,
          DeleteItemButton,
          ({ item: { TargetEntityID, TargetEntityModel }, ...rest }: { item: Evidence }) => (
            <Edit initialState={{ TargetEntityID, TargetEntityModel }} label={undefined} {...rest} />
          )
        ]}
        predicates={predicates}
        select={false}
        subtype={undefined}
        onChange={undefined}
        error={undefined}
        fullSize={undefined}
      />
    </View>
  );
}
