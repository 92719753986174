/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { ScenarioAnalysisProcess } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function ScenarioAnalysisProcessUpdateForm(props) {
  const {
    id: idProp,
    scenarioAnalysisProcess: scenarioAnalysisProcessModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    ScenarioAnalysisProcessName: "",
    ScenarioAnalysisProcessDescription: "",
    ScenarioAnalysisProcessType: "",
    GovernanceBodyScenarioAnalysisRole: "",
    ManagementScenarioAnalysisRole: "",
    ModelingUndertakenDescription: "",
    ReasonSelectionofModel: "",
    ExternalPartyInvolvement: "",
    StakeholderInvolvement: "",
  };
  const [ScenarioAnalysisProcessName, setScenarioAnalysisProcessName] =
    React.useState(initialValues.ScenarioAnalysisProcessName);
  const [
    ScenarioAnalysisProcessDescription,
    setScenarioAnalysisProcessDescription,
  ] = React.useState(initialValues.ScenarioAnalysisProcessDescription);
  const [ScenarioAnalysisProcessType, setScenarioAnalysisProcessType] =
    React.useState(initialValues.ScenarioAnalysisProcessType);
  const [
    GovernanceBodyScenarioAnalysisRole,
    setGovernanceBodyScenarioAnalysisRole,
  ] = React.useState(initialValues.GovernanceBodyScenarioAnalysisRole);
  const [ManagementScenarioAnalysisRole, setManagementScenarioAnalysisRole] =
    React.useState(initialValues.ManagementScenarioAnalysisRole);
  const [ModelingUndertakenDescription, setModelingUndertakenDescription] =
    React.useState(initialValues.ModelingUndertakenDescription);
  const [ReasonSelectionofModel, setReasonSelectionofModel] = React.useState(
    initialValues.ReasonSelectionofModel
  );
  const [ExternalPartyInvolvement, setExternalPartyInvolvement] =
    React.useState(initialValues.ExternalPartyInvolvement);
  const [StakeholderInvolvement, setStakeholderInvolvement] = React.useState(
    initialValues.StakeholderInvolvement
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = scenarioAnalysisProcessRecord
      ? { ...initialValues, ...scenarioAnalysisProcessRecord }
      : initialValues;
    setScenarioAnalysisProcessName(cleanValues.ScenarioAnalysisProcessName);
    setScenarioAnalysisProcessDescription(
      cleanValues.ScenarioAnalysisProcessDescription
    );
    setScenarioAnalysisProcessType(cleanValues.ScenarioAnalysisProcessType);
    setGovernanceBodyScenarioAnalysisRole(
      cleanValues.GovernanceBodyScenarioAnalysisRole
    );
    setManagementScenarioAnalysisRole(
      cleanValues.ManagementScenarioAnalysisRole
    );
    setModelingUndertakenDescription(cleanValues.ModelingUndertakenDescription);
    setReasonSelectionofModel(cleanValues.ReasonSelectionofModel);
    setExternalPartyInvolvement(cleanValues.ExternalPartyInvolvement);
    setStakeholderInvolvement(cleanValues.StakeholderInvolvement);
    setErrors({});
  };
  const [scenarioAnalysisProcessRecord, setScenarioAnalysisProcessRecord] =
    React.useState(scenarioAnalysisProcessModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(ScenarioAnalysisProcess, idProp)
        : scenarioAnalysisProcessModelProp;
      setScenarioAnalysisProcessRecord(record);
    };
    queryData();
  }, [idProp, scenarioAnalysisProcessModelProp]);
  React.useEffect(resetStateValues, [scenarioAnalysisProcessRecord]);
  const validations = {
    ScenarioAnalysisProcessName: [],
    ScenarioAnalysisProcessDescription: [],
    ScenarioAnalysisProcessType: [],
    GovernanceBodyScenarioAnalysisRole: [],
    ManagementScenarioAnalysisRole: [],
    ModelingUndertakenDescription: [],
    ReasonSelectionofModel: [],
    ExternalPartyInvolvement: [],
    StakeholderInvolvement: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          ScenarioAnalysisProcessName,
          ScenarioAnalysisProcessDescription,
          ScenarioAnalysisProcessType,
          GovernanceBodyScenarioAnalysisRole,
          ManagementScenarioAnalysisRole,
          ModelingUndertakenDescription,
          ReasonSelectionofModel,
          ExternalPartyInvolvement,
          StakeholderInvolvement,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            ScenarioAnalysisProcess.copyOf(
              scenarioAnalysisProcessRecord,
              (updated) => {
                Object.assign(updated, modelFields);
              }
            )
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "ScenarioAnalysisProcessUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Scenario Analysis Process"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS3 paragraph 51 b i, ii, iv."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update this record that describes how the scenario analysis process has been conducted."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Scenario analysis process name"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioAnalysisProcessName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName: value,
              ScenarioAnalysisProcessDescription,
              ScenarioAnalysisProcessType,
              GovernanceBodyScenarioAnalysisRole,
              ManagementScenarioAnalysisRole,
              ModelingUndertakenDescription,
              ReasonSelectionofModel,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioAnalysisProcessName ?? value;
          }
          if (errors.ScenarioAnalysisProcessName?.hasError) {
            runValidationTasks("ScenarioAnalysisProcessName", value);
          }
          setScenarioAnalysisProcessName(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ScenarioAnalysisProcessName",
            ScenarioAnalysisProcessName
          )
        }
        errorMessage={errors.ScenarioAnalysisProcessName?.errorMessage}
        hasError={errors.ScenarioAnalysisProcessName?.hasError}
        {...getOverrideProps(overrides, "ScenarioAnalysisProcessName")}
      ></TextField>
      <TextAreaField
        label="Scenario analysis process description"
        isRequired={false}
        isReadOnly={false}
        value={ScenarioAnalysisProcessDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName,
              ScenarioAnalysisProcessDescription: value,
              ScenarioAnalysisProcessType,
              GovernanceBodyScenarioAnalysisRole,
              ManagementScenarioAnalysisRole,
              ModelingUndertakenDescription,
              ReasonSelectionofModel,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioAnalysisProcessDescription ?? value;
          }
          if (errors.ScenarioAnalysisProcessDescription?.hasError) {
            runValidationTasks("ScenarioAnalysisProcessDescription", value);
          }
          setScenarioAnalysisProcessDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ScenarioAnalysisProcessDescription",
            ScenarioAnalysisProcessDescription
          )
        }
        errorMessage={errors.ScenarioAnalysisProcessDescription?.errorMessage}
        hasError={errors.ScenarioAnalysisProcessDescription?.hasError}
        {...getOverrideProps(overrides, "ScenarioAnalysisProcessDescription")}
      ></TextAreaField>
      <SelectField
        label="Scenario analysis process type"
        placeholder="Please select an option"
        isDisabled={false}
        value={ScenarioAnalysisProcessType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName,
              ScenarioAnalysisProcessDescription,
              ScenarioAnalysisProcessType: value,
              GovernanceBodyScenarioAnalysisRole,
              ManagementScenarioAnalysisRole,
              ModelingUndertakenDescription,
              ReasonSelectionofModel,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.ScenarioAnalysisProcessType ?? value;
          }
          if (errors.ScenarioAnalysisProcessType?.hasError) {
            runValidationTasks("ScenarioAnalysisProcessType", value);
          }
          setScenarioAnalysisProcessType(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ScenarioAnalysisProcessType",
            ScenarioAnalysisProcessType
          )
        }
        errorMessage={errors.ScenarioAnalysisProcessType?.errorMessage}
        hasError={errors.ScenarioAnalysisProcessType?.hasError}
        {...getOverrideProps(overrides, "ScenarioAnalysisProcessType")}
      >
        <option
          children="Standalone"
          value="Standalone"
          {...getOverrideProps(overrides, "ScenarioAnalysisProcessTypeoption0")}
        ></option>
        <option
          children="Integrated in strategy processes"
          value="Integrated_in_Strategy_Processes"
          {...getOverrideProps(overrides, "ScenarioAnalysisProcessTypeoption1")}
        ></option>
      </SelectField>
      <TextAreaField
        label="Governance body role overseeing scenario analysis"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceBodyScenarioAnalysisRole}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName,
              ScenarioAnalysisProcessDescription,
              ScenarioAnalysisProcessType,
              GovernanceBodyScenarioAnalysisRole: value,
              ManagementScenarioAnalysisRole,
              ModelingUndertakenDescription,
              ReasonSelectionofModel,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceBodyScenarioAnalysisRole ?? value;
          }
          if (errors.GovernanceBodyScenarioAnalysisRole?.hasError) {
            runValidationTasks("GovernanceBodyScenarioAnalysisRole", value);
          }
          setGovernanceBodyScenarioAnalysisRole(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GovernanceBodyScenarioAnalysisRole",
            GovernanceBodyScenarioAnalysisRole
          )
        }
        errorMessage={errors.GovernanceBodyScenarioAnalysisRole?.errorMessage}
        hasError={errors.GovernanceBodyScenarioAnalysisRole?.hasError}
        {...getOverrideProps(overrides, "GovernanceBodyScenarioAnalysisRole")}
      ></TextAreaField>
      <TextAreaField
        label="Management role overseeing scenario analysis"
        isRequired={false}
        isReadOnly={false}
        value={ManagementScenarioAnalysisRole}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName,
              ScenarioAnalysisProcessDescription,
              ScenarioAnalysisProcessType,
              GovernanceBodyScenarioAnalysisRole,
              ManagementScenarioAnalysisRole: value,
              ModelingUndertakenDescription,
              ReasonSelectionofModel,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.ManagementScenarioAnalysisRole ?? value;
          }
          if (errors.ManagementScenarioAnalysisRole?.hasError) {
            runValidationTasks("ManagementScenarioAnalysisRole", value);
          }
          setManagementScenarioAnalysisRole(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ManagementScenarioAnalysisRole",
            ManagementScenarioAnalysisRole
          )
        }
        errorMessage={errors.ManagementScenarioAnalysisRole?.errorMessage}
        hasError={errors.ManagementScenarioAnalysisRole?.hasError}
        {...getOverrideProps(overrides, "ManagementScenarioAnalysisRole")}
      ></TextAreaField>
      <TextAreaField
        label="Describe any modeling undertaken"
        isRequired={false}
        isReadOnly={false}
        value={ModelingUndertakenDescription}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName,
              ScenarioAnalysisProcessDescription,
              ScenarioAnalysisProcessType,
              GovernanceBodyScenarioAnalysisRole,
              ManagementScenarioAnalysisRole,
              ModelingUndertakenDescription: value,
              ReasonSelectionofModel,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.ModelingUndertakenDescription ?? value;
          }
          if (errors.ModelingUndertakenDescription?.hasError) {
            runValidationTasks("ModelingUndertakenDescription", value);
          }
          setModelingUndertakenDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ModelingUndertakenDescription",
            ModelingUndertakenDescription
          )
        }
        errorMessage={errors.ModelingUndertakenDescription?.errorMessage}
        hasError={errors.ModelingUndertakenDescription?.hasError}
        {...getOverrideProps(overrides, "ModelingUndertakenDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Reasons why specific models were choosen"
        isRequired={false}
        isReadOnly={false}
        value={ReasonSelectionofModel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              ScenarioAnalysisProcessName,
              ScenarioAnalysisProcessDescription,
              ScenarioAnalysisProcessType,
              GovernanceBodyScenarioAnalysisRole,
              ManagementScenarioAnalysisRole,
              ModelingUndertakenDescription,
              ReasonSelectionofModel: value,
              ExternalPartyInvolvement,
              StakeholderInvolvement,
            };
            const result = onChange(modelFields);
            value = result?.ReasonSelectionofModel ?? value;
          }
          if (errors.ReasonSelectionofModel?.hasError) {
            runValidationTasks("ReasonSelectionofModel", value);
          }
          setReasonSelectionofModel(value);
        }}
        onBlur={() =>
          runValidationTasks("ReasonSelectionofModel", ReasonSelectionofModel)
        }
        errorMessage={errors.ReasonSelectionofModel?.errorMessage}
        hasError={errors.ReasonSelectionofModel?.hasError}
        {...getOverrideProps(overrides, "ReasonSelectionofModel")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid11")}
      >
        <TextField
          label="Name of any external party involved in modeling"
          isRequired={false}
          isReadOnly={false}
          value={ExternalPartyInvolvement}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioAnalysisProcessName,
                ScenarioAnalysisProcessDescription,
                ScenarioAnalysisProcessType,
                GovernanceBodyScenarioAnalysisRole,
                ManagementScenarioAnalysisRole,
                ModelingUndertakenDescription,
                ReasonSelectionofModel,
                ExternalPartyInvolvement: value,
                StakeholderInvolvement,
              };
              const result = onChange(modelFields);
              value = result?.ExternalPartyInvolvement ?? value;
            }
            if (errors.ExternalPartyInvolvement?.hasError) {
              runValidationTasks("ExternalPartyInvolvement", value);
            }
            setExternalPartyInvolvement(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "ExternalPartyInvolvement",
              ExternalPartyInvolvement
            )
          }
          errorMessage={errors.ExternalPartyInvolvement?.errorMessage}
          hasError={errors.ExternalPartyInvolvement?.hasError}
          {...getOverrideProps(overrides, "ExternalPartyInvolvement")}
        ></TextField>
        <TextField
          label="Name of any stakeholder involved in modeling"
          isRequired={false}
          isReadOnly={false}
          value={StakeholderInvolvement}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                ScenarioAnalysisProcessName,
                ScenarioAnalysisProcessDescription,
                ScenarioAnalysisProcessType,
                GovernanceBodyScenarioAnalysisRole,
                ManagementScenarioAnalysisRole,
                ModelingUndertakenDescription,
                ReasonSelectionofModel,
                ExternalPartyInvolvement,
                StakeholderInvolvement: value,
              };
              const result = onChange(modelFields);
              value = result?.StakeholderInvolvement ?? value;
            }
            if (errors.StakeholderInvolvement?.hasError) {
              runValidationTasks("StakeholderInvolvement", value);
            }
            setStakeholderInvolvement(value);
          }}
          onBlur={() =>
            runValidationTasks("StakeholderInvolvement", StakeholderInvolvement)
          }
          errorMessage={errors.StakeholderInvolvement?.errorMessage}
          hasError={errors.StakeholderInvolvement?.hasError}
          {...getOverrideProps(overrides, "StakeholderInvolvement")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || scenarioAnalysisProcessModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || scenarioAnalysisProcessModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
