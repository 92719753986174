import {PersistentModelConstructor} from "aws-amplify/dist/esm/datastore";
import {downloadData} from "aws-amplify/storage";
import React, {useState} from "react";
import {ProgressBar, Toast, ToastContainer} from "react-bootstrap";
import {BsDownload} from "react-icons/bs";
import {Evidence} from "../../models";
import ItemButton from "../action/ItemButton";
import useDownload from "../import/useDownload";
import style from "./Evidence.module.css";
import {defaultAccessLevel} from "./EvidenceForm";
const percentFormat = new Intl.NumberFormat(undefined, {
  style: "percent"
});

const DownloadButton: React.FC<{
  id: string;
  model: PersistentModelConstructor<any>;
  item: Evidence;
}> = ({model, id, item, item: {AttachmentKey, Name, ContentType}, ...rest}) => {
  const [{error: downloadError, fractionComplete}, setDownload] = useState<
    Partial<{
      fractionComplete: number;
      error: string;
    }>
  >({});
  const {busy, handleClick} = useDownload({
    data: async () => {
      if (AttachmentKey) {
        try {
          const {body} = await downloadData({
            key: AttachmentKey,
            options: {
              accessLevel: defaultAccessLevel,
              onProgress: ({totalBytes, transferredBytes}) => {
                setDownload({
                  fractionComplete: Math.max(
                    fractionComplete || 0,
                    transferredBytes / (totalBytes || 1)
                  )
                });
              } // optional progress callback
            }
          }).result;
          const result = await body.blob();
          setDownload({});
          return result;
        } catch (error) {
          setDownload({error: error ? error.toString() : "Unknown problem"});
        }
      }
    },
    contentType: ContentType || undefined,
    filename: Name || "unknown"
  });
  return (
    !!AttachmentKey && (
      <>
        <ItemButton
          id={id}
          item={item}
          disabled={undefined}
          className={undefined}
          variant="windowed"
          variation="primary"
          label="Download"
          isLoading={busy}
          model={model}
          icon={<BsDownload />}
          requiredPermissions="read"
          onClick={handleClick}
          suffix={undefined}
          {...rest}
        />
        <ToastContainer position="middle-center">
          <Toast
            show={!!fractionComplete || !!downloadError}
            autohide={false}
            bg={downloadError ? "danger" : "light"}
          >
            <Toast.Header>Downloading {Name}</Toast.Header>
            <Toast.Body>
              {downloadError ? (
                downloadError
              ) : (
                <ProgressBar
                  className={style.progress}
                  min={0}
                  max={1}
                  now={fractionComplete}
                  variant="info"
                  label={percentFormat.format(
                    Math.max(fractionComplete || 0, 0)
                  )}
                />
              )}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </>
    )
  );
};

export default DownloadButton;
