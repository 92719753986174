import React, {PropsWithChildren} from "react";

type Layout = {
  layout:
    | "normal" // Normal ui item display
    | "dense"; // attempt to show compact ui
};

export const LayoutContext = React.createContext<Layout>({} as Layout);

/**
 * Context for ui features
 * #
 */
export const LayoutProvider: React.FC<PropsWithChildren<Layout>> = ({
  layout = "normal",
  children
}) => {
  return (
    <LayoutContext.Provider value={{layout}}>{children}</LayoutContext.Provider>
  );
};
