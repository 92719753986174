import HelpBox from "./HelpBox";

export default function DisclosureBuilderHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Disclosure Builder Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          The Disclosure Builder helps you manage Climate Disclosures that conform to the NZ CS1 and CS3 standards.
        </p>
        <p>
          Follow these steps to create and manage a disclosure:
        </p>
        <ol type="1">
          <li>
            To load an existing disclosure, click in the <i>Choose Disclosure</i> box at the top left, a list of disclosures will appear, then select a disclosure. If there are no disclosures available, create a new disclosure.
          </li>
          <li>
            To create a new disclosure, fill in the fields on the left hand side of the screen. Enter a name and description for your disclosure, enter a disclosure author, select <i>Draft</i> from the <i>Disclosure status</i> list, then choose where you want the disclosure published to by selecting one or more options from the <i>Published to</i> dropdown menu and clicking Add. Four publishing options are available:
          </li>
          <ul>
            <li>Public - formal disclosure simplified for viewing by the general public</li>
            <li>Stakeholder - detailed analytics on disclosure, emission and asset data for stakeholders</li>
            <li>Assurance - version of the disclosure for use by an assurance partner</li>
            <li>Regulator - formal disclosure laid out with the requirements/objectives from the formal CS1-3 standard</li>
          </ul>
          <li>
            Each Disclosure is comprised of 7 mandatory sets of records, which group together related records into key disclosure focus areas.
            <ol type="a">
              <li>
                <b>Reporting Entity</b> - core data about your organisation making the disclosure.
              </li>
              <li>
                <b>Disclosure Period</b> - the reporting period your disclosure applies to.
              </li>
              <li>
                <b>Governance Records</b> - records that describe your Governance and Management of climate related risks and opportunities.
              </li>
              <li>
                <b>Strategy Records</b> - records that describe how climate change is currently impacting you, and how it may do so in the future.
              </li>
              <li>
                <b>Risk Management Records</b> - records that describe how climate-related risks are identified, assessed, and managed and how those processes are integrated into existing risk management processes.
              </li>
              <li>
                <b>Metrics and Targets Records</b> - records that describe how you measure and manage your climate-related risks and opportunities.
              </li>
              <li>
                <b>Supplemental Disclosure Records</b> - records describing additional information about changes in content or methods between disclosures, and use of any adoption provisions for your disclosure.
              </li>
            </ol>
          </li>
          <li>To add these records to the Disclosure, select the required records from the middle panels and click the <i>Create</i> or <i>Update</i> button at the top right of the screen.
          </li>
        </ol>
        For background information on disclosures, click this link to access the{" "}
        <a href="https://www.xrb.govt.nz/standards/climate-related-disclosures/aotearoa-new-zealand-climate-standards/aotearoa-new-zealand-climate-standard-1/">
          XRB NZCS1 Climate Disclosure standard
        </a>
        {" "}and this link to access the{" "}
        <a href="https://www.xrb.govt.nz/standards/climate-related-disclosures/aotearoa-new-zealand-climate-standards/aotearoa-new-zealand-climate-standard-3/">
          XRB NZCS3 Climate Disclosure standard.
        </a>
      </HelpBox.Content>
    </HelpBox>
  );
}
