import clsx from "clsx";
import {omit} from "lodash";
import {BsCopy} from "react-icons/bs";
import {combinedSchemaFor, findUniqueFieldName} from "../amplify/schemaHelpers";
import {useCustomerDataStore} from "../customer/useCustomerDataStore";
import ItemButton from "./ItemButton";
import styles from "./ItemButtons.module.css";

export default function CloneItemButton({
  icon = <BsCopy />,
  label = "Clone",
  buttonClass = undefined,
  id,
  model,
  subtype,
  ...rest
}) {
  const datastore = useCustomerDataStore();
  async function findUniqueName(item, nameField) {
    var copyIndex = 0;
    do {
      const name = `Copy ${copyIndex > 0 ? `(${copyIndex}) ` : ""}of ${
        item[nameField]
      }`;
      try {
        const [exists] = await datastore.query(
          model,
          (c) => c[nameField].eq(name),
          {limit: 1}
        );
        if (exists) continue;
      } catch (e) {
        continue;
      }
      return name;
    } while (copyIndex++ < Number.MAX_SAFE_INTEGER);
  }
  async function handleClick() {
    const {fields} = combinedSchemaFor({model, subtype});
    const itemToClone = await datastore.query(model, id);
    const uniqueFieldName = findUniqueFieldName(model);
    const newItem = await datastore.save(
      new model({
        ...omit(itemToClone, ["updatedAt", "createdAt", "id"]),
        ...Object.fromEntries(
          Object.values(fields)
            .filter((fields) => "valueOnClone" in fields) // can be null or undefined to ignore source
            .map(({name, valueOnClone}) => [name, valueOnClone])
        ),
        [uniqueFieldName]:
          uniqueFieldName &&
          (await findUniqueName(itemToClone, uniqueFieldName))
      })
    );
    console.debug("Cloned item:", newItem);
  }
  return (
    <ItemButton
      buttonClass={clsx(buttonClass, styles.cloneButton)}
      icon={icon}
      label={label}
      requiredPermissions={["create", "read"]}
      onClick={handleClick}
      disabled={!id}
      model={model}
      subtype={subtype}
      id={id}
      {...rest}
    />
  );
}

CloneItemButton.propTypes = {
  ...ItemButton.propTypes
};
