import { PersistentModelConstructor } from "aws-amplify/datastore";
import { combinedSchemaFor } from "../amplify/schemaHelpers";
import { UISchemaModel } from "../backend";
import EvidenceTable from "../evidence/EvidenceTable";
import { DecoratedFormProps } from "./withDecorations";

interface ExpectedProps {
  id?: string;
}
export interface ToolProps {
  id?: string;
  model: PersistentModelConstructor<any>;
  subtype?: string;
  children?: any;
}

export default function withEvidence(
  Form: React.FC<ExpectedProps & DecoratedFormProps>,
  {
    model,
    subtype
  }: {
    model: PersistentModelConstructor<any>;
    subtype?: string;
  }
): React.FC<ToolProps & DecoratedFormProps> {
  const modelSchema: UISchemaModel = combinedSchemaFor({ model, subtype });
  const evidenceArea = modelSchema?.layout?.Evidence ?? "Tertiary";

  const Component: React.FC<any> = modelSchema.showEvidence
    ? ({ id, decorations, ...rest }) => {
        return (
          <Form
            id={id}
            decorations={[
              decorations,
              !!id && {
                [evidenceArea]: <EvidenceTable model={model} subtype={subtype} id={id} />
              }
            ]}
            {...rest}
          />
        );
      }
    : Form;
  Component.displayName = "withEvidence";
  return Component;
}
