import { Button, Link } from "@aws-amplify/ui-react";
import clsx from "clsx";
import React, { useContext, useEffect, useLayoutEffect, useReducer, useRef } from "react";
import { CloseButton, Col, Container } from "react-bootstrap";
import { BsChevronDoubleLeft } from "react-icons/bs";
import styles from "./FormStack.module.css";
import { useContainerDimensions } from "./useContainerDimensions";
const FormStackContext = React.createContext(undefined);

function formsStackReducer({ forms, currentIndex }, action) {
  const current = forms.at(-1);
  switch (action.type) {
    case "reset":
      return {
        currentIndex: 0,
        forms: [{ form: action.form, breadcrumbName: action.breadcrumbName }]
      };
    case "complete":
      return {
        currentIndex,
        forms: forms.slice(0, currentIndex + 1)
      };
    case "push":
      return {
        currentIndex: forms.length,
        forms: [
          ...forms.slice(0, -1),
          { ...current, lastFocus: action.lastFocus },
          { form: action.form, breadcrumbName: action.breadcrumbName }
        ]
      };
    case "pop":
      return {
        currentIndex: Math.max(0, forms.length - 2),
        forms
      };
    case "goto":
      const topIndex = Math.max(0, Math.min(forms.length - 1, action.index));
      return {
        currentIndex: topIndex,
        forms
      };
    default:
      throw new Error(`unknown a action ${action.type}`);
  }
}
export function FormStackProvider({ base, baseBreadcrumbName, onChange }) {
  const [{ currentIndex, forms }, dispatch] = useReducer(formsStackReducer, {
    currentIndex: 0,
    forms: [{ form: base, breadcrumbName: baseBreadcrumbName }]
  });
  const current = forms?.[currentIndex];
  const wrapperRef = useRef();
  const formsRef = useRef();
  function pushForm(form, breadcrumbName) {
    const lastFocus = document.activeElement;
    dispatch({ type: "push", form, breadcrumbName, lastFocus });
    onChange && onChange(forms.length);
  }
  function popForm() {
    dispatch({ type: "pop" });
    onChange && onChange(Math.max(0, currentIndex - 1));
  }
  function gotoForm(index) {
    dispatch({ type: "goto", index });
    onChange && onChange(index);
  }
  function complete() {
    dispatch({ type: "complete" });
  }

  const { width } = useContainerDimensions(wrapperRef);
  const widthVal = `${width}px`;
  // Ensure reset when parent rerender occurs
  useEffect(() => dispatch({ type: "reset", form: base, breadcrumbName: baseBreadcrumbName }), [base, baseBreadcrumbName]);
  // On current form change, either scroll to its last known focussed component or to doc body
  useLayoutEffect(() => {
    (current.lastFocus || wrapperRef.current || document.body).scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [current]);

  // Keep all previous forms rendered but invisible
  return (
    <FormStackContext.Provider
      value={{
        breadcrumbs: forms.map(({ breadcrumbName }) => breadcrumbName),
        currentIndex,
        pushForm,
        popForm,
        gotoForm
      }}
    >
      <div ref={wrapperRef} className={styles.wrapper}>
        <div
          ref={formsRef}
          className={styles.formList}
          style={{
            transform: `translateX(-${currentIndex * width}px)`
          }}
          onTransitionEndCapture={({ target }) => {
            target === formsRef.current && complete();
          }}
        >
          {forms.map(({ form }, i, forms) => {
            return (
              <Container key={i} fluid style={{ flex: `0 0 ${widthVal}` }}>
                {form}
              </Container>
            );
          })}
        </div>
      </div>
    </FormStackContext.Provider>
  );
}

export function useFormStack() {
  const functions = useContext(FormStackContext);
  if (!functions) throw new Error("FormStackContext required");
  return functions;
}

export function FormStackBreadcrumbs() {
  const { breadcrumbs, gotoForm, popForm } = useFormStack();
  return (
    <div className="d-flex px-2 gap-2 align-items-center">
      <Button onClick={popForm}>
        <BsChevronDoubleLeft />
        Back
      </Button>
      <Col as="ul" className={clsx(styles.breadcrumbs)}>
        {breadcrumbs.map(
          (breadcrumbName, i) =>
            breadcrumbName && (
              <li key={i}>
                <Link onClick={() => gotoForm(i)}>{breadcrumbName}</Link>
              </li>
            )
        )}
      </Col>
      <CloseButton className="ms-auto" onClick={() => gotoForm(0)} />
    </div>
  );
}
