import HelpBox from "./HelpBox";

export default function GovernanceOversightActivitiesHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Governance Oversight Activities Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to collate records on how the governance body performs oversight over the organisation's climate-related risks and opportunities.
                </p>
                <p>
                    Follow these steps to collate the required governance oversight records:
                </p>
                <ol>
                    <li>Enter a name for the governance oversight records.</li>
                    <li>Enter a description of how the governance body ensures it has appropriate skills and competencies.</li>
                    <li>Select one or more items from the three tables in the middle of the screen to link these governance process records into your disclosure. If no records are present, click the <i>New</i> button at the bottom of each table to create new records.</li>
                    <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of how your governance body ensures it has these skills and competencies.</li>
                    <li>Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
