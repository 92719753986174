import { Flex } from "@aws-amplify/ui-react";
import { AnySchema, ErrorObject, Schema } from "ajv";
import { PersistentModel, PersistentModelConstructor } from "aws-amplify/datastore";
import { BaseSchema, JSONSchema } from "fluent-json-schema";
import { get, isEmpty, startCase } from "lodash";
import pluralize from "pluralize";
import { ReactNode } from "react";
import { toObjectGraph } from "../amplify/schemaHelpers";
import { uischema } from "../backend";
import { createLinkRenderer } from "../field/linkRenderer";
import { LayoutProvider } from "../modal/LayoutContext";
import { useModal } from "../modal/useModal";
import styles from "./Validators.module.css";
import { ajv } from "./ajv";
import { FieldValidationResponse, FieldValidator, FormValidator } from "./withValidators";
export type ValidationSchema = Schema | JSONSchema;
export function isSchemaValidator(validator: any): validator is ValidationSchema {
  return typeof validator === "object";
}

export function isFluentSchema(validator: any): validator is BaseSchema<any> {
  return isSchemaValidator(validator) && (validator as BaseSchema<any>).isFluentSchema;
}

function compile(schema: ValidationSchema) {
  const ajvSchema: AnySchema = isFluentSchema(schema) ? schema.valueOf() : schema;
  return ajv.compile(ajvSchema);
}

function splitAtDeepestItem(graph: PersistentModel, jsvPath: string) {
  const jsonPath = jsvPath
    .replaceAll(/(^\/)|[[\]]/g, "")
    .split("/")
    .filter(Boolean);
  const itemPathIndex = jsonPath.findLastIndex((facet, index, path) => {
    const object = get(graph, path.slice(0, index + 1).join("."));
    return typeof object === "object" && object && object?.constructor?.name && object.id;
  });
  return itemPathIndex === -1
    ? { itemPath: [], fieldPath: jsonPath }
    : { itemPath: jsonPath.slice(0, itemPathIndex + 1), fieldPath: jsonPath.slice(itemPathIndex + 1) };
}

function humanize(error: ErrorObject, fieldName?: string, fieldDisplayName?: string): string {
  const limit = error?.params?.limit;
  if (limit && fieldDisplayName) {
    const humanLimit = limit === 0 ? "zero" : limit === 1 ? "one" : limit;
    if (error?.keyword === "minItems") {
      return `must have ${humanLimit} '${pluralize(fieldDisplayName, limit)}'`;
    }
  }

  return !!error.message
    ? fieldName && fieldDisplayName // we have a field/displayname
      ? error.message.includes(fieldName)
        ? error.message?.replaceAll(fieldName, fieldDisplayName) // replace instances of field name with full display name
        : `${fieldDisplayName}: ${error.message}` // or prefix with displayname
      : error.message
    : "";
}

function Edit({ model, subtype, id }: { model: PersistentModelConstructor<any>; subtype?: string; id: string }): ReactNode {
  const Modal = useModal({ model, subtype });
  return <Modal.Edit className="ms-auto" id={id} />;
}

function Error({
  graph,
  itemPath,
  fieldPath,
  error
}: {
  graph: PersistentModel;
  error: ErrorObject;
  itemPath: string[];
  fieldPath: string[];
}): ReactNode {
  const errorItem = isEmpty(itemPath) ? graph : (get(graph, itemPath) as PersistentModel);
  const modelName = errorItem?.constructor?.name as string; // can use root model if at root and no model on root object
  const modelSchema = modelName && uischema().models?.[modelName];
  const subtype = modelSchema && modelSchema.discriminatorField && errorItem?.[modelSchema.discriminatorField];
  const fieldName = fieldPath.findLast((n) => !!n) || error?.params?.missingProperty;
  const fieldDisplayName =
    fieldName && modelSchema ? modelSchema?.fields?.[fieldName]?.displayName : fieldPath.map(startCase).join(".");
  const Link = modelSchema && createLinkRenderer({ modelName, subtype });
  const errorMessage = humanize(error, fieldName, fieldDisplayName);
  return errorItem ? (
    <Flex>
      <span>
        {Link && <Link row={errorItem} />}
        &nbsp;{errorMessage}
      </span>
      {modelSchema && errorItem.id && (
        <Edit model={errorItem.constructor as PersistentModelConstructor<any>} subtype={subtype} id={errorItem.id} />
      )}
    </Flex>
  ) : (
    error.message
  );
}

export function schemaFormValidator(
  schema: ValidationSchema,
  options?: {
    deep?: boolean; // use item passed as modelFields as root of whole graph
    generateOverrides?: boolean; // true to place field errors on root object as 'overrides' for form props rather than in list of errors
  }
): FormValidator {
  try {
    const compiledSchema = compile(schema);
    //console.debug("Publish schema", compiledSchema.schema);
    return async ({ modelFields }) => {
      const { deep, generateOverrides } = options || {};
      const graph = deep ? (await toObjectGraph(modelFields)).graph : modelFields;
      //console.debug("Publish Graph", graph);
      if (!(await compiledSchema(graph))) {
        const errors = compiledSchema.errors;
        if (Array.isArray(errors)) {
          const { overrides, nestedErrors } = errors.reduce(
            (acc, error) => {
              const { itemPath, fieldPath } = splitAtDeepestItem(graph, error.instancePath);
              if (generateOverrides && isEmpty(itemPath) && fieldPath.length === 1) {
                acc.overrides[fieldPath[0]] = { errorMessage: error.message, hasError: true };
              } else {
                acc.nestedErrors.push(<Error graph={graph} itemPath={itemPath} fieldPath={fieldPath} error={error} />);
              }
              return acc;
            },
            { overrides: {}, nestedErrors: [] } as { overrides: Record<string, any>; nestedErrors: ReactNode[] }
          );

          return {
            hasError: true,
            errorMessage: (
              <LayoutProvider layout="dense">
                <section className={styles.formValidationResult}>
                  <header>Record Validation issues:</header>
                  <main>
                    <ul>
                      {nestedErrors.map((error, i) => (
                        <li key={i}>{error}</li>
                      ))}
                    </ul>
                  </main>
                </section>
              </LayoutProvider>
            ),
            overrides
          };
        } else return undefined;
      } else return undefined;
    };
  } catch (e) {
    console.error("Failed compile:", schema);
    throw e;
  }
}
export function schemaFieldValidator(schema: ValidationSchema, isRequired?: boolean): FieldValidator {
  try {
    const compiledSchema = compile(
      isFluentSchema(schema) && !isRequired
        ? // Fluent schema has a nullable added if it's not required as Ajv treats unrequired root values as required otherwise
          schema.raw({ nullable: true })
        : schema
    );
    return async (value, chainedResponse: FieldValidationResponse): Promise<FieldValidationResponse> => {
      if (isRequired && !value) {
        // special case required field
        return {
          hasError: true,
          errorMessage: "a value is required"
        };
      } else if (!compiledSchema(value || null)) {
        const errors = compiledSchema.errors;
        return Array.isArray(errors)
          ? {
              hasError: true,
              errorMessage: errors.map((error) => error.message).join()
            }
          : chainedResponse;
      } else return chainedResponse;
    };
  } catch (e) {
    console.error("Failed compile:", schema);
    throw e;
  }
}
