import HelpBox from "./HelpBox";

export default function ManagementOversightProcessHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Management Oversight Process Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    This form allows you to create a record that describes a process by which management is informed about, makes decisions on, and monitors, climate-related risks and opportunities.
                </p>
                <p>
                    Follow these steps to define your management oversight process record:
                </p>
                <ol>
                    <li>Enter a name for the management oversight process.</li>
                    <li>Enter a description of the management oversight process.</li>
                    <li>Select a value from the <i>Process type</i> dropdown menu that describes the nature of this process. <b>Note</b>: if you have more than one oversight process, create separate processes each with a different process type. If you only have one unified oversight process, select <i>Integrated</i> from the process type dropdown menu.</li>
                    <li>Select a value for how often this process operates from the <i>Process frequency</i> dropdown menu.</li>
                    <li>Click the <i>New</i> button in the <i>Evidence Attachments</i> table to upload evidence of your management oversight process.</li>
                    <li>Finally, click the <b>Create</b> or <b>Update</b> buttons at the bottom right of the window.</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
