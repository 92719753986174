import { PersistentModelConstructor } from "aws-amplify/datastore";
import { combinedSchemaFor } from "../amplify/schemaHelpers";
import { formComponents } from "../backend";
export declare type FormAction = "Create" | "Update";

/**
 *
 * Return form for the amplify studio forms using a naming convention <ModelName>[<SubType>]<Create|Update>
 * @param {*} model amplify odel
 * @param {*} action
 * @param {*} subtype
 * @returns
 */
export function amplifyFormName(model: PersistentModelConstructor<any>, action: FormAction, subtype: string = ""): string {
  return `${model.name}${subtype}${action}Form`;
}

/**
 *
 * Return form component from the amplify studio forms using a naming convention <ModelName>[<SubType>]<Create|Update>
 * uischema override and/or wrapper(s) honoured
 * @param {*} model amplify model
 * @param {*} action
 * @param {*} subtype
 * @returns
 */
export function amplifyForm(model: PersistentModelConstructor<any>, action: FormAction, subtype: string = ""): React.FC {
  const modelSchema = combinedSchemaFor({ model, subtype });
  const { formWrappers, formOverride } = modelSchema?.formOptions?.[action] || {};
  const form = formOverride || formComponents()?.[amplifyFormName(model, action, subtype)];
  if (form) {
    if (formWrappers) {
      const basicOptions = { model, subtype, action };
      const wrappedForm = [formWrappers]
        .flat()
        .map((wrapper) =>
          typeof wrapper === "function"
            ? { wrapper, options: basicOptions }
            : { wrapper: wrapper.wrapper, options: { ...basicOptions, ...wrapper?.extraOptions } }
        )
        .reduceRight((func, { wrapper, options }) => wrapper(func, options), form);
      return wrappedForm;
    } else return form;
  } else throw new Error(`Could not find ${action} form for ${model.name}`);
}
