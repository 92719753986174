import {get} from "aws-amplify/api";
import {signOut} from "aws-amplify/auth";
import {DataStore} from "aws-amplify/datastore";
import {isArray, uniq} from "lodash";
import {useEffect, useState} from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Spinner
} from "react-bootstrap";
import {
  BsBoxArrowRight,
  BsDiagram3Fill,
  BsKey,
  BsPersonCircle
} from "react-icons/bs";
import {NavLink} from "react-router-dom";
import {fullDisplayName, shortDisplayName} from "../amplify/cognitoHelpers";
import {
  useCustomerAccount,
  useUserAttributes
} from "../customer/CustomerSessionProvider";
import {useToast} from "../util/Toast";

export default function UserMenu({...rest}) {
  const userAttributes = useUserAttributes();
  const showToast = useToast();
  const {allAppUsers, customer, setCustomer} = useCustomerAccount();
  const [customers, setCustomers] = useState();
  useEffect(() => {
    (async () => {
      if (isArray(allAppUsers)) {
        const customers = (
          await Promise.all(
            uniq(allAppUsers.map(({CustomerID}) => CustomerID)).map(
              async (customerId) => {
                try {
                  const {body} = await get({
                    apiName: "support",
                    path: `/customer/${customerId}`
                  }).response;
                  const customer = await body.json();
                  return customer;
                } catch (e) {
                  // unable to get customer details - ignore
                  console.warn("No customer known for, ignoring ", customerId);
                  return undefined;
                }
              }
            )
          )
        ).filter(Boolean);
        setCustomers(customers);
      }
    })();
  }, [allAppUsers]);
  return (
    <>
      <Dropdown as={ButtonGroup} className="ms-auto">
        <Button variant="secondary">
          <BsDiagram3Fill size="1.5em" />
          &nbsp;
          {customer?.companyName}
        </Button>
        <Dropdown.Toggle variant="secondary" split id="dropdown-split-basic" />
        <Dropdown.Menu>
          {customers &&
            customers.map(({companyName, customerIdentifier}, i) => (
              <Dropdown.Item
                key={i}
                disabled={
                  customerIdentifier === customer?.customerIdentifier || false
                }
                onClick={async () => await setCustomer(customerIdentifier)}
              >
                {companyName}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      <DropdownButton
        menuRole="menu"
        variant="secondary"
        title={
          <>
            <BsPersonCircle size="1.5em" />
            &nbsp;
            {fullDisplayName(userAttributes)}
          </>
        }
        {...rest}
        className="ms-2"
      >
        <Dropdown.Item as={NavLink} to="/user/changePassword">
          <BsKey />
          &nbsp;Change Password
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            showToast({
              content: (
                <div className="d-flex align-items-center  gap-2">
                  <Spinner />
                  Logging out {fullDisplayName(userAttributes)}
                </div>
              ),
              bg: "primary",
              onClose: async () => {
                await Promise.all([signOut(), DataStore.clear()]);
                document.location.href = "/";
              }
            });
          }}
        >
          <BsBoxArrowRight size="1em" />
          &nbsp;Logout {shortDisplayName(userAttributes)}
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
}
