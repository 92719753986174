/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Button, Flex, Grid, TextField } from "@aws-amplify/ui-react";
import { Evidence } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function EvidenceUpdateForm(props) {
  const {
    id: idProp,
    evidence: evidenceModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    CustomerID: "",
    TargetEntityModel: "",
    TargetEntityID: "",
    TargetSubtype: "",
    AttachmentKey: "",
    ContentType: "",
    Description: "",
    Name: "",
    ValidFrom: "",
    ValidTo: "",
    Size: "",
    Url: "",
  };
  const [CustomerID, setCustomerID] = React.useState(initialValues.CustomerID);
  const [TargetEntityModel, setTargetEntityModel] = React.useState(
    initialValues.TargetEntityModel
  );
  const [TargetEntityID, setTargetEntityID] = React.useState(
    initialValues.TargetEntityID
  );
  const [TargetSubtype, setTargetSubtype] = React.useState(
    initialValues.TargetSubtype
  );
  const [AttachmentKey, setAttachmentKey] = React.useState(
    initialValues.AttachmentKey
  );
  const [ContentType, setContentType] = React.useState(
    initialValues.ContentType
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [Name, setName] = React.useState(initialValues.Name);
  const [ValidFrom, setValidFrom] = React.useState(initialValues.ValidFrom);
  const [ValidTo, setValidTo] = React.useState(initialValues.ValidTo);
  const [Size, setSize] = React.useState(initialValues.Size);
  const [Url, setUrl] = React.useState(initialValues.Url);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = evidenceRecord
      ? { ...initialValues, ...evidenceRecord }
      : initialValues;
    setCustomerID(cleanValues.CustomerID);
    setTargetEntityModel(cleanValues.TargetEntityModel);
    setTargetEntityID(cleanValues.TargetEntityID);
    setTargetSubtype(cleanValues.TargetSubtype);
    setAttachmentKey(cleanValues.AttachmentKey);
    setContentType(cleanValues.ContentType);
    setDescription(cleanValues.Description);
    setName(cleanValues.Name);
    setValidFrom(cleanValues.ValidFrom);
    setValidTo(cleanValues.ValidTo);
    setSize(cleanValues.Size);
    setUrl(cleanValues.Url);
    setErrors({});
  };
  const [evidenceRecord, setEvidenceRecord] = React.useState(evidenceModelProp);
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(Evidence, idProp)
        : evidenceModelProp;
      setEvidenceRecord(record);
    };
    queryData();
  }, [idProp, evidenceModelProp]);
  React.useEffect(resetStateValues, [evidenceRecord]);
  const validations = {
    CustomerID: [],
    TargetEntityModel: [],
    TargetEntityID: [],
    TargetSubtype: [],
    AttachmentKey: [],
    ContentType: [],
    Description: [],
    Name: [],
    ValidFrom: [],
    ValidTo: [],
    Size: [],
    Url: [{ type: "URL" }],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          CustomerID,
          TargetEntityModel,
          TargetEntityID,
          TargetSubtype,
          AttachmentKey,
          ContentType,
          Description,
          Name,
          ValidFrom,
          ValidTo,
          Size,
          Url,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            Evidence.copyOf(evidenceRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "EvidenceUpdateForm")}
      {...rest}
    >
      <TextField
        label="Customer id"
        isRequired={false}
        isReadOnly={false}
        value={CustomerID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID: value,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.CustomerID ?? value;
          }
          if (errors.CustomerID?.hasError) {
            runValidationTasks("CustomerID", value);
          }
          setCustomerID(value);
        }}
        onBlur={() => runValidationTasks("CustomerID", CustomerID)}
        errorMessage={errors.CustomerID?.errorMessage}
        hasError={errors.CustomerID?.hasError}
        {...getOverrideProps(overrides, "CustomerID")}
      ></TextField>
      <TextField
        label="Target entity model"
        isRequired={false}
        isReadOnly={false}
        value={TargetEntityModel}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel: value,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.TargetEntityModel ?? value;
          }
          if (errors.TargetEntityModel?.hasError) {
            runValidationTasks("TargetEntityModel", value);
          }
          setTargetEntityModel(value);
        }}
        onBlur={() =>
          runValidationTasks("TargetEntityModel", TargetEntityModel)
        }
        errorMessage={errors.TargetEntityModel?.errorMessage}
        hasError={errors.TargetEntityModel?.hasError}
        {...getOverrideProps(overrides, "TargetEntityModel")}
      ></TextField>
      <TextField
        label="Target entity id"
        isRequired={false}
        isReadOnly={false}
        value={TargetEntityID}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID: value,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.TargetEntityID ?? value;
          }
          if (errors.TargetEntityID?.hasError) {
            runValidationTasks("TargetEntityID", value);
          }
          setTargetEntityID(value);
        }}
        onBlur={() => runValidationTasks("TargetEntityID", TargetEntityID)}
        errorMessage={errors.TargetEntityID?.errorMessage}
        hasError={errors.TargetEntityID?.hasError}
        {...getOverrideProps(overrides, "TargetEntityID")}
      ></TextField>
      <TextField
        label="Target subtype"
        isRequired={false}
        isReadOnly={false}
        value={TargetSubtype}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype: value,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.TargetSubtype ?? value;
          }
          if (errors.TargetSubtype?.hasError) {
            runValidationTasks("TargetSubtype", value);
          }
          setTargetSubtype(value);
        }}
        onBlur={() => runValidationTasks("TargetSubtype", TargetSubtype)}
        errorMessage={errors.TargetSubtype?.errorMessage}
        hasError={errors.TargetSubtype?.hasError}
        {...getOverrideProps(overrides, "TargetSubtype")}
      ></TextField>
      <TextField
        label="Attachment key"
        isRequired={false}
        isReadOnly={false}
        value={AttachmentKey}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey: value,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.AttachmentKey ?? value;
          }
          if (errors.AttachmentKey?.hasError) {
            runValidationTasks("AttachmentKey", value);
          }
          setAttachmentKey(value);
        }}
        onBlur={() => runValidationTasks("AttachmentKey", AttachmentKey)}
        errorMessage={errors.AttachmentKey?.errorMessage}
        hasError={errors.AttachmentKey?.hasError}
        {...getOverrideProps(overrides, "AttachmentKey")}
      ></TextField>
      <TextField
        label="Content type"
        isRequired={false}
        isReadOnly={false}
        value={ContentType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType: value,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.ContentType ?? value;
          }
          if (errors.ContentType?.hasError) {
            runValidationTasks("ContentType", value);
          }
          setContentType(value);
        }}
        onBlur={() => runValidationTasks("ContentType", ContentType)}
        errorMessage={errors.ContentType?.errorMessage}
        hasError={errors.ContentType?.hasError}
        {...getOverrideProps(overrides, "ContentType")}
      ></TextField>
      <TextField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        value={Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description: value,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextField>
      <TextField
        label="Name"
        isRequired={false}
        isReadOnly={false}
        value={Name}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name: value,
              ValidFrom,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.Name ?? value;
          }
          if (errors.Name?.hasError) {
            runValidationTasks("Name", value);
          }
          setName(value);
        }}
        onBlur={() => runValidationTasks("Name", Name)}
        errorMessage={errors.Name?.errorMessage}
        hasError={errors.Name?.hasError}
        {...getOverrideProps(overrides, "Name")}
      ></TextField>
      <TextField
        label="Valid from"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={ValidFrom}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom: value,
              ValidTo,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.ValidFrom ?? value;
          }
          if (errors.ValidFrom?.hasError) {
            runValidationTasks("ValidFrom", value);
          }
          setValidFrom(value);
        }}
        onBlur={() => runValidationTasks("ValidFrom", ValidFrom)}
        errorMessage={errors.ValidFrom?.errorMessage}
        hasError={errors.ValidFrom?.hasError}
        {...getOverrideProps(overrides, "ValidFrom")}
      ></TextField>
      <TextField
        label="Valid to"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={ValidTo}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo: value,
              Size,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.ValidTo ?? value;
          }
          if (errors.ValidTo?.hasError) {
            runValidationTasks("ValidTo", value);
          }
          setValidTo(value);
        }}
        onBlur={() => runValidationTasks("ValidTo", ValidTo)}
        errorMessage={errors.ValidTo?.errorMessage}
        hasError={errors.ValidTo?.hasError}
        {...getOverrideProps(overrides, "ValidTo")}
      ></TextField>
      <TextField
        label="Size"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={Size}
        onChange={(e) => {
          let value = isNaN(parseInt(e.target.value))
            ? e.target.value
            : parseInt(e.target.value);
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size: value,
              Url,
            };
            const result = onChange(modelFields);
            value = result?.Size ?? value;
          }
          if (errors.Size?.hasError) {
            runValidationTasks("Size", value);
          }
          setSize(value);
        }}
        onBlur={() => runValidationTasks("Size", Size)}
        errorMessage={errors.Size?.errorMessage}
        hasError={errors.Size?.hasError}
        {...getOverrideProps(overrides, "Size")}
      ></TextField>
      <TextField
        label="Url"
        isRequired={false}
        isReadOnly={false}
        value={Url}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              CustomerID,
              TargetEntityModel,
              TargetEntityID,
              TargetSubtype,
              AttachmentKey,
              ContentType,
              Description,
              Name,
              ValidFrom,
              ValidTo,
              Size,
              Url: value,
            };
            const result = onChange(modelFields);
            value = result?.Url ?? value;
          }
          if (errors.Url?.hasError) {
            runValidationTasks("Url", value);
          }
          setUrl(value);
        }}
        onBlur={() => runValidationTasks("Url", Url)}
        errorMessage={errors.Url?.errorMessage}
        hasError={errors.Url?.hasError}
        {...getOverrideProps(overrides, "Url")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || evidenceModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || evidenceModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
