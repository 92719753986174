import { useRef } from "react";

import { Modal } from "react-bootstrap";

import { Flex } from "@aws-amplify/ui-react";

import EditItemButton from "../action/EditItemButton";
import NewItemButton from "../../shared/action/NewItemButton";
import { combinedSchemaFor, findUniqueFieldName } from "../../shared/amplify/schemaHelpers";
import { amplifyForm } from "../form/amplifyForms";
import { withAll } from "../form/withAll";
import { FormStackBreadcrumbs, useFormStack } from "../util/FormStack";
import { useToast } from "../util/Toast";
function Outer({ children, ...rest }) {
  return (
    <Flex padding=".5em" {...rest}>
      {children}
    </Flex>
  );
}

function ModalDialog({
  action: Action,
  dialog: Dialog,
  onSuccess,
  header,
  footer,
  children,
  modalComponent: ModalComponent,
  modalHeaderComponent: ModalHeaderComponent,
  modalBodyComponent: ModalBodyComponent,
  modalFooterComponent: ModalFooterComponent,
  id,
  model,
  subtype,
  nameField,
  size,
  className,
  ...rest
}) {
  const { pushForm, popForm } = useFormStack();
  const actionsRef = useRef(null);
  const { displayName } = combinedSchemaFor({ model, subtype });
  function handleSuccess(fields) {
    handleClose();
  }
  const handleClose = () => popForm();
  const handleShow = () =>
    pushForm(
      <div className="shadow p-3 mb-5 rounded" style={{ height: "100%" }}>
        <header>
          <FormStackBreadcrumbs />
          {header}
        </header>
        <hr></hr>
        <section>
          <Dialog
            onSuccess={handleSuccess}
            onError={(_modelFields, message) =>
              showToast({
                header: "Sorry, we encountered a problem saving this data",
                content: message,
                delay: undefined,
                bg: "danger"
              })
            }
            id={id}
            actionsRef={actionsRef}
            {...rest}
          />
          {children}
        </section>
        <Flex as="footer" justifyContent="flex-end">
          {footer}
          {actionsBar}
        </Flex>
      </div>,
      displayName
    );
  const showToast = useToast();
  const actionsBar = <Flex className="p-2" ref={actionsRef}></Flex>;
  return (
    <>
      <Action onClick={handleShow} className={className} />
    </>
  );
}

/**
 * Create an Edit New modal button pair component using with the given forms:
 *
 * MyEntityModal.jsx:
 *
 *  export default createModal({
 *    createDialog: MyEntityCreateForm,
 *    updateDialog: MyEntityUpdateForm,
 * })
 *
 * MyEntityComponent.jsx:
 *
 * import MyEntityModal from "./MyEntityModal"
 *
 * <MyEntityModal id={id}/> displays both Edit and New buttons, if id id null edit is disabled
 * <MyEntityModal direction="column" id={id}/> Display buttons vertically (props passed to Flex wrapper)
 * <MyEntityModal.Edit id={id}/> displays Edit button, if id id null edit is disabled
 * <MyEntityModal.New/> New button props passed to Button
 *
 *
 * @param {*} param0
 * @returns
 */
export function createModal({
  model,
  subtype,
  createDialog = amplifyForm(model, "Create", subtype),
  updateDialog = amplifyForm(model, "Update", subtype),
  modalComponent = Modal,
  modalHeaderComponent = Modal.Header,
  modalBodyComponent = Modal.Body,
  modalFooterComponent = Modal.Footer,
  size = "xl",
  nameField = findUniqueFieldName(model)
}) {
  const componentProps = {
    modalComponent,
    modalHeaderComponent,
    modalBodyComponent,
    modalFooterComponent
  };
  const Dialog = withAll({
    createDialog,
    updateDialog,
    model,
    nameField,
    subtype
  });
  const editButtonAndDlg =
    updateDialog &&
    (({ id, item, children, ...rest }) => (
      <ModalDialog
        action={(props) => <EditItemButton disabled={!id} item={item} model={model} {...props} />}
        dialog={(props) => <Dialog {...props}>{children}</Dialog>}
        id={id}
        model={model}
        subtype={subtype}
        nameField={nameField}
        size={size}
        {...componentProps}
        {...rest}
      />
    ));
  const newButtonAndDlg =
    createDialog &&
    (({ children, ...rest }) => (
      <ModalDialog
        action={(props) => <NewItemButton model={model} {...props} />}
        dialog={(props) => <Dialog {...props}>{children}</Dialog>}
        model={model}
        subtype={subtype}
        nameField={nameField}
        size={size}
        {...componentProps}
        {...rest}
      />
    ));
  const all = ({ id, children, ...rest }) => (
    <Outer {...rest}>
      {editButtonAndDlg({ id, children })}
      {newButtonAndDlg({ children })}
    </Outer>
  );
  return Object.assign(all, {
    Outer: Outer,
    Edit: editButtonAndDlg,
    New: newButtonAndDlg
  });
}
