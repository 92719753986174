import { UISchema } from "./backend";
import EvidenceForm from "./evidence/EvidenceForm";
import FileSize from "./field/FileSizeField";
import { createLinkRenderer } from "./field/linkRenderer";
const uiSchemaShared: Partial<UISchema> = {
  /**
   * Config for schema items that are common between all app versions
   */
  models: {
    AppUsers: {
      hideValidationTool: true,
      hideComments: true,
      hideFromSearch: true,
      fields: {
        Email: {
          isUnique: true
        },
        Username: {
          hideInTable: true
        }
      }
    },
    AuditLog: {
      hideValidationTool: true,
      noUpload: true,
      noDownload: true,
      fields: {
        LogName: {
          isUnique: true
        }
      }
    },
    Comment: {
      noUpload: true,
      hideValidationTool: true,
      hideComments: true
    },
    Evidence: {
      formOptions: {
        Create: {
          formOverride: EvidenceForm
        },
        Update: {
          formOverride: EvidenceForm
        }
      },
      includeColumns: /^(?!AttachmentKey|ContentType|TargetEntityModel|TargetSubtype)/,
      fields: {
        TargetEntityModel: {
          displayName: "Source",
          order: 0
        },
        TargetSubtype: {
          order: 1
        },
        Name: {
          order: 2
        },
        Description: {
          order: 3
        },
        ValidFrom: {
          order: 4
        },
        ValidTo: {
          order: 5
        },
        ContentType: {
          order: 6
        },
        Size: {
          order: 7,
          customRenderer: FileSize
        },
        AttachmentKey: {
          order: 8,
          hideFromSearch: true
        },
        TargetEntityID: {
          displayName: "Attached To",
          customRenderer: createLinkRenderer({
            idField: "TargetEntityID",
            modelField: "TargetEntityModel",
            subtypeField: "TargetSubtype"
          })
        }
      },
      hideValidationTool: true
    }
  },
  enums: {
    AppGroup: { displayColor: "rgba(49, 66, 111,0.8)" }
  }
};

export default uiSchemaShared;
