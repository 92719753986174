import HelpBox from "./HelpBox";

export default function StrategyDataSetHelp() {
  return (
    <HelpBox>
      <HelpBox.Header>Strategy Records Help</HelpBox.Header>
      <HelpBox.Content>
        <p>
          This form allows you to collate records on Strategy data required for a climate-related disclosure.
        </p>
        <p>
          The purpose of your Strategy data is to understand current and future
          impacts from climate change arising from climate-related risks and opportunities, how these are
          integrated into your business strategy, and implications for your
          business model, including how resources will be used to support
          strategic resilience to climate-related risks and to realise
          climate-related opportunities.
        </p>
        <p>Follow these steps to create the required Strategy records:</p>
        <ol>
          <li>
            Create a name for the set of records in the <i>Strategy data set name</i> field.
          </li>
          <li>Next, enter a <i>Description</i> for this record set.</li>
          <li>
            Finally, select or create the appropriate records from the tables in the center of the screen.
          </li>
          <li>
            Once selected, choose <b>Create</b> or <b>Update</b>.
          </li>
        </ol>

      </HelpBox.Content>
    </HelpBox>
  );
}
