import {createTheme, defaultTheme} from "@aws-amplify/ui-react";

const grlTheme = createTheme(
  {
    name: "grl-theme",
    tokens: {
      colors: {
        brand: {
          primary: Object.fromEntries(
            Array.from({length: 10}, (_, i) => [
              (i + 1) * 10,
              {value: `rgba(2,73,87,${(i + 1) / 10})`}
            ])
          )
        },
        background: {
          primary: "var(--bs-body-bg)",
          secondary: "var(--bs-secondary-bg)",
          tertiary: "var(--bs-tertiary-bg)"
        }
      },
      components: {
        expander: {
          boxShadow: "var(--amplify-shadows-medium)"
        }
      }
    }

    //...
  },
  defaultTheme
);

export default grlTheme;
