import {View} from "@aws-amplify/ui-react";
import {
  PersistentModelConstructor,
  RecursiveModelPredicateExtender
} from "aws-amplify/datastore";
import React from "react";
import {BsPaperclip} from "react-icons/bs";
import {JSX} from "react/jsx-runtime";
import {Evidence} from "../../models";
import DeleteItemButton from "../action/DeleteItemButton";
import {models} from "../backend";
import {useModal} from "../modal/useModal";
import DataItemTable from "../table/DataItemTable";
import DownloadButton from "./DownloadButton";
import style from "./Evidence.module.css";
import {MutableEvidence} from "./EvidenceForm";
export const percentFormat = new Intl.NumberFormat(undefined, {
  style: "percent"
});

export declare interface EvidenceTableProps {
  model: PersistentModelConstructor<any>;
  id: string;
  subtype?: string;
}

export default function EvidenceTable({
  model,
  subtype,
  id
}: EvidenceTableProps): React.JSX.Element {
  const evidenceModel = models().Evidence;
  const {Edit, New} = useModal({model: evidenceModel, subtype});
  const initialState: MutableEvidence = {
    TargetEntityID: id,
    TargetEntityModel: model.name,
    TargetSubtype: subtype
  };
  const predicates: RecursiveModelPredicateExtender<Evidence>[] = [
    (c) => c.TargetEntityID.eq(id),
    (c) => c.TargetEntityModel.eq(model.name)
  ];
  if (subtype) {
    predicates.push((c) => c.TargetSubtype.eq(subtype));
  }
  return (
    <View className={style.root}>
      <h6>
        <BsPaperclip />
        &nbsp; Evidence Attachments
      </h6>
      <DataItemTable
        model={evidenceModel}
        new={(props: JSX.IntrinsicAttributes) => (
          <New initialState={initialState} label={undefined} {...props} />
        )}
        itemActions={[
          DownloadButton,
          DeleteItemButton,
          (props: JSX.IntrinsicAttributes) => (
            <Edit initialState={initialState} label={undefined} {...props} />
          )
        ]}
        select={false}
        subtype={undefined}
        onChange={undefined}
        error={undefined}
        fullSize={undefined}
        predicates={predicates}
      />
    </View>
  );
}
