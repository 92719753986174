/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { EmissionReductionPathway } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function EmissionReductionPathwayCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    PathwayName: "",
    ScopeofOperations: "",
    PolicyAssumptions: "",
    SocioeconomicAssumptions: "",
    MacroeconomicTrends: "",
    EnergyPathways: "",
    CarbonSequestration: "",
    TechnologyAssumptions: "",
  };
  const [PathwayName, setPathwayName] = React.useState(
    initialValues.PathwayName
  );
  const [ScopeofOperations, setScopeofOperations] = React.useState(
    initialValues.ScopeofOperations
  );
  const [PolicyAssumptions, setPolicyAssumptions] = React.useState(
    initialValues.PolicyAssumptions
  );
  const [SocioeconomicAssumptions, setSocioeconomicAssumptions] =
    React.useState(initialValues.SocioeconomicAssumptions);
  const [MacroeconomicTrends, setMacroeconomicTrends] = React.useState(
    initialValues.MacroeconomicTrends
  );
  const [EnergyPathways, setEnergyPathways] = React.useState(
    initialValues.EnergyPathways
  );
  const [CarbonSequestration, setCarbonSequestration] = React.useState(
    initialValues.CarbonSequestration
  );
  const [TechnologyAssumptions, setTechnologyAssumptions] = React.useState(
    initialValues.TechnologyAssumptions
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setPathwayName(initialValues.PathwayName);
    setScopeofOperations(initialValues.ScopeofOperations);
    setPolicyAssumptions(initialValues.PolicyAssumptions);
    setSocioeconomicAssumptions(initialValues.SocioeconomicAssumptions);
    setMacroeconomicTrends(initialValues.MacroeconomicTrends);
    setEnergyPathways(initialValues.EnergyPathways);
    setCarbonSequestration(initialValues.CarbonSequestration);
    setTechnologyAssumptions(initialValues.TechnologyAssumptions);
    setErrors({});
  };
  const validations = {
    PathwayName: [],
    ScopeofOperations: [],
    PolicyAssumptions: [],
    SocioeconomicAssumptions: [],
    MacroeconomicTrends: [],
    EnergyPathways: [],
    CarbonSequestration: [],
    TechnologyAssumptions: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          PathwayName,
          ScopeofOperations,
          PolicyAssumptions,
          SocioeconomicAssumptions,
          MacroeconomicTrends,
          EnergyPathways,
          CarbonSequestration,
          TechnologyAssumptions,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new EmissionReductionPathway(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "EmissionReductionPathwayCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Emissions Reduction Pathway"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS3 paragraph 51 a iii."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Create a record describing the emissions reduction pathway for your scenario, and the assumptions underlying pathway development over time."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Pathway name"
        isRequired={false}
        isReadOnly={false}
        value={PathwayName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName: value,
              ScopeofOperations,
              PolicyAssumptions,
              SocioeconomicAssumptions,
              MacroeconomicTrends,
              EnergyPathways,
              CarbonSequestration,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.PathwayName ?? value;
          }
          if (errors.PathwayName?.hasError) {
            runValidationTasks("PathwayName", value);
          }
          setPathwayName(value);
        }}
        onBlur={() => runValidationTasks("PathwayName", PathwayName)}
        errorMessage={errors.PathwayName?.errorMessage}
        hasError={errors.PathwayName?.hasError}
        {...getOverrideProps(overrides, "PathwayName")}
      ></TextField>
      <TextAreaField
        label="Scope of operations covered"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations: value,
              PolicyAssumptions,
              SocioeconomicAssumptions,
              MacroeconomicTrends,
              EnergyPathways,
              CarbonSequestration,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.ScopeofOperations ?? value;
          }
          if (errors.ScopeofOperations?.hasError) {
            runValidationTasks("ScopeofOperations", value);
          }
          setScopeofOperations(value);
        }}
        onBlur={() =>
          runValidationTasks("ScopeofOperations", ScopeofOperations)
        }
        errorMessage={errors.ScopeofOperations?.errorMessage}
        hasError={errors.ScopeofOperations?.hasError}
        {...getOverrideProps(overrides, "ScopeofOperations")}
      ></TextAreaField>
      <TextAreaField
        label="Policy assumptions"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations,
              PolicyAssumptions: value,
              SocioeconomicAssumptions,
              MacroeconomicTrends,
              EnergyPathways,
              CarbonSequestration,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.PolicyAssumptions ?? value;
          }
          if (errors.PolicyAssumptions?.hasError) {
            runValidationTasks("PolicyAssumptions", value);
          }
          setPolicyAssumptions(value);
        }}
        onBlur={() =>
          runValidationTasks("PolicyAssumptions", PolicyAssumptions)
        }
        errorMessage={errors.PolicyAssumptions?.errorMessage}
        hasError={errors.PolicyAssumptions?.hasError}
        {...getOverrideProps(overrides, "PolicyAssumptions")}
      ></TextAreaField>
      <TextAreaField
        label="Socioeconomic assumptions"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations,
              PolicyAssumptions,
              SocioeconomicAssumptions: value,
              MacroeconomicTrends,
              EnergyPathways,
              CarbonSequestration,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.SocioeconomicAssumptions ?? value;
          }
          if (errors.SocioeconomicAssumptions?.hasError) {
            runValidationTasks("SocioeconomicAssumptions", value);
          }
          setSocioeconomicAssumptions(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "SocioeconomicAssumptions",
            SocioeconomicAssumptions
          )
        }
        errorMessage={errors.SocioeconomicAssumptions?.errorMessage}
        hasError={errors.SocioeconomicAssumptions?.hasError}
        {...getOverrideProps(overrides, "SocioeconomicAssumptions")}
      ></TextAreaField>
      <TextAreaField
        label="Macroeconomic trends"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations,
              PolicyAssumptions,
              SocioeconomicAssumptions,
              MacroeconomicTrends: value,
              EnergyPathways,
              CarbonSequestration,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.MacroeconomicTrends ?? value;
          }
          if (errors.MacroeconomicTrends?.hasError) {
            runValidationTasks("MacroeconomicTrends", value);
          }
          setMacroeconomicTrends(value);
        }}
        onBlur={() =>
          runValidationTasks("MacroeconomicTrends", MacroeconomicTrends)
        }
        errorMessage={errors.MacroeconomicTrends?.errorMessage}
        hasError={errors.MacroeconomicTrends?.hasError}
        {...getOverrideProps(overrides, "MacroeconomicTrends")}
      ></TextAreaField>
      <TextAreaField
        label="Energy pathways"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations,
              PolicyAssumptions,
              SocioeconomicAssumptions,
              MacroeconomicTrends,
              EnergyPathways: value,
              CarbonSequestration,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.EnergyPathways ?? value;
          }
          if (errors.EnergyPathways?.hasError) {
            runValidationTasks("EnergyPathways", value);
          }
          setEnergyPathways(value);
        }}
        onBlur={() => runValidationTasks("EnergyPathways", EnergyPathways)}
        errorMessage={errors.EnergyPathways?.errorMessage}
        hasError={errors.EnergyPathways?.hasError}
        {...getOverrideProps(overrides, "EnergyPathways")}
      ></TextAreaField>
      <TextAreaField
        label="Carbon sequestration"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations,
              PolicyAssumptions,
              SocioeconomicAssumptions,
              MacroeconomicTrends,
              EnergyPathways,
              CarbonSequestration: value,
              TechnologyAssumptions,
            };
            const result = onChange(modelFields);
            value = result?.CarbonSequestration ?? value;
          }
          if (errors.CarbonSequestration?.hasError) {
            runValidationTasks("CarbonSequestration", value);
          }
          setCarbonSequestration(value);
        }}
        onBlur={() =>
          runValidationTasks("CarbonSequestration", CarbonSequestration)
        }
        errorMessage={errors.CarbonSequestration?.errorMessage}
        hasError={errors.CarbonSequestration?.hasError}
        {...getOverrideProps(overrides, "CarbonSequestration")}
      ></TextAreaField>
      <TextAreaField
        label="Technology assumptions"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              PathwayName,
              ScopeofOperations,
              PolicyAssumptions,
              SocioeconomicAssumptions,
              MacroeconomicTrends,
              EnergyPathways,
              CarbonSequestration,
              TechnologyAssumptions: value,
            };
            const result = onChange(modelFields);
            value = result?.TechnologyAssumptions ?? value;
          }
          if (errors.TechnologyAssumptions?.hasError) {
            runValidationTasks("TechnologyAssumptions", value);
          }
          setTechnologyAssumptions(value);
        }}
        onBlur={() =>
          runValidationTasks("TechnologyAssumptions", TechnologyAssumptions)
        }
        errorMessage={errors.TechnologyAssumptions?.errorMessage}
        hasError={errors.TechnologyAssumptions?.hasError}
        {...getOverrideProps(overrides, "TechnologyAssumptions")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
