/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { Opportunities } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function OpportunitiesCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    OpportunityName: "",
    Description: "",
    OpportunityType: "",
    ArisesinValueChain: "",
    PrimaryFinancialImpact: "",
    Likelihood: "",
    Priority: "",
    TimeHorizon: "",
  };
  const [OpportunityName, setOpportunityName] = React.useState(
    initialValues.OpportunityName
  );
  const [Description, setDescription] = React.useState(
    initialValues.Description
  );
  const [OpportunityType, setOpportunityType] = React.useState(
    initialValues.OpportunityType
  );
  const [ArisesinValueChain, setArisesinValueChain] = React.useState(
    initialValues.ArisesinValueChain
  );
  const [PrimaryFinancialImpact, setPrimaryFinancialImpact] = React.useState(
    initialValues.PrimaryFinancialImpact
  );
  const [Likelihood, setLikelihood] = React.useState(initialValues.Likelihood);
  const [Priority, setPriority] = React.useState(initialValues.Priority);
  const [TimeHorizon, setTimeHorizon] = React.useState(
    initialValues.TimeHorizon
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setOpportunityName(initialValues.OpportunityName);
    setDescription(initialValues.Description);
    setOpportunityType(initialValues.OpportunityType);
    setArisesinValueChain(initialValues.ArisesinValueChain);
    setPrimaryFinancialImpact(initialValues.PrimaryFinancialImpact);
    setLikelihood(initialValues.Likelihood);
    setPriority(initialValues.Priority);
    setTimeHorizon(initialValues.TimeHorizon);
    setErrors({});
  };
  const validations = {
    OpportunityName: [],
    Description: [],
    OpportunityType: [],
    ArisesinValueChain: [],
    PrimaryFinancialImpact: [],
    Likelihood: [],
    Priority: [],
    TimeHorizon: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          OpportunityName,
          Description,
          OpportunityType,
          ArisesinValueChain,
          PrimaryFinancialImpact,
          Likelihood,
          Priority,
          TimeHorizon,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new Opportunities(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "OpportunitiesCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Climate Related Opportunity"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 14 b-c."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Create a record that describes a climate-related Opportunity you have identified."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Opportunity name"
        isRequired={false}
        isReadOnly={false}
        value={OpportunityName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OpportunityName: value,
              Description,
              OpportunityType,
              ArisesinValueChain,
              PrimaryFinancialImpact,
              Likelihood,
              Priority,
              TimeHorizon,
            };
            const result = onChange(modelFields);
            value = result?.OpportunityName ?? value;
          }
          if (errors.OpportunityName?.hasError) {
            runValidationTasks("OpportunityName", value);
          }
          setOpportunityName(value);
        }}
        onBlur={() => runValidationTasks("OpportunityName", OpportunityName)}
        errorMessage={errors.OpportunityName?.errorMessage}
        hasError={errors.OpportunityName?.hasError}
        {...getOverrideProps(overrides, "OpportunityName")}
      ></TextField>
      <TextAreaField
        label="Description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OpportunityName,
              Description: value,
              OpportunityType,
              ArisesinValueChain,
              PrimaryFinancialImpact,
              Likelihood,
              Priority,
              TimeHorizon,
            };
            const result = onChange(modelFields);
            value = result?.Description ?? value;
          }
          if (errors.Description?.hasError) {
            runValidationTasks("Description", value);
          }
          setDescription(value);
        }}
        onBlur={() => runValidationTasks("Description", Description)}
        errorMessage={errors.Description?.errorMessage}
        hasError={errors.Description?.hasError}
        {...getOverrideProps(overrides, "Description")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid6")}
      >
        <SelectField
          label="Opportunity type"
          placeholder="Please select an option"
          isDisabled={false}
          value={OpportunityType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OpportunityName,
                Description,
                OpportunityType: value,
                ArisesinValueChain,
                PrimaryFinancialImpact,
                Likelihood,
                Priority,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.OpportunityType ?? value;
            }
            if (errors.OpportunityType?.hasError) {
              runValidationTasks("OpportunityType", value);
            }
            setOpportunityType(value);
          }}
          onBlur={() => runValidationTasks("OpportunityType", OpportunityType)}
          errorMessage={errors.OpportunityType?.errorMessage}
          hasError={errors.OpportunityType?.hasError}
          {...getOverrideProps(overrides, "OpportunityType")}
        >
          <option
            children="Energy source"
            value="Energy_Source"
            {...getOverrideProps(overrides, "OpportunityTypeoption0")}
          ></option>
          <option
            children="Markets"
            value="Markets"
            {...getOverrideProps(overrides, "OpportunityTypeoption1")}
          ></option>
          <option
            children="Products and services"
            value="Products_and_Services"
            {...getOverrideProps(overrides, "OpportunityTypeoption2")}
          ></option>
          <option
            children="Resilience"
            value="Resilience"
            {...getOverrideProps(overrides, "OpportunityTypeoption3")}
          ></option>
          <option
            children="Resource efficiency"
            value="Resource_Efficiency"
            {...getOverrideProps(overrides, "OpportunityTypeoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Where the opportunity arises in your value chain"
          placeholder="Please select an option"
          isDisabled={false}
          value={ArisesinValueChain}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OpportunityName,
                Description,
                OpportunityType,
                ArisesinValueChain: value,
                PrimaryFinancialImpact,
                Likelihood,
                Priority,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.ArisesinValueChain ?? value;
            }
            if (errors.ArisesinValueChain?.hasError) {
              runValidationTasks("ArisesinValueChain", value);
            }
            setArisesinValueChain(value);
          }}
          onBlur={() =>
            runValidationTasks("ArisesinValueChain", ArisesinValueChain)
          }
          errorMessage={errors.ArisesinValueChain?.errorMessage}
          hasError={errors.ArisesinValueChain?.hasError}
          {...getOverrideProps(overrides, "ArisesinValueChain")}
        >
          <option
            children="Upstream"
            value="Upstream"
            {...getOverrideProps(overrides, "ArisesinValueChainoption0")}
          ></option>
          <option
            children="Direct operations"
            value="Direct_Operations"
            {...getOverrideProps(overrides, "ArisesinValueChainoption1")}
          ></option>
          <option
            children="Downstream"
            value="Downstream"
            {...getOverrideProps(overrides, "ArisesinValueChainoption2")}
          ></option>
          <option
            children="All segments"
            value="All_Segments"
            {...getOverrideProps(overrides, "ArisesinValueChainoption3")}
          ></option>
          <option
            children="Other"
            value="Other"
            {...getOverrideProps(overrides, "ArisesinValueChainoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <SelectField
          label="Primary financial impact"
          placeholder="Please select an option"
          isDisabled={false}
          value={PrimaryFinancialImpact}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OpportunityName,
                Description,
                OpportunityType,
                ArisesinValueChain,
                PrimaryFinancialImpact: value,
                Likelihood,
                Priority,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.PrimaryFinancialImpact ?? value;
            }
            if (errors.PrimaryFinancialImpact?.hasError) {
              runValidationTasks("PrimaryFinancialImpact", value);
            }
            setPrimaryFinancialImpact(value);
          }}
          onBlur={() =>
            runValidationTasks("PrimaryFinancialImpact", PrimaryFinancialImpact)
          }
          errorMessage={errors.PrimaryFinancialImpact?.errorMessage}
          hasError={errors.PrimaryFinancialImpact?.hasError}
          {...getOverrideProps(overrides, "PrimaryFinancialImpact")}
        >
          <option
            children="Increased access to capital"
            value="Increased_Access_to_Capital"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption0")}
          ></option>
          <option
            children="Increased access to resources"
            value="Increased_Access_to_Resources"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption1")}
          ></option>
          <option
            children="Increased capital expenditures"
            value="Increased_Capital_Expenditures"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption2")}
          ></option>
          <option
            children="Increased credit risk"
            value="Increased_Credit_Risk"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption3")}
          ></option>
          <option
            children="Increased direct costs"
            value="Increased_Direct_Costs"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption4")}
          ></option>
          <option
            children="Increased indirect operating costs"
            value="Increased_Indirect_Operating_Costs"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption5")}
          ></option>
          <option
            children="Increased insurance claims liability"
            value="Increased_Insurance_Claims_Liability"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption6")}
          ></option>
          <option
            children="Increased revenue"
            value="Increased_Revenue"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption7")}
          ></option>
          <option
            children="Decreased access to capital"
            value="Decreased_Access_to_Capital"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption8")}
          ></option>
          <option
            children="Decreased asset value or asset useful life leading to writeoffs asset impairment or early retirement of existing assets"
            value="Decreased_Asset_Value_or_Asset_Useful_Life_Leading_to_Writeoffs_Asset_Impairment_or_Early_Retirement_of_Existing_Assets"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption9")}
          ></option>
          <option
            children="Decreased opex"
            value="Decreased_OPEX"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption10")}
          ></option>
          <option
            children="Decreased revenues due to reduced demand for products and services"
            value="Decreased_Revenues_Due_to_Reduced_Demand_For_Products_and_Services"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption11")}
          ></option>
          <option
            children="Decreased revenues due to reduced production capacity"
            value="Decreased_Revenues_Due_to_Reduced_Production_Capacity"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption12")}
          ></option>
          <option
            children="Devaluation of colltateral or potential for stranded illiquid assets"
            value="Devaluation_of_Colltateral_or_Potential_For_Stranded_Illiquid_Assets"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption13")}
          ></option>
          <option
            children="Increased capital expenditure"
            value="Increased_Capital_Expenditure"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption14")}
          ></option>
          <option
            children="Increased gross margin"
            value="Increased_Gross_Margin"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption15")}
          ></option>
          <option
            children="Increased operating expenditure"
            value="Increased_Operating_Expenditure"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption16")}
          ></option>
          <option
            children="Increased or decreased revenue"
            value="Increased_Or_Decreased_Revenue"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption17")}
          ></option>
          <option
            children="Reduced gross margin"
            value="Reduced_Gross_Margin"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption18")}
          ></option>
          <option
            children="Reduced profitability or investment portfolios"
            value="Reduced_Profitability_or_Investment_Portfolios"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption19")}
          ></option>
          <option
            children="Other please specify"
            value="Other_Please_Specify"
            {...getOverrideProps(overrides, "PrimaryFinancialImpactoption20")}
          ></option>
        </SelectField>
        <SelectField
          label="Likelihood of occurring"
          placeholder="Please select an option"
          isDisabled={false}
          value={Likelihood}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OpportunityName,
                Description,
                OpportunityType,
                ArisesinValueChain,
                PrimaryFinancialImpact,
                Likelihood: value,
                Priority,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.Likelihood ?? value;
            }
            if (errors.Likelihood?.hasError) {
              runValidationTasks("Likelihood", value);
            }
            setLikelihood(value);
          }}
          onBlur={() => runValidationTasks("Likelihood", Likelihood)}
          errorMessage={errors.Likelihood?.errorMessage}
          hasError={errors.Likelihood?.hasError}
          {...getOverrideProps(overrides, "Likelihood")}
        >
          <option
            children="None"
            value="None"
            {...getOverrideProps(overrides, "Likelihoodoption0")}
          ></option>
          <option
            children="Low"
            value="Low"
            {...getOverrideProps(overrides, "Likelihoodoption1")}
          ></option>
          <option
            children="Medium"
            value="Medium"
            {...getOverrideProps(overrides, "Likelihoodoption2")}
          ></option>
          <option
            children="High"
            value="High"
            {...getOverrideProps(overrides, "Likelihoodoption3")}
          ></option>
        </SelectField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <SelectField
          label="Priority"
          placeholder="Please select an option"
          isDisabled={false}
          value={Priority}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OpportunityName,
                Description,
                OpportunityType,
                ArisesinValueChain,
                PrimaryFinancialImpact,
                Likelihood,
                Priority: value,
                TimeHorizon,
              };
              const result = onChange(modelFields);
              value = result?.Priority ?? value;
            }
            if (errors.Priority?.hasError) {
              runValidationTasks("Priority", value);
            }
            setPriority(value);
          }}
          onBlur={() => runValidationTasks("Priority", Priority)}
          errorMessage={errors.Priority?.errorMessage}
          hasError={errors.Priority?.hasError}
          {...getOverrideProps(overrides, "Priority")}
        >
          <option
            children="Highest"
            value="Highest"
            {...getOverrideProps(overrides, "Priorityoption0")}
          ></option>
          <option
            children="High"
            value="High"
            {...getOverrideProps(overrides, "Priorityoption1")}
          ></option>
          <option
            children="Medium"
            value="Medium"
            {...getOverrideProps(overrides, "Priorityoption2")}
          ></option>
          <option
            children="Low"
            value="Low"
            {...getOverrideProps(overrides, "Priorityoption3")}
          ></option>
          <option
            children="Lowest"
            value="Lowest"
            {...getOverrideProps(overrides, "Priorityoption4")}
          ></option>
        </SelectField>
        <SelectField
          label="Time horizon"
          placeholder="Please select an option"
          isDisabled={false}
          value={TimeHorizon}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OpportunityName,
                Description,
                OpportunityType,
                ArisesinValueChain,
                PrimaryFinancialImpact,
                Likelihood,
                Priority,
                TimeHorizon: value,
              };
              const result = onChange(modelFields);
              value = result?.TimeHorizon ?? value;
            }
            if (errors.TimeHorizon?.hasError) {
              runValidationTasks("TimeHorizon", value);
            }
            setTimeHorizon(value);
          }}
          onBlur={() => runValidationTasks("TimeHorizon", TimeHorizon)}
          errorMessage={errors.TimeHorizon?.errorMessage}
          hasError={errors.TimeHorizon?.hasError}
          {...getOverrideProps(overrides, "TimeHorizon")}
        >
          <option
            children="Current"
            value="Current"
            {...getOverrideProps(overrides, "TimeHorizonoption0")}
          ></option>
          <option
            children="Short term"
            value="Short_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption1")}
          ></option>
          <option
            children="Medium term"
            value="Medium_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption2")}
          ></option>
          <option
            children="Long term"
            value="Long_Term"
            {...getOverrideProps(overrides, "TimeHorizonoption3")}
          ></option>
          <option
            children="All climate planning horizons"
            value="All_Climate_Planning_Horizons"
            {...getOverrideProps(overrides, "TimeHorizonoption4")}
          ></option>
        </SelectField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
