import { useAuthenticator } from "@aws-amplify/ui-react";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { DataStore } from "aws-amplify/datastore";
import { isEmpty, pick } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Modal, ModalBody, Row, Spinner } from "react-bootstrap";
import { shortDisplayName } from "../../shared/amplify/cognitoHelpers";
import { models } from "../backend";
import useLocalStorage from "../storage/useLocalStorage";
import { useToast } from "../util/Toast";
import LoggerProvider from "../logger/LoggerProvider";
export const CustomerSessionContext = React.createContext();

/**
 * Context provider to use within an Amplify Aythentiactor.Provider contect to add the current user's customer
 * and user groups to the context. Use useCustomerAccount() and useGroups()
 *
 * @param {*} param0
 * @returns
 */
export function CustomerSessionProvider({ children }) {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [appUsers, setAppUsers] = useState();
  const [{ appUser, customer }, setCustomerAccount] = useState({});
  const [lastCustomerId, setLastCustomerId] = useLocalStorage("LastCustomerId");
  const [authSession, setAuthSession] = useState();
  const userAttributes = authSession && authSession?.tokens?.idToken?.payload;
  const showToast = useToast();
  const customerFail = useCallback(
    (e) => {
      const message = e.message || "The authenticated user is not associated with a current customer for this application";
      showToast({
        header: "Problem Logging In",
        content: `${message}. Please Contact your administrator if the problem persists. You will be logged out`,
        bg: "danger",
        delay: undefined,
        onClose: async () => {
          await signOut();
        }
      });
      e && console.error(e);
    },
    [showToast, signOut]
  );

  const setCustomer = useCallback(
    async function setCustomer(customerId) {
      if (customerId !== lastCustomerId) {
        // customer is changing from what it was when page was loaded: reset in local storage and reload to index page
        setLastCustomerId(customerId);
        showToast({
          content: (
            <Row className="align-items-center p-2 gap-2">
              <Spinner size="small" />
              Switching customer organization...
            </Row>
          ),
          bg: "info",
          delay: 1000,
          onClose: async () => {
            document.location.reload();
          }
        });
      } else {
        const newAppUser = !!appUsers && appUsers.find(({ CustomerID }) => CustomerID === customerId);
        if (newAppUser) {
          const { body } = await get({
            apiName: "support",
            path: `/customer/${customerId}`
          }).response;
          const customer = await body.json();
          setCustomerAccount({ appUser: newAppUser, customer });
        } else {
          throw new Error(`CustomerId ${customerId} is not valid for this user`);
        }
      }
    },
    [appUsers, setLastCustomerId, lastCustomerId, showToast]
  );

  useEffect(
    () => {
      if (!isEmpty(appUsers)) {
        (async () => {
          try {
            if (lastCustomerId) {
              try {
                await setCustomer(lastCustomerId);
              } catch (e) {
                console.warn("Previous customer setting will be changed", e);
                await setCustomer(appUsers[0].CustomerID);
              }
            } else {
              // Just use first available customer (user can switch)
              await setCustomer(appUsers[0].CustomerID);
            }
          } catch (e) {
            customerFail(e);
          }
        })();
      }
    },
    // This is *just* for when the appUsers list changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appUsers]
  );
  useEffect(() => {
    (async () => {
      if (user && !authSession) {
        try {
          const session = await fetchAuthSession(); //{ forceRefresh: true });
          setAuthSession(session);
          console.debug("Session", session);
          if (isEmpty(session?.tokens?.accessToken?.payload?.["cognito:groups"])) {
            console.warn("No group permissions on token"); /// refresh cannot extend due to cognito bug whereby clientMetadata is ignored so customer info cannot be passed
            throw new Error("Session expired, please login in again."); // display as if expired
          }
        } catch (e) {
          customerFail(e);
        }
      }
    })();
  }, [user, authSession, customerFail]);
  useEffect(() => {
    (async () => {
      const { username } = user || {};
      if (username && !appUser) {
        const subscription = DataStore.observeQuery(models().AppUsers, (c) => {
          return c.Username.eq(username);
        }).subscribe(({ items: newAppUsers, isSynced }) => {
          if (isSynced) {
            if (!isEmpty(newAppUsers)) {
              setAppUsers(newAppUsers);
            } else {
              customerFail({ message: "No users found" });
            }
          }
        });

        return () => {
          subscription.unsubscribe();
        };
      }
    })();
  }, [signOut, user, customerFail, appUser]);
  return (
    <>
      <CustomerSessionContext.Provider
        value={{
          customer,
          appUser,
          appUsers,
          user,
          userAttributes, // note derived value
          authSession,
          setCustomer
        }}
      >
        <LoggerProvider extra={{ user: pick(appUser, ["CustomerID", "id", "Email", "Username"]) }}>
          {children}
          <Modal show={!(customer && appUser && user)}>
            <ModalBody className="d-flex align-items-center  gap-2">
              <Spinner />
              {user ? `Please wait while we fetch your profile ${shortDisplayName(userAttributes)}` : "Signing in"}
              &hellip;
            </ModalBody>
          </Modal>
        </LoggerProvider>
      </CustomerSessionContext.Provider>
    </>
  );
}

export function useUser() {
  const session = useContext(CustomerSessionContext);
  if (!session) throw new Error("wrapping CustomerSessionProvider required");
  return session?.user;
}

export function useUserAttributes() {
  const session = useContext(CustomerSessionContext);
  if (!session) throw new Error("wrapping CustomerSessionProvider required");
  return session?.userAttributes;
}

export function useCustomerAccount() {
  const session = useContext(CustomerSessionContext);
  if (!session) throw new Error("wrapping CustomerSessionProvider required");
  return {
    allAppUsers: session?.appUsers,
    appUser: session?.appUser,
    customer: session?.customer,
    setCustomer: session?.setCustomer
  };
}

export function useGroups() {
  const session = useContext(CustomerSessionContext);
  if (!session) throw new Error("wrapping CustomerSessionProvider required");
  return new Set(session?.authSession?.tokens?.idToken?.payload?.["cognito:groups"]);
}
