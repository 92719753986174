import HelpBox from "./HelpBox";

export default function TargetsHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Targets Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    Targets record how you will manage climate-related risks and opportunities.
                </p>
                <p>
                    Use the table to create one ore more Targets.
                </p>
                <p>
                    ClimateTracker allows you to group these Targets in different ways for different disclosures. For example, you may add Targets over time as your climate response evolves.
                    To group the Targets you require for this disclosure, in the left hand side, enter a name for the set of Targets that are relevant to this disclosure, then select the Targets you have created or create and select new Targets.
                    This ensures that only the relevant Targets will be used in the disclosure you are currently building.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
