/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { GovernanceData } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function GovernanceDataManagementAccountabilitiesUpdateForm(
  props
) {
  const {
    id: idProp,
    governanceData: governanceDataModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GovernanceDataName: "",
    ManagementPositionAssignment: "",
    ManagementOrgStructure: "",
  };
  const [GovernanceDataName, setGovernanceDataName] = React.useState(
    initialValues.GovernanceDataName
  );
  const [ManagementPositionAssignment, setManagementPositionAssignment] =
    React.useState(initialValues.ManagementPositionAssignment);
  const [ManagementOrgStructure, setManagementOrgStructure] = React.useState(
    initialValues.ManagementOrgStructure
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = governanceDataRecord
      ? { ...initialValues, ...governanceDataRecord }
      : initialValues;
    setGovernanceDataName(cleanValues.GovernanceDataName);
    setManagementPositionAssignment(cleanValues.ManagementPositionAssignment);
    setManagementOrgStructure(cleanValues.ManagementOrgStructure);
    setErrors({});
  };
  const [governanceDataRecord, setGovernanceDataRecord] = React.useState(
    governanceDataModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(GovernanceData, idProp)
        : governanceDataModelProp;
      setGovernanceDataRecord(record);
    };
    queryData();
  }, [idProp, governanceDataModelProp]);
  React.useEffect(resetStateValues, [governanceDataRecord]);
  const validations = {
    GovernanceDataName: [],
    ManagementPositionAssignment: [],
    ManagementOrgStructure: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GovernanceDataName,
          ManagementPositionAssignment,
          ManagementOrgStructure,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            GovernanceData.copyOf(governanceDataRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(
        overrides,
        "GovernanceDataManagementAccountabilitiesUpdateForm"
      )}
      {...rest}
    >
      <Heading
        level={4}
        children="Management Role and Accountabilities"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 Paragraph 7c, 9a-c; NZCS3 paragraph 51 b ii."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update this record that describes management’s role in assessing and managing climate-related risks and opportunities. This should include the engagement processes that management uses to engage with the governance body, and the oversight processes that management uses to provider oversight of climate-related risks and opportunities."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Management accountability record name (e.g. 2025 management accountabilities records)"
        isRequired={false}
        isReadOnly={false}
        value={GovernanceDataName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceDataName: value,
              ManagementPositionAssignment,
              ManagementOrgStructure,
            };
            const result = onChange(modelFields);
            value = result?.GovernanceDataName ?? value;
          }
          if (errors.GovernanceDataName?.hasError) {
            runValidationTasks("GovernanceDataName", value);
          }
          setGovernanceDataName(value);
        }}
        onBlur={() =>
          runValidationTasks("GovernanceDataName", GovernanceDataName)
        }
        errorMessage={errors.GovernanceDataName?.errorMessage}
        hasError={errors.GovernanceDataName?.hasError}
        {...getOverrideProps(overrides, "GovernanceDataName")}
      ></TextField>
      <Heading
        children="NZCS1 Paragraph 9a."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Heading>
      <TextAreaField
        label="Describe how climate-related responsibilities are assigned to management-level positions or committees"
        isRequired={false}
        isReadOnly={false}
        value={ManagementPositionAssignment}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceDataName,
              ManagementPositionAssignment: value,
              ManagementOrgStructure,
            };
            const result = onChange(modelFields);
            value = result?.ManagementPositionAssignment ?? value;
          }
          if (errors.ManagementPositionAssignment?.hasError) {
            runValidationTasks("ManagementPositionAssignment", value);
          }
          setManagementPositionAssignment(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "ManagementPositionAssignment",
            ManagementPositionAssignment
          )
        }
        errorMessage={errors.ManagementPositionAssignment?.errorMessage}
        hasError={errors.ManagementPositionAssignment?.hasError}
        {...getOverrideProps(overrides, "ManagementPositionAssignment")}
      ></TextAreaField>
      <Heading
        children="NZCS1 Paragraph 9b."
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Heading>
      <TextAreaField
        label="Describe the organisational structure(s) showing where these management-level positions and committees lie"
        isRequired={false}
        isReadOnly={false}
        value={ManagementOrgStructure}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GovernanceDataName,
              ManagementPositionAssignment,
              ManagementOrgStructure: value,
            };
            const result = onChange(modelFields);
            value = result?.ManagementOrgStructure ?? value;
          }
          if (errors.ManagementOrgStructure?.hasError) {
            runValidationTasks("ManagementOrgStructure", value);
          }
          setManagementOrgStructure(value);
        }}
        onBlur={() =>
          runValidationTasks("ManagementOrgStructure", ManagementOrgStructure)
        }
        errorMessage={errors.ManagementOrgStructure?.errorMessage}
        hasError={errors.ManagementOrgStructure?.hasError}
        {...getOverrideProps(overrides, "ManagementOrgStructure")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || governanceDataModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || governanceDataModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
