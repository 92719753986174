import HelpBox from "./HelpBox";

export default function EmissionsNarrativeHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>Emissions Narrative Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    For the set of Metrics and Targets data you will use in your disclosure, you are also required to enter some commentary in relation to your greenhouse gas emissions.
                </p>
                <p>
                    Answer the questions on the left hand side, then also specify one or more emission exclusions using the table.
                </p>
            </HelpBox.Content>
        </HelpBox>
    );
}
