import { Predicates } from "aws-amplify/datastore";
import { AdoptionProvisionEnum, DisclosurePeriod } from "../../models";
import { Validators } from "../../shared/validators/withValidators";
import { startCase } from "lodash";

/*
{
  id: "03865c27-8b25-4a68-8ce1-99ace8c81e46",
  CustomerID: "mikeagainhikosoftwarelimited-guest",
  AdoptionProvisionType: "Adoption_provision_2_Anticipated_financial_impacts",
  AdoptionProvision3Description: null,
  AdoptionProvision4Description: null,
  createdAt: "2024-05-22T03:14:20.863Z",
  updatedAt: "2024-05-22T03:29:32.969Z",
  adoptionProvisionDisclosurePeriodId: null,
  owner: "796e34e8-9041-7076-0456-ceefb29b1ae3",
  _version: 4,
  _lastChangedAt: 1716348775936,
  _deleted: null,
  DisclosurePeriod: [
    {
      id: "efb3a5ab-e26a-4cba-aeaf-96ade43f1033",
      CustomerID: "mikeagainhikosoftwarelimited-guest",
      DisclosurePeriodName: "2025",
      StartDate: "2025-01-01",
      EndDate: "2025-04-25",
      DisclosurePeriodYear: "2025",
      Notes: null,
      ReportingPeriodChange: null,
      createdAt: "2024-05-22T03:04:31.805Z",
      updatedAt: "2024-05-22T03:04:32.427Z",
      owner: "796e34e8-9041-7076-0456-ceefb29b1ae3",
      _version: 2,
      _lastChangedAt: 1716347072475,
      _deleted: null,
    },
  ],
}
*/
const validators: Validators = {
  "*": async ({ modelFields: { DisclosurePeriod: disclosurePeriod, AdoptionProvisionType }, customerDataStore }) => {
    const allDisclosurePeriods = await customerDataStore.query(DisclosurePeriod, Predicates.ALL);
    const ascendingYears = allDisclosurePeriods
      .map(({ DisclosurePeriodYear }) => (DisclosurePeriodYear ? parseInt(DisclosurePeriodYear) : 0))
      .filter(Boolean)
      .sort((a, b) => a - b);
    const disclosureYear = disclosurePeriod.DisclosurePeriodYear && parseInt(disclosurePeriod.DisclosurePeriodYear);
    if (disclosureYear) {
      switch (AdoptionProvisionType) {
        case AdoptionProvisionEnum.ADOPTION_PROVISION_1_CURRENT_FINANCIAL_IMPACTS:
        case AdoptionProvisionEnum.ADOPTION_PROVISION_2_ANTICIPATED_FINANCIAL_IMPACTS:
        case AdoptionProvisionEnum.ADOPTION_PROVISION_3_TRANSITION_PLANNING:
        case AdoptionProvisionEnum.ADOPTION_PROVISION_4_SCOPE_3_GHG_EMISSIONS:
        case AdoptionProvisionEnum.ADOPTION_PROVISION_6_COMPARATIVES_FOR_METRICS:
        case AdoptionProvisionEnum.ADOPTION_PROVISION_7_ANALYSIS_OF_TRENDS:
          {
            const legalYears = ascendingYears.slice(0, 1);
            if (!legalYears.includes(disclosureYear))
              return {
                hasError: true,
                errorMessage: `'${startCase(
                  AdoptionProvisionType
                )}' can only be selected for the first disclosure period (${legalYears.join(",")})`,
                overrides: {
                  /*
                Note to maintainer - to do individual fields
                AdoptionProvisionType: {
                  hasError: true,
                  errorMessage: "Either change this"
                },
                DisclosurePeriod: {
                  hasError: true,
                  errorMessage: "Or change this"
                }
                */
                }
              };
          }
          break;
        case AdoptionProvisionEnum.ADOPTION_PROVISION_5_COMPARATIVES_FOR_SCOPE_3_GHG_EMISSIONS:
          {
            const legalYears = ascendingYears.slice(1, 2);
            if (!legalYears.includes(disclosureYear))
              return {
                hasError: true,
                errorMessage: `'${startCase(
                  AdoptionProvisionType
                )}' can only be selected for the second disclosure period (${legalYears.join(",")})`
              };
          }
          break;
        default:
          break;
      }
    }
    return undefined;
  }
};
export default validators;
