// Note order of these css imports important (watch organize imports!)_
import "@aws-amplify/ui/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Do not move

//import "./localComponents/amplify/styles.css";
import React from "react";

import ReactDOM from "react-dom/client";

import {ThemeProvider} from "@aws-amplify/ui-react";

import App from "./App";
import uischema from "./localComponents/uischema-nzcs1";
import reportWebVitals from "./reportWebVitals";
import grlTheme from "./shared/amplify/grlTheme";
import {configure} from "./shared/backend";
import forms from "./ui-components/forms.uibuilder.json";

import amplifyConfig from "./amplifyconfiguration.json";
import * as validators from "./localComponents/validators";
import * as models from "./models";
import * as formComponents from "./ui-components";
console.dir(grlTheme);

// Tell shared app package where our this app's amplify schema etc is and configure amplify
configure({
  customerId: window.localStorage.getItem("LastCustomerId"),
  uischema,
  validators,
  models,
  forms,
  formComponents,
  amplifyConfig
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={grlTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
