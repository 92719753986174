import { Card, CardBody, CardHeader, Col, Row, Spinner } from "react-bootstrap";

import { Alert, Badge, Button } from "@aws-amplify/ui-react";
import { get } from "aws-amplify/api";
import { Predicates } from "aws-amplify/datastore";
import clsx from "clsx";
import { Dictionary, identity, isEmpty, memoize, pickBy, uniq } from "lodash";
import { Fragment, ReactNode, useEffect, useState } from "react";
import {
  BsArrowClockwise,
  BsArrowRight,
  BsChevronDoubleDown,
  BsChevronDoubleUp,
  BsDashCircle,
  BsDownload,
  BsPencil,
  BsPlusCircle,
  BsTrash
} from "react-icons/bs";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { AppUsers } from "../../models";
import { defaultFilter, findUniqueFieldName } from "../amplify/schemaHelpers";
import { models, uischema } from "../backend";
import HelpBox from "../content/HelpBox";
import useCustomerQuery from "../customer/useCustomerQuery";
import { shortDisplayName } from "../field/AppUserField";
import { renderField } from "../field/renderField";
import useDownload from "../import/useDownload";
import { useModal } from "../modal/useModal";
import useLocalStorage from "../storage/useLocalStorage";
import DataItemTable from "../table/DataItemTable";
import { useToast } from "../util/Toast";
import styles from "./AuditLog.module.css";
const auditDateFormat = new Intl.DateTimeFormat(undefined, {
  dateStyle: "full",
  timeStyle: "short"
});

const changeUi = {
  create: { name: "Created", icon: <BsPlusCircle /> },
  delete: { name: "Deleted", icon: <BsTrash /> },
  softDelete: { name: "Soft Deleted", icon: <BsDashCircle /> },
  update: { name: "Updated", icon: <BsPencil /> }
};

type FieldDetail = {
  __typename: string;
  owner: string;
  _deleted: boolean;
  id: string;
  [k: string]: any;
};
type AuditEvent = {
  timestamp?: string;
  detail: {
    old?: FieldDetail;
    new?: FieldDetail;
  };
};
type Change =
  | {
      type: "create" | "update" | "softDelete" | "delete";
      user?: string;
      displayName: string;
      changedAt?: string;
      error?: string;
      link?: string;
      fieldValues?: [string, ReactNode, ReactNode][];
    }
  | { type: "error"; error: string; detail: AuditEvent["detail"] };
function changeSet(events: AuditEvent[], appUsersByUserName: Record<string, AppUsers>): Change[] {
  return events.map(
    ({ timestamp, detail, detail: { old: oldFields, new: newFields, new: { __typename, owner, _deleted, id } = {} } = {} }) => {
      const model = __typename && models()[__typename];
      if (model) {
        const modelSchema = uischema().models[model.name];
        if (modelSchema) {
          const nameField = memoize(findUniqueFieldName)(model);
          const ownerName = owner && owner.replace(/:.*/, "");
          const appUser = ownerName && appUsersByUserName && appUsersByUserName[ownerName];
          const change = {
            user: appUser && shortDisplayName(appUser),
            changedAt: timestamp && auditDateFormat.format(new Date(timestamp)),
            displayName: [modelSchema.displayName, nameField && (newFields || oldFields)?.[nameField]].filter(Boolean).join(": "),
            link: `/model/${model.name}/${id}`
          };
          if (newFields) {
            if (!_deleted) {
              const allFields = uniq(Object.keys(newFields || []).concat(Object.keys(oldFields || [])))
                .map((fieldName) => modelSchema.fields[fieldName])
                .filter((field) => !!field && defaultFilter(modelSchema, field))
                .map((field) => [memoize(renderField)(field), field]);
              if (!oldFields) {
                return {
                  ...change,
                  type: "create",
                  fieldValues: allFields.map(([renderer, { name, displayName }]) => [
                    displayName,
                    undefined,
                    newFields[name] && renderer({ value: newFields[name] })
                  ])
                };
              } else {
                return {
                  ...change,
                  type: "update",
                  fieldValues: allFields
                    .filter(([_, { name }]) => newFields[name] !== oldFields[name])
                    .map(([renderer, { name, displayName }]) => [
                      displayName,
                      oldFields[name] && renderer({ value: oldFields[name] }),
                      newFields[name] && renderer({ value: newFields[name] })
                    ])
                };
              }
            } else {
              return {
                ...change,
                link: undefined,
                type: "softDelete"
              };
            }
          } else {
            return {
              ...change,
              type: "delete",
              link: undefined
            };
          }
        } else {
          return {
            type: "error",
            error: `Unrecognised model: {model.name}`,
            detail
          };
        }
      } else {
        return {
          type: "error",
          error: "No model info",
          detail
        };
      }
    }
  );
}

/**
 *
 * @param {*} customerId
 * @param {*} logName
 * @returns a suitable cloudwatch stream name for the given customer/log combination
 */
function logPath(id: string): string {
  return "/audit/" + id;
}
const pageSize = 50;

type EventResult = { events: AuditEvent[]; nextToken?: string };
async function fetchEvents({
  logId,
  limit,
  nextToken
}: {
  logId: string;
  limit?: number;
  nextToken?: string;
}): Promise<EventResult> {
  const { body } = await get({
    apiName: "support",
    path: logPath(logId),
    options: {
      queryParams: pickBy({ nextToken, limit: limit && limit?.toString() }, identity) as Dictionary<string> // strip undefineds as backend seems to get these stringifyed by get()
    }
  }).response;
  const { events, nextToken: nextNextToken } = (await body.json()) as EventResult;
  return nextNextToken && !limit
    ? { events: [...events, ...(await fetchEvents({ logId, nextToken: nextNextToken })).events] }
    : { events, nextToken: nextNextToken };
}

function LogView({ logId, logName }: { logId: string; logName: string }): ReactNode {
  const { AppUsers } = models();
  const { result: appUsers, error: appUsersError, loading: appUsersLoading } = useCustomerQuery(AppUsers, Predicates.ALL);
  const [{ events, nextToken, error: eventsError, loading: eventsLoading }, setEvents] = useState<
    Partial<{ events: AuditEvent[]; nextToken: string; loading: true; error: string }>
  >({});
  const [changes, setChanges] = useState<Change[]>();
  const [appUsersByUserName, setAppUsersByUserName] = useState<Record<string, AppUsers>>();
  const [itemsVisible, setItemsVisible] = useState(pageSize * 2);
  const { handleClick: downloadClick, busy } = useDownload({
    data: async () => {
      const { events } = await fetchEvents({ logId });
      return changeSet(events, appUsersByUserName || {});
    },
    filename: "auditlog.json"
  });
  const showToast = useToast();
  const { ref, inView } = useInView({ rootMargin: "10%" });
  useEffect(() => {
    if (appUsers) setAppUsersByUserName(Object.fromEntries(appUsers.map((appUser) => [appUser.Username, appUser])));
  }, [appUsers]);
  useEffect(() => {
    if (events && appUsersByUserName) {
      const changes = changeSet(events, appUsersByUserName);
      setChanges(changes || []);
    }
  }, [events, appUsersByUserName]);
  useEffect(() => {
    // Load the log
    (async () => {
      if (!eventsError && !eventsLoading && logId && (!events || (nextToken && inView))) {
        setEvents({ events, nextToken, loading: true });
        try {
          const { events: nextEvents, nextToken: nextNextToken } = await fetchEvents({ logId, limit: pageSize, nextToken });
          setEvents({ events: [...(events || []), ...nextEvents], nextToken: nextNextToken });
        } catch (err: any) {
          console.error(err);
          showToast({
            header: "Unable to fetch log content",
            content: err.toString(),
            autohide: false
          });
          setEvents({ error: err });
        }
      }
    })();
  }, [events, eventsError, eventsLoading, nextToken, inView, logId, showToast]);
  const loading = appUsersLoading || eventsLoading;
  return (
    <Card>
      <CardHeader>
        <div className="d-flex gap-2 justify-content-end">
          <Col>
            <h2>{logName}</h2>
          </Col>
          <Button isLoading={loading} onClick={() => setEvents({})}>
            <BsArrowClockwise />
          </Button>
          <Button isLoading={busy} onClick={downloadClick}>
            <BsDownload />
          </Button>
        </div>
        {appUsersError && <Alert variation="error">Unable show user names</Alert>}
      </CardHeader>
      <CardBody>
        {changes && (
          <>
            <div className="d-flex justify-content-center m-2">
              {changes.length >= itemsVisible ? (
                <Button onClick={() => setItemsVisible(itemsVisible + pageSize)}>
                  <BsChevronDoubleUp />
                  &nbsp;Show more
                </Button>
              ) : (
                <Badge>Start of log</Badge>
              )}
            </div>
            <VerticalTimeline lineColor={"var(--amplify-colors-border-primary)"}>
              {changes.slice(-itemsVisible).map((change, i) => {
                if (change.type !== "error") {
                  const { type, user, link, changedAt, displayName, fieldValues } = change;
                  return (
                    <VerticalTimelineElement
                      key={`event-${i}`}
                      date={`${!!user ? `${user} on ` : ""}${changedAt}`}
                      icon={changeUi[type].icon}
                      iconClassName={clsx(styles.timelineIcon, styles[type])}
                      contentArrowStyle={{
                        borderRightColor: "var(--amplify-colors-overlay-20)"
                      }}
                      contentStyle={{
                        backgroundColor: "var(--amplify-colors-overlay-20)",
                        padding: "var(--amplify-space-relative-small)"
                      }}
                    >
                      <Card>
                        <CardHeader>
                          <Row key={i} className="d-inline-flex justify-content-between">
                            <Col className="flex-grow-0">
                              <Badge variation="info" className={styles[type]}>
                                {changeUi[type].name}
                              </Badge>
                            </Col>
                            <Col>{link ? <Link to={link}>{displayName}</Link> : <span>{displayName}</span>}</Col>
                          </Row>
                        </CardHeader>
                        {fieldValues && (
                          <CardBody>
                            <dl>
                              {fieldValues.map(([name, oldValue, newValue], i) => {
                                return (
                                  (oldValue || newValue) && (
                                    <Fragment key={`field-${i}`}>
                                      <dt>{name}</dt>
                                      <dd>
                                        {oldValue && <span className={styles.removed}>{oldValue}</span>}
                                        {<BsArrowRight />}
                                        {newValue && <span className={styles.added}>{newValue}</span>}
                                      </dd>
                                    </Fragment>
                                  )
                                );
                              })}
                            </dl>
                          </CardBody>
                        )}
                      </Card>
                    </VerticalTimelineElement>
                  );
                } else return undefined;
              })}
            </VerticalTimeline>
            <div className="d-flex justify-content-center m-2">
              {nextToken ? <>{loading ? <Spinner /> : <BsChevronDoubleDown fontSize={"2em"} />}</> : <Badge>No more events</Badge>}
            </div>
            <div ref={ref}></div>
          </>
        )}
      </CardBody>
    </Card>
  );
}
export default function AuditLog() {
  const { AuditLog } = models();
  const modal = useModal({ model: AuditLog });
  const [{ id, LogName }, setSelectedLog] = useLocalStorage("selectedLog", {});
  function logSelected(selection?: string[]) {
    setSelectedLog(selection && !isEmpty(selection) && selection[0]);
  }

  return (
    <Row>
      <Col>
        <h1>Audit Logs</h1>
        <DataItemTable
          fullSize
          model={AuditLog}
          modal={modal}
          select
          selection={[id]}
          multiple={false}
          onChange={logSelected}
          error={undefined}
          subtype={undefined}
        />
        {id ? <LogView key={id} logId={id} logName={LogName} /> : "Select a log to view"}
      </Col>
      <Col>
        <HelpBox>
          <HelpBox.Header>Audit Log</HelpBox.Header>
          <HelpBox.Content>
            Use an Audit Log to record changes committed between the start and end dates.
            <ol>
              <li>If the start date is omitted the log collects data immediately.</li>
              <li>If the end date is omitted the log continues forever.</li>
            </ol>
            <p>
              The contents of logs can be viewed by selecting a the radio button next to the log and clicking the refresh{" "}
              <BsArrowClockwise /> button to see the latest changes. There may be a delay of several minutes before individual
              changes appear in the log
            </p>
            <p>
              A JSON file of the current log contents can be downloaded using the download <BsDownload /> button
            </p>
          </HelpBox.Content>
        </HelpBox>
      </Col>
    </Row>
  );
}
