import { Col, Container, Row, Stack } from "react-bootstrap";

import { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewItemButton from "../../shared/action/NewItemButton";
import DownloadButton from "../action/DownloadButton";
import EditItemButton from "../action/EditItemButton";
import UploadButton from "../action/UploadButton";
import { models, uischema } from "../backend";
import { withAll } from "../form/withAll";
import SelectDataItem from "../select/SelectDataItem";
import { FormStackProvider } from "../util/FormStack";
import { amplifyForm } from "./amplifyForms";

export default function DataItemBase() {
  const actionsRef = useRef(null);
  const { modelSubtype, id } = useParams();
  const [modelName, subtype] = (modelSubtype || "").split("-");
  const model = modelName && models()[modelName];
  if (!model) throw new Error("Unknown model: " + modelName);
  const [renderKey, setRenderKey] = useState();
  const Form = withAll({
    createDialog: amplifyForm(model, "Create", subtype),
    updateDialog: amplifyForm(model, "Update", subtype),
    actionsRef,
    model,
    subtype
  });
  const navigate = useNavigate();
  const { displayName } = uischema().models[modelName];
  const { pathname } = useLocation();
  const itemPath = pathname.replace(RegExp(`/${id}/?$`), "");
  function change(item) {
    setRenderKey(new Date().getTime());
  }
  return (
    <Stack className="page">
      <FormStackProvider
        baseBreadcrumbName={displayName}
        base={
          <>
            <Container fluid>
              <Row className="align-items-center">
                <Col>
                  <SelectDataItem
                    model={model}
                    new={!!id && (() => <NewItemButton variant="windowed" model={model} onClick={() => navigate(itemPath)} />)}
                    edit={({ id }) =>
                      !!id && (
                        <EditItemButton id={id} variant="windowed" model={model} onClick={() => navigate(`${itemPath}/${id}`)} />
                      )
                    }
                  />
                </Col>
                <Col xs="auto">
                  <Container fluid className="d-inline-flex gap-2 align-items-center">
                    <UploadButton model={model} />
                    <DownloadButton model={model} />
                    <div ref={actionsRef} />
                  </Container>
                </Col>
              </Row>
            </Container>
            <hr></hr>
            <Form key={renderKey} id={id} onSuccess={change} />
          </>
        }
      />
    </Stack>
  );
}
