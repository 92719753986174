import { PersistentModelConstructor } from "aws-amplify/datastore";
import { Heading, Text } from "@aws-amplify/ui-react";
import React, { useMemo } from "react";
import { forms } from "../backend";
import StandardReference, { isStandardReference } from "../content/StandardReference";
import { amplifyFormName } from "./amplifyForms";
import styles from "./formHelpers.module.css";
import clsx from "clsx";
import { merge } from "lodash";
const textComponents: Record<string, (props: Record<string, any>) => [React.FC<any>, Record<string, any> | undefined]> = {
  Heading: ({ className }) => {
    return [
      Heading,
      {
        level:
          className &&
          className
            ?.split(" ")
            .map((cls: string) => cls.match(/amplify-heading--(\d)/)?.[1])
            .find(Boolean) // Hackily determine heading level from class as Amplify loses it when 'as' is used
      }
    ];
  },
  Text: () => [Text, undefined]
};

declare type ExpectedProps = { id?: string; overrides?: Record<string, unknown> };

export default function withStandardReferences(
  Form: React.FC<ExpectedProps>,
  {
    model,
    subtype
  }: {
    model: PersistentModelConstructor<any>;
    subtype?: string;
  }
): React.FC<ExpectedProps> {
  const Component: React.FC<any> = ({ id, overrides, ...rest }) => {
    const formName = amplifyFormName(model, id ? "Update" : "Create", subtype);
    const extraOverrides = useMemo(() => {
      const formDef = (forms()?.entities || []).find(({ name }) => name === formName);
      return formDef
        ? Object.fromEntries(
            Object.entries(formDef?.sectionalElements as Record<string, { type: string; text?: string }>)
              .filter(([_, { text, type }]) => !!text && type in textComponents)
              .map(([key, { type }]) => [
                key,
                {
                  as: ({
                    children,
                    className,
                    ...rest
                  }: React.HTMLAttributes<typeof Heading> | React.HTMLAttributes<typeof Text>) => {
                    const [FieldComponent, extraProps] = textComponents[type]({ className, ...rest });
                    const isr = isStandardReference(children);
                    const content = isr ? <StandardReference text={children as string} /> : children;
                    return (
                      <FieldComponent
                        className={clsx(className, isr && styles.standardReference)}
                        children={content}
                        {...rest}
                        {...extraProps}
                      />
                    );
                  }
                }
              ])
          )
        : {};
    }, [formName]);
    return <Form id={id} overrides={merge({}, extraOverrides, overrides)} {...rest} />;
  };
  Component.displayName = "withStandardReferences";
  return Component;
}
