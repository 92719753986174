import HelpBox from "./HelpBox";

export default function ClimatePlanningHorizonsHelp() {
    return (
        <HelpBox>
            <HelpBox.Header>ClimatePlanning Horizons Help</HelpBox.Header>
            <HelpBox.Content>
                <p>
                    For each Climate Planning Horizon you created previously, link or create one or more of the following two elements.
                </p>
                <ol>
                    <li>Links to strategic planning horizons (on the next screen).</li>
                    <li>Links to capital deployment plans (on the next screen).</li>
                </ol>
            </HelpBox.Content>
        </HelpBox>
    );
}
