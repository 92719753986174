/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
} from "@aws-amplify/ui-react";
import { AdoptionProvision } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function AdoptionProvisionUpdateForm(props) {
  const {
    id: idProp,
    adoptionProvision: adoptionProvisionModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    AdoptionProvisionType: "",
    AdoptionProvision3Description: "",
    AdoptionProvision4Description: "",
  };
  const [AdoptionProvisionType, setAdoptionProvisionType] = React.useState(
    initialValues.AdoptionProvisionType
  );
  const [AdoptionProvision3Description, setAdoptionProvision3Description] =
    React.useState(initialValues.AdoptionProvision3Description);
  const [AdoptionProvision4Description, setAdoptionProvision4Description] =
    React.useState(initialValues.AdoptionProvision4Description);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = adoptionProvisionRecord
      ? { ...initialValues, ...adoptionProvisionRecord }
      : initialValues;
    setAdoptionProvisionType(cleanValues.AdoptionProvisionType);
    setAdoptionProvision3Description(cleanValues.AdoptionProvision3Description);
    setAdoptionProvision4Description(cleanValues.AdoptionProvision4Description);
    setErrors({});
  };
  const [adoptionProvisionRecord, setAdoptionProvisionRecord] = React.useState(
    adoptionProvisionModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? await DataStore.query(AdoptionProvision, idProp)
        : adoptionProvisionModelProp;
      setAdoptionProvisionRecord(record);
    };
    queryData();
  }, [idProp, adoptionProvisionModelProp]);
  React.useEffect(resetStateValues, [adoptionProvisionRecord]);
  const validations = {
    AdoptionProvisionType: [],
    AdoptionProvision3Description: [],
    AdoptionProvision4Description: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          AdoptionProvisionType,
          AdoptionProvision3Description,
          AdoptionProvision4Description,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(
            AdoptionProvision.copyOf(adoptionProvisionRecord, (updated) => {
              Object.assign(updated, modelFields);
            })
          );
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "AdoptionProvisionUpdateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Adoption Provision"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS2 paragraph 10-22."
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Update this record of an adoption provision for this disclosure."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <SelectField
        label="Adoption provision type"
        placeholder="Please select an option"
        isDisabled={false}
        value={AdoptionProvisionType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AdoptionProvisionType: value,
              AdoptionProvision3Description,
              AdoptionProvision4Description,
            };
            const result = onChange(modelFields);
            value = result?.AdoptionProvisionType ?? value;
          }
          if (errors.AdoptionProvisionType?.hasError) {
            runValidationTasks("AdoptionProvisionType", value);
          }
          setAdoptionProvisionType(value);
        }}
        onBlur={() =>
          runValidationTasks("AdoptionProvisionType", AdoptionProvisionType)
        }
        errorMessage={errors.AdoptionProvisionType?.errorMessage}
        hasError={errors.AdoptionProvisionType?.hasError}
        {...getOverrideProps(overrides, "AdoptionProvisionType")}
      >
        <option
          children="Adoption provision 1 current financial impacts"
          value="Adoption_provision_1_Current_financial_impacts"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption0")}
        ></option>
        <option
          children="Adoption provision 2 anticipated financial impacts"
          value="Adoption_provision_2_Anticipated_financial_impacts"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption1")}
        ></option>
        <option
          children="Adoption provision 3 transition planning"
          value="Adoption_provision_3_Transition_planning"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption2")}
        ></option>
        <option
          children="Adoption provision 4 scope 3 ghg emissions"
          value="Adoption_provision_4_Scope_3_GHG_emissions"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption3")}
        ></option>
        <option
          children="Adoption provision 5 comparatives for scope 3 ghg emissions"
          value="Adoption_provision_5_Comparatives_for_Scope_3_GHG_emissions"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption4")}
        ></option>
        <option
          children="Adoption provision 6 comparatives for metrics"
          value="Adoption_provision_6_Comparatives_for_metrics"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption5")}
        ></option>
        <option
          children="Adoption provision 7 analysis of trends"
          value="Adoption_provision_7_Analysis_of_trends"
          {...getOverrideProps(overrides, "AdoptionProvisionTypeoption6")}
        ></option>
      </SelectField>
      <Text
        children="If adopting provision 3, describe progress towards developing the transition plan aspects of your strategy, in your first reporting period."
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Text>
      <TextAreaField
        label="Adoption provision 3 description"
        isRequired={false}
        isReadOnly={false}
        value={AdoptionProvision3Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AdoptionProvisionType,
              AdoptionProvision3Description: value,
              AdoptionProvision4Description,
            };
            const result = onChange(modelFields);
            value = result?.AdoptionProvision3Description ?? value;
          }
          if (errors.AdoptionProvision3Description?.hasError) {
            runValidationTasks("AdoptionProvision3Description", value);
          }
          setAdoptionProvision3Description(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AdoptionProvision3Description",
            AdoptionProvision3Description
          )
        }
        errorMessage={errors.AdoptionProvision3Description?.errorMessage}
        hasError={errors.AdoptionProvision3Description?.hasError}
        {...getOverrideProps(overrides, "AdoptionProvision3Description")}
      ></TextAreaField>
      <Text
        children="If adopting provision 4 for a subset of scope 3 emissions, identify which sources you have not disclosed."
        {...getOverrideProps(overrides, "SectionalElement30")}
      ></Text>
      <TextAreaField
        label="Adoption provision 4 description"
        isRequired={false}
        isReadOnly={false}
        value={AdoptionProvision4Description}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              AdoptionProvisionType,
              AdoptionProvision3Description,
              AdoptionProvision4Description: value,
            };
            const result = onChange(modelFields);
            value = result?.AdoptionProvision4Description ?? value;
          }
          if (errors.AdoptionProvision4Description?.hasError) {
            runValidationTasks("AdoptionProvision4Description", value);
          }
          setAdoptionProvision4Description(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "AdoptionProvision4Description",
            AdoptionProvision4Description
          )
        }
        errorMessage={errors.AdoptionProvision4Description?.errorMessage}
        hasError={errors.AdoptionProvision4Description?.hasError}
        {...getOverrideProps(overrides, "AdoptionProvision4Description")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || adoptionProvisionModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || adoptionProvisionModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
