/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { GhgMethodAssumption } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function GhgMethodAssumptionCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    GHGMethodAssumptionName: "",
    GHGMethodDescription: "",
    GHGAssumptionDescription: "",
    GHGMethodAssumptionLimitation: "",
    GHGMethodChoiceRationale: "",
  };
  const [GHGMethodAssumptionName, setGHGMethodAssumptionName] = React.useState(
    initialValues.GHGMethodAssumptionName
  );
  const [GHGMethodDescription, setGHGMethodDescription] = React.useState(
    initialValues.GHGMethodDescription
  );
  const [GHGAssumptionDescription, setGHGAssumptionDescription] =
    React.useState(initialValues.GHGAssumptionDescription);
  const [GHGMethodAssumptionLimitation, setGHGMethodAssumptionLimitation] =
    React.useState(initialValues.GHGMethodAssumptionLimitation);
  const [GHGMethodChoiceRationale, setGHGMethodChoiceRationale] =
    React.useState(initialValues.GHGMethodChoiceRationale);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setGHGMethodAssumptionName(initialValues.GHGMethodAssumptionName);
    setGHGMethodDescription(initialValues.GHGMethodDescription);
    setGHGAssumptionDescription(initialValues.GHGAssumptionDescription);
    setGHGMethodAssumptionLimitation(
      initialValues.GHGMethodAssumptionLimitation
    );
    setGHGMethodChoiceRationale(initialValues.GHGMethodChoiceRationale);
    setErrors({});
  };
  const validations = {
    GHGMethodAssumptionName: [],
    GHGMethodDescription: [],
    GHGAssumptionDescription: [],
    GHGMethodAssumptionLimitation: [],
    GHGMethodChoiceRationale: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          GHGMethodAssumptionName,
          GHGMethodDescription,
          GHGAssumptionDescription,
          GHGMethodAssumptionLimitation,
          GHGMethodChoiceRationale,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new GhgMethodAssumption(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "GhgMethodAssumptionCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Greenhouse Gas Methods and Assumptions"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS3 paragraph 52."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Heading>
      <Text
        children="Create a record that describes the methods and assumptions used to calculate or estimate GHG emissions, and the limitations of those methods. If you have chosen between different methods, you must disclose the methods used and the rationale for doing so."
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <TextField
        label="Method and assumption record name (e.g. 2025 GHG method and assumption records)"
        isRequired={false}
        isReadOnly={false}
        value={GHGMethodAssumptionName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGMethodAssumptionName: value,
              GHGMethodDescription,
              GHGAssumptionDescription,
              GHGMethodAssumptionLimitation,
              GHGMethodChoiceRationale,
            };
            const result = onChange(modelFields);
            value = result?.GHGMethodAssumptionName ?? value;
          }
          if (errors.GHGMethodAssumptionName?.hasError) {
            runValidationTasks("GHGMethodAssumptionName", value);
          }
          setGHGMethodAssumptionName(value);
        }}
        onBlur={() =>
          runValidationTasks("GHGMethodAssumptionName", GHGMethodAssumptionName)
        }
        errorMessage={errors.GHGMethodAssumptionName?.errorMessage}
        hasError={errors.GHGMethodAssumptionName?.hasError}
        {...getOverrideProps(overrides, "GHGMethodAssumptionName")}
      ></TextField>
      <TextAreaField
        label="Describe the method used to calculate or estimate greenhouse gas emissions"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGMethodAssumptionName,
              GHGMethodDescription: value,
              GHGAssumptionDescription,
              GHGMethodAssumptionLimitation,
              GHGMethodChoiceRationale,
            };
            const result = onChange(modelFields);
            value = result?.GHGMethodDescription ?? value;
          }
          if (errors.GHGMethodDescription?.hasError) {
            runValidationTasks("GHGMethodDescription", value);
          }
          setGHGMethodDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("GHGMethodDescription", GHGMethodDescription)
        }
        errorMessage={errors.GHGMethodDescription?.errorMessage}
        hasError={errors.GHGMethodDescription?.hasError}
        {...getOverrideProps(overrides, "GHGMethodDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Describe the assumptions used to calculate or estimate greenhouse gas emissions"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGMethodAssumptionName,
              GHGMethodDescription,
              GHGAssumptionDescription: value,
              GHGMethodAssumptionLimitation,
              GHGMethodChoiceRationale,
            };
            const result = onChange(modelFields);
            value = result?.GHGAssumptionDescription ?? value;
          }
          if (errors.GHGAssumptionDescription?.hasError) {
            runValidationTasks("GHGAssumptionDescription", value);
          }
          setGHGAssumptionDescription(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GHGAssumptionDescription",
            GHGAssumptionDescription
          )
        }
        errorMessage={errors.GHGAssumptionDescription?.errorMessage}
        hasError={errors.GHGAssumptionDescription?.hasError}
        {...getOverrideProps(overrides, "GHGAssumptionDescription")}
      ></TextAreaField>
      <TextAreaField
        label="Describe any limitations in your greenhouse gas method assumption"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGMethodAssumptionName,
              GHGMethodDescription,
              GHGAssumptionDescription,
              GHGMethodAssumptionLimitation: value,
              GHGMethodChoiceRationale,
            };
            const result = onChange(modelFields);
            value = result?.GHGMethodAssumptionLimitation ?? value;
          }
          if (errors.GHGMethodAssumptionLimitation?.hasError) {
            runValidationTasks("GHGMethodAssumptionLimitation", value);
          }
          setGHGMethodAssumptionLimitation(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GHGMethodAssumptionLimitation",
            GHGMethodAssumptionLimitation
          )
        }
        errorMessage={errors.GHGMethodAssumptionLimitation?.errorMessage}
        hasError={errors.GHGMethodAssumptionLimitation?.hasError}
        {...getOverrideProps(overrides, "GHGMethodAssumptionLimitation")}
      ></TextAreaField>
      <TextAreaField
        label="Describe the rationale in your choice of greenhouse gas calculation method"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              GHGMethodAssumptionName,
              GHGMethodDescription,
              GHGAssumptionDescription,
              GHGMethodAssumptionLimitation,
              GHGMethodChoiceRationale: value,
            };
            const result = onChange(modelFields);
            value = result?.GHGMethodChoiceRationale ?? value;
          }
          if (errors.GHGMethodChoiceRationale?.hasError) {
            runValidationTasks("GHGMethodChoiceRationale", value);
          }
          setGHGMethodChoiceRationale(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "GHGMethodChoiceRationale",
            GHGMethodChoiceRationale
          )
        }
        errorMessage={errors.GHGMethodChoiceRationale?.errorMessage}
        hasError={errors.GHGMethodChoiceRationale?.hasError}
        {...getOverrideProps(overrides, "GHGMethodChoiceRationale")}
      ></TextAreaField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
