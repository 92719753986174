import clsx from "clsx";
import {BsPlusCircleFill} from "react-icons/bs";
import ItemButton from "./ItemButton";
import styles from "./ItemButtons.module.css";

export default function NewItemButton({
  icon = <BsPlusCircleFill />,
  label = "New",
  buttonClass = undefined,
  ...rest
}) {
  return (
    <ItemButton
      buttonClass={clsx(buttonClass, styles.newButton)}
      icon={icon}
      label={label}
      requiredPermissions="create"
      {...rest}
    />
  );
}

NewItemButton.propTypes = {
  ...ItemButton.propTypes
};
