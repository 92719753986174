/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { MetricsDataSet } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function MetricsDataSetCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    DataSetName: "",
    Scope1Emissions: "",
    Scope2Emissions: "",
    Scope3Emissions: "",
    Scope4Emissions: "",
    TotalEmissions: "",
    TransitionRisksType: "",
    TransitionRisksValue: "",
    PhysicalRisksType: "",
    PhysicalRisksValue: "",
    OpportunitiesType: "",
    OpportunitiesValue: "",
    CapitalDeploymentValue: "",
    InternalEmissionsPrice: "",
    RemunerationType: "",
    RemunerationValue: "",
    RemunerationAlternative: "",
    MetricTrendAnalysis: "",
  };
  const [DataSetName, setDataSetName] = React.useState(
    initialValues.DataSetName
  );
  const [Scope1Emissions, setScope1Emissions] = React.useState(
    initialValues.Scope1Emissions
  );
  const [Scope2Emissions, setScope2Emissions] = React.useState(
    initialValues.Scope2Emissions
  );
  const [Scope3Emissions, setScope3Emissions] = React.useState(
    initialValues.Scope3Emissions
  );
  const [Scope4Emissions, setScope4Emissions] = React.useState(
    initialValues.Scope4Emissions
  );
  const [TotalEmissions, setTotalEmissions] = React.useState(
    initialValues.TotalEmissions
  );
  const [TransitionRisksType, setTransitionRisksType] = React.useState(
    initialValues.TransitionRisksType
  );
  const [TransitionRisksValue, setTransitionRisksValue] = React.useState(
    initialValues.TransitionRisksValue
  );
  const [PhysicalRisksType, setPhysicalRisksType] = React.useState(
    initialValues.PhysicalRisksType
  );
  const [PhysicalRisksValue, setPhysicalRisksValue] = React.useState(
    initialValues.PhysicalRisksValue
  );
  const [OpportunitiesType, setOpportunitiesType] = React.useState(
    initialValues.OpportunitiesType
  );
  const [OpportunitiesValue, setOpportunitiesValue] = React.useState(
    initialValues.OpportunitiesValue
  );
  const [CapitalDeploymentValue, setCapitalDeploymentValue] = React.useState(
    initialValues.CapitalDeploymentValue
  );
  const [InternalEmissionsPrice, setInternalEmissionsPrice] = React.useState(
    initialValues.InternalEmissionsPrice
  );
  const [RemunerationType, setRemunerationType] = React.useState(
    initialValues.RemunerationType
  );
  const [RemunerationValue, setRemunerationValue] = React.useState(
    initialValues.RemunerationValue
  );
  const [RemunerationAlternative, setRemunerationAlternative] = React.useState(
    initialValues.RemunerationAlternative
  );
  const [MetricTrendAnalysis, setMetricTrendAnalysis] = React.useState(
    initialValues.MetricTrendAnalysis
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setDataSetName(initialValues.DataSetName);
    setScope1Emissions(initialValues.Scope1Emissions);
    setScope2Emissions(initialValues.Scope2Emissions);
    setScope3Emissions(initialValues.Scope3Emissions);
    setScope4Emissions(initialValues.Scope4Emissions);
    setTotalEmissions(initialValues.TotalEmissions);
    setTransitionRisksType(initialValues.TransitionRisksType);
    setTransitionRisksValue(initialValues.TransitionRisksValue);
    setPhysicalRisksType(initialValues.PhysicalRisksType);
    setPhysicalRisksValue(initialValues.PhysicalRisksValue);
    setOpportunitiesType(initialValues.OpportunitiesType);
    setOpportunitiesValue(initialValues.OpportunitiesValue);
    setCapitalDeploymentValue(initialValues.CapitalDeploymentValue);
    setInternalEmissionsPrice(initialValues.InternalEmissionsPrice);
    setRemunerationType(initialValues.RemunerationType);
    setRemunerationValue(initialValues.RemunerationValue);
    setRemunerationAlternative(initialValues.RemunerationAlternative);
    setMetricTrendAnalysis(initialValues.MetricTrendAnalysis);
    setErrors({});
  };
  const validations = {
    DataSetName: [],
    Scope1Emissions: [],
    Scope2Emissions: [],
    Scope3Emissions: [],
    Scope4Emissions: [],
    TotalEmissions: [],
    TransitionRisksType: [],
    TransitionRisksValue: [],
    PhysicalRisksType: [],
    PhysicalRisksValue: [],
    OpportunitiesType: [],
    OpportunitiesValue: [],
    CapitalDeploymentValue: [],
    InternalEmissionsPrice: [],
    RemunerationType: [],
    RemunerationValue: [],
    RemunerationAlternative: [],
    MetricTrendAnalysis: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          DataSetName,
          Scope1Emissions,
          Scope2Emissions,
          Scope3Emissions,
          Scope4Emissions,
          TotalEmissions,
          TransitionRisksType,
          TransitionRisksValue,
          PhysicalRisksType,
          PhysicalRisksValue,
          OpportunitiesType,
          OpportunitiesValue,
          CapitalDeploymentValue,
          InternalEmissionsPrice,
          RemunerationType,
          RemunerationValue,
          RemunerationAlternative,
          MetricTrendAnalysis,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new MetricsDataSet(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "MetricsDataSetCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Cross-Industry Metrics"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Heading
        children="NZCS1 paragraph 22a-h,NZCS3 paragraph 40,42."
        {...getOverrideProps(overrides, "SectionalElement14")}
      ></Heading>
      <Text
        children="Create a record describing metrics that are relevant to all entities regardless of industry and business model."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Metrics record name (e.g. 2025 cross-industry metrics record)"
        isRequired={false}
        isReadOnly={false}
        value={DataSetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName: value,
              Scope1Emissions,
              Scope2Emissions,
              Scope3Emissions,
              Scope4Emissions,
              TotalEmissions,
              TransitionRisksType,
              TransitionRisksValue,
              PhysicalRisksType,
              PhysicalRisksValue,
              OpportunitiesType,
              OpportunitiesValue,
              CapitalDeploymentValue,
              InternalEmissionsPrice,
              RemunerationType,
              RemunerationValue,
              RemunerationAlternative,
              MetricTrendAnalysis,
            };
            const result = onChange(modelFields);
            value = result?.DataSetName ?? value;
          }
          if (errors.DataSetName?.hasError) {
            runValidationTasks("DataSetName", value);
          }
          setDataSetName(value);
        }}
        onBlur={() => runValidationTasks("DataSetName", DataSetName)}
        errorMessage={errors.DataSetName?.errorMessage}
        hasError={errors.DataSetName?.hasError}
        {...getOverrideProps(overrides, "DataSetName")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement3")}
      ></Divider>
      <Heading
        children="Greenhouse Gas Emissions Metrics"
        {...getOverrideProps(overrides, "SectionalElement4")}
      ></Heading>
      <Text
        children="Enter the gross emissions in metric tonnes of carbon dioxide equivalent (CO2e), for each scope, using the following entry fields or upload a spreadsheet with your data."
        {...getOverrideProps(overrides, "SectionalElement5")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid8")}
      >
        <TextField
          label="Scope 1 emissions"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope1Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions: value,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.Scope1Emissions ?? value;
            }
            if (errors.Scope1Emissions?.hasError) {
              runValidationTasks("Scope1Emissions", value);
            }
            setScope1Emissions(value);
          }}
          onBlur={() => runValidationTasks("Scope1Emissions", Scope1Emissions)}
          errorMessage={errors.Scope1Emissions?.errorMessage}
          hasError={errors.Scope1Emissions?.hasError}
          {...getOverrideProps(overrides, "Scope1Emissions")}
        ></TextField>
        <TextField
          label="Scope 2 emissions"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope2Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions: value,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.Scope2Emissions ?? value;
            }
            if (errors.Scope2Emissions?.hasError) {
              runValidationTasks("Scope2Emissions", value);
            }
            setScope2Emissions(value);
          }}
          onBlur={() => runValidationTasks("Scope2Emissions", Scope2Emissions)}
          errorMessage={errors.Scope2Emissions?.errorMessage}
          hasError={errors.Scope2Emissions?.hasError}
          {...getOverrideProps(overrides, "Scope2Emissions")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid9")}
      >
        <TextField
          label="Scope 3 emissions"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope3Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions: value,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.Scope3Emissions ?? value;
            }
            if (errors.Scope3Emissions?.hasError) {
              runValidationTasks("Scope3Emissions", value);
            }
            setScope3Emissions(value);
          }}
          onBlur={() => runValidationTasks("Scope3Emissions", Scope3Emissions)}
          errorMessage={errors.Scope3Emissions?.errorMessage}
          hasError={errors.Scope3Emissions?.hasError}
          {...getOverrideProps(overrides, "Scope3Emissions")}
        ></TextField>
        <TextField
          label="Scope4 emissions"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={Scope4Emissions}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions: value,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.Scope4Emissions ?? value;
            }
            if (errors.Scope4Emissions?.hasError) {
              runValidationTasks("Scope4Emissions", value);
            }
            setScope4Emissions(value);
          }}
          onBlur={() => runValidationTasks("Scope4Emissions", Scope4Emissions)}
          errorMessage={errors.Scope4Emissions?.errorMessage}
          hasError={errors.Scope4Emissions?.hasError}
          {...getOverrideProps(overrides, "Scope4Emissions")}
        ></TextField>
      </Grid>
      <TextField
        label="Total emissions"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={TotalEmissions}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              DataSetName,
              Scope1Emissions,
              Scope2Emissions,
              Scope3Emissions,
              Scope4Emissions,
              TotalEmissions: value,
              TransitionRisksType,
              TransitionRisksValue,
              PhysicalRisksType,
              PhysicalRisksValue,
              OpportunitiesType,
              OpportunitiesValue,
              CapitalDeploymentValue,
              InternalEmissionsPrice,
              RemunerationType,
              RemunerationValue,
              RemunerationAlternative,
              MetricTrendAnalysis,
            };
            const result = onChange(modelFields);
            value = result?.TotalEmissions ?? value;
          }
          if (errors.TotalEmissions?.hasError) {
            runValidationTasks("TotalEmissions", value);
          }
          setTotalEmissions(value);
        }}
        onBlur={() => runValidationTasks("TotalEmissions", TotalEmissions)}
        errorMessage={errors.TotalEmissions?.errorMessage}
        hasError={errors.TotalEmissions?.hasError}
        {...getOverrideProps(overrides, "TotalEmissions")}
      ></TextField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement6")}
      ></Divider>
      <Heading
        children="Risks and Opportunities Metrics"
        {...getOverrideProps(overrides, "SectionalElement7")}
      ></Heading>
      <Text
        children="Enter the amount or percentage of assets or business activities subject to your climate related risks and opportunities. You can also enter detailed data for Business Activities and Assets through the provided links."
        {...getOverrideProps(overrides, "SectionalElement8")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid14")}
      >
        <SelectField
          label="Transition risks amount or %"
          placeholder="Please select an option"
          isDisabled={false}
          value={TransitionRisksType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType: value,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.TransitionRisksType ?? value;
            }
            if (errors.TransitionRisksType?.hasError) {
              runValidationTasks("TransitionRisksType", value);
            }
            setTransitionRisksType(value);
          }}
          onBlur={() =>
            runValidationTasks("TransitionRisksType", TransitionRisksType)
          }
          errorMessage={errors.TransitionRisksType?.errorMessage}
          hasError={errors.TransitionRisksType?.hasError}
          {...getOverrideProps(overrides, "TransitionRisksType")}
        >
          <option
            children="Amount"
            value="Amount"
            {...getOverrideProps(overrides, "TransitionRisksTypeoption0")}
          ></option>
          <option
            children="Percent"
            value="Percent"
            {...getOverrideProps(overrides, "TransitionRisksTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Transition risks value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={TransitionRisksValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue: value,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.TransitionRisksValue ?? value;
            }
            if (errors.TransitionRisksValue?.hasError) {
              runValidationTasks("TransitionRisksValue", value);
            }
            setTransitionRisksValue(value);
          }}
          onBlur={() =>
            runValidationTasks("TransitionRisksValue", TransitionRisksValue)
          }
          errorMessage={errors.TransitionRisksValue?.errorMessage}
          hasError={errors.TransitionRisksValue?.hasError}
          {...getOverrideProps(overrides, "TransitionRisksValue")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid15")}
      >
        <SelectField
          label="Physical risks amount or %"
          placeholder="Please select an option"
          isDisabled={false}
          value={PhysicalRisksType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType: value,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.PhysicalRisksType ?? value;
            }
            if (errors.PhysicalRisksType?.hasError) {
              runValidationTasks("PhysicalRisksType", value);
            }
            setPhysicalRisksType(value);
          }}
          onBlur={() =>
            runValidationTasks("PhysicalRisksType", PhysicalRisksType)
          }
          errorMessage={errors.PhysicalRisksType?.errorMessage}
          hasError={errors.PhysicalRisksType?.hasError}
          {...getOverrideProps(overrides, "PhysicalRisksType")}
        >
          <option
            children="Amount"
            value="Amount"
            {...getOverrideProps(overrides, "PhysicalRisksTypeoption0")}
          ></option>
          <option
            children="Percent"
            value="Percent"
            {...getOverrideProps(overrides, "PhysicalRisksTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Physical risks value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={PhysicalRisksValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue: value,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.PhysicalRisksValue ?? value;
            }
            if (errors.PhysicalRisksValue?.hasError) {
              runValidationTasks("PhysicalRisksValue", value);
            }
            setPhysicalRisksValue(value);
          }}
          onBlur={() =>
            runValidationTasks("PhysicalRisksValue", PhysicalRisksValue)
          }
          errorMessage={errors.PhysicalRisksValue?.errorMessage}
          hasError={errors.PhysicalRisksValue?.hasError}
          {...getOverrideProps(overrides, "PhysicalRisksValue")}
        ></TextField>
      </Grid>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid16")}
      >
        <SelectField
          label="Opportunities amount or %"
          placeholder="Please select an option"
          isDisabled={false}
          value={OpportunitiesType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType: value,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.OpportunitiesType ?? value;
            }
            if (errors.OpportunitiesType?.hasError) {
              runValidationTasks("OpportunitiesType", value);
            }
            setOpportunitiesType(value);
          }}
          onBlur={() =>
            runValidationTasks("OpportunitiesType", OpportunitiesType)
          }
          errorMessage={errors.OpportunitiesType?.errorMessage}
          hasError={errors.OpportunitiesType?.hasError}
          {...getOverrideProps(overrides, "OpportunitiesType")}
        >
          <option
            children="Amount"
            value="Amount"
            {...getOverrideProps(overrides, "OpportunitiesTypeoption0")}
          ></option>
          <option
            children="Percent"
            value="Percent"
            {...getOverrideProps(overrides, "OpportunitiesTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Opportunities value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={OpportunitiesValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue: value,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.OpportunitiesValue ?? value;
            }
            if (errors.OpportunitiesValue?.hasError) {
              runValidationTasks("OpportunitiesValue", value);
            }
            setOpportunitiesValue(value);
          }}
          onBlur={() =>
            runValidationTasks("OpportunitiesValue", OpportunitiesValue)
          }
          errorMessage={errors.OpportunitiesValue?.errorMessage}
          hasError={errors.OpportunitiesValue?.hasError}
          {...getOverrideProps(overrides, "OpportunitiesValue")}
        ></TextField>
      </Grid>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement9")}
      ></Divider>
      <Heading
        children="Other Emissions Metrics"
        {...getOverrideProps(overrides, "SectionalElement10")}
      ></Heading>
      <Text
        children="Enter data for the following emissions metrics."
        {...getOverrideProps(overrides, "SectionalElement11")}
      ></Text>
      <TextField
        label="Amount of capital, financing, or investment deployed toward risks and opportunities"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={CapitalDeploymentValue}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              DataSetName,
              Scope1Emissions,
              Scope2Emissions,
              Scope3Emissions,
              Scope4Emissions,
              TotalEmissions,
              TransitionRisksType,
              TransitionRisksValue,
              PhysicalRisksType,
              PhysicalRisksValue,
              OpportunitiesType,
              OpportunitiesValue,
              CapitalDeploymentValue: value,
              InternalEmissionsPrice,
              RemunerationType,
              RemunerationValue,
              RemunerationAlternative,
              MetricTrendAnalysis,
            };
            const result = onChange(modelFields);
            value = result?.CapitalDeploymentValue ?? value;
          }
          if (errors.CapitalDeploymentValue?.hasError) {
            runValidationTasks("CapitalDeploymentValue", value);
          }
          setCapitalDeploymentValue(value);
        }}
        onBlur={() =>
          runValidationTasks("CapitalDeploymentValue", CapitalDeploymentValue)
        }
        errorMessage={errors.CapitalDeploymentValue?.errorMessage}
        hasError={errors.CapitalDeploymentValue?.hasError}
        {...getOverrideProps(overrides, "CapitalDeploymentValue")}
      ></TextField>
      <TextField
        label="Internal emissions price ($ per tonne CO2e)"
        isRequired={false}
        isReadOnly={false}
        type="number"
        step="any"
        value={InternalEmissionsPrice}
        onChange={(e) => {
          let value = isNaN(parseFloat(e.target.value))
            ? e.target.value
            : parseFloat(e.target.value);
          if (onChange) {
            const modelFields = {
              DataSetName,
              Scope1Emissions,
              Scope2Emissions,
              Scope3Emissions,
              Scope4Emissions,
              TotalEmissions,
              TransitionRisksType,
              TransitionRisksValue,
              PhysicalRisksType,
              PhysicalRisksValue,
              OpportunitiesType,
              OpportunitiesValue,
              CapitalDeploymentValue,
              InternalEmissionsPrice: value,
              RemunerationType,
              RemunerationValue,
              RemunerationAlternative,
              MetricTrendAnalysis,
            };
            const result = onChange(modelFields);
            value = result?.InternalEmissionsPrice ?? value;
          }
          if (errors.InternalEmissionsPrice?.hasError) {
            runValidationTasks("InternalEmissionsPrice", value);
          }
          setInternalEmissionsPrice(value);
        }}
        onBlur={() =>
          runValidationTasks("InternalEmissionsPrice", InternalEmissionsPrice)
        }
        errorMessage={errors.InternalEmissionsPrice?.errorMessage}
        hasError={errors.InternalEmissionsPrice?.hasError}
        {...getOverrideProps(overrides, "InternalEmissionsPrice")}
      ></TextField>
      <Text
        children="Remuneration (management remuneration linked to climate-related risks and opportunities in the current period, as a value or description)."
        {...getOverrideProps(overrides, "SectionalElement13")}
      ></Text>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid23")}
      >
        <SelectField
          label="Remuneration amount or %"
          placeholder="Please select an option"
          isDisabled={false}
          value={RemunerationType}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType: value,
                RemunerationValue,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.RemunerationType ?? value;
            }
            if (errors.RemunerationType?.hasError) {
              runValidationTasks("RemunerationType", value);
            }
            setRemunerationType(value);
          }}
          onBlur={() =>
            runValidationTasks("RemunerationType", RemunerationType)
          }
          errorMessage={errors.RemunerationType?.errorMessage}
          hasError={errors.RemunerationType?.hasError}
          {...getOverrideProps(overrides, "RemunerationType")}
        >
          <option
            children="Amount"
            value="Amount"
            {...getOverrideProps(overrides, "RemunerationTypeoption0")}
          ></option>
          <option
            children="Percent"
            value="Percent"
            {...getOverrideProps(overrides, "RemunerationTypeoption1")}
          ></option>
        </SelectField>
        <TextField
          label="Remuneration value"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={RemunerationValue}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                DataSetName,
                Scope1Emissions,
                Scope2Emissions,
                Scope3Emissions,
                Scope4Emissions,
                TotalEmissions,
                TransitionRisksType,
                TransitionRisksValue,
                PhysicalRisksType,
                PhysicalRisksValue,
                OpportunitiesType,
                OpportunitiesValue,
                CapitalDeploymentValue,
                InternalEmissionsPrice,
                RemunerationType,
                RemunerationValue: value,
                RemunerationAlternative,
                MetricTrendAnalysis,
              };
              const result = onChange(modelFields);
              value = result?.RemunerationValue ?? value;
            }
            if (errors.RemunerationValue?.hasError) {
              runValidationTasks("RemunerationValue", value);
            }
            setRemunerationValue(value);
          }}
          onBlur={() =>
            runValidationTasks("RemunerationValue", RemunerationValue)
          }
          errorMessage={errors.RemunerationValue?.errorMessage}
          hasError={errors.RemunerationValue?.hasError}
          {...getOverrideProps(overrides, "RemunerationValue")}
        ></TextField>
      </Grid>
      <TextAreaField
        label="Description of management remuneration"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Scope1Emissions,
              Scope2Emissions,
              Scope3Emissions,
              Scope4Emissions,
              TotalEmissions,
              TransitionRisksType,
              TransitionRisksValue,
              PhysicalRisksType,
              PhysicalRisksValue,
              OpportunitiesType,
              OpportunitiesValue,
              CapitalDeploymentValue,
              InternalEmissionsPrice,
              RemunerationType,
              RemunerationValue,
              RemunerationAlternative: value,
              MetricTrendAnalysis,
            };
            const result = onChange(modelFields);
            value = result?.RemunerationAlternative ?? value;
          }
          if (errors.RemunerationAlternative?.hasError) {
            runValidationTasks("RemunerationAlternative", value);
          }
          setRemunerationAlternative(value);
        }}
        onBlur={() =>
          runValidationTasks("RemunerationAlternative", RemunerationAlternative)
        }
        errorMessage={errors.RemunerationAlternative?.errorMessage}
        hasError={errors.RemunerationAlternative?.hasError}
        {...getOverrideProps(overrides, "RemunerationAlternative")}
      ></TextAreaField>
      <TextAreaField
        label="Metric trend analysis"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              DataSetName,
              Scope1Emissions,
              Scope2Emissions,
              Scope3Emissions,
              Scope4Emissions,
              TotalEmissions,
              TransitionRisksType,
              TransitionRisksValue,
              PhysicalRisksType,
              PhysicalRisksValue,
              OpportunitiesType,
              OpportunitiesValue,
              CapitalDeploymentValue,
              InternalEmissionsPrice,
              RemunerationType,
              RemunerationValue,
              RemunerationAlternative,
              MetricTrendAnalysis: value,
            };
            const result = onChange(modelFields);
            value = result?.MetricTrendAnalysis ?? value;
          }
          if (errors.MetricTrendAnalysis?.hasError) {
            runValidationTasks("MetricTrendAnalysis", value);
          }
          setMetricTrendAnalysis(value);
        }}
        onBlur={() =>
          runValidationTasks("MetricTrendAnalysis", MetricTrendAnalysis)
        }
        errorMessage={errors.MetricTrendAnalysis?.errorMessage}
        hasError={errors.MetricTrendAnalysis?.hasError}
        {...getOverrideProps(overrides, "MetricTrendAnalysis")}
      ></TextAreaField>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement12")}
      ></Divider>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
