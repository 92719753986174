import {Button, Flex} from "@aws-amplify/ui-react";
import clsx from "clsx";
import {isArray, pick, omit, defaults} from "lodash";
import PropTypes from "prop-types";
import {useState} from "react";
import {Form, Modal, Table} from "react-bootstrap";
import {BsExclamationTriangleFill, BsUpload} from "react-icons/bs";
import {combinedSchemaFor, exportFields} from "../amplify/schemaHelpers";
import {useCustomerDataStore} from "../customer/useCustomerDataStore";
import ItemButton from "./ItemButton";
import styles from "./ItemButtons.module.css";
import {uischema} from "../backend";
import {useToast} from "../util/Toast";
import {parse} from "papaparse";
import {DateTime} from "luxon";

// Browsers on Windowoze apparently report non-standard content types for csv's so allow:
const csvTypes = [
  "text/plain",
  "text/x-csv",
  "application/vnd.ms-excel",
  "application/csv",
  "application/x-csv",
  "text/csv",
  "text/comma-separated-values",
  "text/x-comma-separated-values",
  "text/tab-separated-values"
];

function mapCsvField(
  stringValue,
  {name, type, isArray} = {type: "String", isArray: false}
) {
  if (stringValue) {
    if (isArray) {
      return stringValue
        .split(",")
        .map((element) => mapCsvField(element, {name, type, isArray: false}));
    } else {
      switch (type) {
        case "Int":
          return parseInt(stringValue);
        case "Float":
          return parseFloat(stringValue);
        case "Boolean":
          return stringValue.toLocaleLowerCase() === "true";
        case "AWSDate":
          return DateTime.fromISO(stringValue).toISODate();
        case "AWSDateTime":
          return DateTime.fromISO(stringValue).toISOString();
        case "AWSTime":
          return DateTime.fromIso(stringValue).toISOTime();
        case "AWSTimeStamp":
          return new Date(parseInt(stringValue)).toISOString();
        case "String":
        case "ID":
        case "AWSEmail":
        case "AWSPhone":
        default:
          return stringValue;
      }
    }
  } else return undefined;
}

function mapCsvFields(item, {fields, name}) {
  return defaults(
    Object.fromEntries(
      Object.entries(item)
        .filter(([fieldName]) => fieldName in fields)
        .map(([fieldName, stringValue]) => [
          fieldName,
          mapCsvField(stringValue, fields[fieldName])
        ])
    ),
    {model: name} // default to known schema name
  );
}
export default function UploadButton({
  model,
  subtype,
  icon = <BsUpload />,
  label = "Import",
  buttonClass,
  ...rest
}) {
  const datastore = useCustomerDataStore();
  const schema = combinedSchemaFor({model, subtype});
  const {noUpload, displayPluralName} = schema;
  const [browseOpen, setBrowseOpen] = useState(false);
  const [{busy, error, items}, setItems] = useState({});
  const showToast = useToast();

  function handleFileChange(file) {
    setItems({busy: true});
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        try {
          const items = csvTypes.includes(file.type)
            ? parse(reader.result, {
                header: true,
                skipEmptyLines: true
              })?.data.map((item) => mapCsvFields(item, schema))
            : JSON.parse(reader.result);
          if (isArray(items)) setItems({items});
          else throw new Error(`Expected array of ${displayPluralName}`);
        } catch (e) {
          setItems({error: e.toString()});
        }
      },
      false
    );
    reader.addEventListener(
      "error",
      (error) => {
        setItems({error: JSON.stringify(error)});
      },
      false
    );
    reader.readAsText(file);
  }

  async function handleUpload() {
    const modelSchema = uischema().models[model.name];
    const existing = await datastore.query(model);
    const existingById = Object.fromEntries(
      existing.map((item) => [item.id, item])
    );
    const importFields = exportFields({modelSchema, subtype});
    return await Promise.all(
      items.map(async (item) => {
        if (item) {
          if (item?.model === model.name) {
            if (item?.id) {
              if (item?.id in existingById) {
                // Item with id already exists for this customer - merge (only overwrite fields we want)
                const result = await datastore.save(
                  model.copyOf(existingById[item.id], (updated) => {
                    Object.assign(updated, pick(item, importFields));
                  })
                );
                return ["Merged", "Already exists", item, result];
              } else {
                const result = await datastore.save(
                  new model(omit(item, "id"))
                );
                return [
                  "Cloned",
                  "Item not present for this customer",
                  item,
                  result
                ];
              }
            } else {
              // Item has no id, just create
              const result = await datastore.save(new model(omit(item, "id")));
              return ["Created", "New item", item, result];
            }
          } else {
            return ["Ignored", "Model mismatch", item, null];
          }
        } else {
          return ["Ignored", "Item absent", null, null];
        }
      })
    );
  }

  return (
    !noUpload && (
      <>
        <ItemButton
          variant="windowed"
          variation="secondary"
          //isLoading={busy}
          model={model}
          icon={icon}
          label={label}
          requiredPermissions="update"
          buttonClass={clsx(buttonClass, styles.uploadButton)}
          onClick={() => setBrowseOpen(true)}
          title={`${label} ${schema?.displayPluralName}`}
          {...rest}
        />
        <Modal
          show={browseOpen}
          dialogClassName="shadow-lg rounded"
          onHide={() => setBrowseOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <BsUpload size="2rem" />
              &nbsp; Upload {displayPluralName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>File</Form.Label>
              <Form.Control
                type="file"
                onClick={() => {
                  setItems({});
                }}
                onChange={({target: {files}}) => {
                  files && handleFileChange(files[0]);
                }}
              />
            </Form.Group>
            {error && (
              <div>
                <BsExclamationTriangleFill />
                {error}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Flex>
              <Button autoFocus onClick={() => setBrowseOpen(false)}>
                Cancel
              </Button>
              <Button
                isDisabled={!items}
                isLoading={busy}
                variation="primary"
                onClick={async () => {
                  try {
                    const results = await handleUpload();
                    showToast({
                      bg: "success",
                      header: "Import Summary",
                      content: (
                        <div>
                          <Table>
                            {results.map(
                              (
                                [action, description, _importItem, newItem],
                                i
                              ) => (
                                <tr key={i}>
                                  <td>{action}</td>
                                  <td>{newItem?.id}</td>
                                  <td>{description}</td>
                                </tr>
                              )
                            )}
                          </Table>
                          {results.length} record(s) processed
                        </div>
                      ),
                      autohide: false
                    });
                  } catch (e) {
                    showToast({bg: "danger", content: e.toString()});
                  } finally {
                    setBrowseOpen(false);
                  }
                }}
              >
                <BsUpload />
                Upload
              </Button>
            </Flex>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
}

UploadButton.propTypes = {
  ...ItemButton.propTypes,
  model: PropTypes.func.isRequired,
  subtype: PropTypes.string
};
