import { ModelField, SchemaModel, isEnumFieldType, isModelFieldType } from "aws-amplify/datastore";
import S, { BaseSchema, JSONSchema, ObjectSchema } from "fluent-json-schema";
import { isEmpty } from "lodash";
import { allSubtypes, combinedSchemaFor, parseManyMany } from "../amplify/schemaHelpers";
import { UISchemaField, UISchemaModel, uischema } from "../backend";
import { AWSDate, AWSDateTime, Bool, Float, Int, String } from "./ajv";

const awsToJsonTypes: Record<string, BaseSchema<any>> = {
  String: String,
  AWSDate: AWSDate,
  AWSDateTime: AWSDateTime,
  AWSURL: String,
  Float: Float,
  Int: Int,
  Boolean: Bool
};

export type JsonSchemaModelMapper = ObjectSchema | ((defaultSchema: ObjectSchema) => ObjectSchema);
export type JsonSchemaFieldMapper = JSONSchema | ((defaultSchema: JSONSchema) => JSONSchema);

function isCheckedField({ type, name, hideInRelations }: ModelField & UISchemaField) {
  return !(hideInRelations || type === "ID" || /^(_.*)|createdAt|updatedAt|CustomerId|id$/.test(name));
}
function mapModel(mapperKey: keyof (UISchemaField | UISchemaModel), modelSchema: UISchemaModel & SchemaModel): ObjectSchema {
  const mapper = modelSchema?.[mapperKey] as JsonSchemaModelMapper;
  if (mapper && typeof mapper === "object") return mapper as ObjectSchema;
  else {
    const objectSchema = Object.values(modelSchema.fields)
      .filter(isCheckedField)
      .reduce((definition, field) => definition.prop(field.name, mapField(mapperKey, modelSchema, field)), S.object());
    return mapper && typeof mapper === "function" ? mapper(objectSchema) : objectSchema;
  }
}
function mapModelSubtypes(mapperKey: keyof (UISchemaField | UISchemaModel), modelSchema: UISchemaModel & SchemaModel): JSONSchema {
  const subtypes = allSubtypes({ modelSchema });
  if (isEmpty(subtypes)) {
    return mapModel(mapperKey, modelSchema);
  } else if (modelSchema.discriminatorField) {
    const { discriminatorField } = modelSchema;
    return S.raw({
      type: "object",
      discriminator: { propertyName: discriminatorField },
      required: [modelSchema.discriminatorField],
      oneOf: subtypes
        .map((subtype) => [subtype, mapModel(mapperKey, combinedSchemaFor({ modelSchema, subtype }))])
        .map(([subtype, objectSchema]) => {
          const { required, properties } = objectSchema.valueOf() as Record<string, any>;
          return {
            properties: {
              ...properties,
              [discriminatorField]: { const: subtype }
            },
            required
          };
        })
    });
  } else {
    throw new Error(`Unexpected - no discriminator field in subtyped model ${modelSchema.name}`);
  }
}
function mapField(
  mapperKey: keyof UISchemaField,
  model: SchemaModel & UISchemaModel,
  field: ModelField & UISchemaField
): JSONSchema {
  let fieldSchema;
  const mapper = field?.[mapperKey];
  if (mapper && typeof mapper === "object") return mapper as JSONSchema;
  if (isModelFieldType(field.type)) {
    const many = parseManyMany(model, field);
    fieldSchema = S.ref(`#/definitions/models/${many ? many.targetModelSchema.name : field.type.model}`);
  } else if (isEnumFieldType(field.type)) {
    fieldSchema = S.ref(`#/definitions/enums/${field.type.enum}`);
  } else {
    fieldSchema = awsToJsonTypes?.[field.type as string];
  }
  if (!!fieldSchema) {
    if (field.isArray)
      fieldSchema = S.array()
        .minItems(1)
        .items(fieldSchema as JSONSchema);
    fieldSchema = fieldSchema.required();
    return mapper ? mapper(fieldSchema) : fieldSchema;
  } else {
    throw new Error(`No mapping for ${model.name} field ${JSON.stringify(field, null, 2)}`);
  }
}
export default function createJsonSchema(rootModel?: string): ObjectSchema {
  const result = S.object()
    .definition(
      "models",
      S.raw(
        Object.values(uischema().models).reduce<Record<string, any>>(
          (schema, model) => ({
            ...schema,
            [model.name]: mapModelSubtypes("publishSchema", model).valueOf()
          }),
          {}
        )
      )
    )
    .definition(
      "enums",
      S.raw(
        Object.values(uischema().enums).reduce<Record<string, any>>(
          (schema, enumModel) => ({
            ...schema,
            [enumModel.name]: S.enum(enumModel.values).valueOf()
          }),
          {}
        )
      )
    );
  return rootModel ? result.ref(`#/definitions/models/${rootModel}`) : result;
}
