/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  SelectField,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
import { OffsetAccountEntry } from "../models";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { DataStore } from "aws-amplify/datastore";
export default function OffsetAccountEntryCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    OffsetName: "",
    OffsetDescription: "",
    OffsetDataSource: "",
    GrossOffset: "",
    OffsetPurchaseDate: "",
    OffsetValidityStartDate: "",
    OffsetValidityEndDate: "",
    OffsetSchemeType: "",
    OffsetCertificationSchemeName: "",
  };
  const [OffsetName, setOffsetName] = React.useState(initialValues.OffsetName);
  const [OffsetDescription, setOffsetDescription] = React.useState(
    initialValues.OffsetDescription
  );
  const [OffsetDataSource, setOffsetDataSource] = React.useState(
    initialValues.OffsetDataSource
  );
  const [GrossOffset, setGrossOffset] = React.useState(
    initialValues.GrossOffset
  );
  const [OffsetPurchaseDate, setOffsetPurchaseDate] = React.useState(
    initialValues.OffsetPurchaseDate
  );
  const [OffsetValidityStartDate, setOffsetValidityStartDate] = React.useState(
    initialValues.OffsetValidityStartDate
  );
  const [OffsetValidityEndDate, setOffsetValidityEndDate] = React.useState(
    initialValues.OffsetValidityEndDate
  );
  const [OffsetSchemeType, setOffsetSchemeType] = React.useState(
    initialValues.OffsetSchemeType
  );
  const [OffsetCertificationSchemeName, setOffsetCertificationSchemeName] =
    React.useState(initialValues.OffsetCertificationSchemeName);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setOffsetName(initialValues.OffsetName);
    setOffsetDescription(initialValues.OffsetDescription);
    setOffsetDataSource(initialValues.OffsetDataSource);
    setGrossOffset(initialValues.GrossOffset);
    setOffsetPurchaseDate(initialValues.OffsetPurchaseDate);
    setOffsetValidityStartDate(initialValues.OffsetValidityStartDate);
    setOffsetValidityEndDate(initialValues.OffsetValidityEndDate);
    setOffsetSchemeType(initialValues.OffsetSchemeType);
    setOffsetCertificationSchemeName(
      initialValues.OffsetCertificationSchemeName
    );
    setErrors({});
  };
  const validations = {
    OffsetName: [],
    OffsetDescription: [],
    OffsetDataSource: [],
    GrossOffset: [],
    OffsetPurchaseDate: [],
    OffsetValidityStartDate: [],
    OffsetValidityEndDate: [],
    OffsetSchemeType: [],
    OffsetCertificationSchemeName: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          OffsetName,
          OffsetDescription,
          OffsetDataSource,
          GrossOffset,
          OffsetPurchaseDate,
          OffsetValidityStartDate,
          OffsetValidityEndDate,
          OffsetSchemeType,
          OffsetCertificationSchemeName,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await DataStore.save(new OffsetAccountEntry(modelFields));
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            onError(modelFields, err.message);
          }
        }
      }}
      {...getOverrideProps(overrides, "OffsetAccountEntryCreateForm")}
      {...rest}
    >
      <Heading
        level={4}
        children="Carbon Credit / Greenhouse Gas Offset Account Transaction"
        {...getOverrideProps(overrides, "SectionalElement0")}
      ></Heading>
      <Text
        children="Create a record of a carbon credit / greenhouse gas offset you have purchased."
        {...getOverrideProps(overrides, "SectionalElement1")}
      ></Text>
      <Divider
        orientation="horizontal"
        {...getOverrideProps(overrides, "SectionalElement2")}
      ></Divider>
      <TextField
        label="Offset name"
        isRequired={false}
        isReadOnly={false}
        value={OffsetName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OffsetName: value,
              OffsetDescription,
              OffsetDataSource,
              GrossOffset,
              OffsetPurchaseDate,
              OffsetValidityStartDate,
              OffsetValidityEndDate,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
            };
            const result = onChange(modelFields);
            value = result?.OffsetName ?? value;
          }
          if (errors.OffsetName?.hasError) {
            runValidationTasks("OffsetName", value);
          }
          setOffsetName(value);
        }}
        onBlur={() => runValidationTasks("OffsetName", OffsetName)}
        errorMessage={errors.OffsetName?.errorMessage}
        hasError={errors.OffsetName?.hasError}
        {...getOverrideProps(overrides, "OffsetName")}
      ></TextField>
      <TextAreaField
        label="Offset description"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OffsetName,
              OffsetDescription: value,
              OffsetDataSource,
              GrossOffset,
              OffsetPurchaseDate,
              OffsetValidityStartDate,
              OffsetValidityEndDate,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
            };
            const result = onChange(modelFields);
            value = result?.OffsetDescription ?? value;
          }
          if (errors.OffsetDescription?.hasError) {
            runValidationTasks("OffsetDescription", value);
          }
          setOffsetDescription(value);
        }}
        onBlur={() =>
          runValidationTasks("OffsetDescription", OffsetDescription)
        }
        errorMessage={errors.OffsetDescription?.errorMessage}
        hasError={errors.OffsetDescription?.hasError}
        {...getOverrideProps(overrides, "OffsetDescription")}
      ></TextAreaField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid5")}
      >
        <TextField
          label="Offset data source"
          isRequired={false}
          isReadOnly={false}
          value={OffsetDataSource}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetDescription,
                OffsetDataSource: value,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
              };
              const result = onChange(modelFields);
              value = result?.OffsetDataSource ?? value;
            }
            if (errors.OffsetDataSource?.hasError) {
              runValidationTasks("OffsetDataSource", value);
            }
            setOffsetDataSource(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetDataSource", OffsetDataSource)
          }
          errorMessage={errors.OffsetDataSource?.errorMessage}
          hasError={errors.OffsetDataSource?.hasError}
          {...getOverrideProps(overrides, "OffsetDataSource")}
        ></TextField>
        <TextField
          label="Gross offset"
          isRequired={false}
          isReadOnly={false}
          type="number"
          step="any"
          value={GrossOffset}
          onChange={(e) => {
            let value = isNaN(parseFloat(e.target.value))
              ? e.target.value
              : parseFloat(e.target.value);
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetDescription,
                OffsetDataSource,
                GrossOffset: value,
                OffsetPurchaseDate,
                OffsetValidityStartDate,
                OffsetValidityEndDate,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
              };
              const result = onChange(modelFields);
              value = result?.GrossOffset ?? value;
            }
            if (errors.GrossOffset?.hasError) {
              runValidationTasks("GrossOffset", value);
            }
            setGrossOffset(value);
          }}
          onBlur={() => runValidationTasks("GrossOffset", GrossOffset)}
          errorMessage={errors.GrossOffset?.errorMessage}
          hasError={errors.GrossOffset?.hasError}
          {...getOverrideProps(overrides, "GrossOffset")}
        ></TextField>
      </Grid>
      <TextField
        label="Offset purchase date"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={OffsetPurchaseDate}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OffsetName,
              OffsetDescription,
              OffsetDataSource,
              GrossOffset,
              OffsetPurchaseDate: value,
              OffsetValidityStartDate,
              OffsetValidityEndDate,
              OffsetSchemeType,
              OffsetCertificationSchemeName,
            };
            const result = onChange(modelFields);
            value = result?.OffsetPurchaseDate ?? value;
          }
          if (errors.OffsetPurchaseDate?.hasError) {
            runValidationTasks("OffsetPurchaseDate", value);
          }
          setOffsetPurchaseDate(value);
        }}
        onBlur={() =>
          runValidationTasks("OffsetPurchaseDate", OffsetPurchaseDate)
        }
        errorMessage={errors.OffsetPurchaseDate?.errorMessage}
        hasError={errors.OffsetPurchaseDate?.hasError}
        {...getOverrideProps(overrides, "OffsetPurchaseDate")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid7")}
      >
        <TextField
          label="Offset validity start date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={OffsetValidityStartDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetDescription,
                OffsetDataSource,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetValidityStartDate: value,
                OffsetValidityEndDate,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
              };
              const result = onChange(modelFields);
              value = result?.OffsetValidityStartDate ?? value;
            }
            if (errors.OffsetValidityStartDate?.hasError) {
              runValidationTasks("OffsetValidityStartDate", value);
            }
            setOffsetValidityStartDate(value);
          }}
          onBlur={() =>
            runValidationTasks(
              "OffsetValidityStartDate",
              OffsetValidityStartDate
            )
          }
          errorMessage={errors.OffsetValidityStartDate?.errorMessage}
          hasError={errors.OffsetValidityStartDate?.hasError}
          {...getOverrideProps(overrides, "OffsetValidityStartDate")}
        ></TextField>
        <TextField
          label="Offset validity end date"
          isRequired={false}
          isReadOnly={false}
          type="date"
          value={OffsetValidityEndDate}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                OffsetName,
                OffsetDescription,
                OffsetDataSource,
                GrossOffset,
                OffsetPurchaseDate,
                OffsetValidityStartDate,
                OffsetValidityEndDate: value,
                OffsetSchemeType,
                OffsetCertificationSchemeName,
              };
              const result = onChange(modelFields);
              value = result?.OffsetValidityEndDate ?? value;
            }
            if (errors.OffsetValidityEndDate?.hasError) {
              runValidationTasks("OffsetValidityEndDate", value);
            }
            setOffsetValidityEndDate(value);
          }}
          onBlur={() =>
            runValidationTasks("OffsetValidityEndDate", OffsetValidityEndDate)
          }
          errorMessage={errors.OffsetValidityEndDate?.errorMessage}
          hasError={errors.OffsetValidityEndDate?.hasError}
          {...getOverrideProps(overrides, "OffsetValidityEndDate")}
        ></TextField>
      </Grid>
      <SelectField
        label="Offset scheme type"
        placeholder="Please select an option"
        isDisabled={false}
        value={OffsetSchemeType}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OffsetName,
              OffsetDescription,
              OffsetDataSource,
              GrossOffset,
              OffsetPurchaseDate,
              OffsetValidityStartDate,
              OffsetValidityEndDate,
              OffsetSchemeType: value,
              OffsetCertificationSchemeName,
            };
            const result = onChange(modelFields);
            value = result?.OffsetSchemeType ?? value;
          }
          if (errors.OffsetSchemeType?.hasError) {
            runValidationTasks("OffsetSchemeType", value);
          }
          setOffsetSchemeType(value);
        }}
        onBlur={() => runValidationTasks("OffsetSchemeType", OffsetSchemeType)}
        errorMessage={errors.OffsetSchemeType?.errorMessage}
        hasError={errors.OffsetSchemeType?.hasError}
        {...getOverrideProps(overrides, "OffsetSchemeType")}
      >
        <option
          children="Certified"
          value="Certified"
          {...getOverrideProps(overrides, "OffsetSchemeTypeoption0")}
        ></option>
        <option
          children="Verified"
          value="Verified"
          {...getOverrideProps(overrides, "OffsetSchemeTypeoption1")}
        ></option>
      </SelectField>
      <TextField
        label="Offset certification scheme name"
        isRequired={false}
        isReadOnly={false}
        value={OffsetCertificationSchemeName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              OffsetName,
              OffsetDescription,
              OffsetDataSource,
              GrossOffset,
              OffsetPurchaseDate,
              OffsetValidityStartDate,
              OffsetValidityEndDate,
              OffsetSchemeType,
              OffsetCertificationSchemeName: value,
            };
            const result = onChange(modelFields);
            value = result?.OffsetCertificationSchemeName ?? value;
          }
          if (errors.OffsetCertificationSchemeName?.hasError) {
            runValidationTasks("OffsetCertificationSchemeName", value);
          }
          setOffsetCertificationSchemeName(value);
        }}
        onBlur={() =>
          runValidationTasks(
            "OffsetCertificationSchemeName",
            OffsetCertificationSchemeName
          )
        }
        errorMessage={errors.OffsetCertificationSchemeName?.errorMessage}
        hasError={errors.OffsetCertificationSchemeName?.hasError}
        {...getOverrideProps(overrides, "OffsetCertificationSchemeName")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
